import { isValidElement } from "react";
import {
  PositioningShorthand,
  Tooltip as FluentUITooltip,
  TooltipProps as FluentUITooltipProps,
} from "@fluentui/react-components";

import { TranslationKey, isTranslationKey } from "i18n";
import { useTranslation } from "hooks";
import "./Tooltip.scss";
import ConditionalWrapper from "components/conditionalWrapper";

export type TooltipProps = Omit<FluentUITooltipProps, "relationship"> & {
  children: JSX.Element | null;
  content: TranslationKey | string | JSX.Element;
  notTranslatable?: boolean;
  hasJSXContent?: boolean;
  enabled?: boolean;
  isTextBold?: boolean;
  wrapperClassName?: string;
  className?: string;
  childrenClassName?: string;
  positioning?: PositioningShorthand;
  appearance?: FluentUITooltipProps["appearance"];
};

export default function Tooltip({
  children,
  content,
  notTranslatable = false,
  hasJSXContent = false,
  enabled = true,
  isTextBold = false,
  wrapperClassName = "",
  className,
  childrenClassName = "",
  positioning = "above",
  appearance = "inverted",
  ...rest
}: TooltipProps) {
  const { ts } = useTranslation();
  let contentText: string | JSX.Element;

  if (!enabled) {
    return children;
  }

  if (
    notTranslatable &&
    typeof content !== "string" &&
    !isValidElement(content)
  ) {
    throw new Error("Content must be a string if not translatable");
  } else if (
    !notTranslatable &&
    typeof content === "string" &&
    !isTranslationKey(content)
  ) {
    throw new Error("Content must be a translation key if translatable");
  }

  if (isValidElement(content)) {
    contentText = content;
  } else {
    contentText = notTranslatable ? content : ts(content as TranslationKey);
  }
  if (!contentText) {
    return children;
  }

  const tooltipContent =
    hasJSXContent && !isValidElement(content) ? (
      <div
        className={`tooltip-v9-content ${isTextBold ? "text-bold" : ""}`}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: contentText as string }}
      />
    ) : (
      contentText
    );

  return (
    <ConditionalWrapper
      condition={!!wrapperClassName}
      wrapper={<span className={wrapperClassName} />}
    >
      <FluentUITooltip
        content={{ className, children: tooltipContent }}
        relationship="label"
        positioning={positioning}
        withArrow
        appearance={appearance}
        {...rest}
      >
        <span className={`pointer-events-visible ${childrenClassName}`}>
          {children}
        </span>
      </FluentUITooltip>
    </ConditionalWrapper>
  );
}
