export enum TabViewValueEnum {
  ALL = "all",
  ACTIVE = "active",
  COLLAPSED = "collapsed",
  TabViewValueEnum = "TabViewValueEnum",
}

export enum SearchByEnum {
  MyCustomers = "0",
  AllCustomers = "1",
  Employee = "2",
}
