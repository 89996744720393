import AppConfig from "app-config";
import { Outlet } from "react-router-dom";

import CustomerPageLayout from "views/conveyorBelt/components/customerPage";
import CustomerPageBalancesPage from "views/conveyorBelt/components/customerPage/customerPageBalances/customerPageBalancesPage";
import { CustomerPageContactsPage } from "views/conveyorBelt/components/customerPage/customerPageContacts/customerPageContactsPage";
import { CustomerPageDetailedInfoPage } from "views/conveyorBelt/components/customerPage/customerPageDetailedInfo/customerPageDetailedInfoPage";
import { CustomerPageKYCPage } from "views/conveyorBelt/components/customerPage/customerPageKYCYearlyEvaluation/CustomerPageKYCPage";
import CustomerMainPage from "views/conveyorBelt/components/customerPage/customerPageMainPage";
import CustomerPagePowerOfAttorneyPage from "views/conveyorBelt/components/customerPage/customerPagePowerOfAttorney/customerPagePowerOfAttorneyPage";
import CustomerPageServiceNotePage from "views/conveyorBelt/components/customerPage/customerPageServiceNotes/customerPageServiceNotePage";
import CustomerSettingsPguPage from "views/conveyorBelt/components/customerPage/tables/customerSettingsPguPage";
import CustomerSettingsPage from "views/conveyorBelt/components/customerSettings";
import EditCustomerAssignments from "views/conveyorBelt/components/editActivities";
import AssignmentManagement from "views/conveyorBelt";
import AssignmentServiceInstance from "views/conveyorBelt/components/serviceActivities";
import { CustomerSimulationDeliveriesPage } from "views/conveyorBelt/components/customerPage/components/customerSimulationDeliveries";
import { FortnoxAdminPage } from "views/admin/fortnoxAdmin";
import CreateNew from "views/createNew";
import BusinessOpportunityWizard from "views/createNew/businessOpportunity";
import { CreateBusinessOpportunityContextProvider } from "views/createNew/businessOpportunity/context/CreateBusinessOpportunityContext";
import businessOpportunityWizardRoutes from "views/createNew/businessOpportunity/routes/BusinessOpportunityWizardRoutes";
import CreateNewCustomer from "views/createNew/customers";
import { CreateCustomerContextProvider } from "views/createNew/customers/context/CreateCustomerContext";
import createCustomerWizardRoutes from "views/createNew/customers/routes/createCustomerWizardRoutes";
import CustomerDetails from "views/conveyorBelt/components/customerProjects";
import { LudvigServiceFeedbackForm } from "views/conveyorBelt/components/ludvigServiceFeedback";
import OtherApplications from "views/otherApplications";
import OfferWizard from "views/createNew/offer";
import { ServicesProvider } from "views/createNew/offer/wizard/Services/ServicesContextProvider";
import { Deals } from "views/myBusinessOpportunities";
import CustomerDeliveryPlanner from "views/deliveryPlanner";
import { CustomerDeliveryPlannerConfiguration } from "views/deliveryPlanner/components/configuration";
import { FirstExample } from "views/fluentUi";
import { HomePage } from "views/home";
import { ManageCustomersContextProvider } from "views/management/context/ManageCustomersContext";
import manageCustomersRoutes from "views/management/routes/manageCustomersRoutes";
import { ReportsPage } from "views/admin/reports";
import offerRoutes from "views/createNew/offer/routes/offerRoutes";
import FailedKYCCustomers from "views/kycAdministration/FailedKYCCustomers";
import { ExternalKYCPage } from "views/conveyorBelt/components/customerPage/customerPageExternalKYC/ExternalKYCPage";
import { NewKYCPage } from "views/conveyorBelt/components/newKYC";


export type CustomerDetailsParams = {
  customerId: string;
  jobnumber: string;
  purposename: string;
  details: string;
  detailsTaskName: string;
  partialInvoice: string;
};

export type ServicesParams = {
  serviceId: string;
};

export type RouteConfig = {
  path: string;
  element: JSX.Element;
  subRoutes: RouteConfig[];
};

type UrlParamType = string | number;

export const AppRouteHelper = {
  getAssignmentsServices: (
    customerId: UrlParamType = ":customerId",
    serviceId: UrlParamType = ":serviceId"
  ) => `/assignments/${customerId}/services/${serviceId}`,
  getAssignmentsEdit: (customerId: UrlParamType = ":customerId") =>
    `/assignments/${customerId}/edit`,
  getAssignments: (focusedClient?: string) => {
    let queryParams = "";
    if (focusedClient) {
      queryParams += `focusedClient=${focusedClient}&`;
    }
    return queryParams ? `/assignments?${queryParams}` : "/assignments";
  },
  getNewKYCPage: (customerId: UrlParamType = ":customerId") =>
    `/assignments/${customerId}/generate-new-kyc`,
  getCustomers: (focusedClient?: string) =>
    focusedClient ? `/customers?focusedClient=${focusedClient}` : "/customers",
  getCreateCustomer: () => "/create-customer",
  getCustomerPguPage: (customerId: UrlParamType = ":customerId") =>
    `/assignments/customers/${customerId}/pgu`,
  getAssignmentTable: (
    customerId: UrlParamType = ":customerId",
    jobnumber: UrlParamType = ":jobnumber"
  ) =>
    `/assignments/customers/${customerId}/pgu/jobnumber/${jobnumber}/assignment`,
  getFinancialStatementsTable: (
    customerId: UrlParamType = ":customerId",
    jobnumber: UrlParamType = ":jobnumber",
    purposename: UrlParamType = ":purposename"
  ) =>
    `/assignments/customers/${customerId}/pgu/jobnumber/${jobnumber}/purposename/${purposename}/financial_statements`,
  getPartiallyInvoicedTable: (
    customerId: UrlParamType = ":customerId",
    jobnumber: UrlParamType = ":jobnumber",
    partialInvoice: UrlParamType = ":partialInvoice"
  ) =>
    `/assignments/customers/${customerId}/pgu/jobnumber/${jobnumber}/partialInvoice/${partialInvoice}/partially-invoiced-amount`,
  getPGUDetailsTable: (
    customerId: UrlParamType = ":customerId",
    jobnumber: UrlParamType = ":jobnumber",
    details: UrlParamType = ":details"
  ) =>
    `/assignments/customers/${customerId}/pgu/jobnumber/${jobnumber}/details/${details}/details`,
  getDetailsPerTask: (
    customerId: UrlParamType = ":customerId",
    jobnumber: UrlParamType = ":jobnumber",
    detailsTaskName: UrlParamType = ":detailsTaskName"
  ) =>
    `/assignments/customers/${customerId}/pgu/jobnumber/${jobnumber}/detailsTaskName/${detailsTaskName}/details-per-task`,
  getCustomerDetails: (customerId: UrlParamType = ":customerId") =>
    `/assignments/customers/${customerId}`,
  getCustomerSettings: (customerId: UrlParamType = ":customerId") =>
    `/assignments/customers/${customerId}/customer-settings`,
  getCustomerPage: (customerId: UrlParamType = ":customerId") =>
    `/assignments/customers/${customerId}/customer-page`,
  getSimulateDeliveriesPage: (customerId: UrlParamType = ":customerId") =>
    `/simulations/${customerId}`,
  getCustomerPageSpecificInformationPage: (
    customerId: UrlParamType = ":customerId"
  ) => `/assignments/customers/${customerId}/customer-page/detailed-info`,
  getCustomerPageContactsPage: (customerId: UrlParamType = ":customerId") =>
    `/assignments/customers/${customerId}/customer-page/contacts`,
  getCustomerServiceNotePage: (customerId: UrlParamType = ":customerId") =>
    `/assignments/customers/${customerId}/customer-page/service-notes`,
  getCustomerPowerOfAttorneyPage: (customerId: UrlParamType = ":customerId") =>
    `/assignments/customers/${customerId}/customer-page/power-of-attorney`,
  getCustomerDeliveryPlanner: (customerId: UrlParamType = ":customerId") =>
    `/assignments/customers/${customerId}/delivery_planner`,
  getCustomerDeliveryPlannerPackages: (
    customerId: UrlParamType = ":customerId",
    serviceId: UrlParamType = ":serviceId"
  ) =>
    `/assignments/customers/${customerId}/delivery_planner/${serviceId}/packages`,
  getCustomerLudvigServiceFeedback: (
    customerId: UrlParamType = ":customerId",
    serviceId: UrlParamType = ":serviceId"
  ) =>
    `/assignments/customers/${customerId}/ludvig_service_feedback/${serviceId}`,
  getDeals: () => "/deals",
  getOffers: () => "/offers",
  getCreateNew: () => "/create-new",
  getHome: () => "/home",
  getFortnoxAdmin: () => "/fortnox-admin",
  getBiReports: () => "/bi-reports",
  getPowerBiUrl: () => AppConfig.POWER_BI_URL,
  getCustomerBalancesInvoicesPaymentsPage: (
    customerId: UrlParamType = ":customerId"
  ) => `/assignments/customers/${customerId}/balances-invoices-payments`,
  getKYCYearlyEvaluationsPage: (customerId: UrlParamType = ":customerId") =>
    `/assignments/customers/${customerId}/customer-page/kyc-evaluations`,
  getOtherApplications: () => "/other-applications",
  getExternalKYCPage: (customerId: UrlParamType = ":customerId") =>
    `/customers/${customerId}/customer-page/external-kyc`,
  getMittKontor: () => "https://mittkontor.ludvig.se/login",
  getIntranat: () => "https://intranet.ludvig.se/",
  getFortnox: () => "https://apps.fortnox.se/fs/fs/login.php",
  getFeatureVoting: () =>
    "https://intranet.ludvig.se/chefssidorna/ekonomisk-uppfoljning/ny-utformning-av-resultatrapporten/forbattringsforslag-ludvig-bi/",
  getBusinessOpportunity: () => "/business-opportunity",
  getFailedKYCCUstomers: () => "/kyc-administration",
  getManageCustomers: () => "/manage-customers",
  getCustomerMaconomyUrl: (customerId: UrlParamType = ":customerId") =>
    `${AppConfig.MACONOMY_URL}/workspace/customers;CustomerNumber=${customerId}`,
};

const routes: RouteConfig[] = [
  {
    path: AppRouteHelper.getHome(),
    element: <HomePage />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getCreateNew(),
    element: <CreateNew />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getFortnoxAdmin(),
    element: <FortnoxAdminPage />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getManageCustomers(),
    element: (
      <ManageCustomersContextProvider>
        <Outlet />
      </ManageCustomersContextProvider>
    ),
    subRoutes: manageCustomersRoutes,
  },
  {
    path: AppRouteHelper.getBiReports(),
    element: <ReportsPage />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getFailedKYCCUstomers(),
    element: <FailedKYCCustomers />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getAssignmentTable(),
    element: (
      <CustomerPageLayout>
        <CustomerSettingsPguPage tableType="assignment" />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getFinancialStatementsTable(),
    element: (
      <CustomerPageLayout>
        <CustomerSettingsPguPage tableType="financial_statements" />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getPartiallyInvoicedTable(),
    element: (
      <CustomerPageLayout>
        <CustomerSettingsPguPage tableType="partial_invoice" />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getPGUDetailsTable(),
    element: (
      <CustomerPageLayout>
        <CustomerSettingsPguPage tableType="detailed_table" />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getDetailsPerTask(),
    element: (
      <CustomerPageLayout>
        <CustomerSettingsPguPage tableType="detailed_per_task" />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getAssignmentsServices(),
    element: <AssignmentServiceInstance />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getAssignmentsEdit(),
    element: <EditCustomerAssignments />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getAssignments(),
    element: <AssignmentManagement />,
    subRoutes: [],
  },

  {
    path: AppRouteHelper.getCreateCustomer(),
    element: (
      <CreateCustomerContextProvider>
        <CreateNewCustomer />
      </CreateCustomerContextProvider>
    ),
    subRoutes: createCustomerWizardRoutes,
  },
  {
    path: AppRouteHelper.getCustomerDetails(),
    element: <CustomerDetails />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getCustomerDeliveryPlanner(),
    element: <CustomerDeliveryPlanner />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getCustomerDeliveryPlannerPackages(),
    element: <CustomerDeliveryPlannerConfiguration />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getDeals(),
    element: <Deals />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getCustomerLudvigServiceFeedback(),
    element: <LudvigServiceFeedbackForm />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getOffers(),
    element: (
      <ServicesProvider>
        <OfferWizard />
      </ServicesProvider>
    ),
    subRoutes: offerRoutes,
  },
  {
    path: AppRouteHelper.getBusinessOpportunity(),
    element: (
      <CreateBusinessOpportunityContextProvider>
        <BusinessOpportunityWizard />
      </CreateBusinessOpportunityContextProvider>
    ),
    subRoutes: businessOpportunityWizardRoutes,
  },
  {
    path: AppRouteHelper.getCustomerPage(),
    element: (
      <CustomerPageLayout>
        <CustomerMainPage />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getCustomerPageSpecificInformationPage(),
    element: (
      <CustomerPageLayout>
        <CustomerPageDetailedInfoPage />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getSimulateDeliveriesPage(),
    element: (
      <CustomerPageLayout>
        <CustomerSimulationDeliveriesPage />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getCustomerPageContactsPage(),
    element: (
      <CustomerPageLayout>
        <CustomerPageContactsPage />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getCustomerSettings(), // change with new Settings Page
    element: <CustomerSettingsPage />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getCustomerServiceNotePage(),
    element: (
      <CustomerPageLayout>
        <CustomerPageServiceNotePage />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getCustomerPowerOfAttorneyPage(),
    element: (
      <CustomerPageLayout>
        <CustomerPagePowerOfAttorneyPage />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },

  {
    path: AppRouteHelper.getKYCYearlyEvaluationsPage(),
    element: (
      <CustomerPageLayout>
        <CustomerPageKYCPage />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },

  {
    path: AppRouteHelper.getExternalKYCPage(),
    element: (
      <CustomerPageLayout>
        <ExternalKYCPage />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },

  {
    path: AppRouteHelper.getCustomerBalancesInvoicesPaymentsPage(),
    element: <CustomerPageBalancesPage />,
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getCustomerPguPage(),
    element: (
      <CustomerPageLayout>
        <CustomerSettingsPguPage tableType="main" />
      </CustomerPageLayout>
    ),
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getOtherApplications(),
    element: <OtherApplications />,
    subRoutes: [],
  },
  {
    path: "/fluentui",
    element: (
      <>
        <div>Examples:</div>
        <FirstExample />
      </>
    ),
    subRoutes: [],
  },
  {
    path: AppRouteHelper.getNewKYCPage(),
    element: <NewKYCPage />,
    subRoutes: [],
  },
  {
    path: "*",
    element: <p>No such route!</p>,
    subRoutes: [],
  },
];

export default routes;
