import { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ArrowRight24Regular, ArrowLeft24Regular } from "@fluentui/react-icons";

import Tooltip from "components/tooltip/Tooltip";

type Props = {
  children: JSX.Element[];
  prevTooltipContent: string;
  nextTooltipContent: string;
};

const LG_BREAKPOINT = 1150;
const XL_BREAKPOINT = 1650;

export default function PickerCarousel({
  children,
  prevTooltipContent,
  nextTooltipContent,
}: Props) {
  const pickerCarouselRef = useRef<HTMLDivElement | null>(null);
  const [wrapperSize, setWrapperSize] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const sidebarState = localStorage.getItem("sidebar-state");
  const isLargeScreen = wrapperSize > LG_BREAKPOINT;
  const isXLScreen = wrapperSize > XL_BREAKPOINT;
  const maxPickerSizeXL = isXLScreen ? 5 : 4;
  const hasPrevious = currentIndex === 0;
  const hasNext =
    currentIndex + (isLargeScreen ? maxPickerSizeXL : 3) >= children.length;

  useEffect(() => {
    const handleResize = () => {
      if (pickerCarouselRef.current) {
        setWrapperSize(pickerCarouselRef.current.offsetWidth);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isInteractive = useMemo(() => {
    switch (true) {
      case isLargeScreen && !isXLScreen && children.length > 4:
      case !isLargeScreen && !isXLScreen && children.length > 3:
      case !isLargeScreen && isXLScreen && children.length > 5:
        return true;
      default:
        return false;
    }
  }, [isLargeScreen, isXLScreen, children.length]);

  const renderChildren = useMemo(() => {
    if (isLargeScreen && !isXLScreen) {
      return children.slice(currentIndex, currentIndex + 4);
    }
    if (sidebarState === "collapsed" && isXLScreen) {
      return children.slice(currentIndex, currentIndex + 5);
    }
    return children.slice(currentIndex, currentIndex + 3);
  }, [isLargeScreen, isXLScreen, currentIndex, children, sidebarState]);

  const handleNextClick = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < children.length) {
      setCurrentIndex(nextIndex);
    }
  };

  const handlePrevClick = () => {
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      setCurrentIndex(prevIndex);
    }
  };

  return (
    <Row ref={pickerCarouselRef} className="minHeight-100">
      <Col xl={1} lg={1} className="pt-lg m-auto d-flex justify-content-center">
        {isInteractive ? (
          <Tooltip
            content={prevTooltipContent}
            notTranslatable
            enabled={!hasPrevious}
          >
            <ArrowLeft24Regular
              className={`carousel-arrow ${hasPrevious ? "disabled" : ""}`}
              onClick={handlePrevClick}
            />
          </Tooltip>
        ) : (
          <div className="horizontal-divider w-100" />
        )}
      </Col>
      <Col xl={8} lg={10} className="m-auto">
        <div
          className={`d-flex flex-nowrap justify-content-center ${
            renderChildren.length > 3 ? "fg-4" : "fg-3"
          }`}
        >
          {renderChildren}
        </div>
      </Col>
      <Col xl={1} lg={1} className="pt-lg m-auto d-flex justify-content-center">
        {isInteractive ? (
          <Tooltip
            content={nextTooltipContent}
            notTranslatable
            enabled={!hasNext}
          >
            <ArrowRight24Regular
              className={`carousel-arrow ${hasNext ? "disabled" : ""}`}
              onClick={handleNextClick}
            />
          </Tooltip>
        ) : (
          <div className="horizontal-divider w-100" />
        )}
      </Col>
    </Row>
  );
}
