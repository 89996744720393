/* eslint-disable max-classes-per-file */
import { Type } from "class-transformer";
import { IKycQuestion, kycQuestions } from "constants/offer/kyc-questions";

export class KYC {
  org_no: string;

  has_beneficial_owner = false;

  person_is_on_pep_list = false;

  person_is_on_sanctions_list = false;

  residence_is_high_risk_country = false;

  company_operations_text = "";

  accept_risk_level = false;

  @Type(() => Date)
  modified?: Date;

  @Type(() => Date)
  created?: Date;
}

export class KYCCached {
  org_no: string;

  has_beneficial_owner: boolean;

  person_is_on_pep_list: boolean;

  person_is_on_sanctions_list: boolean;

  residence_is_high_risk_country: boolean;

  company_operations_text: string;

  accept_risk_level: boolean;

  @Type(() => Date)
  modified: Date;

  @Type(() => Date)
  created: Date;
}

export function getWeightPoints(kyc: Partial<KYC>) {
  return kycQuestions.reduce((weight, q) => {
    const questionWeight = q.weighting[getQuestionValue(kyc, q) ? "yes" : "no"];
    return weight + questionWeight;
  }, 0);
}

export function getQuestionValue(kyc: Partial<KYC>, question: IKycQuestion) {
  const kycIsNotEmpty = kyc && Object.keys(kyc ?? {}).length !== 0;
  if (kycIsNotEmpty) {
    const { [question.key]: answer } = kyc;
    return answer === true ? 1 : 0;
  }
  return 0;
}

export function isKYCDataExpiredForCustomer(kyc: KYCCached) {
  if (!kyc.created) {
    return false;
  }

  const compareTo = new Date();
  compareTo.setMonth(compareTo.getMonth() - 18);

  const lastKyc = new Date(kyc.created);
  return lastKyc < compareTo;
}

export function getLatestKYC(kyc: KYCCached[]) {
  return kyc.sort(
    (a, b) => new Date(b.created).getDate() - new Date(a.created).getDate()
  )[0];
}
