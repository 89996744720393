import {
  Deal,
  DealContact,
  DealOption,
  DynamicPropertyOption,
} from "models/deals/deal";
import { Pipeline } from "models/deals/pipeline";
import { Product } from "models/deals/product";
import { sortDealsByEnteredCurrentStageDate } from "libs/sort-deals-by-date";
import { StageConfiguration } from "models/deals/stagesConfig";
import {
  APPEND_DEAL,
  CREATE_NEW_CONTACT,
  REMOVE_DEAL,
  RESET_CURRENT_DEAL,
  RESET_CUSTOMER_CONTACTS,
  SET_CURRENT_DEAL,
  SET_CUSTOMER_CONTACTS,
  SET_CUSTOMER_CONTACTS_LOADING,
  SET_DEALS,
  SET_DEALS_BY_ORG_NUMBER,
  SET_DEALS_BY_ORG_NUMBER_HAS_FAILED,
  SET_DEAL_PROPERTIES,
  SET_DYNAMIC_DEAL_PROPERTIES,
  SET_HAS_FAILED,
  SET_IS_NEW_DEAL_CUSTOMER,
  SET_LOADING_DEALS,
  SET_LOADING_DEALS_BY_ORG_NUMBER,
  SET_LOADING_DEAL_OPTIONS,
  SET_LOADING_PIPELINES,
  SET_LOADING_PRODUCTS,
  SET_PIPELINES,
  SET_PRODUCTS,
  SET_STAGE_CONFIGURATION,
  SalesAction,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  UPDATE_DEAL,
  UPDATE_DEAL_CONTACT,
} from ".";

export type SalesState = {
  data: Deal[];
  isLoading: boolean;
  hasLoaded: boolean;
  hasFailed: boolean;

  dealsByOrgNumber: {
    data: Deal[];
    isLoading: boolean;
    hasFailed: boolean;
  };

  dealOptions: {
    data: DealOption[];
    isLoading: boolean;
    hasFailed: boolean;
  };

  pipelines: {
    data: Pipeline[];
    isLoading: boolean;
    hasFailed: boolean;
  };

  products: {
    data: Product[];
    isLoading: boolean;
    hasFailed: boolean;
  };

  stageConfiguration: {
    data: StageConfiguration[];
    isLoading: boolean;
    hasFailed: boolean;
  };
  currentDeal?: Deal;
  customerContacts: {
    data: DealContact[];
    isLoading: boolean;
  };
  isNewCustomer?: boolean;

  dynamicDealProperties: {
    data: Record<string, DynamicPropertyOption[]>;
  };
};

const initialState: SalesState = {
  data: [],
  isLoading: false,
  hasFailed: false,
  hasLoaded: false,
  dealsByOrgNumber: {
    data: [],
    isLoading: false,
    hasFailed: false,
  },
  dealOptions: {
    data: [],
    hasFailed: false,
    isLoading: false,
  },
  pipelines: {
    data: [],
    hasFailed: false,
    isLoading: false,
  },
  products: {
    data: [],
    hasFailed: false,
    isLoading: false,
  },
  stageConfiguration: {
    data: [],
    hasFailed: false,
    isLoading: false,
  },
  currentDeal: undefined,
  customerContacts: {
    data: [],
    isLoading: false,
  },
  isNewCustomer: false,

  dynamicDealProperties: {
    data: {},
  },
};

export function salesReducer(state = initialState, action: SalesAction) {
  const { data } = state;

  switch (action.type) {
    case SET_PRODUCTS: {
      return {
        ...state,
        products: { data: action.payload, isLoading: false, hasFailed: false },
      };
    }
    case SET_DEAL_PROPERTIES: {
      return {
        ...state,
        dealOptions: {
          data: action.payload,
          isLoading: false,
          hasFailed: false,
        },
      };
    }
    case SET_STAGE_CONFIGURATION: {
      return {
        ...state,
        stageConfiguration: {
          data: action.payload,
          hasFailed: false,
          isLoading: false,
        },
      };
    }
    case SET_PIPELINES: {
      const pipelines = action.payload.map((pipeline) => {
        // Add the stage configuration to each stage
        return {
          ...pipeline,
          stages: pipeline.stages.map((s) => {
            const configuration = state.stageConfiguration.data.find(
              (sc) => sc.StageId === s.id
            );

            return { ...s, configuration };
          }),
        };
      });

      return {
        ...state,
        pipelines: { data: pipelines, isLoading: false, hasFailed: false },
      };
    }
    case SET_DEALS: {
      return {
        ...state,
        data: action.payload,
        hasFailed: false,
        hasLoaded: true,
      };
    }
    case SET_DEALS_BY_ORG_NUMBER: {
      return {
        ...state,
        dealsByOrgNumber: {
          data: action.payload,
          isLoading: false,
          hasFailed: false,
        },
      };
    }
    case SET_HAS_FAILED: {
      return {
        ...state,
        hasFailed: action.payload,
      };
    }
    case SET_DEALS_BY_ORG_NUMBER_HAS_FAILED: {
      return {
        ...state,
        dealsByOrgNumber: {
          ...state.dealsByOrgNumber,
          isLoading: false,
          hasFailed: action.payload,
        },
      };
    }
    case SET_LOADING_DEALS: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case SET_LOADING_DEAL_OPTIONS: {
      return {
        ...state,
        dealOptions: { ...state.dealOptions, isLoading: action.payload },
      };
    }
    case SET_LOADING_PIPELINES: {
      return {
        ...state,
        pipelines: { ...state.pipelines, isLoading: action.payload },
      };
    }
    case SET_LOADING_PRODUCTS: {
      return {
        ...state,
        products: { ...state.products, isLoading: action.payload },
      };
    }
    case SET_LOADING_DEALS_BY_ORG_NUMBER: {
      return {
        ...state,
        dealsByOrgNumber: {
          ...state.dealsByOrgNumber,
          isLoading: action.payload,
        },
      };
    }
    case UPDATE_DEAL: {
      const existing = data.find((deal) => deal.id === action.payload.id);
      const updatedDeal = { ...existing, ...action.payload.update };
      const updatedDeals = [
        updatedDeal,
        ...data.filter((deal) => deal.id !== updatedDeal.id),
      ];
      return {
        ...state,
        data: sortDealsByEnteredCurrentStageDate(updatedDeals as Deal[]),
      };
    }
    case APPEND_DEAL: {
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    }
    case REMOVE_DEAL: {
      const filtered = data.filter((d) => d.id !== action.payload.id);
      return {
        ...state,
        data: filtered,
      };
    }
    case UPDATE_DEAL_CONTACT: {
      const existing = data.find((d) => d.id === action.payload.id);
      if (existing) {
        const contact = { ...existing.contact, ...action.payload.update };
        const deal = { ...existing, contact };
        return {
          ...state,
          data: [deal, ...data.filter((d) => d.id !== deal.id)],
        };
      }
      return {
        ...state,
        data: [...state.data],
      };
    }
    case SET_CURRENT_DEAL: {
      return {
        ...state,
        currentDeal: { ...state.currentDeal, ...action.payload.deal },
      };
    }
    case RESET_CURRENT_DEAL: {
      return {
        ...state,
        currentDeal: undefined,
      };
    }
    case SET_CUSTOMER_CONTACTS_LOADING: {
      return {
        ...state,
        customerContacts: {
          ...state.customerContacts.data,
          isLoading: action.payload,
        },
      };
    }
    case SET_CUSTOMER_CONTACTS: {
      return {
        ...state,
        customerContacts: {
          isLoading: false,
          data: action.payload,
        },
      };
    }
    case RESET_CUSTOMER_CONTACTS: {
      return {
        ...state,
        customerContacts: {
          isLoading: false,
          data: [],
        },
      };
    }
    case CREATE_NEW_CONTACT: {
      // need to check if its an existing contact since we are using create contact for existing contacts as well
      // needed for POG-49 where we need to associate an existing Hubspot contact with a company
      const existingContact = state.customerContacts.data.find(
        (contact) => contact.id === action.payload.id
      );
      if (existingContact) {
        const updatedContact = { ...existingContact, ...action.payload };
        return {
          ...state,
          customerContacts: {
            isLoading: false,
            data: state.customerContacts.data.map((contact) =>
              contact.id === existingContact.id ? updatedContact : contact
            ),
          },
        };
      }
      return {
        ...state,
        customerContacts: {
          isLoading: false,
          data: [...state.customerContacts.data, action.payload],
        },
      };
    }
    case UPDATE_CONTACT: {
      const existingContact = state.customerContacts.data.find(
        (contact) => contact.id === action.payload.id
      );
      if (existingContact) {
        const updatedContact = { ...existingContact, ...action.payload };
        return {
          ...state,
          customerContacts: {
            isLoading: false,
            data: state.customerContacts.data.map((contact) =>
              contact.id === existingContact.id ? updatedContact : contact
            ),
          },
        };
      }
      return {
        state,
      };
    }
    case DELETE_CONTACT: {
      const contacts = [...state.customerContacts.data];
      const filteredContacts = contacts.filter(
        (contact) => contact.email !== action.payload.contactEmail
      );
      return {
        ...state,
        customerContacts: {
          isLoading: false,
          data: filteredContacts,
        },
      };
    }
    case SET_IS_NEW_DEAL_CUSTOMER: {
      return {
        ...state,
        isNewCustomer: action.payload,
      };
    }
    case SET_DYNAMIC_DEAL_PROPERTIES: {
      const { objectName, propertyName, properties } = action.payload;
      const key = `${objectName}-${propertyName}`;

      return {
        ...state,
        dynamicDealProperties: {
          data: {
            ...state.dynamicDealProperties.data,
            [key]: properties,
          },
        },
      };
    }

    default:
      return state;
  }
}
