import { createContext, useContext } from "react";
import "./Box.scss";
import { BoxHeader } from "./BoxHeader";

export const BoxContext = createContext({ disabled: false });

export function useBoxContext() {
  const context = useContext(BoxContext);

  if (context === undefined) {
    throw new Error("useBoxContext must be used within a Box");
  }

  return context;
}

type BoxProps = {
  title: string;
  children?: unknown;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLElement>;

export default function BoxLayout({ title, children, disabled }: BoxProps) {
  return (
    <BoxContext.Provider value={{ disabled: !!disabled }}>
      <div className={`box${disabled ? " disabled" : ""}`}>
        <BoxHeader>{title}</BoxHeader>
        {children}
      </div>
    </BoxContext.Provider>
  );
}
