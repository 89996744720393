import { Warning16Filled } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { plainToClass } from "class-transformer";
import { SolidAccordion } from "components/accordion/solidAccordion";
import { OverlaySpinner } from "components/spinner";
import {
  createIncomeTaxObjectServices,
  createOrUpdateHSContactInPogOffer,
  getServiceCategoryWithDefaultServices,
} from "helpers/DealToPogHelper";
import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";
import { useServiceMatrix } from "libs/service-matrix";
import { Deal } from "models/deals/deal";
import { DealCustomer } from "models/offer/Customer";
import { ServiceGroup } from "models/offer/ServiceLine";
import { useTranslation } from "hooks/use-translate";
import { TooltipInfoIcon } from "components/icon";
import { RootState } from "state";
import { createOrUpdateCustomer } from "state/offer/companyThunks";
import {
  addOfferSelectedServiceLine,
  addServiceLineGroup,
  resetSelectedCustomerSource,
  setSelectedCustomerSource,
  updateCurrentOffer,
  updateOfferCustomer,
} from "state/offer/offersSlice";
import { resetOffer, setupNewOfferForCustomer } from "state/offer/offersThunks";
import { resetCurrentDeal } from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import CustomerContactsList from "views/createNew/offer/components/customerContactsList";
import Offers from "views/createNew/offer/components/offers";
import { WizardSection } from "views/createNew/offer/components/wizardSection";
import { OfferRouteHelper } from "../../routes/offerRoutes";
import PotentialDeals from "../components/potentialDeals";
import CustomerSource from "../CustomerSource";
import "./Business.scss";
import { useFilterDealsAndContracts } from "./useFilterDealsAndContracts";
import { useFilterPotentialDeals } from "./useFilterPotentialDeals";

export default function Business() {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { data: currentOffer, status } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  const offersStatus = useSelector(
    (state: RootState) => state.offers.createdDeals.status
  );
  const { status: customerContactsStatus, data: customerContacts } =
    useSelector((state: RootState) => state.offers.availableCompanyContacts);
  const {
    dealsByOrgNumber: { isLoading: isPotentialDealsLoading },
    currentDeal,
    products: { data: products },
  } = useSelector((state: RootState) => state.sales);
  const { data: offerTemplate } = useSelector(
    (state: RootState) => state.offers.offerTemplate
  );
  const { currentUser } = useSelector((state: RootState) => state.users);

  const [customerSourceIsOpen, setCustomerSourceIsOpen] = useState(false);
  const [createPogOfferLoading, setCreatePogOfferLoading] = useState(false);

  const filteredPotentialDeals = useFilterPotentialDeals();
  const {
    filteredAndSortedContracts,
    filteredDealsContracts,
    filteredAndSortedOffers,
    filteredDealsOffers,
    totalOffersCount,
    totalContractsCount,
  } = useFilterDealsAndContracts();

  const hasServicesSelected =
    (currentOffer?.service_areas[0].service_lines.length ?? 0) > 0;
  const customer = currentOffer?.customer;
  const dealCustomerUpdatedByUser = !customer?.created_by_username
    ? {
        created_by_username: currentUser.email,
        changed_by_username: currentUser.email,
      }
    : {
        changed_by_username: currentUser.email,
      };

  const { GetAllServiceGroupsFlattened, GetAllServiceLinesFlattened } =
    useServiceMatrix();

  const serviceGroups = GetAllServiceGroupsFlattened(offerTemplate);
  const serviceLines = GetAllServiceLinesFlattened(offerTemplate);

  useEffect(() => {
    if (currentOffer?.new_customer && !hasServicesSelected) {
      dispatch(resetOffer());
    }
  }, [hasServicesSelected, currentOffer, dispatch]);

  useEffect(() => {
    if (customer) {
      dispatch(resetCurrentDeal());
      dispatch(resetSelectedCustomerSource());
      dispatch(setupNewOfferForCustomer(customer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMappedDealProducts = (deal: Deal) => {
    return deal.productIds.map(
      (productId) =>
        products
          ?.find((product) => product.id === productId)
          ?.description?.toLowerCase() ?? ""
    );
  };

  // adding the serviceLine and service groups to the offer
  // (eg. Accounting as service area, Income tax as service group etc.)
  const addServiceLinesAndGroupsToPogOffer = (
    offerDealCustomer: DealCustomer,
    deal: Deal
  ) => {
    const accountingServiceLine = serviceLines.find(
      (serviceLine) => serviceLine.name === ACCOUNTING_SERVICE_LINE
    );
    if (accountingServiceLine) {
      dispatch(addOfferSelectedServiceLine(accountingServiceLine));
    }

    const dealServiceGroups = serviceGroups.filter((group) =>
      getMappedDealProducts(deal).includes(group.name.toLowerCase())
    );
    dealServiceGroups.forEach((serviceGroup) => {
      const groupCatagories = serviceGroup.service_categories
        .filter((category) => category.name)
        .map((category) => {
          const serviceCategory =
            getServiceCategoryWithDefaultServices(category);
          return serviceCategory;
        });

      if (groupCatagories.length > 0) {
        const allServices = groupCatagories.flatMap((sc) => sc.services);

        createIncomeTaxObjectServices(
          allServices,
          offerDealCustomer,
          currentUser,
          dispatch
        );
        const newGroup: ServiceGroup = {
          name: serviceGroup.name,
          service_categories: groupCatagories,
          sorting_number: serviceGroup.sorting_number,
          skip_price_calculation: serviceGroup.skip_price_calculation,
        };
        dispatch(addServiceLineGroup(newGroup));
      }
    });
  };

  const startCreatingPogOffer = async () => {
    const deal = plainToClass(Deal, currentDeal);
    setCreatePogOfferLoading(true);
    try {
      if (customer && customer.org_number) {
        const customerHasAdressCityAndZip =
          !!customer.postal_town &&
          !!customer.postal_code &&
          !!customer.address;

        // adding the serviceLine and service groups to the offer
        // (eg. Accounting as service area, Income tax as service group etc.)
        addServiceLinesAndGroupsToPogOffer(customer, deal);
        // create or update pog contact with deal's contact
        await createOrUpdateHSContactInPogOffer(deal, currentUser, dispatch);
        // set the hubspot_deal_id to the offer to link the new offer with the HS deal
        if (deal.id) {
          dispatch(
            updateCurrentOffer({
              hubspot_deal_id: deal.id,
            })
          );
        }
        dispatch(setSelectedCustomerSource(deal.deal_source));

        if (customerHasAdressCityAndZip) {
          const tempDealCustomerUpdatedByUser = !customer.created_by_username
            ? {
                created_by_username:
                  customer.created_by_username ?? currentUser.email,
                changed_by_username: currentUser.email,
              }
            : {
                changed_by_username: currentUser.email,
              };
          // this is needed to get the tax objects for the customer
          await dispatch(
            createOrUpdateCustomer(
              plainToClass(DealCustomer, {
                ...customer,
                ...tempDealCustomerUpdatedByUser,
              })
            )
          );
          // navigate the user to the offer contacts to select a signer and proceed
          navigate(OfferRouteHelper.getContactInfo());
        } else {
          // navigate the user to the customer details to populate the missing required fields
          navigate(OfferRouteHelper.getRegisterCompany());
        }
        setCreatePogOfferLoading(false);
      }
    } catch (e) {
      dispatch(
        updateCurrentOffer({
          hubspot_deal_id: deal.id,
          new_customer: true,
        })
      );
      dispatch(
        updateOfferCustomer(
          plainToClass(DealCustomer, {
            ...customer,
            ...dealCustomerUpdatedByUser,
            legal_form: deal.company ? "ab" : "pp",
          })
        )
      );
      dispatch(setSelectedCustomerSource(deal.deal_source));
      setCreatePogOfferLoading(false);
      // navigate to offers/customer-type -> register flow
      navigate(OfferRouteHelper.getRegisterCompany());
    }
  };

  const handleOnNext = () => {
    if (currentDeal && !!currentDeal.deal_source) {
      startCreatingPogOffer();
    } else {
      setCustomerSourceIsOpen(true);
    }
  };

  const handleSourceDialogConfirm = () => {
    setCustomerSourceIsOpen(false);
    startCreatingPogOffer();
  };

  return (
    <WizardSection
      loadingStatus={status}
      hideNavigation={!customer}
      isRoutingButton={false}
      onNext={handleOnNext}
      subtitles={[translate("CUSTOMER_DETAILS_SUBHEADER")]}
    >
      {createPogOfferLoading && (
        <OverlaySpinner label={translate("CREATE_POG_OFFER_LOADER")} />
      )}
      <Row className="pt-xl">
        <Col className="bottom-border" />
        <Col className="business-customer-name">
          <h2 className="text-align-center fw-700 text-color-primary">
            {customer?.customer_name}
          </h2>
        </Col>
        <Col className="bottom-border" />
      </Row>
      <Row className="customer-details justify-content-center text-align-center pt-lg">
        <Col lg="auto">
          {translate("ORG_NUM")}:{" "}
          <span className="fw-semibold">{customer?.org_number}</span>
        </Col>
        <Col lg="auto">
          {translate("CUSTOMER_NUM")}:{" "}
          <span className="fw-semibold">
            {customer?.customer_number || (
              <TooltipInfoIcon
                tooltip={{ content: "MISSING_CUSTOMER_NUMBER" }}
                tooltipIcon={<Warning16Filled className="text-color-red" />}
              />
            )}
          </span>
        </Col>
        <Col lg="auto">
          {translate("ADDRESS")}:{" "}
          <span className="fw-semibold">{customer?.address}</span>
        </Col>
        <Col lg="auto">
          {translate("ZIP")}:{" "}
          <span className="fw-semibold">{customer?.postal_code}</span>
        </Col>
        <Col lg="auto">
          {translate("CITY")}:{" "}
          <span className="fw-semibold">{customer?.postal_town}</span>
        </Col>
      </Row>
      <Row className="pt-xl">
        <div className="d-flex align-items-center pb-lg">
          <div className="w-100">
            <Row className="pt-lg">
              <Col md={12} className="m-auto">
                <SolidAccordion
                  title={
                    <>
                      {translate("BUSINESS_OPPORTUNITIES")}{" "}
                      <span className="disabled">
                        ({filteredPotentialDeals.length})
                      </span>
                    </>
                  }
                  subtitles={
                    isPotentialDealsLoading ||
                    filteredPotentialDeals.length === 0
                      ? []
                      : ["|", translate("POTENTIAL_DEALS_ACCORDION_SUBTITLE")]
                  }
                  isInitiallyOpen
                  loadingStatus={isPotentialDealsLoading ? "pending" : ""} // we only care if its loading in order to display a spinner
                >
                  <PotentialDeals
                    filteredPotentialDeals={filteredPotentialDeals}
                  />
                </SolidAccordion>
                <SolidAccordion
                  title={
                    <>
                      {translate("OFFER_OFFERS_LABEL")}{" "}
                      <span className="disabled">({totalOffersCount})</span>
                    </>
                  }
                  loadingStatus={offersStatus}
                >
                  <Offers
                    filteredAndSortedContracts={filteredAndSortedOffers}
                    filteredDeals={filteredDealsOffers}
                    count={totalOffersCount}
                    noDealsFound={translate("OFFERS_NOT_FOUND")}
                  />
                </SolidAccordion>
                <SolidAccordion
                  title={
                    <>
                      {translate("OFFER_CONTRACTS_LABEL")}{" "}
                      <span className="disabled">({totalContractsCount})</span>
                    </>
                  }
                  loadingStatus={offersStatus}
                >
                  <Offers
                    filteredAndSortedContracts={filteredAndSortedContracts}
                    filteredDeals={filteredDealsContracts}
                    count={totalContractsCount}
                    noDealsFound={translate("CONTRACTS_NOT_FOUND")}
                  />
                </SolidAccordion>
                <SolidAccordion
                  title={
                    <>
                      {translate("CONTACTS")}{" "}
                      <span className="disabled">
                        ({customerContacts.length})
                      </span>
                    </>
                  }
                  loadingStatus={customerContactsStatus}
                >
                  <CustomerContactsList
                    contacts={customerContacts ?? []}
                    showNumberOfItems={3}
                    editable
                  />
                </SolidAccordion>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
      <CustomerSource
        isOpen={customerSourceIsOpen}
        customer={customer}
        handleCancel={() => setCustomerSourceIsOpen(false)}
        handleConfirm={handleSourceDialogConfirm}
      />
    </WizardSection>
  );
}
