import { createContext, useContext, useMemo } from "react";
import "./content.scss";

/*

  These components are used to create a structure for the content of a layout. Current setup is:
   _______
  |___2___|
  |   3   |
  |       |
  |_______|
  |___4___|

  0. Content               // This is the wrapper for everything Content
  2. ContentHeader
  3. ContentBody
  4. ContentFooter         // Sticked to bottom of Content

  These components should only be used once for every Layout. If you want to split the content
  into several sections, use the component Section.
  
*/

export const ContentContext = createContext({ isLoading: false });

export function useContentContext() {
  const context = useContext(ContentContext);

  if (context === undefined) {
    throw new Error("useBoxContext must be used within a Box");
  }

  return context;
}

type ContentProps = {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  hasVerticalScroll?: boolean;
};

export function Content({ children, className, isLoading }: ContentProps) {
  const state = useMemo(() => ({ isLoading: !!isLoading }), [isLoading]);

  return (
    <ContentContext.Provider value={state}>
      <div className={`content${!className ? "" : ` ${className}`}`}>
        {children}
      </div>
    </ContentContext.Provider>
  );
}

export function ContentBody({ children, className, hasVerticalScroll=false }: ContentProps) {
  const { isLoading } = useContentContext();
  return (
    <div
      className={`contentBody${!className ? "" : ` ${className}`}${
        isLoading ? " content--loading" : ""
      }${!hasVerticalScroll ? "" : ` overflow-y-auto`}`}
    >
      {children}
    </div>
  );
}

export function ContentBodyCenter({ children, className }: ContentProps) {
  return (
    <div className="h-100 w-100 d-flex align-items-center">
      <div className={`w-100${!className ? "" : ` ${className}`}`}>
        {children}
      </div>
    </div>
  );
}

export function ContentFooter({ children, className }: ContentProps) {
  const { isLoading } = useContentContext();
  return (
    <div
      className={`contentFooter${!className ? "" : ` ${className}`}${
        isLoading ? " content--loading" : ""
      }`}
    >
      {children}
    </div>
  );
}
