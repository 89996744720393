import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { CompletedIcon } from "components/icon/CompletedIcon";
import { useTranslation } from "hooks/use-translate";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import { WizardSection } from "views/createNew/offer/components/wizardSection";
import DownloadPdfButton from "views/createNew/offer/wizard/components/downloadPdfButton";

export function CompletionDigital() {
  const { data: currentOffer } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );

  const { translate } = useTranslation();

  const contact = currentOffer?.contacts.find((c) => c.is_signer);

  const isLoading = () => !currentOffer?.contacts || !contact;

  return (
    <WizardSection
      loadingStatus={isLoading() ? "pending" : "idle"}
      serviceName={translate("COMPLETION_TITLE")}
      customBackButtonRoute={AppRouteHelper.getOffers()}
    >
      <Col xs={12} className="mt-xxl pt-xxl">
        <Row>
          <Col className="d-flex justify-content-center">
            <CompletedIcon className="fem-7" />
          </Col>
        </Row>
        <Row className="text-center pt-lg">
          <Col xs={12}>
            <Row>
              <Col xs={12} md={2} className="m-auto">
                <div className="horizontal-divider" />
              </Col>

              <Col xs={12} md={8}>
                <div className="fw-bold mt-lg">
                  {translate("DIGITAL_CONFIRMATION_FILE_UPLOADED", [
                    currentOffer?.id ?? "",
                  ])}
                </div>
              </Col>

              <Col xs={12} md={2} className="m-auto">
                <div className="horizontal-divider" />
              </Col>

              <Col xs={12} className="mt-sm">
                <div className="p-2">
                  {translate("DIGITAL_CONFIRMATION_ACCEPT")}
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto" className="mt-md text-center">
                <DownloadPdfButton dealId={currentOffer?.id} type="link" />{" "}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </WizardSection>
  );
}
