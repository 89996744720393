import { ArrowUpRight16Regular } from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@fluentui/react-components";

import { AppRouteHelper } from "routes";
import { LoadingStatus } from "state";
import { Button } from "components/button";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { ExternalKYCdata } from "models/customerSettings/customerSettingsExternalKYC";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { ExternalKYCTable } from "./ExternalKYCTable";

type ExternalKYCLatestProps = {
  customerId: string;
  externalKYCdata: ExternalKYCdata[];
  loadingStatus: LoadingStatus;
};

export default function ExternalKYCLatest({
  customerId,
  externalKYCdata,
  loadingStatus,
}: ExternalKYCLatestProps) {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      {loadingStatus === LoadingStatusEnum.PENDING && (
        <Spinner size={SpinnerSize.ExtraSmall} />
      )}

      {externalKYCdata.length > 0 ? (
        <>
          <div className="p-lg cs-content">
            <ExternalKYCTable externalKYCdata={externalKYCdata} />
          </div>
          <Col md={12}>
            <Row className="mt-md">
              <div className="d-flex align-items-end mb-xs px-0">
                <Button
                  variant="outline-outline"
                  className="justify-content-start primary-colored-text"
                  onClick={() =>
                    navigate(AppRouteHelper.getExternalKYCPage(customerId))
                  }
                >
                  <ArrowUpRight16Regular />
                  <span className="d-inline mx-sm fw-bold primary">
                    {translate("CUSTOMER_PAGE.EXTERNAL_KYC_PAGE")}
                  </span>
                </Button>
              </div>
            </Row>
          </Col>
        </>
      ) : (
        <div className="p-lg cs-content">
          {translate("CUSTOMER_PAGE.EXTERNAL_KYC_NO_DATA_FOUND")}
        </div>
      )}
    </div>
  );
}
