import { Deal } from "models/deals/deal";
import { FieldGroup } from "models/deals/fieldTypes";

import "./FieldGroup.scss";

type Props = {
  group: FieldGroup;
  deal: Deal;
};

export function FieldGroupTable({ group, deal }: Props) {
  return (
    <table className="table-fs-14">
      <tbody>
        {group.fields
          .filter((f) => {
            return (
              f.showForDealTypes === "any" ||
              f.showForDealTypes.some((dt) => deal.deal_type === dt)
            );
          })
          .sort((a, b) => a.order - b.order)
          .map((f) => (
            <tr key={f.property}>
              <td
                className={`field-w-300 ${
                  f.property === "meddelande_salj"
                    ? "align-content-flex-start"
                    : ""
                }`}
              >
                <span className="fw-semibold">{f.label}</span>
              </td>
              <td className="field-w-300">{f.render(deal)}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
