import { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { CompletedIcon } from "components/icon/CompletedIcon";
import { useTranslation } from "hooks/use-translate";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import {
  SearchBy,
  searchForCustomer,
} from "state/offer/companyThunks";
import {
  CompletionStatus,
  setCompletionStatus,
} from "state/offer/offersSlice";
import { fetchDeal } from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { WizardSection } from "views/createNew/offer/components/wizardSection";

export default function CompletionPhysical() {
  const { offerId, orgId } = useParams<{ orgId: string; offerId: string }>();

  const { translate } = useTranslation();

  const {
    offers: {
      currentOffer: { data: currentOffer, status: currentOfferStatus },
      completionStatus,
    },
  } = useSelector((state: RootState) => state);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (orgId && offerId && !currentOffer?.id) {
      dispatch(
        searchForCustomer({
          searchData: {
            customerId: orgId,
            searchByParam: SearchBy.OrgNumber,
          },
          checkCustomerBlocked: false,
        })
      );

      dispatch(fetchDeal({ dealId: offerId, orgId }));
    }
  }, [orgId, offerId, dispatch, currentOffer?.id]);

  const renderPhysicalSigningMethodSuccess = () => (
    <Col xs={12} className="mt-xxl pt-xxl">
      <Row>
        <Col className="d-flex justify-content-center pt-xxl">
          <CompletedIcon className="fem-7" />
        </Col>
      </Row>

      <Row className="text-center pt-lg">
        <Col xs={12}>
          <Row>
            <Col xs={12} md={2} className="m-auto">
              <div className="horizontal-divider" />
            </Col>

            <Col xs={12} md={8}>
              <div className="fw-bold mt-lg">
                {translate("PHYSICAL_CONFIRMATION_FILE_UPLOADED")}
              </div>
            </Col>

            <Col xs={12} md={2} className="m-auto">
              <div className="horizontal-divider" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );

  const reset = () => {
    dispatch(setCompletionStatus(CompletionStatus.FILE_DOWNLOADED));
  };

  const showResetButton = () => (
    <Button onClick={reset}>
      {translate("PHYSICAL_CONFIRMATION_UPLOAD_AGAIN")}
    </Button>
  );

  const renderPhysicalSigningMethodFailure = () => (
    <Col xs={12} className="mt-xxl pt-xxl">
      <Row className="text-center pt-xxl mt-xxl">
        <Col xs={12}>
          <Row>
            <Col xs={12} md={2} className="m-auto">
              <div className="horizontal-divider" />
            </Col>

            <Col xs={12} md={8}>
              <div className="fw-bold p-2">
                {translate("PHYSICAL_CONFIRMATION_FILE_ERROR")}
              </div>
            </Col>

            <Col xs={12} md={2} className="m-auto">
              <div className="horizontal-divider" />
            </Col>
          </Row>

          <Row className="mt-lg">
            <Col className="align-self-center">{showResetButton()}</Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );

  const renderPhysicalSigningMethod = () => {
    if (completionStatus === CompletionStatus.FAIL) {
      return renderPhysicalSigningMethodFailure();
    }

    if (completionStatus === CompletionStatus.SUCCESS) {
      return renderPhysicalSigningMethodSuccess();
    }
  };

  const isLoading = () => {
    return currentOfferStatus === LoadingStatusEnum.PENDING;
  };

  return (
    <WizardSection
      loadingStatus={isLoading() ? "pending" : "idle"}
      subtitles={[translate("COMPLETION_SUBTITLE")]}
      customBackButtonRoute={AppRouteHelper.getOffers()}
    >
      {renderPhysicalSigningMethod()}
    </WizardSection>
  );
}
