import { useForm } from "react-hook-form";
import { ArrowLeft20Regular, Checkmark20Regular } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import Modal from "components/modal";
import { TextArea } from "components/textarea";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";

type ManuallyApproveKYCModalProps = {
  onDismiss: () => void;
  handleManuallyApproveKYC: (comment: string) => void;
  isLoading: boolean;
};

type ManuallyApproveKYCForm = {
  comment: string;
};

export function ManuallyApproveKYCModal({
  onDismiss,
  handleManuallyApproveKYC,
  isLoading,
}: ManuallyApproveKYCModalProps) {
  const { translate } = useTranslation();

  const {
    register,
    formState: { errors, isValid },
    setValue,
    trigger,
    getValues,
  } = useForm<ManuallyApproveKYCForm>({
    mode: "onTouched",
  });

  return (
    <Modal
      size="medium"
      header={
        <ModalHeader
          headerTitleText="KYC_ADMINISTRATION.APPROVE_MODAL_HEADER"
          subheaderText="KYC_ADMINISTRATION.APPROVE_MODAL_SUBHEADER"
        />
      }
      onDismiss={onDismiss}
      isOpen
      footer={
        <ModalFooter
          onCancel={onDismiss}
          onSave={() => handleManuallyApproveKYC(getValues("comment"))}
          labelSubmit="KYC_ADMINISTRATION.APPROVE_MODAL_BTN_CONFIRM"
          sendButtonIcon={<Checkmark20Regular />}
          isDisabled={!isValid || isLoading}
          isLoading={isLoading}
          cancelButtonIcon={<ArrowLeft20Regular />}
        />
      }
    >
      <div className="d-flex align-items-center px-md pt-md">
        <div className="w-100">
          <div className="text-left">
            <label className="p-0 fw-bold">{translate("COMMENT")} *</label>
            <br />
            <TextArea
              rows={5}
              formRegister={register("comment", {
                required: translate("FORM_FIELD_REQUIRED"),
              })}
              trigger={trigger}
              placeholder={translate(
                "KYC_ADMINISTRATION.ADD_COMMENT_PLACEHOLDER"
              )}
              onChange={(e) => {
                setValue("comment", e.target.value, {
                  shouldValidate: true,
                });
              }}
              errorMessage={errors.comment?.message}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
