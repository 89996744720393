import React from "react";

import { SolidAccordionContent } from "./components/AccordionBody";
import { SolidAccordionHeader } from "./components/SolidAccordionHeader";
import { AccordionProvider } from "../context/AccordionContextProvider";
import "./index.scss";

interface Props {
  title: string | React.ReactNode;
  children: React.ReactNode;
  loadingStatus?: string;
  isInitiallyOpen?: boolean;
  subtitles?: string[];
  limitSubtitles?: number;
}

export function SolidAccordion({
  title,
  children,
  loadingStatus,
  isInitiallyOpen = false,
  subtitles = [],
  limitSubtitles,
}: Props) {
  return (
    <AccordionProvider isInitiallyOpen={isInitiallyOpen}>
      <div className="accordion-custom-item">
        <SolidAccordionHeader
          title={title}
          loadingStatus={loadingStatus}
          subtitles={subtitles}
          limitSubtitles={limitSubtitles}
        />
        <SolidAccordionContent loadingStatus={loadingStatus}>
          {children}
        </SolidAccordionContent>
      </div>
    </AccordionProvider>
  );
}
