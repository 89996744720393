import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "@fluentui/react-components";

import {
  Add20Regular,
  ArrowLeft16Regular,
  ArrowRight16Regular,
} from "@fluentui/react-icons";

import "./index.scss";
import { RootState } from "state";
import { useTranslation } from "hooks/use-translate";
import { AppRouteHelper, CustomerDetailsParams } from "routes";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutWrapper,
} from "components/layout/Layout";
import { AppDispatch } from "state/use-app-redux";
import { fetchCustomerContactsByOrgNr } from "state/offer/companyThunks";
import Breadcrumb, { BreadcrumbDefinitionItem } from "components/breadcrumb";
import { Button } from "components/button";
import { SpinnerSize } from "components/spinner";
import { NewKYCTable } from "./components/NewKYCTable";
import AddContactModal from "./components/AddContactModal";
import { useSendNewKYC } from "./useSendNewKYC";

export function NewKYCPage() {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { customerId } = useParams<CustomerDetailsParams>();
  const dispatch: AppDispatch = useDispatch();

  const contacts = useSelector(
    (state: RootState) => state.offers.availableCompanyContacts.data
  );
  const status = useSelector(
    (state: RootState) => state.offers.availableCompanyContacts.status
  );

  const customers = useSelector((state: RootState) => state.customers.data);
  const customer = customers.find(
    (e) => e.customer.customer_number === customerId
  )?.customer;

  const { sendNewKYC } = useSendNewKYC();

  const [selectedContactsIds, setSelectedContactsIds] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingCustomerContacts, setIsLoadingCustomerContacts] =
    useState(true);
  const [isLoadingSendingKYC, setIsLoadingSendingKYC] = useState(false);

  const isLoading = status === "pending" || isLoadingCustomerContacts;
  const isNextDisabled =
    isLoadingSendingKYC || isLoading || selectedContactsIds.length < 1;
  const hasContacts = contacts.length > 0;

  useEffect(() => {
    if (customer) {
      dispatch(
        fetchCustomerContactsByOrgNr(customer.company_registration_number)
      );
      setIsLoadingCustomerContacts(false);
    }
  }, [customer, dispatch]);

  const handleBackToCB = () => {
    navigate({
      pathname: AppRouteHelper.getAssignments(),
    });
  };

  const handleToggleKYCContact = (
    contactId: string,
    isChecked: boolean | undefined
  ) => {
    const contactsIds = [...selectedContactsIds];
    if (isChecked) {
      contactsIds.push(contactId);
    } else {
      contactsIds.filter((id, index) => {
        if (id === contactId) {
          contactsIds.splice(index, 1);
        }
        return contactsIds;
      });
    }
    setSelectedContactsIds(contactsIds);
  };

  const handleSendNewKYC = async () => {
    setIsLoadingSendingKYC(true);
    const selectedContacts = contacts.filter((contact) =>
      selectedContactsIds.includes(contact.id)
    );

    if (customer) {
      await sendNewKYC(customer, selectedContacts);
      setIsLoadingSendingKYC(false);
    }
  };

  const handleOpenAddContactModal = () => {
    setIsModalOpen(true);
  };

  const breadcrumbItems: BreadcrumbDefinitionItem[] = [
    {
      text: translate("MY_ASSIGNMENTS"),
      onClick: () =>
        navigate({
          pathname: AppRouteHelper.getAssignments(),
        }),
    },
    {
      text: customer?.name ?? "",
      onClick: () =>
        navigate({
          pathname: AppRouteHelper.getAssignments(customerId),
        }),
    },
    {
      text: translate("NEW_KYC_FORM.GENERATE_NEY_KYC_FORM"),
    },
  ];

  const renderKYCTable = () =>
    isLoading ? (
      <Spinner
        className="mt-lg"
        size={SpinnerSize.Tiny}
        label={translate("NEW_KYC_FORM.LOADING_CONTACTS")}
      />
    ) : (
      <div className={`${!hasContacts ? "no-contacts-found" : ""}`}>
        {hasContacts ? (
          <NewKYCTable contacts={contacts} onToggle={handleToggleKYCContact} />
        ) : (
          <p className="text-gray fs-italic m-0">
            {translate("NEW_KYC_FORM.NO_CONTACTS_FOUND")}
          </p>
        )}
        <Button
          variant="outline-outline"
          className={`d-flex align-items-center pog-link my-md ${
            !hasContacts ? "mx-auto" : ""
          }`}
          onClick={handleOpenAddContactModal}
        >
          <Add20Regular />
          {translate("NEW_KYC_FORM.ADD_NEW_CONTACT")}
        </Button>
      </div>
    );

  const renderCustomerNotFound = () => (
    <p className="text-gray fs-italic m-0">{translate("CUSTOMER_NOT_FOUND")}</p>
  );

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <Breadcrumb items={breadcrumbItems} />
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutWrapper>
        <div className="content">
          <div className="header-title d-block pr-xxs contentHeader">
            <h3 className="pl-sm stringLength no-margin">
              {translate("NEW_KYC_FORM.HEADER.TITLE")}
            </h3>
            <p className="header-subtitle ml-sm">
              {translate("NEW_KYC_FORM.HEADER.SUBTITLE")}
            </p>
          </div>
          <div className="contentBody">
            {!customer ? renderCustomerNotFound() : renderKYCTable()}
          </div>
          <div className="contentFooter">
            <div className="d-flex justify-content-space-between">
              <Button
                variant="outline-primary"
                className="d-flex align-items-center"
                onClick={handleBackToCB}
              >
                <ArrowLeft16Regular className="mr-sm" />
                {translate("NEW_KYC_FORM.BACK_TO_CONVEYOR_BELT")}
              </Button>
              <Button
                variant="primary"
                disabled={isNextDisabled}
                className="d-flex align-items-center"
                onClick={handleSendNewKYC}
              >
                {isLoadingSendingKYC && (
                  <Spinner className="mr-sm" size={SpinnerSize.ExtraTiny} />
                )}
                {translate("NEW_KYC_FORM.SEND_NEW_KYC")}
                <ArrowRight16Regular className="ml-sm" />
              </Button>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <AddContactModal
            orgNum={customer?.company_registration_number}
            setOpenModal={setIsModalOpen}
          />
        )}
      </LayoutWrapper>
    </>
  );
}
