import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner, MessageBar } from "@fluentui/react-components";
import { Add16Regular } from "@fluentui/react-icons";

import Breadcrumb, { BreadcrumbDefinitionItem } from "components/breadcrumb";
import { Button } from "components/button";

import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
  LayoutWrapper,
} from "components/layout/Layout";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { isUserInCustomerTeam } from "libs/customer-settings-helpers";
import { dateLanguageFormatter } from "libs/date/date-format";
import {
  Activity,
  ActivityInstance,
  ActivityStatus,
} from "models/activities/activity";
import { CustomerPageDetailedPageEnum } from "constants/enums/CustomerPageDetailedPage.enum";
import { AppRouteHelper, CustomerDetailsParams, ServicesParams } from "routes";
import { RootState } from "state";
import {
  EditActivityStatus,
  fetchOptionalActivities,
  getServiceBoxActivities,
  setServiceBoxActivities,
  updateOptionalServiceActivities,
} from "state/activities/actions";
import { StatusIconColor } from "components/icon/StatusIndicatorIcon";
import { setOpenCreateModalFor } from "state/customerSettings/customerSettingsSlice";
import { useAppDispatch } from "state/use-app-redux";
import { fetchServiceLinesCallerWrapper } from "state/customerSettings/customerSettingsThunk";
import { fetchServicesDescriptions } from "state/servicesDescriptions/actions";
import CustomerPageServiceNotesModal from "../customerPage/customerPageServiceNotes/customerPageServiceNotesModal";
import { EditActivitiesTable } from "../editActivities/components/EditActivitiesTable";
import { ActivitiesDetailsPanel } from "./components/ActivitiesDetailsPanel";
import { ActivitiesTable } from "./components/ActivitiesTable";
import "./index.scss";

export type ActivityInstancesGroup = {
  categoryName?: string;
  categorySortingNumber: number;
  activities: ActivityInstance[];
};

export type ServiceActivitiesGroup = {
  [index: string]: ActivityInstancesGroup;
};

export type ActivityGroup = {
  categoryName: string;
  activities: Activity[];
};

export type OptionalActivitiesGroup = {
  [index: string]: ActivityGroup;
};

export const ActivityStatusMap = new Map<ActivityStatus, StatusIconColor>([
  [ActivityStatus.SupportNeeded, "red"],
  [ActivityStatus.SupportFinished, "green"],
  [ActivityStatus.SupportSentToClient, "blue"],
  [ActivityStatus.ManagerSupport, "yellow"],
]);

export default function ServiceInstanceView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { translate, language } = useTranslation();
  const formatter = dateLanguageFormatter(language);

  const { customerId } = useParams<CustomerDetailsParams>();
  const { serviceId } = useParams<ServicesParams>();

  const [isEditing, setIsEditing] = useState(false);
  const [showActivitiesPanel, setShowActivitiesPanel] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<
    Activity | undefined
  >();
  const [openServiceModal, setOpenServiceModal] = useState(false);

  const {
    isLoading: activitiesLoading,
    optionalActivities: {
      data: optionalActivities,
      isLoading: optionalActivitiesLoading,
    },
    serviceBoxActivities: {
      data: serviceBoxActivities,
      isLoading: serviceBoxActivitiesLoading,
    },
    definitions: { dataMap: definitionsMap },
  } = useSelector((state: RootState) => state.activities);
  const { data: customers, isLoading: customersLoading } = useSelector(
    (state: RootState) => state.customers
  );
  const { currentUser } = useSelector((state: RootState) => state.users);
  const { data: descriptions, isLoading: descriptionsLoading } = useSelector(
    (state: RootState) => state.servicesDescriptions
  );
  const customer = customers.find(
    (customerState) => customerState.customer.customer_number === customerId
  )?.customer;

  useEffect(() => {
    if (customerId && serviceId && serviceBoxActivities.length < 1) {
      dispatch(getServiceBoxActivities(customerId, serviceId));
    }
    /* serviceBoxActivities is intentionally not in the dependency
    array because it can cause an infinite loop if we try to
    fetch a service box that doesn't exist */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, dispatch, serviceId]);

  useEffect(() => {
    const init = async () => {
      if (!descriptions.length) {
        dispatch(fetchServicesDescriptions());
      }
    };

    init();
  }, [descriptions.length, dispatch]);

  useEffect(() => {
    if (!customer) {
      return;
    }

    dispatch(fetchOptionalActivities(customer));
  }, [dispatch, customer]);

  useEffect(() => {
    return () => {
      dispatch(setServiceBoxActivities([], ""));
    };
  }, [dispatch]);

  if (!customer || !serviceId) {
    return null;
  }

  const service = serviceBoxActivities ? serviceBoxActivities[0] : undefined;

  const optionalActivitiesForService = optionalActivities.filter(
    (activity) =>
      service &&
      service.cb_service_type === activity.service_type &&
      service.recurrence ===
        activity.getRecurrence(definitionsMap[activity.activity_type])
  );

  const isLoading =
    activitiesLoading ||
    customersLoading ||
    serviceBoxActivitiesLoading ||
    descriptionsLoading;

  const serviceTitle = service?.getTitle(language) || "";

  const servicePeriod =
    service && !!service.getPeriod(formatter)
      ? `(${service.getPeriod(formatter)})`
      : "";

  const navigateBack = (focusCustomer = false) => {
    navigate(
      AppRouteHelper.getAssignments(
        focusCustomer ? customer.customer_number : ""
      )
    );
  };

  const breadcrumbTitle = `${serviceTitle} ${servicePeriod}`.trim();
  const breadcrumbs: BreadcrumbDefinitionItem[] = [
    {
      text: translate("MY_ASSIGNMENTS"),
      onClick: () => navigateBack(),
    },
    {
      text: customer.name,
      onClick: () => navigateBack(true),
    },
    { text: breadcrumbTitle },
  ];

  const openActivityDetailsPanel = (activity: Activity) => {
    if (showActivitiesPanel) {
      setShowActivitiesPanel(false);
      setSelectedActivity(undefined);
    } else {
      setShowActivitiesPanel(true);
      setSelectedActivity(activity);
    }
  };

  const updateServiceActivities = (
    toAdd: EditActivityStatus[],
    toRemove: EditActivityStatus[],
    isOnetime: boolean,
    toAddStartDate?: Date,
    toRemoveEndDate?: Date
  ) => {
    if (!service) {
      return;
    }

    setIsEditing(false);
    dispatch(
      updateOptionalServiceActivities(
        customer,
        toAdd,
        toRemove,
        isOnetime,
        toAddStartDate,
        toRemoveEndDate
      )
    );
  };

  const renderError = () => (
    <MessageBar intent="warning">
      {translate("FAILED_LOADING_SERVICE", [serviceId || "n/a"])}
    </MessageBar>
  );

  const renderTable = () =>
    isEditing ? (
      <>
        <p>{translate("RELATED_SERVICE_ACTIVITIES")}</p>
        <EditActivitiesTable
          customerOptionalActivitiesLoading={optionalActivitiesLoading}
          customerOptionalActivities={optionalActivitiesForService}
          onSave={updateServiceActivities}
          onCancel={() => setIsEditing(false)}
          customer={customer}
          openActivityDetailsPanel={openActivityDetailsPanel}
          disabled={optionalActivitiesLoading}
        />
      </>
    ) : (
      <ActivitiesTable
        customer={customer}
        service={service}
        openActivityDetailsPanel={openActivityDetailsPanel}
        setEditing={setIsEditing}
        serviceId={serviceId}
      />
    );

  const onClickAddServiceNote = () => {
    dispatch(fetchServiceLinesCallerWrapper());
    dispatch(setOpenCreateModalFor(CustomerPageDetailedPageEnum.SERVICE_NOTES));
    setOpenServiceModal(true);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center">
          <Spinner
            className="my-sm"
            label={translate("LOADING_SERVICE")}
            size={SpinnerSize.Small}
          />
        </div>
      );
    }

    if (!service) {
      return renderError();
    }

    return renderTable();
  };

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <Breadcrumb items={breadcrumbs} />
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          {currentUser.isEmployee() && (
            <Button
              onClick={() => onClickAddServiceNote()}
              variant="outline"
              disabled={!isUserInCustomerTeam(customer, currentUser)}
            >
              <div className="d-flex flex-wrap blue-link align-items-center">
                <label className="mr-sm">
                  <Add16Regular />
                </label>
                <label className="cursor-pointer">
                  {translate("ADD_NEW_SERVICE_NOTE")}
                </label>
              </div>
            </Button>
          )}
        </LayoutHeaderRight>
      </LayoutHeader>
      <LayoutWrapper>
        {renderContent()}

        <ActivitiesDetailsPanel
          showActivitiesPanel={showActivitiesPanel}
          selectedActivity={selectedActivity}
          onDismiss={() => setShowActivitiesPanel(false)}
        />
      </LayoutWrapper>
      {openServiceModal && customerId && (
        <CustomerPageServiceNotesModal
          customerId={customerId}
          openModal={openServiceModal}
          setOpenModal={setOpenServiceModal}
          createdFromCB
        />
      )}
    </>
  );
}
