import { Spinner } from "@fluentui/react-components";
import { Add20Regular, Edit16Regular } from "@fluentui/react-icons";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { SpinnerSize } from "components/spinner";
import Tooltip from "components/tooltip/Tooltip";
import { useTranslation } from "hooks/use-translate";
import DealContactDetails from "models/offer/DealContactDetails";
import { searchContact } from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import ContactsEditModal from "views/createNew/offer/wizard/CustomerContactDetails/components/ContactsEditModal";
import "./ContactCards.scss";

type Props = {
  contacts: DealContactDetails[];
  editable: boolean;
  showPanel: boolean;
  onShowPanel: (setShowPanel: boolean) => void;
  showAllContacts?: boolean;
  showNumberOfItems?: number;
};

export type CardOption = {
  label: string;
  value: string | JSX.Element;
  hasJSXContent?: boolean;
};

export default function ContactsCards({
  contacts,
  editable,
  showPanel,
  onShowPanel,
  showAllContacts = false,
  showNumberOfItems,
}: Props) {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [editModalData, setEditModalData] = useState<
    DealContactDetails | undefined
  >(undefined);
  const [loadingDataFor, setLoadingDataFor] = useState("");

  const alignContactsClass = showAllContacts
    ? "d-flex flex-column"
    : "d-flex horizontal-scroll";
  const cardClass = showAllContacts ? "mb-md" : "mr-md";
  const displayContacts = !showNumberOfItems
    ? contacts
    : contacts.slice(0, showNumberOfItems);
  const remainingContacts = contacts.length - displayContacts.length;
  const showRemainingContacts = !showAllContacts && remainingContacts > 0;

  const handleAddContact = () => {
    setEditModalData(new DealContactDetails());
  };

  const handleModalToggle = (isOpen: boolean) => {
    if (!isOpen) {
      setEditModalData(undefined);
    }
  };
  const handleEditContact = async (contact: DealContactDetails) => {
    setLoadingDataFor(contact.id);
    const searchContacts = await dispatch(searchContact(contact.email));
    const contactFound = searchContacts && searchContacts[0];
    const editModalContactData = { ...contact };
    if (contactFound) {
      editModalContactData.phone_number = contactFound.phone;
    }
    setEditModalData(editModalContactData);
    setLoadingDataFor("");
  };
  const getContactCardOptions = (contact: DealContactDetails): CardOption[] => {
    return [
      {
        label: translate("NAME"),
        value: `${contact.first_name} ${contact.last_name}`,
      },
      {
        label: translate("CONTACT_CARD.MOBILE_PHONE"),
        value: contact.mobile_phone,
      },
      {
        label: translate("PHONE"),
        value: contact.phone_number ?? "",
      },
      {
        label: translate("EMAIL"),
        value: contact.email,
      },
    ];
  };

  const renderCardContent = (cardOption: CardOption, contactId: string) => (
    <div
      className="contact-card-row pb-sm d-flex"
      key={`${contactId}-${cardOption.label}`}
    >
      <Tooltip
        content={cardOption.label}
        notTranslatable
        childrenClassName="contact-card-row-title"
        positioning="above-start"
      >
        <div className="stringLength">{cardOption.label}</div>
      </Tooltip>
      <Tooltip
        content={cardOption.value}
        notTranslatable
        childrenClassName="contact-card-row-text"
        positioning="above-start"
      >
        <div className="stringLength">{cardOption.value}</div>
      </Tooltip>
    </div>
  );

  return (
    <>
      <div className={`customer-contacts-list ${alignContactsClass}`}>
        {(showAllContacts ? contacts : displayContacts).map((contact) => (
          <div className={`contact-card ${cardClass}`} key={contact.id}>
            {editable && (
              <div
                className="edit-icon"
                onClick={() => handleEditContact(contact)}
              >
                {loadingDataFor === contact.id ? (
                  <Spinner size={SpinnerSize.ExtraSmall} />
                ) : (
                  <Edit16Regular className="icon-blue" />
                )}
              </div>
            )}
            {getContactCardOptions(contact).map((cardOption) =>
              renderCardContent(cardOption, contact.id)
            )}
          </div>
        ))}
        <div className="d-flex align-items-center justify-content-between ml-lg w-100">
          {showRemainingContacts && (
            <>
              <div
                className="cursor-pointer pog-link"
                onClick={() => {
                  onShowPanel(!showPanel);
                }}
              >
                <span className="blue-link-underline text-wrap-none fw-semibold">
                  {translate("SHOW_ALL")}
                </span>
              </div>
              <div className="vertical-divider mx-xxl" />
            </>
          )}
          <div
            className="cursor-pointer pog-link d-flex"
            onClick={handleAddContact}
          >
            <Add20Regular />
            <span className="ml-sm">{translate("CREATE_CONTACT")}</span>
          </div>
        </div>
      </div>
      <ContactsEditModal
        isOpen={!!editModalData}
        contactDetails={editModalData}
        onToggle={handleModalToggle}
      />
    </>
  );
}
