import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Spinner } from "@fluentui/react-components";
import { SpinnerSize } from "components/spinner";
import {
  ErrorCircle20Regular,
  TriangleDown12Filled,
  TriangleRight12Filled,
} from "@fluentui/react-icons";

import { LoadingStatus } from "state";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { SectionContext } from "./Section";
import "./section.scss";

export type SectionHeaderProps = {
  title?: string;
  count?: number;
  subtitles?: string[];
  loadingStatus?: LoadingStatus;
  collapsibleContent?: boolean;
  hoverableTitle?: boolean;
} & React.HTMLAttributes<HTMLElement>;

export default function SectionSummaryHeader({
  title = "",
  count,
  subtitles = [],
  loadingStatus = "idle",
  collapsibleContent = false,
  hoverableTitle = true,
}: SectionHeaderProps) {
  const { isContentExpanded, setIsContentExpanded } =
    useContext(SectionContext);
  const headerStyleClasses = `align-items-center${
    hoverableTitle ? " hover-light-text" : ""
  }`;
  return (
    <div
      onClick={() =>
        collapsibleContent && setIsContentExpanded(!isContentExpanded)
      }
      className="p-lg background-gray-400"
    >
      <Row
        className={
          collapsibleContent
            ? `${headerStyleClasses} cursor-pointer`
            : headerStyleClasses
        }
      >
        {collapsibleContent && (
          <Col md="auto" className="pb-xs">
            {isContentExpanded ? (
              <TriangleDown12Filled />
            ) : (
              <TriangleRight12Filled />
            )}
          </Col>
        )}
        <Col md className="d-flex align-items-center px-0">
          <div className="sub-h1">
            <span className="fw-semibold"> {title}</span>
          </div>
          <div className="text-gray ml-sm mr-md">
            {count ? `(${count})` : ""}
          </div>
          <div>
            {subtitles.map((subtitle, index) => (
              <span className="disabled mr-md">{subtitle}</span>
            ))}
          </div>

          {loadingStatus === "pending" && (
            <span>
              <Spinner size={SpinnerSize.Tiny} className="ml-sm" />
            </span>
          )}
          {loadingStatus === LoadingStatusEnum.FAILED && (
            <span>
              <ErrorCircle20Regular className="ml-sm blue-link text-color-red" />
            </span>
          )}
        </Col>
      </Row>
    </div>
  );
}
