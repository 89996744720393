import { useState } from "react";
import { ArrowLeft16Regular, ArrowRight16Regular } from "@fluentui/react-icons";
import { addHours } from "date-fns";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import { useTranslation } from "hooks/use-translate";
import { getDigitsFromString } from "libs/number-format";
import { DateFormat } from "libs/date/date-format";
import { isValidSwedishSSN } from "libs/is-valid-ssn";
import { DealIdCheck } from "models/dealIdCheck";
import { IdType, IdTypeOptions } from "models/idType";
import { BoxFileUpload } from "components/Box/BoxFileUpload";
import { Boxes } from "components/Box/Boxes";

import { Dropdown } from "components/dropdown";
import { RHFInput } from "components/input/RHFInput";
import Modal, { ModalStepsConfig } from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { DatePicker } from "components/date/DatePicker";

type CompletionPhysicalModalProps = {
  onSave: (dealFile: File, kycFile: File, data: DealIdCheck) => void;
  isOpen?: boolean;
  onClose?: (isConfirmationModalFilled: boolean) => void;
};

export function CompletionPhysicalModal({
  isOpen,
  onSave,
  onClose,
}: CompletionPhysicalModalProps) {
  const { ts, translate, dateFormatter } = useTranslation();

  const [dealFile, setDealFile] = useState<File>();
  const [kycFile, setKycFile] = useState<File>();
  const [expirationDate, setExpirationDate] = useState<Date>();

  const [stepsConfig, setStepsConfig] = useState<ModalStepsConfig>({
    activeStep: 1,
    stepsCount: 2,
  });

  const {
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
    control,
    handleSubmit,
  } = useForm<DealIdCheck>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: new DealIdCheck(),
  });

  const handleCloseModal = () => {
    if (stepsConfig.activeStep === 1) {
      setDealFile(undefined);
      setKycFile(undefined);

      if (onClose) {
        onClose(isValid && !!expirationDate);
      }
    }

    setStepsConfig({ ...stepsConfig, activeStep: 1 });
  };

  const handleOnSave = () => {
    if (stepsConfig.activeStep === 1) {
      setStepsConfig({ ...stepsConfig, activeStep: 2 });
    }

    if (stepsConfig.activeStep === 2) {
      handleSubmit((formData: DealIdCheck) => {
        if (!dealFile || !kycFile) {
          return;
        }

        onSave(dealFile, kycFile, {
          ...formData,
          id_expiration_date: expirationDate?.toISOString() || "",
        });

        if (onClose) {
          onClose(isValid && !!expirationDate);
        }
      })();
    }
  };

  const isNextButtonDisabled = () => {
    if (stepsConfig.activeStep === 1) {
      return !dealFile || !kycFile;
    }

    if (stepsConfig.activeStep === 2) {
      return !isValid || !expirationDate;
    }

    return true;
  };

  const renderHeader = () => {
    let translation = "";
    if (stepsConfig.activeStep === 1) {
      translation = "UPLOAD_FILES";
    }

    if (stepsConfig.activeStep === 2) {
      translation = "ID_CHECK";
    }

    return <ModalHeader headerTitleText={translation} />;
  };

  const socialSecurityNumberOptions = register("social_security_number", {
    required: translate("SOCIAL_SECURITY_NUMBER_REQUIRED"),
    validate: {
      isValid: (value) => {
        return value && value?.length !== 12
          ? translate("INVALID_FULLSSN_MESSAGE")
          : isValidSwedishSSN(value) || translate("INVALID_SSN_MESSAGE");
      },
    },
  });

  const idNumber = register("id_number", {
    required: translate("FIELD_REQUIRED"),
    validate: {
      isValid: (value) => {
        const idType = getValues("id_type");
        const errorMessage = translate("INVALID_ID_NUMBER");

        switch (idType) {
          case IdType.DrivingLicense:
            return value.length !== 9 ? errorMessage : true;
          case IdType.Passport:
            return value.length !== 8 && value.length !== 9
              ? errorMessage
              : true;
          case IdType.NationalIdCard:
            return value.length !== 8 ? errorMessage : true;
          case IdType.SISIdCard:
            return value.length !== 12 ? errorMessage : true;
          case IdType.IDcard:
            return value.length !== 19 ? errorMessage : true;
          default:
            return true;
        }
      },
    },
  });

  const handleIdNumberChange = (target: EventTarget & HTMLInputElement) => {
    const idType = getValues("id_type");
    setValue(
      "id_number",
      idType === IdType.Passport
        ? target.value
        : getDigitsFromString(target.value),
      { shouldValidate: true }
    );
  };

  const handleIdTypeChange = (
    onChange: (e: string) => void,
    onBlur: () => void,
    e: string
  ) => {
    onChange(e);
    onBlur();
    setValue("id_number", "");
    if (getValues("id_number").length > 0) {
      trigger("id_number");
    }
  };

  const renderFileUpload = () => {
    return (
      <div className="pt-lg pb-sm">
        <Boxes>
          <BoxFileUpload
            title={translate("POG_CONTRACT")}
            actionTitle={translate("UPLOAD_FILE")}
            actionSubtitle={`${translate("OR")} ${translate("DRAG_AND_DROP")}`}
            fileName={dealFile?.name}
            accept={{
              "application/pdf": [".pdf"],
            }}
            onFileDropped={(file: File) => setDealFile(file)}
            onFileDelete={() => setDealFile(undefined)}
          />

          <BoxFileUpload
            title={translate("KYC_TITLE")}
            actionTitle={translate("UPLOAD_FILE")}
            actionSubtitle={`${translate("OR")} ${translate("DRAG_AND_DROP")}`}
            fileName={kycFile?.name}
            accept={{
              "application/pdf": [".pdf"],
            }}
            onFileDropped={(file: File) => setKycFile(file)}
            onFileDelete={() => setKycFile(undefined)}
          />
        </Boxes>
      </div>
    );
  };

  const renderKyc = () => {
    return (
      <Row className="py-sm">
        <Col md={12}>
          <div className="info-label pb-md">{translate("FIELDS_REQUIRED")}</div>
        </Col>

        <Col lg={6}>
          <Row className="gy-lg">
            <Col xs={12}>
              <label className="fw-semibold">{translate("FIRST_NAME")} *</label>
              <RHFInput
                placeholder={translate("FIRST_NAME")}
                errorMessage={errors.first_name && translate("FIELD_REQUIRED")}
                register={register("first_name", { required: true })}
                onChange={({ target }) => {
                  setValue("first_name", target.value, {
                    shouldValidate: true,
                  });
                }}
              />
            </Col>

            <Col xs={12}>
              <label className="fw-semibold">{translate("LAST_NAME")} *</label>
              <RHFInput
                placeholder={translate("LAST_NAME")}
                errorMessage={errors.last_name && translate("FIELD_REQUIRED")}
                register={register("last_name", { required: true })}
                onChange={({ target }) => {
                  setValue("last_name", target.value, { shouldValidate: true });
                }}
              />
            </Col>

            <Col xs={12}>
              <label className="fw-semibold">{translate("SSN")} *</label>
              <RHFInput
                placeholder={translate("SSN_PLACEHOLDER")}
                errorMessage={errors.social_security_number?.message}
                register={socialSecurityNumberOptions}
                onChange={({ target }) => {
                  setValue(
                    "social_security_number",
                    getDigitsFromString(target.value),
                    { shouldValidate: true }
                  );
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col lg={6}>
          <Row className="gy-lg">
            <Col xs={12}>
              <label className="fw-semibold">{translate("ID_TYPE")} *</label>
              <Controller
                name="id_type"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <Dropdown
                    options={IdTypeOptions.map((idOption) => ({
                      ...idOption,
                      label: ts(`ID_OPTION_${idOption.value}`),
                    }))}
                    onChange={(e) => {
                      handleIdTypeChange(onChange, onBlur, e);
                    }}
                    value={value}
                    errorMessage={errors.id_type && translate("FIELD_REQUIRED")}
                  />
                )}
              />
            </Col>

            <Col xs={12}>
              <label className="fw-semibold">{translate("ID_NUMBER")} *</label>
              <RHFInput
                errorMessage={errors.id_number?.message}
                register={idNumber}
                onChange={({ target }) => {
                  handleIdNumberChange(target);
                }}
              />
            </Col>

            <Col xs={12}>
              <label className="fw-semibold">
                {translate("ID_EXPIRATION_DATE")} *
              </label>
              <DatePicker
                fullWidth
                dateFormatter={(date) =>
                  dateFormatter(date, DateFormat.YearDayMonth)
                }
                calendarProps={{
                  isDayPickerVisible: true,
                }}
                selectedDate={expirationDate}
                onSelectDate={(date) => {
                  if (!date) {
                    return;
                  }
                  setExpirationDate(addHours(date, 12));
                }}
                showGoToToday={false}
                minDate={new Date()}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      size="large"
      isOpen={!!isOpen}
      onDismiss={handleCloseModal}
      stepsConfig={stepsConfig}
      footer={
        <ModalFooter
          isDisabled={isNextButtonDisabled()}
          onCancel={handleCloseModal}
          onSave={handleOnSave}
          labelSubmit={translate("NEXT")}
          labelCancel={
            stepsConfig.activeStep === 1
              ? translate("CANCEL")
              : translate("BACK_TO_FILES")
          }
          sendButtonIcon={<ArrowRight16Regular />}
          cancelButtonIcon={<ArrowLeft16Regular />}
        />
      }
      header={renderHeader()}
    >
      <div className="px-lg">
        {stepsConfig.activeStep === 1 ? renderFileUpload() : renderKyc()}
      </div>
    </Modal>
  );
}
