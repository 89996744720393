import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkmark16Regular } from "@fluentui/react-icons";
import { Spinner } from "@fluentui/react-components";

import { RootState } from "state";
import {
  getFailedKycData,
  manuallyApproveFailedKYC,
} from "state/offer/kycThunks";
import { resetFailedKycData } from "state/offer/kycSlice";
import { AppDispatch } from "state/use-app-redux";
import { useAuth } from "auth/use-auth";
import AppConfig from "app-config";
import { UserRoles } from "models/user";
import { useTranslation } from "hooks/use-translate";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { Button } from "components/button";
import {
  Content,
  ContentBody,
  ContentFooter,
} from "components/content/Content";
import { ContentHeader } from "components/content/ContentHeader";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutWrapper,
} from "components/layout/Layout";
import { SpinnerSize } from "components/spinner";
import { FailedKYCCustomersTable } from "./components/FailedKYCCustomersTable";
import { ManuallyApproveKYCModal } from "./components/ManuallyApproveKYCModal";

export type SelectedCustomer = {
  envelopeId: string;
  customerName: string;
};

export default function FailedKYCCustomers() {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { data, status } = useSelector(
    (state: RootState) => state.kyc.failedKyc
  );

  const { user, userHasSystemRole } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomer>();
  const [isLoading, setIsLoading] = useState(false);

  const isDataLoaded =
    status === LoadingStatusEnum.SUCCEEDED ||
    status === LoadingStatusEnum.FAILED;

  useEffect(() => {
    if (status === LoadingStatusEnum.IDLE) {
      if (
        userHasSystemRole(AppConfig.USER_ROLES.MITT_LUDVIG_SUPERUSER) ||
        user?.roles?.includes(UserRoles.USERS_SUPER)
      ) {
        dispatch(getFailedKycData());
      } else {
        dispatch(getFailedKycData(user?.email));
      }
    }

    return () => {
      if (status === LoadingStatusEnum.FAILED) {
        dispatch(resetFailedKycData());
      }
    };
  }, [dispatch, status, user, userHasSystemRole]);

  const handleToggleFailedCustomer = (
    customer: SelectedCustomer,
    isChecked: boolean | undefined
  ) => {
    if (isChecked) {
      setSelectedCustomer(customer);
    } else {
      setSelectedCustomer(undefined);
    }
  };

  const handleManuallyApproveKYC = async (comment: string) => {
    if (selectedCustomer) {
      setIsLoading(true);
      const body = { ...selectedCustomer, comment };
      await dispatch(manuallyApproveFailedKYC(body)).then(() => {
        setIsLoading(false);
        setIsModalOpen(false);
        setSelectedCustomer(undefined);
      });
    }
  };

  const renderFailedKYCTable = () =>
    data.length > 0 ? (
      <FailedKYCCustomersTable
        failedCustomers={data}
        onToggle={handleToggleFailedCustomer}
        selectedCustomer={selectedCustomer}
      />
    ) : (
      <p className="text-gray fs-italic m-0">
        {translate("KYC_ADMINISTRATION.NO_FAILED_KYCS_FOUND")}
      </p>
    );

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <h1>{translate("KYC_ADMINISTRATION.KNOW_YOUR_CUSTOMER.HEADER")}</h1>
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutWrapper>
        <Content>
          <ContentHeader
            breadcrumbs={[
              {
                title: translate(
                  "KYC_ADMINISTRATION.KNOW_YOUR_CUSTOMER.CONTENT_HEADER"
                ),
              },
              {
                title: translate(
                  "KYC_ADMINISTRATION.KNOW_YOUR_CUSTOMER.LIST_OF_FAILED_KYC"
                ),
              },
            ]}
            subtitles={[
              translate(
                "KYC_ADMINISTRATION.KNOW_YOUR_CUSTOMER.CONTENT_SUBHEADER"
              ),
            ]}
          />
          <ContentBody>
            {isDataLoaded ? (
              renderFailedKYCTable()
            ) : (
              <Spinner
                size={SpinnerSize.ExtraSmall}
                className="mt-lg"
                label={translate("KYC_ADMINISTRATION.LOADING_FAILED_KYCS")}
              />
            )}
          </ContentBody>
          <ContentFooter className="align-self-end">
            <Button
              className="d-flex align-items-center"
              onClick={() => setIsModalOpen(true)}
              disabled={!selectedCustomer}
            >
              <Checkmark16Regular className="mr-sm" />
              {translate("KYC_ADMINISTRATION.FOOTER.APPROVE_CUSTOMERS")}
            </Button>
          </ContentFooter>
        </Content>
      </LayoutWrapper>
      {isModalOpen && (
        <ManuallyApproveKYCModal
          onDismiss={() => setIsModalOpen(false)}
          handleManuallyApproveKYC={handleManuallyApproveKYC}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
