import { useContext } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ChevronLeft20Regular } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import {
  Content,
  ContentBody,
  ContentFooter,
} from "components/content/Content";
import { ContentHeader } from "components/content/ContentHeader";
import { CompletedIcon } from "components/icon/CompletedIcon";
import { RootState } from "state";
import { CreateCustomerContext } from "../context/CreateCustomerContext";
import { CustomerWizardRouteHelper } from "../routes/createCustomerWizardRoutes";

export default function CustomerRegisteredPage() {
  const { translate } = useTranslation();

  const { currentDeal } = useSelector((state: RootState) => state.sales);

  const { newCustomer, resetContext, dealIdToUpdate } = useContext(
    CreateCustomerContext
  );

  const navigate = useNavigate();
  return (
    <Content>
      <ContentHeader
        title={translate("COMPLETION_TITLE")}
        subtitles={[translate("CUSTOMER_CREATION_FLOW_COMPLETE")]}
      />
      <ContentBody>
        <div className="p-md display-grid-center minHeight-100">
          <Row className="text-center w-100">
            <Col xs={12} lg={3} className="m-auto">
              <div className="horizontal-divider" />
            </Col>
            <Col xs={12} lg={4} className="justify-content-center">
              <div className="pb-xxl">
                <CompletedIcon className="fem-7" />
              </div>
              <div className="sub-h1 fw-semibold pb-lg">
                {translate("CREATE_CUSTOMER_SUCCESS")}
              </div>
              <div
                className="sub-h1 pb-xs"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: translate("CREATE_CUSTOMER_INFO", [
                    newCustomer.CompanyName,
                  ]),
                }}
              />
              <div className="sub-h1">
                {translate(
                  dealIdToUpdate || currentDeal
                    ? "DEAL_UPDATED_HUBSPOT"
                    : "DEAL_CREATED_HUBSPOT"
                )}
              </div>
            </Col>
            <Col xs={12} lg={3} className="m-auto">
              <div className="horizontal-divider" />
            </Col>
          </Row>
        </div>
      </ContentBody>
      <ContentFooter>
        <Row className="stepper-buttons">
          <Col
            md={2}
            onClick={() => {
              resetContext();
              navigate(
                CustomerWizardRouteHelper.getCustomerBusinessAreaRoute()
              );
            }}
            className="float-left"
          >
            <span className="d-flex cursor-pointer align-items-center">
              <ChevronLeft20Regular className="text-color-blue mr-sm" />
              {translate("REGISTER_ANOTHER_CUSTOMER")}
            </span>
          </Col>
        </Row>
      </ContentFooter>
    </Content>
  );
}
