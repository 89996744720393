import { RouteConfig } from "routes";
import CustomerSearch from "../wizard/customerSearch";
import BusinessOpportunityCustomerDetails from "../wizard/customerDetails";
import BusinessOpportunityCustomerContacts from "../wizard/customerContacts";
import BusinessAreaPicker from "../wizard/businessAreaPicker";
import BusinessOpportunitiesDetails from "../wizard/businessOpportunityDetails";
import BusinessOpportunityCompletion from "../wizard/completion";
import BusinessOpportunitiesCreateCustomer from "../wizard/createCustomer";

export const BusinessOpportunityWizardRouteHelper = {
  getCustomerSearchRoute: () => "/business-opportunity",
  getCustomerDetailsRoute: () => "/business-opportunity/details",
  getCustomerContactsRoute: () => "/business-opportunity/contacts",
  getBusinessOpportunityBusinessAreaRoute: () =>
    "/business-opportunity/business-area",
  getBusinessOpportunityBusinessDetailsRoute: () =>
    "/business-opportunity/business-details",
  getBusinessOpportunityCompletionRoute: () =>
    "/business-opportunity/completion",
  getNewCustomerDetailsRoute: () => "/business-opportunity/register",
};

const businessOpportunityWizardRoutes: RouteConfig[] = [
  {
    path: BusinessOpportunityWizardRouteHelper.getCustomerSearchRoute(),
    element: <CustomerSearch />,
    subRoutes: [],
  },
  {
    path: BusinessOpportunityWizardRouteHelper.getCustomerDetailsRoute(),
    element: <BusinessOpportunityCustomerDetails />,
    subRoutes: [],
  },
  {
    path: BusinessOpportunityWizardRouteHelper.getCustomerContactsRoute(),
    element: <BusinessOpportunityCustomerContacts />,
    subRoutes: [],
  },
  {
    path: BusinessOpportunityWizardRouteHelper.getBusinessOpportunityBusinessAreaRoute(),
    element: <BusinessAreaPicker />,
    subRoutes: [],
  },
  {
    path: BusinessOpportunityWizardRouteHelper.getBusinessOpportunityBusinessDetailsRoute(),
    element: <BusinessOpportunitiesDetails />,
    subRoutes: [],
  },
  {
    path: BusinessOpportunityWizardRouteHelper.getBusinessOpportunityCompletionRoute(),
    element: <BusinessOpportunityCompletion />,
    subRoutes: [],
  },
  {
    path: BusinessOpportunityWizardRouteHelper.getNewCustomerDetailsRoute(),
    element: <BusinessOpportunitiesCreateCustomer />,
    subRoutes: [],
  },
  {
    path: "*",
    element: <p>No such route!</p>,
    subRoutes: [],
  },
];

export default businessOpportunityWizardRoutes;
