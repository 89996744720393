import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowLeft20Regular,
  Checkmark20Regular,
  Warning20Regular,
} from "@fluentui/react-icons";
import { Spinner } from "@fluentui/react-components";

import { RootState } from "state";
import { RHFInput } from "components/input/RHFInput";
import Modal from "components/modal";
import { useTranslation } from "hooks/use-translate";
import { isValidEmail } from "libs/is-valid-email";
import { isValidPhone } from "libs/is-valid-phone";
import { getDigitsFromString } from "libs/number-format";
import { AppDispatch } from "state/use-app-redux";
import { searchContact } from "state/sales/actions";
import VerticalStepper from "components/verticalStepper";
import { ModalFooter } from "components/modal/ModalFooter";
import DealContactDetails from "models/offer/DealContactDetails";
import { isValidSwedishSSN } from "libs/is-valid-ssn";
import { createOrUpdateCompanyContact } from "state/offer/companyThunks";
import { appendToastMessage } from "state/notifications";
import { ModalHeader } from "components/modal/ModalHeader";
import { SpinnerSize } from "components/spinner";

type AddContactModalProps = {
  setOpenModal: (arg: boolean) => void;
  orgNum?: string;
};

export default function AddContactModal({
  setOpenModal,
  orgNum,
}: AddContactModalProps) {
  const dispatch: AppDispatch = useDispatch();
  const { translate } = useTranslation();

  const contacts = useSelector(
    (state: RootState) => state.offers.availableCompanyContacts.data
  );
  const { currentUser } = useSelector((state: RootState) => state.users);

  const [existingContact, setExistingContact] =
    useState<Partial<DealContactDetails>>();
  const [existingContactError, setExistingContactError] = useState("");

  const [loadingStates, setLoadingStates] = useState({
    isEnteringEmail: false,
    isLoadingContact: false,
    isExistingContact: false,
    isEmailFilled: false,
    isLoading: false,
  });
  const {
    isEnteringEmail,
    isLoadingContact,
    isExistingContact,
    isEmailFilled,
    isLoading,
  } = loadingStates;

  const {
    register,
    formState: { errors, isValid },
    setValue,
    trigger,
    reset,
    getValues,
  } = useForm<DealContactDetails>({
    mode: "onTouched",
    defaultValues: new DealContactDetails(),
  });

  const firstNameOptions = register("first_name", {
    required: translate("FIRSTNAME_REQUIRED"),
  });

  const lastNameOptions = register("last_name", {
    required: translate("LASTNAME_REQUIRED"),
  });

  const emailOptions = register("email", {
    required: translate("EMAIL_REQUIRED"),
    validate: {
      isValid: (value) => {
        return isValidEmail(value) || translate("EMAIL_VALIDATION_MESSAGE");
      },
    },
  });

  const phoneNumberOptions = register("phone_number", {
    validate: {
      isValid: (value) => {
        if (!value) {
          return true;
        }
        return isValidPhone(value) || translate("PHONE_VALIDATION_MESSAGE");
      },
    },
  });

  const ssnOptions = register("social_security_number", {
    validate: {
      isValid: (value) => {
        if (!value) {
          return true;
        }
        return isValidSwedishSSN(value) || translate("INVALID_SSN_MESSAGE");
      },
    },
  });

  const fetchContact = async (email: string) => {
    if (!isValidEmail(email)) {
      return;
    }

    setLoadingStates((prev) => ({
      ...prev,
      isEmailFilled: true,
      isLoadingContact: true,
    }));

    try {
      const fetchedContacts = await dispatch(searchContact(email));
      const contactFound = fetchedContacts && fetchedContacts.length > 0;

      if (contactFound) {
        const contact = {
          email,
          id: fetchedContacts[0].id,
          last_name: fetchedContacts[0].lastName,
          first_name: fetchedContacts[0].firstName,
          mobile_phone: fetchedContacts[0].mobile,
          phone_number: fetchedContacts[0].phone,
          social_security_number: fetchedContacts[0].social_security_number,
        };
        const isCustomerContact = contacts.find(
          (customerContact) => customerContact.id === contact.id
        );

        if (isCustomerContact) {
          setExistingContactError(translate("NEW_KYC_FORM.CONTACT_EXISTS"));
          setLoadingStates((prev) => ({
            ...prev,
            isExistingContact: true,
          }));
        } else {
          setExistingContact(contact);
        }
        reset(contact);
      } else {
        reset(new DealContactDetails({ email }));
        setLoadingStates((prev) => ({
          ...prev,
          isExistingContact: false,
        }));
      }
    } finally {
      setLoadingStates((prev) => ({
        ...prev,
        isEnteringEmail: false,
        isLoadingContact: false,
      }));
      trigger();
    }
  };

  const renderEmailForm = () => (
    <Row className="mb-md pr-xl">
      <Col lg={6}>
        <label className="fw-semibold">
          {translate("NEW_KYC_FORM.EMAIL")} *
        </label>
        <RHFInput
          type="email"
          required
          placeholder="email@example.com"
          disabled={isLoadingContact}
          errorMessage={
            isExistingContact ? (
              <>
                <Warning20Regular className="mr-xs" /> {existingContactError}
              </>
            ) : (
              errors.email?.message
            )
          }
          register={emailOptions}
          onChange={({ target }) => {
            setLoadingStates((prev) => ({
              ...prev,
              isExistingContact: false,
            }));
            setValue("email", target.value, {
              shouldValidate: true,
            });
          }}
          onBlur={() => {
            fetchContact(getValues("email"));
          }}
          onKeyDown={() => {
            setLoadingStates((prev) => ({
              ...prev,
              isEnteringEmail: false,
            }));
          }}
        />
      </Col>
      {isLoadingContact && (
        <Col className="d-flex align-items-center">
          <Spinner size={SpinnerSize.Tiny} className="mt-lg" />
        </Col>
      )}
    </Row>
  );

  const renderContactDetailsForm = () => (
    <Row>
      <Row>
        <Col lg={6} className="mb-md">
          <label className="fw-semibold">
            {translate("NEW_KYC_FORM.FIRST_NAME")} *
          </label>
          <RHFInput
            placeholder={translate("NEW_KYC_FORM.FIRST_NAME")}
            errorMessage={errors.first_name?.message}
            register={firstNameOptions}
            disabled={isEnteringEmail || isLoadingContact}
            maxLength={50}
            onChange={({ target }) => {
              setValue("first_name", target.value, {
                shouldValidate: true,
              });
            }}
          />
        </Col>
        <Col lg={6} className="mb-md">
          <label className="fw-semibold">
            {translate("NEW_KYC_FORM.LAST_NAME")} *
          </label>
          <RHFInput
            placeholder={translate("NEW_KYC_FORM.LAST_NAME")}
            errorMessage={errors.last_name?.message}
            register={lastNameOptions}
            disabled={isEnteringEmail || isLoadingContact}
            maxLength={50}
            onChange={({ target }) => {
              setValue("last_name", target.value, {
                shouldValidate: true,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mb-md">
          <label className="fw-semibold">
            {translate("NEW_KYC_FORM.PHONE_NUMBER")}
          </label>
          <RHFInput
            type="text"
            placeholder={translate("NEW_KYC_FORM.PHONE_NUMBER")}
            errorMessage={errors.mobile_phone?.message}
            register={phoneNumberOptions}
            disabled={isEnteringEmail || isLoadingContact}
            onChange={({ target }) => {
              setValue("phone_number", getDigitsFromString(target.value), {
                shouldValidate: true,
              });
            }}
          />
        </Col>
        <Col lg={6} className="mb-md">
          <label className="fw-semibold">{translate("NEW_KYC_FORM.SSN")}</label>
          <RHFInput
            type="text"
            placeholder={translate("NEW_KYC_FORM.SSN")}
            errorMessage={errors.social_security_number?.message}
            register={ssnOptions}
            disabled={isEnteringEmail || isLoadingContact}
            onChange={({ target }) => {
              setValue(
                "social_security_number",
                getDigitsFromString(target.value),
                {
                  shouldValidate: true,
                }
              );
            }}
          />
        </Col>
      </Row>
    </Row>
  );

  const customerSteps = [
    {
      key: "1",
      label: translate("EMAIL_VERIFICATION"),
      circleLabel: "A",
      component: renderEmailForm(),
      state: {
        highlighted: !getValues("email") || !isValidEmail(getValues("email")),
        active: true,
        disabled: isLoadingContact,
      },
    },
    {
      key: "2",
      label: translate("CONTACT_PERSON_DETAILS"),
      circleLabel: "B",
      component: !isEmailFilled ? (
        <span className="text-gray fs-italic">
          {translate("EMAIL_VERIFY_INFO")}
        </span>
      ) : (
        renderContactDetailsForm()
      ),
      state: {
        highlighted: isEmailFilled,
        active: isEmailFilled,
        disabled: !isEmailFilled || isLoadingContact,
      },
    },
  ];

  const createContact = async () => {
    setLoadingStates((prev) => ({
      ...prev,
      isLoading: true,
    }));
    const tempContact = getValues();
    tempContact.created_by_username = tempContact.created_by_username
      ? tempContact.created_by_username
      : currentUser.email;
    tempContact.changed_by_username = currentUser.email;
    tempContact.mobile_phone = existingContact?.mobile_phone
      ? existingContact.mobile_phone
      : "";

    await dispatch(
      createOrUpdateCompanyContact({
        contactData: tempContact,
        orgNum,
      })
    );
    dispatch(
      appendToastMessage("NEW_KYC_FORM.CONTACT_ADDED_SUCCESSFULLY", "success")
    );
    setLoadingStates((prev) => ({
      ...prev,
      isLoading: false,
    }));
    setOpenModal(false);
  };

  return (
    <Modal
      onDismiss={() => setOpenModal(false)}
      isOpen
      size="large"
      footer={
        <ModalFooter
          isLoading={isLoading}
          onSave={() => {
            createContact();
          }}
          labelSubmit={translate("NEW_KYC_FORM.CREATE_CONTACT")}
          sendButtonIcon={<Checkmark20Regular />}
          cancelButtonIcon={<ArrowLeft20Regular />}
          onCancel={() => setOpenModal(false)}
          isDisabled={
            !isValid || isExistingContact || isLoadingContact || isEnteringEmail
          }
        />
      }
      header={
        <ModalHeader
          headerTitleText="NEW_KYC_FORM.CREATE_CONTACT_TITLE"
          subheaderText="NEW_KYC_FORM.CREATE_CONTACT_SUBTITLE"
        />
      }
    >
      <VerticalStepper steps={customerSteps} />
    </Modal>
  );
}
