import { Customer } from "models/customer";
import { User, UserRoles } from "models/user";

export const isCustomerSupportUser = (currentUser: User) => {
  return currentUser?.roles?.includes(UserRoles.USERS_SUPPORT) || false;
};

// Checks if the user is a team owner or support team user
export const isUserTeamOwner = (customer: Customer, currentUser: User) => {
  if (customer.ms_team?.owners?.includes(currentUser.graphId)) {
    return true;
  }

  if (isCustomerSupportUser(currentUser)) {
    return true;
  }

  return false;
};

// Checks if the user is in the ms team (owner or member) or support team user
export const isUserInCustomerTeam = (
  customer: Customer,
  currentUser: User,
  shouldCheckIsCustomerSupportUser = true
) => {
  return (
    (customer.ms_team?.owners?.length > 0 &&
      customer.ms_team.owners.includes(currentUser.graphId)) ||
    (customer.ms_team?.members?.length > 0 &&
      customer.ms_team.members.includes(currentUser.graphId)) ||
    (shouldCheckIsCustomerSupportUser && isCustomerSupportUser(currentUser))
  );
};

export const isCustomerWithRestrictedAccess = (customer: Customer) => {
  return customer.restrict_access?.some((setting) => setting.status);
};

export const isUserRestricted = (customer: Customer, currentUser: User) => {
  if (isCustomerSupportUser(currentUser)) {
    return false;
  }
  return isCustomerWithRestrictedAccess(customer)
    ? !isUserTeamOwner(customer, currentUser)
    : !isUserInCustomerTeam(customer, currentUser);
};

export const isAllowedToSyncActivities = (
  customer: Customer,
  currentUser: User
) => {
  return (
    isUserTeamOwner(customer, currentUser) ||
    isCustomerSupportUser(currentUser) ||
    !!currentUser.roles?.includes(UserRoles.USERS_SUPER)
  );
};

export const isAllowedToGetTemporaryAccess = (
  customer: Customer,
  currentUser: User
) => {
  return (
    !customer.ms_team?.owners?.includes(currentUser.graphId) &&
    !customer.ms_team?.members?.includes(currentUser.graphId) &&
    !isCustomerWithRestrictedAccess(customer) &&
    customer.teamsStatus
  );
};
