export enum DealType {
  "Generell förfrågan" = "1",
  "Värdeindikator - fastighetsvärdering" = "2",
  "Offertförfrågan ekonomi" = "3",
  "Kostnadsfri värdebedömning" = "4",
  "Kostnadsfritt rådgivningsmöte" = "5",
  "Centrala juristenheten" = "6",
}

export enum FieldGroupKeys {
  PROCESSING = "processing",
  CONTACT = "contact",
  COMPANY = "company",
  REQUEST = "request",
}
