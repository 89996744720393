import Tooltip from "components/tooltip/Tooltip";
import { ReactNode } from "react";
import { Edit16Regular } from "@fluentui/react-icons";

import "./InfoCard.scss";

type InfoCardProps = {
  onEdit?: () => void;
  itemSelected?: (arg?: any) => void;
  contextualMenu?: ReactNode;
  showRibbon?: boolean;
  ribbonTooltipKey?: string;
} & React.HTMLAttributes<HTMLElement>;

export default function InfoCard({
  onEdit,
  itemSelected,
  children,
  className,
  contextualMenu,
  showRibbon = false,
  ribbonTooltipKey = "",
  ...rest
}: InfoCardProps) {
  return (
    <div
      className={`${className ?? ""} cs-contact-card`}
      onClick={itemSelected}
      {...rest}
    >
      {!contextualMenu && onEdit && (
        <div className="edit-icon" onClick={() => onEdit()}>
          <Edit16Regular className="blue-link" />
        </div>
      )}
      {contextualMenu}
      {showRibbon && (
        <div className="ribbon-wrapper">
          <Tooltip content={ribbonTooltipKey}>
            <div className="ribbon"> </div>
          </Tooltip>
        </div>
      )}
      <div className={`cs-contact-card-content ${showRibbon ? "" : "pt-lg"}`}>
        {children}
      </div>
    </div>
  );
}
