import { plainToClass } from "class-transformer";

import AppConfig from "app-config";
import {
  CreateDeal,
  Deal,
  DealContact,
  DealOption,
  DynamicPropertyOption,
  SearchContact,
} from "models/deals/deal";
import { DealReturnProps } from "models/deals/dealReturnProps";
import { Pipeline } from "models/deals/pipeline";
import { Product } from "models/deals/product";
import { StageConfiguration } from "models/deals/stagesConfig";
import { DynamicDealPropertiesObjectName, DynamicDealPropertiesPropertyName } from "constants/enums/dealEnums";
import { getAuthorizationHeader } from "./libs/auth-header";

export class SalesAPI {
  static async searchContact(
    token: string,
    email: string
  ): Promise<SearchContact[]> {
    const response = await fetch(`${AppConfig.API_URL}/sales/contacts/search`, {
      method: "POST",
      body: JSON.stringify({
        restrictions: {
          emails: [email],
        },
      }),
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return (await response.json()) as SearchContact[];
  }

  static async fetchDealsProperties() {
    const response = await fetch(
      `${AppConfig.API_URL}/sales/properties/deals`,
      {}
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return (await response.json()) as DealOption[];
  }

  static async fetchPropertiesByObjectAndPropertyName(
    objectName: DynamicDealPropertiesObjectName,
    propertyName: DynamicDealPropertiesPropertyName
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/sales/properties/${objectName}/${propertyName}`,
      {}
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return (await response.json()) as DynamicPropertyOption[];
  }

  static async createContact(
    token: string,
    contact: Partial<DealContact>,
    orgNumber?: string
  ): Promise<SearchContact> {
    const queryString = orgNumber ? `?company_org_number=${orgNumber}` : "";
    const response = await fetch(
      `${AppConfig.API_URL}/sales/contacts${queryString}`,
      {
        method: "POST",
        body: JSON.stringify(contact),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return (await response.json()) as SearchContact;
  }

  static async updateContact(
    token: string,
    contactId: string,
    update: Partial<DealContact>
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/sales/contacts/${contactId}`,
      {
        method: "PATCH",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify(update),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return (await response.json()) as SearchContact;
  }

  static async createDeal(token: string, deal: Partial<CreateDeal>) {
    const response = await fetch(`${AppConfig.API_URL}/sales/deals`, {
      method: "POST",
      body: JSON.stringify(deal),
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async fetchDeals(token: string) {
    const response = await fetch(`${AppConfig.API_URL}/sales/deals`, {
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const jsonArray = (await response.json()) as Deal[];
    return jsonArray.map((json) => plainToClass(Deal, json));
  }

  static async fetchDealById(token: string, dealId: string) {
    const response = await fetch(`${AppConfig.API_URL}/sales/deals/${dealId}`, {
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return (await response.json()) as Deal;
  }

  static async fetchDealsByOrgNumber(
    token: string,
    orgNumber: string,
    association = true
  ) {
    const url = `${AppConfig.API_URL}/sales/deals/by_org_number/${orgNumber}?association=${association}`;
    const response = await fetch(url, {
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const jsonArray = (await response.json()) as Deal[];
    return jsonArray.map((json) => plainToClass(Deal, json));
  }

  static async fetchPipelines(token: string) {
    const response = await fetch(`${AppConfig.API_URL}/sales/pipelines`, {
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const jsonArray = (await response.json()) as Pipeline[];
    return jsonArray.map((json) => plainToClass(Pipeline, json));
  }

  static async fetchProducts(token: string) {
    const response = await fetch(`${AppConfig.API_URL}/sales/products`, {
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const jsonArray = (await response.json()) as Product[];
    return jsonArray.map((json) => plainToClass(Product, json));
  }

  static async updateDeal(
    token: string,
    dealId: string,
    update: Partial<Deal>
  ) {
    const response = await fetch(`${AppConfig.API_URL}/sales/deals/${dealId}`, {
      method: "PATCH",
      headers: {
        ...getAuthorizationHeader(token),
      },
      body: JSON.stringify(update),
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.json();
  }

  static async updateProducts(
    token: string,
    dealId: string,
    productIds: string[]
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/sales/deals/${dealId}/products`,
      {
        method: "PATCH",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({ productIds }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async returnDeal(
    token: string,
    dealId: string,
    returnProps: DealReturnProps
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/sales/deals/${dealId}/return_to_owner`,
      {
        method: "PATCH",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify(returnProps),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async getHubspotStagesList(token: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/list/hs_pipeline_stages`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const jsonArray = (await response.json()) as StageConfiguration[];
    return jsonArray.map((json) => plainToClass(StageConfiguration, json));
  }

  static async getCustomerContactsByOrgNumber(token: string, orgNum: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${orgNum}/contacts/by_org_number`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const jsonArray = (await response.json()) as DealContact[];
    return jsonArray.map((json) => plainToClass(DealContact, json));
  }
}
