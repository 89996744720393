import { useTranslation } from "hooks";
import {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
} from "components/layout/Layout";
import { ContentBody, Content } from "components/content/Content";

export function ReportsPage() {
  const { translate } = useTranslation();

  const renderChild = () => {
    return (
      <iframe
        title="Mina uppdrag"
        width="100%"
        height="95%"
        frameBorder="0"
        src="https://app.powerbi.com/reportEmbed?reportId=1965a3c3-571f-4224-97bb-a040b2cacd2c&autoAuth=true&ctid=2fe1e157-416a-4c84-85de-1bc44f011521"
        allowFullScreen
      />
    );
  };

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <h1>{translate("POWER_BI_REPORTS_HEADER")}</h1>
        </LayoutHeaderLeft>
      </LayoutHeader>

      <LayoutBody>
        <Content>
          <ContentBody>{renderChild()}</ContentBody>
        </Content>
      </LayoutBody>
    </>
  );
}
