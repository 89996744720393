import {
  Toolbar,
  ToolbarToggleButton,
  ToolbarToggleButtonProps,
  ToolbarProps,
  ButtonProps,
} from "@fluentui/react-components";

import "./ToggleButtonsGroup.scss";
import Tooltip, { TooltipProps } from "components/tooltip/Tooltip";

export interface ToggleButtonsProps {
  buttonProps: ToolbarToggleButtonProps;
  tooltipProps?: Omit<TooltipProps, "children">;
}

type ToggleButtonsGroupProps = {
  size?: ButtonProps["size"];
  buttons: ToggleButtonsProps[];
  checkedValues: Record<string, string[]>;
  onCheckedValueChange: ToolbarProps["onCheckedValueChange"];
} & ToolbarProps;

export function ToggleButtonsGroup({
  size,
  buttons,
  checkedValues,
  onCheckedValueChange,
  ...rest
}: ToggleButtonsGroupProps) {
  const getButtonPositioningClass = (index: number) => {
    if (index === 0) {
      if (buttons.length > 1) {
        return " first";
      }
    } else if (index === buttons.length - 1) {
      if (buttons.length > 1) {
        return " last";
      }
    } else if (index > 0 && index < buttons.length - 1) {
      return " middle";
    }

    return "";
  };

  const renderButton = (
    buttonProps: ToolbarToggleButtonProps,
    index: number,
    key?: React.Key
  ) => {
    const buttonPositioningClass = getButtonPositioningClass(index);

    const checked = checkedValues[buttonProps.name]?.includes(
      buttonProps.value
    );

    return (
      <ToolbarToggleButton
        {...buttonProps}
        key={key}
        className={`button-group-button ${buttonPositioningClass}${
          checked ? " toggled" : ""
        }`}
        size={buttonProps.size ?? size}
      >
        {buttonProps.children}
      </ToolbarToggleButton>
    );
  };

  return (
    <Toolbar
      checkedValues={checkedValues}
      onCheckedValueChange={onCheckedValueChange}
      className="button-group"
      {...rest}
    >
      {buttons.map((props, index) => {
        const { buttonProps, tooltipProps } = props;

        if (tooltipProps) {
          return (
            <Tooltip key={buttonProps.value} {...tooltipProps}>
              {renderButton(buttonProps, index)}
            </Tooltip>
          );
        }

        return renderButton(buttonProps, index, buttonProps.value);
      })}
    </Toolbar>
  );
}
