import React from "react";

import { TransparentAccordionContent } from "./components/TransparentAccordionBody";
import { TransparentAccordionHeader } from "./components/TransparentAccordionHeader";
import { AccordionProvider } from "../context/AccordionContextProvider";
import "./index.scss";

interface Props {
  title: string;
  children: React.ReactNode;
  loadingStatus?: string;
  isInitiallyOpen?: boolean;
  subtitle?: string;
  expanded?: boolean;
  infoTooltip?: string;
  className?: string;
  lengthIndicator?: number;
}

export function TransparentAccordion({
  title,
  children,
  loadingStatus,
  isInitiallyOpen = false,
  subtitle,
  expanded,
  infoTooltip,
  className,
  lengthIndicator,
}: Props) {
  return (
    <AccordionProvider isInitiallyOpen={isInitiallyOpen} expanded={expanded}>
      <div className={`accordion-custom-item2 ${className}`}>
        <TransparentAccordionHeader
          title={title}
          loadingStatus={loadingStatus}
          subtitle={subtitle}
          infoTooltip={infoTooltip}
          lengthIndicator={lengthIndicator}
        />
        <TransparentAccordionContent loadingStatus={loadingStatus}>
          {children}
        </TransparentAccordionContent>
      </div>
    </AccordionProvider>
  );
}
