import { PickerData } from "models/offer/Picker";

export const availableServiceTypes: PickerData[] = [
  {
    name: "Accounting",
    groups: [],
  },
  {
    name: "Other",
    groups: [
      {
        id: 1,
        options: [
          {
            name: "Special_advisory",
            externalURL: "https://app.verified.eu/#/login?lang=sv_SE",
          },
          {
            name: "Real_estate",
            externalURL:
              "https://intranet.ludvig.se/affarsomraden/fastighetsformedling/samarbetspartners-system/verified/",
          },
          {
            name: "Legal",
            externalURL: "https://app.verified.eu/#/login?lang=sv_SE",
          },
          {
            name: "Payroll",
            externalURL: "https://app.verified.eu/#/login?lang=sv_SE",
          },
        ],
      },
    ],
  },
];
