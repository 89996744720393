import { createSlice } from "@reduxjs/toolkit";
import { User } from "models/user";
import { LoadingStatus } from "..";
import { fetchCurrentUserAzureGroups, fetchUser } from "./userThunks";

export type UsersState = {
  currentUser: User;
  loadingStatus: LoadingStatus;
  error: string[];

  graphToken?: string;
};

const initialState: UsersState = {
  loadingStatus: "idle",
  currentUser: {} as User,
  graphToken: undefined,
  error: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUser.rejected, (state: UsersState, { error }) => {
      state.loadingStatus = "failed";
    });
    builder.addCase(fetchUser.fulfilled, (state: UsersState, { payload }) => {
      state.loadingStatus = "succeeded";
      state.currentUser = payload;
    });
    builder.addCase(
      fetchCurrentUserAzureGroups.fulfilled,
      (state: UsersState, { payload }) => {
        state.loadingStatus = "succeeded";
        state.currentUser.azureGroups = payload;
      }
    );
    builder.addCase(fetchUser.pending, (state: UsersState) => {
      state.loadingStatus = "pending";
    });
  },
});
