import { getOnlyNumbers } from "libs/number-format";
import { formatToTextOnlyString } from "libs/text-only-string";

export function taxObjectName(deliveryName: string, taxObjName: string) {
  const taxObjNameFormatted = formatToTextOnlyString(taxObjName);
  const deliveryNameShort = deliveryName.slice(0, 3).toUpperCase();
  const deliveryNameNumber = getOnlyNumbers(deliveryName);

  return `${deliveryNameShort}${deliveryNameNumber} ${taxObjNameFormatted}`;
}
