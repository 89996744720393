import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add16Regular } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import { isValidSwedishSSN } from "libs/is-valid-ssn";
import { isValidEmail } from "libs/is-valid-email";
import { useServiceMatrix } from "libs/service-matrix";
import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";
import { isPlaceholderEmail } from "libs/generate-placeholder-email";
import { isValidPhone } from "libs/is-valid-phone";
import {
  createIncomeTaxObjectServices,
  getServiceCategoryWithDefaultServices,
} from "helpers/DealToPogHelper";
import { AppDispatch } from "state/use-app-redux";
import { LoadingStatus, RootState } from "state";
import { appendToastMessage } from "state/notifications";
import {
  addOfferSelectedServiceLine,
  addServiceLineGroup,
} from "state/offer/offersSlice";
import DealContactDetails from "models/offer/DealContactDetails";
import { SigningMethod } from "models/offer/DealContact";
import { ServiceGroup } from "models/offer/ServiceLine";
import { EmptyList } from "components/EmptyList";
import { searchContact } from "state/sales/actions";
import { defaultPaginatorOptions } from "components/Pagination/consts";
import Paginator from "components/Pagination";
import { WizardSection } from "views/createNew/offer/components/wizardSection";
import { ContactsList } from "./components/ContactsList";
import ContactsEditModal from "./components/ContactsEditModal";

export default function CustomerContactContacts() {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [itemsShownPerPage, setItemsShownPerPage] = useState<number>(10);
  const [selectedContact, setSelectedContact] = useState<DealContactDetails>();

  const offers = useSelector((state: RootState) => state.offers);
  const currentOffer = offers.currentOffer.data;
  const offerCustomer = currentOffer?.customer;
  const signer = currentOffer?.contacts.find((contact) => contact.is_signer);
  const user = useSelector((state: RootState) => state.users.currentUser);
  const { data: offerTemplate } = useSelector(
    (state: RootState) => state.offers.offerTemplate
  );
  const deals = useSelector((state: RootState) => state.sales);
  const sales = useSelector((state: RootState) => state.sales);
  const products = sales.products.data;
  const companyContacts = offers.availableCompanyContacts.data;

  const [loadingDataFor, setLoadingDataFor] = useState("");

  const { GetAllServiceGroupsFlattened, GetAllServiceLinesFlattened } =
    useServiceMatrix();

  const serviceGroups = GetAllServiceGroupsFlattened(offerTemplate);
  const serviceLines = GetAllServiceLinesFlattened(offerTemplate);

  const hubspotDeal = deals.data.find(
    (deal) => deal.id === currentOffer?.hubspot_deal_id
  );

  const isFormValid = useCallback(() => {
    if (!signer) {
      return false;
    }

    /**
     * Not optimal since we need to sync this validation
     * with the one in the ContactsEditModal. Works for now.
     */
    const { contact } = signer;
    if (
      isValidSwedishSSN(contact.social_security_number) &&
      isValidEmail(contact.email) &&
      contact.first_name &&
      contact.last_name &&
      (!contact.phone_number ||
        (contact.phone_number && isValidPhone(contact.phone_number))) &&
      signer.is_signer &&
      ((signer.signing_method === SigningMethod.BANKID &&
        !isPlaceholderEmail(contact.email)) ||
        signer.signing_method === SigningMethod.PHYSICAL)
    ) {
      return true;
    }

    return false;
  }, [signer]);

  const getEditContactData = useCallback(
    async (contact: DealContactDetails) => {
      setLoadingDataFor(contact.id);
      const searchContacts = await dispatch(searchContact(contact.email));
      const contactFound = searchContacts && searchContacts[0];
      const editModalContactData = { ...contact };
      if (contactFound) {
        editModalContactData.phone_number = contactFound.phone;
      }
      setSelectedContact(editModalContactData);
      setLoadingDataFor("");
      setIsOpenModal(true);
    },
    [dispatch]
  );

  useEffect(() => {
    // Don't open edit modal if form is already valid
    if (isFormValid()) {
      return;
    }

    const contacts = currentOffer?.contacts;

    // Don't open modal if we have one or more contact either in deal or in company contacts
    if (
      (contacts && contacts.length >= 1) ||
      (companyContacts && companyContacts.length >= 1)
    ) {
      return;
    }

    let contactToEdit;

    if (signer?.contact) {
      // If we have a signer contact that is invalid
      contactToEdit = new DealContactDetails({
        ...signer.contact,
      });
    } else if (contacts && contacts.length === 1) {
      // If we have one contact that is invalid
      contactToEdit = new DealContactDetails({
        ...contacts[0].contact,
      });
    } else if (companyContacts && companyContacts.length === 1) {
      // If we have one contact in company contacts
      contactToEdit = new DealContactDetails({
        ...companyContacts[0],
      });
    } else {
      // If we have no contacts
      contactToEdit = new DealContactDetails();
    }

    getEditContactData(contactToEdit);
  }, [
    currentOffer,
    offers.availableCompanyContacts,
    signer,
    isFormValid,
    getEditContactData,
    companyContacts,
  ]);

  const handleModalToggle = (isOpen: boolean) => {
    if (!isOpen) {
      setSelectedContact(undefined);
    }

    setIsOpenModal(isOpen);
  };

  const handleAddContact = () => {
    setSelectedContact(new DealContactDetails());
    handleModalToggle(true);
  };

  const getSubTitle = () => {
    if (
      signer &&
      signer.contact.social_security_number &&
      (signer.contact.first_name || signer.contact.last_name)
    ) {
      return translate("CONTACT_SELECTED_INFO", [
        `${signer.contact.first_name} ${signer.contact.last_name}`,
      ]);
    }

    return companyContacts.length === 0
      ? translate("ADD_FIRST_CONTACT")
      : translate("CONTACT_SELECT_INFO");
  };

  const renderAddButton = () => (
    <div className="add-new-btn sticky-button-wrapper my-sm">
      <div
        className="p-sm cursor-pointer pog-link d-flex fit-content align-items-center"
        onClick={handleAddContact}
      >
        <Add16Regular />
        <span className="ml-sm">{translate("CREATE_CONTACT")}</span>
      </div>
    </div>
  );

  const getLoadingStatus = (): LoadingStatus => {
    if (offers.availableCompanyContacts.status !== "idle") {
      return offers.availableCompanyContacts.status;
    }

    if (offers.currentOffer.status !== "idle") {
      return offers.currentOffer.status;
    }

    return "idle";
  };

  if (!offers.currentOffer.data) {
    return <>Missing offer</>;
  }

  if (!offerCustomer) {
    return <>Missing customer</>;
  }

  // adding the serviceLine and service groups to the offer
  // (eg. Accounting as service area, Income tax as service group etc.)
  const addServiceLinesAndGroupsToPogOffer = () => {
    if (hubspotDeal) {
      const mappedDealProducts = hubspotDeal.productIds.map(
        (pid) =>
          products
            ?.find((product) => product.id === pid)
            ?.description?.toLowerCase() ?? ""
      );
      const accountingServiceLine = serviceLines.find(
        (serviceLine) => serviceLine.name === ACCOUNTING_SERVICE_LINE
      );
      if (accountingServiceLine) {
        dispatch(addOfferSelectedServiceLine(accountingServiceLine));
      }

      const dealServiceGroups = serviceGroups.filter((group) =>
        mappedDealProducts.includes(group.name?.toLowerCase())
      );
      dealServiceGroups.forEach((serviceGroup) => {
        const groupCatagories = serviceGroup.service_categories
          .filter((category) => category.name)
          .map((category) => {
            const serviceCategory =
              getServiceCategoryWithDefaultServices(category);
            return serviceCategory;
          });

        if (groupCatagories.length > 0 && currentOffer.customer) {
          const allServices = groupCatagories.flatMap((sc) => sc.services);

          createIncomeTaxObjectServices(
            allServices,
            currentOffer.customer,
            user,
            dispatch
          );
          const newGroup: ServiceGroup = {
            name: serviceGroup.name,
            service_categories: groupCatagories,
            sorting_number: serviceGroup.sorting_number,
            skip_price_calculation: serviceGroup.skip_price_calculation,
          };
          dispatch(addServiceLineGroup(newGroup));
        }
      });
    }
  };

  return (
    <WizardSection
      loadingStatus={getLoadingStatus()}
      numberBreadCrumb={companyContacts.length}
      content={
        <div className="d-flex w-100 justify-content-end align-items-flex-end">
          <Paginator
            itemsLength={companyContacts.length}
            defaultItemsShownPerPage={itemsShownPerPage}
            onChangeShownItems={(v) => setItemsShownPerPage(v)}
            onSelectPage={setActivePage}
            options={defaultPaginatorOptions}
          />
        </div>
      }
      onBeforeNext={async () => {
        let signingMethodTranslation = "";
        if (signer?.signing_method === SigningMethod.BANKID) {
          signingMethodTranslation = translate("SIGNING_METHOD_DIGITAL");
        } else if (signer?.signing_method === SigningMethod.PHYSICAL) {
          signingMethodTranslation = translate("SIGNING_METHOD_PHYSICAL");
        }

        await dispatch(
          appendToastMessage("SIGNER_INFO_TOAST", "info", [
            signingMethodTranslation.toLowerCase(),
          ])
        );

        if (currentOffer.new_customer && hubspotDeal) {
          addServiceLinesAndGroupsToPogOffer();
        }

        return true;
      }}
      isNextHidden={!isFormValid()}
      subtitles={[getSubTitle()]}
    >
      <div className="sticky-button-container">
        <EmptyList
          loadingStatus={offers.availableCompanyContacts.status}
          isEmpty={companyContacts.length === 0}
          title={translate("NO_CONTACTS_FOUND")}
          actionButton={renderAddButton()}
        >
          <ContactsList
            onPressEditContact={getEditContactData}
            loadingDataFor={loadingDataFor}
            activePage={activePage}
            itemsShownPerPage={itemsShownPerPage}
          />
          {renderAddButton()}
        </EmptyList>

        {isOpenModal && (
          <ContactsEditModal
            isOpen={isOpenModal}
            onToggle={handleModalToggle}
            contactDetails={selectedContact}
            isInOffer
          />
        )}
      </div>
    </WizardSection>
  );
}
