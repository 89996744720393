import { ArrowUpRight16Regular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "components/button";
import { OverlaySpinner } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { PowerOfAttorney } from "models/customerSettings/customerSettingsPowerofAttorney";
import { CustomerPageDetailedPageEnum } from "constants/enums/CustomerPageDetailedPage.enum";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import CustomerPagePowerOfAttorneyCommentModal from "./customerPagePowerOfAttorneyCommentModal";
import CustomerPagePowerOfAttorneyModal from "./customerPagePowerOfAttorneyModal";
import CustomerPagePowerOfAttorneyRow from "./customerPagePowerOfAttorneyRow";
import CustomerPagePowerOfAttorneyUpdateModal from "./customerPagePowerOfAttorneyUpdateStatusModal";

type Props = {
  customerId: string;
};

const MAX_ELEMENTS = 6;

export default function CustomerPagePowerOfAttorney({ customerId }: Props) {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const lastUrlSegment = location.pathname.split("/").pop();
  const isDetailedPage =
    lastUrlSegment === CustomerPageDetailedPageEnum.POWER_OF_ATTORNEY;

  const powerOfAttorneyStatus = useSelector(
    (state: RootState) => state.settings.powerOfAttorney.status
  );
  const powerOfAttorneyData = useSelector(
    (state: RootState) => state.settings.powerOfAttorney.data
  );
  const serviceLine = useSelector(
    (state: RootState) => state.settings.serviceLines.currentServiceLine
  );

  const powerOfattorneyStatuses = useSelector(
    (state: RootState) => state.settings.powerOfAttorney.statuses
  );
  const statusOptions = [
    ...powerOfattorneyStatuses.map((status) => ({
      value: status.id,
      text: status.title,
      disabled: !status.fe_input,
    })),
  ];
  const searchTerm = useSelector(
    (state: RootState) => state.settings.searchTerm
  );

  const openCreateModalFor = useSelector(
    (state: RootState) => state.settings.openCreateModalFor
  );

  const [selectedStatus, setSelectedStatus] = useState<number>();

  const isLoading = useSelector((state: RootState) => state.settings.isLoading);
  const [openModal, setOpenModal] = useState(false);
  const [newPoaObj, setNewPoaObj] = useState<PowerOfAttorney>();
  const [currPoaObj, setCurrPoaObj] = useState<PowerOfAttorney>();
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updateStatusId, setUpdateStatusId] = useState<number>();
  const [openEditModal, setOpenEditModal] = useState(false);

  const powerOfAttorneyDataToShow = isDetailedPage
    ? powerOfAttorneyData
    : powerOfAttorneyData.slice(0, MAX_ELEMENTS);

  const powerOfAttorneyDataToShowFrom = searchTerm
    ? powerOfAttorneyDataToShow.filter(
        (poa) =>
          poa.powerofattorneysource.title.toLowerCase().includes(searchTerm) ||
          poa.powerofattorneytype.title.toLowerCase().includes(searchTerm) ||
          poa.employee_name?.toLowerCase().includes(searchTerm) ||
          poa.comment?.toLowerCase().includes(searchTerm)
      )
    : powerOfAttorneyDataToShow;

  const onStatusChange = (
    statusId: number,
    powerOfAttorney: PowerOfAttorney
  ) => {
    setCurrPoaObj(powerOfAttorney);
    setUpdateStatusId(statusId);
    setOpenUpdateModal(true);
  };

  const onCommentClick = (powerOfAttorney: PowerOfAttorney) => {
    setCurrPoaObj(powerOfAttorney);
    setOpenCommentModal(true);
  };

  const handleConfirm = (status: number) => {
    setSelectedStatus(status);
  };

  const openPowerOfAttorneyPage = () => {
    navigate(AppRouteHelper.getCustomerPowerOfAttorneyPage(customerId));
  };

  const onEditClick = (powerOfAttorney: PowerOfAttorney) => {
    setCurrPoaObj(powerOfAttorney);
    setOpenEditModal(true);
  };

  useEffect(() => {
    if (openCreateModalFor === CustomerPageDetailedPageEnum.POWER_OF_ATTORNEY) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [openCreateModalFor]);

  return (
    <div>
      {powerOfAttorneyStatus &&
        powerOfAttorneyStatus === LoadingStatusEnum.PENDING && (
          <OverlaySpinner />
        )}

      <div className={`cs-content ${isDetailedPage ? "p-lg" : "p-xs"}`}>
        <Row className="px-md pt-md m-auto">
          <Col md={12} className="">
            <Row className="border-bottom">
              <Col md={2}>
                <span className="fw-semibold">{translate("PROVIDER")}</span>
              </Col>
              <Col md={2}>
                <span className="fw-semibold">{translate("TYPE")}</span>
              </Col>
              <Col md={3}>
                <span className="fw-semibold">{translate("COMMENT")}</span>
              </Col>
              <Col md={2}>
                <span className="fw-semibold">{translate("HOLDER")}</span>
              </Col>
              <Col md={1}>
                <span className="fw-semibold">{translate("PATH")}</span>
              </Col>
              <Col md={1}>
                <span className="fw-semibold">{translate("STATUS")}</span>
              </Col>
              <Col md={1} />
            </Row>
          </Col>
        </Row>

        <Row className="mb-md p-lg">
          {powerOfAttorneyDataToShowFrom.map(
            (reciever) =>
              reciever.status.fe_visible && (
                <CustomerPagePowerOfAttorneyRow
                  key={`poa ${reciever.id}`}
                  powerofattorney={reciever}
                  value={selectedStatus}
                  statusOptions={statusOptions}
                  onStatusChange={(statusId: number) =>
                    onStatusChange(statusId, reciever)
                  }
                  onCommentClick={() => onCommentClick(reciever)}
                  isDetailedPage={isDetailedPage}
                  onEditClick={() => onEditClick(reciever)}
                />
              )
          )}

          {newPoaObj && isLoading && (
            <Row key={`poa ${newPoaObj.id}`} className="text-muted">
              <Col className="pt-md ps-lg" md={2}>
                {newPoaObj.powerofattorneysource.title}
              </Col>
              <Col className="pt-md ps-lg" md={2}>
                {newPoaObj.powerofattorneytype.title}
              </Col>
              <Col className="pt-md ps-lg" md={3}>
                {newPoaObj.comment}
              </Col>
              <Col className="pt-md ps-lg" md={2}>
                {newPoaObj.employee_name || "Name couldn't be retrieved."}
              </Col>
              <Col className="pt-md ps-lg" md={1}>
                {newPoaObj.path}
              </Col>
              <Col className="pt-md ps-lg" md={1}>
                {newPoaObj.status.title}
              </Col>
              <Col md={1} />
            </Row>
          )}
        </Row>
      </div>

      {openModal && (
        <CustomerPagePowerOfAttorneyModal
          customerId={customerId}
          openModal={openModal}
          setOpenModal={setOpenModal}
          serviceLine={serviceLine}
          onChange={setNewPoaObj}
          statusOptions={statusOptions}
        />
      )}
      {openCommentModal && currPoaObj && currPoaObj.comment && (
        <CustomerPagePowerOfAttorneyCommentModal
          comment={currPoaObj.comment}
          isOpen={openCommentModal}
          onDismiss={() => {
            setOpenCommentModal(false);
          }}
        />
      )}
      {openUpdateModal && currPoaObj && updateStatusId && (
        <CustomerPagePowerOfAttorneyUpdateModal
          powerOfAttorneyObj={currPoaObj}
          isOpen={openUpdateModal}
          onDismiss={() => {
            setUpdateStatusId(currPoaObj.status.id);
            setOpenUpdateModal(false);
          }}
          onConfirm={() => handleConfirm(updateStatusId)}
          updateStatusId={updateStatusId}
          onOpenChange={setOpenUpdateModal}
        />
      )}

      {openEditModal && currPoaObj && (
        <CustomerPagePowerOfAttorneyModal
          customerId={customerId}
          openModal={openEditModal}
          setOpenModal={setOpenEditModal}
          serviceLine={serviceLine}
          onChange={setCurrPoaObj}
          statusOptions={statusOptions}
          poaToUpdate={currPoaObj}
        />
      )}

      {!isDetailedPage && (
        <Col md={12}>
          <Row className="mt-md">
            <div className="d-flex align-items-end mb-xs px-0">
              <Button
                variant="outline-outline"
                className="justify-content-start primary-colored-text"
                onClick={() => openPowerOfAttorneyPage()}
              >
                <ArrowUpRight16Regular />
                <span className="d-inline mx-sm fw-bold primary">
                  {translate("POA_NEW_PAGE")}
                </span>
              </Button>
            </div>
          </Row>
        </Col>
      )}
    </div>
  );
}
