export const EXCLUDE_BUSINESS_AREAS = [
  "Fakturafrågor",
  "Fakturorfrågor",
  "Lediga tjänster/Studentprojekt",
  "Annat",
];
export const INCLUDED_SOURCES = ["1", "2", "3", "9", "12", "13", "14"];
export const DEAL_SOURCE = "deal_source";
export const CUSTOMER_TYPE_COMPANY = "company";
export const CUSTOMER_TYPE_PRIVATE_PERSON = "person";
