/* eslint-disable no-underscore-dangle */
import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { Picker } from "components/picker";
import { useTranslation } from "hooks";
import { EXCLUDE_BUSINESS_AREAS } from "constants/dealConstants";
import { RootState } from "state";
import { CreateBusinessOpportunityContext } from "views/createNew/businessOpportunity/context/CreateBusinessOpportunityContext";
import WizardSectionBusinessOpportunity from "views/createNew/businessOpportunity/components/WizardSectionBusinessOpportunity";

export default function BusinessAreaPicker() {
  const { ts, translate } = useTranslation();
  const { updateNewDeal, newDeal } = useContext(
    CreateBusinessOpportunityContext
  );
  const deals = useSelector((state: RootState) => state.sales);
  const dealsBusinessAreas = deals.dealOptions.data.find(
    (d) => d.name === "affarsomrade_deal_"
  );

  const selectBusinessArea = (businessArea: string) => {
    updateNewDeal({
      affarsomrade_deal_:
        newDeal.affarsomrade_deal_ === businessArea ? "" : businessArea,
    });
  };

  return (
    <WizardSectionBusinessOpportunity
      subtitles={[translate("BO_BUSINESS_AREA_SUBHEADER")]}
      isNextDisabled={!newDeal.affarsomrade_deal_}
      nextLabel="ADD_BUSINESS_DETAILS"
      backLabel="BACK_TO_CONTACT_DETAILS"
      disabledBackMessage={translate(
        "BUSINESS_DETAILS_DISABLE_BACK_MESSAGE_CREATE"
      )}
    >
      <Row className="minHeight-100">
        <Col xl={2} lg={1} className="pt-lg m-auto">
          <div className="horizontal-divider " />
        </Col>
        <Col xl={8} lg={10} className="m-auto">
          <div className="d-flex flex-wrap justify-content-center fg-4">
            {dealsBusinessAreas?.options
              .filter(
                (option) => !EXCLUDE_BUSINESS_AREAS.includes(option.value)
              )
              .map((businessArea) => {
                return (
                  <Picker
                    key={businessArea.value}
                    className="mx-sm"
                    excludingGroups
                    checked={newDeal.affarsomrade_deal_ === businessArea.value}
                    onToggle={() => selectBusinessArea(businessArea.value)}
                    name={businessArea.label}
                    toggles={[]}
                    description={ts(`${businessArea.value}_DESCRIPTION`)}
                  />
                );
              })}
          </div>
        </Col>
        <Col xl={2} lg={1} className="pt-lg m-auto">
          <div className="horizontal-divider" />
        </Col>
      </Row>
    </WizardSectionBusinessOpportunity>
  );
}
