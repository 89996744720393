import { useEffect, useState } from "react";
import {
  ArrowDownload16Regular,
  ArrowUpRight24Regular,
  ArrowUpload16Regular,
} from "@fluentui/react-icons";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { OffersAPI } from "api/offers";
import { useAuth } from "auth/use-auth";

import { RootState } from "state";
import { SearchBy, searchForCustomer } from "state/offer/companyThunks";
import { CompletionStatus, setCompletionStatus } from "state/offer/offersSlice";
import { fetchDeal, sendOfferForSigning } from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import { appendToastMessage } from "state/notifications";
import { useTranslation } from "hooks/use-translate";
import { downloadFileFromURL } from "libs/download-file-from-url";
import { DealIdCheck } from "models/dealIdCheck";
import { BoxAction } from "components/Box/BoxAction";
import { BoxBody } from "components/Box/BoxBody";
import { BoxHorizontalHr } from "components/Box/BoxHorizontalHr";
import { Boxes } from "components/Box/Boxes";
import BoxLayout from "components/Box/BoxLayout";
import {
  useGetDealFileUploadUrlQuery,
  useGetKYCFileUploadUrlQuery,
  usePostDealIdCheckMutation,
} from "api/dealApi";
import { OfferRouteHelper } from "views/createNew/offer/routes/offerRoutes";
import DownloadPdfButton from "views/createNew/offer/wizard/components/downloadPdfButton";
import { WizardSection } from "views/createNew/offer/components/wizardSection";
import { CompletionPhysicalModal } from "./CompletionPhysicalModal";


export default function CompletionManualKyc() {
  const { offerId, orgId } = useParams<{ orgId: string; offerId: string }>();

  const { user } = useAuth();
  const { translate } = useTranslation();

  const currentOfferStatus = useSelector(
    (state: RootState) => state.offers.currentOffer.status
  );

  const { data: currentOffer } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );

  const contact = currentOffer?.contacts.find((c) => c.is_signer);

  const { data: pdfUploadData, isLoading: isPdfUploadDataLoading } =
    useGetDealFileUploadUrlQuery(
      { dealIdOrSSN: currentOffer?.id ?? "" },
      { skip: !currentOffer?.id }
    );

  const { data: kycUploadData, isLoading: isKycUploadDataLoading } =
    useGetKYCFileUploadUrlQuery(
      {
        orgNumber: currentOffer?.customer?.org_number ?? "",
        dealId: currentOffer?.id ?? "",
      },
      { skip: !currentOffer?.customer?.org_number || !currentOffer?.id }
    );

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [isAgreementDownloaded, setIsAgreementDownloaded] = useState(false);

  const [isKYCTemplateDownloaded, setIsKYCTemplateDownloaded] = useState(false);

  const [isConfirmationModalFilled, setIsConfirmationModalFilled] =
    useState(false);

  const [postDealIdCheck, { isLoading: isPostDealIdCheckLoading }] =
    usePostDealIdCheckMutation();

  useEffect(() => {
    //
    // If we're coming from a direct URL, fetch data based on query params
    //
    if (orgId && offerId && !currentOffer?.id) {
      dispatch(
        searchForCustomer({
          searchData: {
            customerId: orgId,
            searchByParam: SearchBy.OrgNumber,
          },
          checkCustomerBlocked: false,
        })
      );

      dispatch(fetchDeal({ dealId: offerId, orgId }));
    }
  }, [orgId, offerId, dispatch, currentOffer?.id]);

  const handleSave = async (
    dealFile: File,
    kycFile: File,
    formData: DealIdCheck
  ) => {
    try {
      if (currentOffer?.id && pdfUploadData && kycUploadData) {
        const response = await Promise.all([
          OffersAPI.uploadFileToAws(pdfUploadData, dealFile),
          OffersAPI.uploadFileToAws(kycUploadData, kycFile),
        ]);

        if (response) {
          await postDealIdCheck({
            ...formData,
            deal_id: currentOffer.id.toString(),
            changed_by_username: user?.email || "",
            created_by_username: user?.email || "",
          });

          await dispatch(
            sendOfferForSigning({
              dealId: currentOffer.id,
              lcoUserEmail: user?.email,
            })
          );

          dispatch(setCompletionStatus(CompletionStatus.SUCCESS));
          navigate(OfferRouteHelper.getCompletion());
        }
      }
    } catch (e) {
      dispatch(setCompletionStatus(CompletionStatus.FAIL));
      navigate(OfferRouteHelper.getCompletion());
    }
  };

  const downloadKYC = () => {
    downloadFileFromURL(
      require("./documents/KYCtemplate.pdf"),
      "KYCtemplate.pdf"
    );
    setIsKYCTemplateDownloaded(true);
    dispatch(appendToastMessage("STARTED_DOWNLOAD", "success"));
  };

  const renderPdfDownloadBox = () => {
    return (
      <span className="box-action mt-lg" onClick={downloadKYC}>
        <DownloadPdfButton
          dealId={currentOffer?.id}
          type="link"
          buttonLabel={`${translate("DOWNLOAD_AGREEMENT")} + ${translate(
            "KYC_TITLE"
          )}`}
          onPdfDownloaded={() => setIsAgreementDownloaded(true)}
        />
      </span>
    );
  };

  const renderInstructionsKYCDownloadBox = () => {
    <BoxAction
      title={translate("DOWNLOAD_PDF")}
      onClick={() => {}}
      icon={<ArrowDownload16Regular />}
    />;
    return (
      <span className="box-action mt-auto">
        <a
          href={require("./documents/KYCfillingInstruction.pdf")}
          download="KYCfillingInstruction.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text-color-blue cursor-pointer d-flex align-items-center">
            <ArrowUpRight24Regular />
            <span className="ms-2">{translate("SCAN_INSTRUCTION")}</span>
          </span>
        </a>
      </span>
    );
  };

  const renderPhysicalSigningMethod = () => {
    const shouldDisableBox =
      (isPdfUploadDataLoading && isKycUploadDataLoading) ||
      !(isAgreementDownloaded && isKYCTemplateDownloaded);

    return (
      <Col
        xs={12}
        className="h-100 d-flex align-items-center justify-content-center px-lg"
      >
        <Boxes>
          <BoxLayout title={translate("DOWNLOAD")}>
            <BoxBody>{translate("COMPLETION_INSTRUCTION_1")}</BoxBody>

            {renderPdfDownloadBox()}
          </BoxLayout>
          <BoxHorizontalHr />
          <BoxLayout
            title={translate("SCAN_AND_SAVE")}
            disabled={shouldDisableBox}
          >
            <BoxBody>{translate("COMPLETION_INSTRUCTION_2")}</BoxBody>

            {renderInstructionsKYCDownloadBox()}
          </BoxLayout>
          <BoxHorizontalHr />
          <BoxLayout title={translate("UPLOAD")} disabled={shouldDisableBox}>
            <BoxBody>{translate("COMPLETION_INSTRUCTION_3")}</BoxBody>

            <BoxAction
              title={translate("UPLOAD_FILES_ID_CHECK")}
              onClick={() => setIsUploadModalOpen(true)}
              icon={<ArrowUpload16Regular className="text-color-blue" />}
            />
          </BoxLayout>
        </Boxes>
      </Col>
    );
  };

  const isLoading = () => {
    return (
      !currentOffer?.contacts ||
      !contact ||
      currentOfferStatus === "pending" ||
      isPdfUploadDataLoading ||
      isKycUploadDataLoading ||
      isPostDealIdCheckLoading
    );
  };

  const onClose = (isModalFilled: boolean) => {
    setIsConfirmationModalFilled(isModalFilled);
    setIsUploadModalOpen(false);
  };

  return (
    <WizardSection
      loadingStatus={isLoading() ? "pending" : "idle"}
      subtitles={[translate("MANUAL_SIGN_KYC_SUBTITLE")]}
      isNextHidden={
        !isKYCTemplateDownloaded ||
        !isAgreementDownloaded ||
        !isConfirmationModalFilled
      }
    >
      {renderPhysicalSigningMethod()}

      <CompletionPhysicalModal
        isOpen={isUploadModalOpen}
        onSave={handleSave}
        onClose={onClose}
      />
    </WizardSection>
  );
}
