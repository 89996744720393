import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import AppConfig from "app-config";
import { authMethod } from "auth";
import { DealIdCheck } from "models/dealIdCheck";

// Create baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: `${AppConfig.API_URL}`,
  prepareHeaders: async (headers) => {
    const token = await authMethod.getStoredAccessToken();
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const dealApiService = createApi({
  reducerPath: "dealApi",
  baseQuery,
  tagTypes: ["deal"],
  endpoints: (build) => ({
    postDealIdCheck: build.mutation({
      query: (data: DealIdCheck) => ({
        url: `deals/id_check`,
        method: "POST",
        body: data,
      }),
    }),
    getDealFileUploadUrl: build.query({
      query: ({ dealIdOrSSN }: { dealIdOrSSN: string }) => ({
        url: `deals/create_upload_deal_url/${dealIdOrSSN}`,
        method: "POST",
      }),
    }),
    getKYCFileUploadUrl: build.query({
      query: ({
        orgNumber,
        dealId,
      }: {
        orgNumber: string;
        dealId: string;
      }) => ({
        url: `deals/create_upload_kyc_url/${orgNumber}/${dealId}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  usePostDealIdCheckMutation,
  useGetDealFileUploadUrlQuery,
  useGetKYCFileUploadUrlQuery,
} = dealApiService;
