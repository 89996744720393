import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getLatestKYC,
  getWeightPoints,
  KYC,
  KYCCached,
} from "models/offer/kyc";

import { FailedKYC, FailedKYCCustomer } from "models/offer/FailedKYCCustomer";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { fetchKycCache, getFailedKycData, queryKycData } from "./kycThunks";
import { LoadingStatus } from "..";

export interface KycState {
  status: LoadingStatus;
  selected: boolean;
  data: Partial<KYC>;
  failedKyc: FailedKYC;
  existingKycData: Partial<KYC>;
  error: Object;
}

const initialState: KycState = {
  status: "idle",
  selected: true,
  data: {},
  existingKycData: {},
  failedKyc: {
    status: "idle",
    data: [],
    error: "",
  },
  error: {},
};

export const kycSlice = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    resetKYC: (state) => {
      Object.assign(state, { ...initialState, failedKyc: state.failedKyc });
    },
    addKyc: (state, action: PayloadAction<Partial<KYC>>) => {
      const weightPoints = getWeightPoints({
        ...state.data,
        ...action.payload,
      });
      const kycData = {
        ...{ ...state.data, ...action.payload },
        ...{ accept_risk_level: weightPoints === 0 },
      };
      state.data = kycData;
      state.status = LoadingStatusEnum.IDLE;
    },
    approveRisk: (state, action: PayloadAction<boolean>) => {
      state.data.accept_risk_level = action.payload;
      state.status = LoadingStatusEnum.IDLE;
    },
    setKycSelected: (state, action: PayloadAction<boolean>) => {
      state.selected = action.payload;
    },
    setFailedKycData: (state, action: PayloadAction<FailedKYCCustomer[]>) => {
      state.failedKyc.data = action.payload;
    },
    resetFailedKycData: (state) => {
      Object.assign(state.failedKyc, { ...initialState.failedKyc });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchKycCache.pending, (state: KycState) => {
      state.status = LoadingStatusEnum.PENDING;
    });
    builder.addCase(
      fetchKycCache.fulfilled,
      (state: KycState, action: PayloadAction<KYCCached[]>) => {
        const weightPoits = getWeightPoints(action.payload[0]);
        const kycData = {
          ...getLatestKYC(action.payload),
          ...{ accept_risk_level: weightPoits === 0 },
        };
        state.status = LoadingStatusEnum.IDLE;
        state.data = kycData;
        state.existingKycData = kycData;
        state.error = {};
      }
    );
    builder.addCase(fetchKycCache.rejected, (state: KycState, { error }) => {
      console.log(error);
      state.data = {};
      state.status = LoadingStatusEnum.FAILED;
      state.error = error;
    });

    builder.addCase(queryKycData.pending, (state: KycState) => {
      state.status = LoadingStatusEnum.PENDING;
    });
    builder.addCase(queryKycData.fulfilled, (state: KycState, { payload }) => {
      const weightPoits = getWeightPoints(payload);
      const kycData = {
        ...payload,
        ...{ accept_risk_level: weightPoits === 0 },
      };
      state.status = LoadingStatusEnum.IDLE;
      state.data = kycData;
      state.existingKycData = kycData;
      state.error = {};
    });
    builder.addCase(queryKycData.rejected, (state: KycState, { error }) => {
      console.log(error);
      state.data = {};
      state.status = LoadingStatusEnum.FAILED;
      state.error = error;
    });
    builder.addCase(
      getFailedKycData.fulfilled,
      (state: KycState, { payload }) => {
        state.failedKyc.status = LoadingStatusEnum.SUCCEEDED;
        const sortedData = payload.sort(
          (a: FailedKYCCustomer, b: FailedKYCCustomer) =>
            new Date(b.created_date).getTime() -
            new Date(a.created_date).getTime()
        );
        state.failedKyc.data = sortedData;
      }
    );
    builder.addCase(getFailedKycData.pending, (state: KycState) => {
      state.failedKyc.status = LoadingStatusEnum.PENDING;
      state.failedKyc.error = "";
    });
    builder.addCase(getFailedKycData.rejected, (state: KycState, { error }) => {
      console.log(error);
      state.failedKyc.status = LoadingStatusEnum.FAILED;
      state.failedKyc.data = [];
      state.failedKyc.error = error;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetKYC,
  addKyc,
  approveRisk,
  setKycSelected,
  setFailedKycData,
  resetFailedKycData,
} = kycSlice.actions;

export default kycSlice.reducer;
