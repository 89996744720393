import { Spinner } from "@fluentui/react-components";
import React, { useState } from "react";

import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks";
import { getNameFromEmail } from "libs/name-from-email";
import { numberAsSwedishCurrency } from "libs/number-format";
import { useServiceMatrix } from "libs/service-matrix";
import { CustomerDeal } from "models/offer/CustomerDeal";
import {
  SigningMethod,
  SigningMethodToPrettyString,
} from "models/offer/DealContact";
import { appendToastMessage } from "state/notifications";
import { fetchDealById, updateDealValues } from "state/sales/actions";
import { useAppDispatch } from "state/use-app-redux";
import DownloadPdfButton from "views/createNew/offer/wizard/components/downloadPdfButton";
import DealCommentDialog from "./DealCommentDialog";

interface DealItemProps {
  deal: CustomerDeal;
  actionsElement?: React.ReactNode;
}
export default function DealItem({ deal, actionsElement }: DealItemProps) {
  const { GetFinalPrice } = useServiceMatrix();
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();

  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dealComment, setDealComment] = useState("");

  const getDateModifiedStringCorrectedTimezone = () => {
    const date = deal.changed_date || new Date();

    // Get the timezone offset in minutes (note the '-' sign, as it returns the opposite sign of the actual offset)
    const timezoneOffsetMinutes = -date.getTimezoneOffset();

    // Convert the timezone offset to milliseconds
    const timezoneOffsetMilliseconds = timezoneOffsetMinutes * 60 * 1000;

    // Calculate the time with the offset applied
    const timeWithOffset = new Date(
      date.getTime() + timezoneOffsetMilliseconds
    );

    return timeWithOffset;
  };

  const offerStateInfo = {
    contract: translate("OFFERS_TABLE.CONTRACT"),
    contract_expired: translate("OFFERS_TABLE.CONTRACT_EXPIRED"),
    contract_rejected: translate("OFFERS_TABLE.CONTRACT_REJECTED"),
    contract_sent: translate("OFFERS_TABLE.CONTRACT_SENT"),
    contract_signed: translate("OFFERS_TABLE.CONTRACT_SIGNED"),
    offer: translate("OFFERS_TABLE.OFFER"),
    deleted: translate("OFFERS_TABLE.OFFER_DELETED"),
  };
  const dateModifiedString = deal.changed_date
    ? getDateModifiedStringCorrectedTimezone()
    : new Date();

  function getSignee() {
    let signer;
    const signerContacts = deal.contacts.filter((contact) => contact.is_signer);
    if (signerContacts.length > 1) {
      signer = signerContacts.find(
        (contact) => contact.signing_method === SigningMethod.PHYSICAL
      );
    } else {
      [signer] = signerContacts;
    }
    if (signer && signer.contact) {
      return {
        fullName: `${signer.contact.first_name} ${signer.contact.last_name}`,
        signingMethod: signer.signing_method
          ? SigningMethodToPrettyString(signer.signing_method)
          : "-",
      };
    }
    return {
      fullName: "-",
      signingMethod: "-",
    };
  }

  const loadHubspotDealComment = async () => {
    if (deal && deal.hubspot_deal_id) {
      setIsLoading(true);
      try {
        const hubspotDeal = await dispatch(fetchDealById(deal.hubspot_deal_id));
        setDealComment(hubspotDeal.meddelande_salj ?? "");
        setIsCommentDialogOpen(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSaveComment = (comment: string) => {
    if (deal.hubspot_deal_id) {
      dispatch(
        updateDealValues(deal.hubspot_deal_id, { meddelande_salj: comment })
      );
    } else {
      dispatch(
        appendToastMessage(
          "CREATE_NEW.CONTRACT.DEAL_MISSING_HUBSPOT_ID",
          "warning"
        )
      );
    }
    setIsCommentDialogOpen(false);
  };

  return (
    <>
      <td>{offerStateInfo[deal.state]}</td>
      <td>{deal.id}</td>
      <td>{dateModifiedString.toISOString().slice(0, 10)}</td>
      <td>{dateModifiedString.toLocaleString("sv-SE").slice(-8)}</td>
      <td>{getNameFromEmail(deal?.changed_by_username ?? "")}</td>
      <td>
        <DownloadPdfButton dealId={deal.id} type="link" />
      </td>
      <td>
        <span
          className={`d-flex blue-link-underline h-100 ${
            isLoading ? "disabled" : ""
          }`}
          onClick={loadHubspotDealComment}
        >
          {translate("CREATE_NEW.CONTRACT.OFFER_TABLE.READ_COMMENT")}
          {isLoading && (
            <Spinner className="ml-sm" size={SpinnerSize.ExtraSmall} />
          )}
        </span>
      </td>
      <td>{numberAsSwedishCurrency(GetFinalPrice(undefined, deal))}</td>
      <td>{getSignee().fullName}</td>
      <td>{getSignee().signingMethod}</td>
      {actionsElement && <td>{actionsElement}</td>}

      {isCommentDialogOpen && (
        <DealCommentDialog
          dealComment={dealComment}
          onDiscard={() => setIsCommentDialogOpen(false)}
          onSaveComment={handleSaveComment}
        />
      )}
    </>
  );
}
