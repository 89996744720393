/* eslint-disable react/jsx-no-useless-fragment */
import {
  ChevronDown12Regular,
  ChevronUp12Regular,
} from "@fluentui/react-icons";
import { Fragment, MouseEvent, useState } from "react";
import { useSelector } from "react-redux";
import {
  createTableColumn,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableColumnId,
  TableHeader,
  TableHeaderCell,
  TableRow,
  useTableSort,
} from "@fluentui/react-components";

import { Persona } from "components/people";
import { useTranslation } from "hooks";
import { RootState } from "state";
import { ServiceTypes } from "models/activities/activity";
import { ActivityGroupedByCustomerId } from "models/activities/changeAssignee";
import ConditionalWrapper from "components/conditionalWrapper";
import {
  useTableExpandingRows,
  useTableFeaturesExtended,
} from "components/table";
import Tooltip from "components/tooltip/Tooltip";
import { ServiceTypesList } from "helpers/conveyorBelt/conveyorBeltHelpers";
import { ViewType } from "models/mgt";
import "./ChangeAssigneeModal.scss";

type Props = {
  groupedActivitiesByCustomerId: ActivityGroupedByCustomerId[];
};

export default function ChangeAssigneeExpandedDetailsTable({
  groupedActivitiesByCustomerId,
}: Props) {
  const { translate, ts, language } = useTranslation();

  const {
    definitions: { dataMap: definitionsMap },
  } = useSelector((state: RootState) => state.activities);
  const customers = useSelector((state: RootState) => state.customers.data);

  const columnsDef: TableColumnDefinition<ActivityGroupedByCustomerId>[] = [
    createTableColumn<ActivityGroupedByCustomerId>({
      columnId: "customerName",
      compare: (a, b) => {
        const customerNameA = customers.find(
          ({ customer }) => customer.customer_number === a.customerId
        )?.customer.name;
        const customerNameB = customers.find(
          ({ customer }) => customer.customer_number === b.customerId
        )?.customer.name;

        return (customerNameA ?? "").localeCompare(customerNameB ?? "");
      },
      renderHeaderCell: () => translate("CUSTOMER"),
      renderCell: (item) => (
        <TableCellLayout truncate className="fw-600">
          {
            customers.find(
              ({ customer }) => customer.customer_number === item.customerId
            )?.customer.name
          }
        </TableCellLayout>
      ),
    }),
    createTableColumn<ActivityGroupedByCustomerId>({
      columnId: "service",
      renderHeaderCell: () => translate("SERVICE"),
      renderCell: () => undefined,
    }),
    createTableColumn<ActivityGroupedByCustomerId>({
      columnId: "activity",
      renderHeaderCell: () => translate("ACTIVITY"),
      renderCell: () => undefined,
    }),
    createTableColumn<ActivityGroupedByCustomerId>({
      columnId: "distributed",
      renderHeaderCell: () => translate("DISTRIBUTED"),
      renderCell: () => undefined,
    }),
  ];

  const [columns] =
    useState<TableColumnDefinition<ActivityGroupedByCustomerId>[]>(columnsDef);

  const renderChildRows = (
    item: ActivityGroupedByCustomerId,
    expanded: boolean
  ) => {
    if (!expanded || !item.distinctActivityTypes.length) {
      return null;
    }

    return item.distinctActivityTypes.map((activityType) => {
      const definition = definitionsMap[activityType];

      const distributedUserId = item.activities
        .filter((activity) => activity.activity_type === activityType)
        ?.sort((activityA, activityB) => {
          const dateA = new Date(activityA.deadline_date);
          const dateB = new Date(activityB.deadline_date);
          return dateA.getTime() - dateB.getTime();
        })[0].assigned_user;

      const activityName = definition.getTranslation(language);

      return (
        <TableRow key={`${item.customerId}-${activityType}-${activityName}`}>
          <TableCell />
          <TableCell>
            <TableCellLayout truncate>
              {ts(
                ServiceTypesList.includes(
                  definition.service_type as ServiceTypes
                )
                  ? definition.service_type
                  : "OTHER"
              )}
            </TableCellLayout>
          </TableCell>
          <TableCell>
            <TableCellLayout truncate>
              <Tooltip content={activityName} notTranslatable>
                <>{activityName}</>
              </Tooltip>
            </TableCellLayout>
          </TableCell>
          <TableCell>
            {distributedUserId ? (
              <Persona userId={distributedUserId} view={ViewType.oneline} />
            ) : (
              <span className="body-italic-light">
                {translate("CB.CHANGE_ASSIGNEE_MODAL.NOT_DISTRIBUTED")}
              </span>
            )}
          </TableCell>
        </TableRow>
      );
    });
  };

  const {
    getRows,
    tableRef,
    expandingRows: { isRowExpanded, toggleRow: selectExpandingRow },
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeaturesExtended(
    {
      columns,
      items: groupedActivitiesByCustomerId,
    },
    [
      useTableSort({
        defaultSortState: {
          sortColumn: "customerName",
          sortDirection: "ascending",
        },
      }),
    ],
    [useTableExpandingRows({ expandingMode: "multiple" })]
  );

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  const rows = sort(
    getRows((row) => {
      const expanded = isRowExpanded(row.rowId);

      return {
        ...row,
        expanded,
        onClick: (e: MouseEvent) => selectExpandingRow(e, row.rowId),
      };
    })
  );

  return (
    <Table ref={tableRef}>
      <TableHeader className="header">
        <TableRow className="bg-gray-450">
          {columnsDef.map((column) => {
            const hasCompare = column.compare.name === "compare";
            const sortableClass = hasCompare ? " sortable" : "";
            const sortProps = hasCompare
              ? headerSortProps(column.columnId)
              : {};
            return (
              <TableHeaderCell
                key={column.columnId}
                className={`header-cell${sortableClass}`}
                {...sortProps}
              >
                {column.renderHeaderCell()}
              </TableHeaderCell>
            );
          })}
        </TableRow>
      </TableHeader>
      <TableBody>
        {rows.map(({ item, rowId, expanded, onClick }) => (
          <Fragment key={rowId}>
            <TableRow className="customer-row" onClick={onClick}>
              {columnsDef.map((column) => {
                const isCustomerNameColumn = column.columnId === "customerName";

                return (
                  <TableCell
                    key={`${rowId}-${column.columnId}`}
                    className={isCustomerNameColumn ? "d-flex" : ""}
                  >
                    <ConditionalWrapper
                      condition={isCustomerNameColumn}
                      wrapper={<div className="d-flex align-items-center" />}
                    >
                      {isCustomerNameColumn && (
                        <span className="mr-sm">
                          {expanded ? (
                            <ChevronUp12Regular />
                          ) : (
                            <ChevronDown12Regular />
                          )}
                        </span>
                      )}
                      {column.renderCell(item)}
                    </ConditionalWrapper>
                  </TableCell>
                );
              })}
            </TableRow>
            {renderChildRows(item, expanded)}
          </Fragment>
        ))}
      </TableBody>
    </Table>
  );
}
