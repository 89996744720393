import { ArrowRight20Regular, SlideAdd16Regular } from "@fluentui/react-icons";
import { useState } from "react";
import { Button } from "react-bootstrap";

import AppConfig from "app-config";
import { useAuth } from "auth/use-auth";
import {
  LayoutBody,
  LayoutFooter,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "components/layout/Layout";
import { TextLink } from "components/textLink/textLink";
import { useTranslation } from "hooks/use-translate";
import { UserRoles } from "models/user";
import { useGetCompanyTypesQuery } from "api/ludvigApi";
import { CreatePogOfferFromHSDealModal } from "./components/CreatePogOfferFromHSDealModal";
import "./index.scss";

export function HomePage() {
  const { translate } = useTranslation();
  const { user } = useAuth();
  const [openCreatePogOfferDialog, setOpenCreatePogOfferDialog] =
    useState(false);

  const isPogContractCreationAllowed = () => {
    return user?.roles?.includes(UserRoles.USERS_CENTRAL_SALES);
  };

  // call this RTK query to get the customer-types
  // the response is cached so it wont be called again when we enter the POG flow for example
  useGetCompanyTypesQuery();

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <h1>{translate("HOME")}</h1>
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          <TextLink url="https://konsultsupport.ludvig.se/support/tickets/new">
            {translate("CREATE_NEW_SUPPORTTICKET")}
          </TextLink>
        </LayoutHeaderRight>
      </LayoutHeader>
      <LayoutBody>
        <div className="d-flex flex-column mittludvig-home">
          <div className="linear-gradient d-flex justify-content-between align-items-center h-70">
            <div className="d-flex flex-column justify-content-between align-items-center w-100">
              <div className="text-white headline-one">
                {translate("WELCOME_BACK_MITTLUDVIG")}
              </div>
              <div className="text-white headline-two">
                {translate("INTRANET_NEWS_PRESENT")}
              </div>
              <div className="quick-buttons d-flex">
                <Button
                  className="me-5 border-white bg-transparent"
                  href="https://intranet.ludvig.se/vart-arbetssatt/kundprocessen/ekonomi/redovisningsprocessen/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="d-flex flex-row align-items-center">
                    <span className="px-sm">
                      {translate("ACCOUNTING_PROCESS")}
                    </span>
                    <ArrowRight20Regular className="ml-xs" />
                  </span>
                </Button>
                <Button
                  className="me-5  border-white bg-transparent"
                  href="https://intranet.ludvig.se/vart-arbetssatt/kundprocessen/ekonomi/process-loneregistrering/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="d-flex flex-row align-items-center">
                    <span className="px-sm">
                      {translate("PAYROLL_PROCESS")}
                    </span>
                    <ArrowRight20Regular className="ml-xs" />
                  </span>
                </Button>
                <Button
                  className="border-white bg-transparent"
                  href="https://intranet.ludvig.se/vart-arbetssatt/kundprocessen/ekonomi/bokslutsprocessen/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="d-flex flex-row align-items-center">
                    <span className="px-sm">
                      {translate("YEAR_END_PROCESS")}
                    </span>
                    <ArrowRight20Regular className="ml-xs" />
                  </span>
                </Button>
                {isPogContractCreationAllowed() && (
                  <>
                    <div className="vertical-divider mr-md ml-lg mt-xs" />
                    <Button
                      className="border-white bg-transparent"
                      onClick={() => setOpenCreatePogOfferDialog(true)}
                    >
                      <span className="d-flex flex-row">
                        <span className="px-2">
                          {translate("HS_TO_POG_DEAL")}
                        </span>
                        <SlideAdd16Regular />
                      </span>
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </LayoutBody>
      <LayoutFooter>
        <div className="footer pl-lg d-flex align-items-center">
          <div className="title">{translate("FOOTER.SUPPORT")}</div>
          <div className="footer-vertical-divider ml-md p-sm" />
          <div>
            <span className="support-label mr-xs">
              {translate("PHONE_SHORT")}:
            </span>
            <span className="support-value">{AppConfig.SUPPORT_PHONE}</span>
          </div>
          <div className="footer-vertical-divider ml-md p-sm" />
          <div>
            <span className="support-label mr-xs">
              {translate("EMAIL_SHORT")}:
            </span>
            <span className="support-value">{AppConfig.SUPPORT_EMAIL}</span>
          </div>
        </div>
      </LayoutFooter>
      {openCreatePogOfferDialog && (
        <CreatePogOfferFromHSDealModal
          onDismiss={() => setOpenCreatePogOfferDialog(false)}
        />
      )}
    </>
  );
}
