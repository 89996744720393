import { DrawerBody } from "@fluentui/react-components";
import { useState } from "react";

import DrawerHeader from "components/drawer/drawerHeader/DrawerHeader";
import OverlayDrawer from "components/OverlayDrawer";
import Tooltip from "components/tooltip/Tooltip";
import { useTranslation } from "hooks/use-translate";
import { DealContact } from "models/deals/deal";
import { CardOption } from "views/createNew/offer/components/customerContactsList/ContactsCards";

interface ContactListProps {
  contacts: DealContact[];
  showNumberOfItems?: number;
}

function CustomerContacts({ contacts, showNumberOfItems }: ContactListProps) {
  const { translate } = useTranslation();

  const [showPanel, setShowPanel] = useState(false);

  const displayContacts = !showNumberOfItems
    ? contacts
    : contacts.slice(0, showNumberOfItems);

  const getContactCardOptions = (contact: DealContact): CardOption[] => {
    return [
      {
        label: translate("NAME"),
        value: `${contact.firstname} ${contact.lastname}`,
      },
      {
        label: translate("PHONE"),
        value: contact.phone ?? "",
      },
      {
        label: translate("EMAIL"),
        value: contact.email,
      },
    ];
  };

  const renderCardContent = (cardOption: CardOption, contactId: string) => (
    <div
      className="contact-card-row pb-sm d-flex"
      key={`${contactId}-${cardOption.label}`}
    >
      <Tooltip
        content={cardOption.label}
        notTranslatable
        childrenClassName="contact-card-row-title"
        positioning="above-start"
      >
        <div className="stringLength">{cardOption.label}</div>
      </Tooltip>
      <Tooltip
        content={cardOption.value}
        notTranslatable
        childrenClassName="contact-card-row-text"
        positioning="above-start"
      >
        <div className="stringLength">{cardOption.value}</div>
      </Tooltip>
    </div>
  );

  const renderEndContactItem = (showAllContacts = false) => {
    const remainingContacts = contacts.length - displayContacts.length;

    const showRemainingContacts = !showAllContacts && remainingContacts > 0;

    return (
      <div className="d-flex align-items-center justify-content-center">
        {showRemainingContacts && (
          <div
            className="cursor-pointer pog-link mt-md ml-lg"
            onClick={() => {
              setShowPanel(!showPanel);
            }}
          >
            <span className="blue-link-underline text-wrap-none fw-semibold counter-number pl-md">
              {translate("SHOW_ALL")}
            </span>
          </div>
        )}
      </div>
    );
  };

  function renderContactsInCards(showAllContacts = false) {
    const alignContactsClass = showAllContacts
      ? "d-flex flex-column"
      : "d-flex horizontal-scroll";

    const cardClass = showAllContacts ? "mb-md" : "mr-md";

    return (
      <div className={`customer-contacts-list ${alignContactsClass}`}>
        {(showAllContacts ? contacts : displayContacts).map((contact) => (
          <div className={`contact-card ${cardClass}`} key={contact.id}>
            {getContactCardOptions(contact).map((cardOption) =>
              renderCardContent(cardOption, contact.id ?? "")
            )}
          </div>
        ))}
        {renderEndContactItem(showAllContacts)}
      </div>
    );
  }

  return contacts.length > 0 ? (
    <>
      {showPanel && (
        <OverlayDrawer size="medium" showPanel={showPanel}>
          <DrawerHeader
            onDismiss={() => {
              setShowPanel(false);
            }}
            header="CONTACTS"
          />
          <DrawerBody>{renderContactsInCards(true)}</DrawerBody>
        </OverlayDrawer>
      )}
      {renderContactsInCards()}
    </>
  ) : (
    <span className="text-color-gray-700">
      {translate("NO_CONTACTS_FOUND")}
    </span>
  );
}

export default CustomerContacts;
