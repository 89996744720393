import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Info20Regular } from "@fluentui/react-icons";
import { Spinner } from "@fluentui/react-components";

import { useTranslation } from "hooks/use-translate";
import { RootState } from "state";
import { isUserRestricted } from "libs/customer-settings-helpers";
import { Customer } from "models/customer";
import { ViewType } from "models/mgt";
import { Persona } from "components/people";
import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import Tooltip from "components/tooltip/Tooltip";
import { ServiceTypes, TeamServiceType } from "models/activities/activity";
import useGetCustomerTeam from "hooks/conveyorBelt/use-get-customer-team";
import AssignedUserForPackage from "../components/AssignedUserForPackage";
import ShowMoreProjects from "../components/ShowMoreProjects";

type Props = {
  customer: Customer;
  isOpen: boolean;
  onDismiss: (open: boolean) => void;
  onPackagePersonPickerChange: (
    packageName: string,
    userEmail: string,
    isDefaultAssignee: boolean,
    assignedUser?: string
  ) => any;
};

export default function TeamModal({
  customer,
  isOpen = true,
  onDismiss,
  onPackagePersonPickerChange,
}: Props) {
  const { translate } = useTranslation();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const [loadingNewAssigneeForPackage, setLoadingNewAssigneeForPackage] =
    useState<string>("");
  const {
    activitiesTeams: { isLoading: isLoadingAssignees },
  } = useSelector((state: RootState) => state.activities);

  const { assigneesData, projectManagersForCustomer } = useGetCustomerTeam({
    customer,
  });

  const assignees = assigneesData[0].service_types;
  const teamServiceTypesWithDefaultAssignee =
    assignees && assignees.length > 0
      ? assignees.filter(
          (serviceType) =>
            serviceType.service_type === ServiceTypes.GeneralAccounting ||
            serviceType.service_type === ServiceTypes.YearEnd ||
            serviceType.service_type === ServiceTypes.IncomeTaxReturn
        )
      : [];

  const teamServiceTypesNoDefaultAssignee =
    assignees && assignees.length > 0
      ? assignees.filter(
          (serviceType) =>
            serviceType.service_type !== ServiceTypes.GeneralAccounting &&
            serviceType.service_type !== ServiceTypes.YearEnd &&
            serviceType.service_type !== ServiceTypes.IncomeTaxReturn
        )
      : [];

  const teamOtherServiceType: TeamServiceType = {
    service_type: "OTHER",
    default_user: "",
    assigned_users: teamServiceTypesNoDefaultAssignee.flatMap(
      (service) => service.assigned_users
    ),
  };

  const renderTeamServiceTypeSection = (
    assigneeData: TeamServiceType,
    hasDefaultAssignee: boolean
  ) => {
    const onPersonChange = async (
      currPackage: string,
      personEmail: string,
      isDefaultAssignee: boolean,
      assignedUser?: string
    ) => {
      try {
        setLoadingNewAssigneeForPackage(assigneeData.service_type);
        const currenAssignee = await onPackagePersonPickerChange(
          currPackage,
          personEmail,
          isDefaultAssignee,
          assignedUser
        );
        if (!currenAssignee) {
          return;
        }
      } finally {
        setLoadingNewAssigneeForPackage("");
      }
    };

    const otherAssignees = assigneeData.assigned_users.filter(
      (assignee) => assignee !== assigneeData.default_user
    );

    return (
      <Col xs={12}>
        <Row className="my-sm py-sm">
          <b>{translate(assigneeData.service_type)}</b>
        </Row>
        {!isUserRestricted(customer, currentUser) && hasDefaultAssignee && (
          <Row className="my-sm pb-sm">
            <Col xs={3}>
              <span className="d-flex align-items-center h-100">
                {translate("CB.TEAM.DEFAULT_ASSIGNEE")}
              </span>
            </Col>
            <Col xs={9}>
              <span className="w-70">
                <AssignedUserForPackage
                  packageName={assigneeData.service_type}
                  assignedUser={assigneeData.default_user}
                  isDefaultAssignee
                  onPackagePersonPickerChange={onPersonChange}
                  isLoading={
                    loadingNewAssigneeForPackage !== "" &&
                    loadingNewAssigneeForPackage === assigneeData.service_type
                  }
                />
              </span>
            </Col>
          </Row>
        )}
        <Row className="mb-sm mt-md pb-sm">
          <Col xs={3}>
            <span className="d-flex align-items-center min-height-32">
              {translate("CB.TEAM.OTHER_ASSIGNEES")}
            </span>
          </Col>
          <Col xs={9}>
            {otherAssignees.length > 0 ? (
              otherAssignees.map((otherAssignee) => (
                <Row className="pb-sm mb-xs" key={otherAssignee}>
                  <span className="w-70">
                    <AssignedUserForPackage
                      packageName={assigneeData.service_type}
                      assignedUser={otherAssignee}
                      onPackagePersonPickerChange={onPersonChange}
                      isLoading={
                        loadingNewAssigneeForPackage !== "" &&
                        loadingNewAssigneeForPackage ===
                          assigneeData.service_type
                      }
                    />
                  </span>
                </Row>
              ))
            ) : (
              <div className="my-sm">
                <span className="text-style">
                  {translate("CB.TEAM.NO_OTHER_ASSIGNEES")}
                </span>
              </div>
            )}
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={() => onDismiss(false)}
      size="medium"
      scrollableContent
      header={
        <ModalHeader
          headerTitleContent={
            <div className="modal-title pr-md d-flex align-items-center position-relative">
              <h3 className="mb-0 pl-sm">
                {customer.name} {translate("TEAM")}
                <Tooltip content="CUSTOMER_USERS_MISSING">
                  <Info20Regular className="ml-md" />
                </Tooltip>
              </h3>
            </div>
          }
        />
      }
    >
      <Row className="m-sm">
        <Col xs={12} className="p-xs">
          <Row className="my-sm pb-sm">
            <Col xs={3}>
              <span className="d-flex align-items-center h-100">
                {translate("SALESPERSONS")}:
              </span>
            </Col>
            <Col xs={9}>
              {customer.salesperson_username ? (
                <Persona
                  userId={customer.salesperson_username}
                  view={ViewType.twolines}
                  line2Property="email"
                />
              ) : (
                <div className="d-flex my-sm">
                  <Tooltip content="MANAGE_CUSTOMERS.ERROR_TOOLTIP_PERSON_NO_LONGER_EMPLOYEE">
                    <div className="d-flex align-items-center">
                      <Persona userId={undefined} />
                      <span className="ml-sm fw-semibold">
                        {customer.salesperson_name}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              )}
            </Col>
          </Row>
          <Row className="horizontal-divider m-0" />
          <Row className="my-sm">
            <Col xs={3}>
              <span className="d-flex align-items-center min-height-32">
                {translate("PROJECT_MANAGERS")}:
              </span>
            </Col>
            <Col xs={9}>
              {projectManagersForCustomer.length > 0 ? (
                projectManagersForCustomer.map((projectManager, index) =>
                  projectManager.email ? (
                    <Row
                      key={projectManager.email}
                      className={
                        index < projectManagersForCustomer.length - 1
                          ? "pb-md"
                          : "pb-sm"
                      }
                    >
                      <Col xs={7}>
                        <Persona
                          userId={projectManager.email}
                          view={ViewType.twolines}
                          line2Property="email"
                        />
                      </Col>
                      <Col
                        xs={5}
                        className="pt-sm d-flex justify-content-center"
                      >
                        {projectManager.projectType.length > 0 ? (
                          <ShowMoreProjects
                            projectManagerProjects={projectManager.projectType}
                            key={`${projectManager.email}-${projectManager.projectType}`}
                          />
                        ) : (
                          <span className="text-style">
                            {translate("CB.TEAM.NO_PROJECTS")}
                          </span>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <div className="d-flex my-sm">
                      <Tooltip content="MANAGE_CUSTOMERS.ERROR_TOOLTIP_PERSON_NO_LONGER_EMPLOYEE">
                        <div className="d-flex align-items-center">
                          <Persona userId={undefined} />
                          <span className="ml-sm fw-semibold">
                            {projectManager.name}
                          </span>
                        </div>
                      </Tooltip>
                    </div>
                  )
                )
              ) : (
                <div className="my-sm">
                  <span className="text-style">
                    {translate("CB.TEAM.NO_PROJECT_MANAGER")}
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <Row className="horizontal-divider m-0" />
          {isLoadingAssignees ? (
            <Row className="mt-md">
              <Spinner
                size="extra-small"
                label={translate("LOADING_ASSIGNEES")}
              />
            </Row>
          ) : (
            <Row>
              {teamServiceTypesWithDefaultAssignee.length > 0 ? (
                teamServiceTypesWithDefaultAssignee.map(
                  (serviceType, index) => (
                    <Col xs={12} key={serviceType.service_type}>
                      <Row>
                        {renderTeamServiceTypeSection(serviceType, true)}
                      </Row>
                      {index <
                        teamServiceTypesWithDefaultAssignee.length - 1 && (
                        <Row className="horizontal-divider m-0" />
                      )}
                    </Col>
                  )
                )
              ) : (
                <div className="my-sm">
                  <span className="text-style">
                    {translate("CB.TEAM.NO_OTHER_ASSIGNEES")}
                  </span>
                </div>
              )}
              {teamOtherServiceType.assigned_users.length > 0 && (
                <Col xs={12}>
                  <Row className="horizontal-divider m-0" />
                  <Row>
                    {renderTeamServiceTypeSection(teamOtherServiceType, false)}
                  </Row>
                </Col>
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Modal>
  );
}