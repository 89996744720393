import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@fluentui/react-components";
import { Warning20Regular } from "@fluentui/react-icons";

import { CustomerDetailsParams } from "routes";
import { RootState } from "state";
import { KycAPI } from "api/kyc";
import { appendError } from "state/notifications";
import { AppDispatch } from "state/use-app-redux";
import { Button } from "components/button";
import { useTranslation } from "hooks/use-translate";
import { downloadFileFromURL } from "libs/download-file-from-url";
import { formatDate } from "libs/date/date-format";
import { ExternalKYCdata } from "models/customerSettings/customerSettingsExternalKYC";
import { SpinnerSize } from "components/spinner";
import { isUserInCustomerTeam } from "libs/customer-settings-helpers";
import ContextualMenu from "components/contextualMenu/ContextualMenu";
import { ManuallyApproveKYCModal } from "views/kycAdministration/components/ManuallyApproveKYCModal";
import { SelectedCustomer } from "views/kycAdministration/FailedKYCCustomers";
import { manuallyApproveFailedKYC } from "state/offer/kycThunks";
import { fetchExternalKYCWrapper } from "state/customerSettings/customerSettingsThunk";

type ExternalKYCTableProps = {
  externalKYCdata: ExternalKYCdata[];
};

export function ExternalKYCTable({ externalKYCdata }: ExternalKYCTableProps) {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const { data: customers } = useSelector(
    (state: RootState) => state.customers
  );
  const { currentUser } = useSelector((state: RootState) => state.users);

  const { customerId } = useParams<CustomerDetailsParams>();

  const customer = customers.find(
    (customerState) => customerState.customer.customer_number === customerId
  )?.customer;

  const [downloadingKYCid, setDownloadingKYCid] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomer>();

  const tableHeaderItems = [
    {
      key: "id",
      label: translate("CUSTOMER_PAGE.EXTERNAL_KYC_TABLE.ID"),
    },
    {
      key: "status",
      label: translate("CUSTOMER_PAGE.EXTERNAL_KYC_TABLE.STATUS"),
    },
    {
      key: "comment",
      label: translate("CUSTOMER_PAGE.EXTERNAL_KYC_TABLE.COMMENT"),
    },
    {
      key: "download",
      label: translate("CUSTOMER_PAGE.EXTERNAL_KYC_TABLE.DOWNLOAD"),
    },
  ];

  const hasFailedKYC = externalKYCdata.some(
    (kyc) => kyc.ext_kyc_status && kyc.aborted
  );

  const userIsInCustomerTeam =
    customer && isUserInCustomerTeam(customer, currentUser, false);

  // show actions in header if if current user is a member of the customer's team
  // and there is at least one failed KYC
  if (userIsInCustomerTeam && hasFailedKYC) {
    tableHeaderItems.push({
      key: "actions",
      label: translate("ACTIONS"),
    });
  }

  const handleDownloadKYCForm = async (
    envelopeId: string,
    documentId: string,
    fileId: string
  ) => {
    setDownloadingKYCid(envelopeId);
    try {
      const downloadURL = await KycAPI.getKYCForm(
        envelopeId,
        documentId,
        fileId
      );
      downloadFileFromURL(downloadURL, `KYC_form_${envelopeId}.pdf`);
    } catch (error) {
      dispatch(
        appendError(
          "CUSTOMER_PAGE.FAILED_TO_DOWNLOAD_EXTERNAL_KYC",
          error as Error
        )
      );
    } finally {
      setDownloadingKYCid(undefined);
    }
  };

  const getFailedExternalKYCStatus = (
    approvedByComplianceTeam: boolean,
    failedDate: string,
    approvedDate: string | null
  ) => (
    <div className="d-flex align-items-center">
      <div className="mr-sm">
        {translate("CUSTOMER_PAGE.EXTERNAL_KYC_FAILED")}
        {!approvedByComplianceTeam && (
          <Warning20Regular className="icon-danger ml-sm" />
        )}
      </div>
      <div className="ml-sm">
        {!approvedByComplianceTeam ? (
          <>
            <span className="mr-sm text-gray">
              {translate("CUSTOMER_PAGE.EXTERNAL_KYC_DATE_OF_FAILURE")}
            </span>
            <span>{formatDate(new Date(failedDate))}</span>
          </>
        ) : (
          <>
            <span className="mr-sm text-gray">
              {translate("CUSTOMER_PAGE.EXTERNAL_KYC_DATE_OF_APPROVAL")}
            </span>
            <span>{approvedDate && formatDate(new Date(approvedDate))}</span>
          </>
        )}
      </div>
    </div>
  );

  const getKYCStatus = (
    status: boolean,
    aborted: boolean,
    failedDate: string,
    approvedDate: string | null
  ) => {
    switch (true) {
      case status && aborted: {
        return getFailedExternalKYCStatus(false, failedDate, approvedDate);
      }
      case !status && aborted: {
        return getFailedExternalKYCStatus(true, failedDate, approvedDate);
      }
      default: {
        return translate("CUSTOMER_PAGE.EXTERNAL_KYC_APPROVED");
      }
    }
  };

  const handleOpenModal = (envelopeId: string) => {
    if (customer) {
      setSelectedCustomer({ customerName: customer.name, envelopeId });
    }
    setIsModalOpen(true);
  };

  const handleManuallyApproveKYC = async (comment: string) => {
    if (selectedCustomer) {
      setIsLoading(true);
      const body = { ...selectedCustomer, comment };
      await dispatch(manuallyApproveFailedKYC(body)).then(() => {
        setIsLoading(false);
        setIsModalOpen(false);
        setSelectedCustomer(undefined);
      });
      if (customer) {
        dispatch(fetchExternalKYCWrapper(customer.company_registration_number));
      }
    }
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            {tableHeaderItems.map((item) => (
              <TableHeaderCell key={item.key}>{item.label}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {externalKYCdata.map((kyc) => (
            <TableRow key={kyc.envelope_id}>
              <TableCell>{kyc.envelope_id}</TableCell>
              <TableCell>
                {getKYCStatus(
                  kyc.ext_kyc_status,
                  kyc.aborted,
                  kyc.created_date,
                  kyc.ext_kyc_approved_date
                )}
              </TableCell>
              <TableCell>
                {kyc.comment ? (
                  `"${kyc.comment}"`
                ) : (
                  <span className="text-gray fs-italic">
                    {translate("CUSTOMER_PAGE.EXTERNAL_KYC_NO_COMMENT")}
                  </span>
                )}
              </TableCell>
              <TableCell>
                <Button
                  variant="link"
                  className="blue-link p-0 text-left"
                  onClick={() =>
                    handleDownloadKYCForm(
                      kyc.envelope_id,
                      kyc.document_id,
                      kyc.file_id
                    )
                  }
                >
                  <div className="d-flex align-items-center">
                    <span className="fw-semibold fpx-12">
                      {translate("CUSTOMER_PAGE.DOWNLOAD_EXTERNAL_KYC")}
                    </span>
                    {downloadingKYCid === kyc.envelope_id && (
                      <Spinner
                        className="ml-sm"
                        size={SpinnerSize.ExtraSmall}
                      />
                    )}
                  </div>{" "}
                </Button>
              </TableCell>
              {userIsInCustomerTeam && kyc.ext_kyc_status && kyc.aborted && (
                <TableCell>
                  <ContextualMenu
                    menuItems={[
                      {
                        text: translate("CUSTOMER_PAGE.APPROVE_KYC"),
                        onClick: () => handleOpenModal(kyc.envelope_id),
                      },
                    ]}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isModalOpen && (
        <ManuallyApproveKYCModal
          onDismiss={() => setIsModalOpen(false)}
          handleManuallyApproveKYC={handleManuallyApproveKYC}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
