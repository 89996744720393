/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useRef, useEffect } from "react";
import { UseFormRegisterReturn, UseFormTrigger } from "react-hook-form";

import { useTranslation } from "hooks/use-translate";
import "./TextArea.scss";

export type TextAreaProps = {
  formRegister?: UseFormRegisterReturn;
  trigger?: UseFormTrigger<any>;
  errorMessage?: string | React.ReactNode;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export function TextArea({
  className = "",
  formRegister,
  trigger,
  errorMessage,
  ...rest
}: TextAreaProps) {
  const { language } = useTranslation();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const classNames = `form-control textarea ${className || ""}`;

  useEffect(() => {
    if (trigger && errorMessage && formRegister) {
      trigger(formRegister.name);
    }
    // this useEffect needs to be triggered only on language change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  // Support tabs
  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (
    e: React.KeyboardEvent
  ) => {
    if (e.key !== "Tab") {
      return;
    }

    e.preventDefault();

    const start = textareaRef.current!.selectionStart;
    const end = textareaRef.current!.selectionEnd;

    textareaRef.current!.value =
      textareaRef.current!.value.substring(0, start) +
      "\t" +
      textareaRef.current!.value.substring(end);
    textareaRef.current!.selectionStart = textareaRef.current!.selectionEnd =
      start + 1;
  };

  return (
    <>
      <textarea
        {...formRegister}
        {...rest}
        className={classNames}
        ref={textareaRef}
        onKeyDown={(e) => onKeyDown(e)}
      />
      {errorMessage && (
        <div className="error-message mr-sm">
          <span className="text-color-red">
            <span>{errorMessage}</span>
          </span>
        </div>
      )}
    </>
  );
}
