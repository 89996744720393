import { plainToClass } from "class-transformer";
import { format } from "date-fns";

import { GenericResponse } from "models/utils";
import AppConfig from "app-config";
import { DateFormat } from "libs/date/date-format";
import {
  ActivitiesTeam,
  Activity,
  ActivityDefinition,
  ActivityInstanceMessage,
  ActivityStatus,
  AssignUserToActivitiesRequest,
  AssignUserToActivityRequest,
  AssignUserToActivityResponse,
  CustomerActivitiesServices,
  FormAnswer,
  FormAnswerBatch,
  FormQuestion,
  GetActivitiesPerAssignedUserRequest,
  GetActivitiesPerAssignedUserResponse,
  ServiceTypeDefinition,
} from "models/activities/activity";
import { Assignment } from "models/assignment";
import { CustomerPackage } from "models/deliveryPlan";
import { ServiceDescription } from "models/serviceDescription";
import { ACTIVITIES_CACHE_DISABLED } from "constants/conveyorBeltConsts";
import { getAuthorizationHeader } from "./libs/auth-header";
import { BatchResponse, ParseBatchResponse } from "./libs/batchResponse";

export class ActivitiesAPI {
  static async fetchServicesMatrix(token: string) {
    const response = await fetch(`${AppConfig.API_URL}/service_matrix`, {
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const jsonArray = (await response.json()) as [];
    return jsonArray.map((json: Record<string, unknown>) =>
      plainToClass(ServiceDescription, json)
    );
  }

  static async fetchOptionalActivityTypes(
    token: string,
    customerNumber: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/activities_optional`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json: { activities: Activity[]; org_number: string } =
      await response.json();

    return json.activities.map((j) => plainToClass(Activity, j));
  }

  static async addOptionalServiceActivityType(
    token: string,
    customerNumber: string,
    activityTypeId: string,
    startDate: Date
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/activities_optional/${activityTypeId}`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          startDate: format(startDate, DateFormat.YearDayMonth),
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async removeOptionalServiceActivityType(
    token: string,
    customerNumber: string,
    activityTypeId: string,
    endDate: Date
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/activities_optional/${activityTypeId}`,
      {
        method: "DELETE",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          endDate: format(endDate, DateFormat.YearDayMonth),
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async addOnetimeActivity(
    token: string,
    customerNumber: string,
    activityTypeId: string,
    startDate: Date
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/activities_onetime`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          startDate: format(startDate, DateFormat.YearDayMonth),
          activity_onetime_id: activityTypeId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async removeOnetimeActivity(
    token: string,
    customerNumber: string,
    activityId: string,
    someActivityOriginalId: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/activities_onetime`,
      {
        method: "DELETE",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          someActivityOriginalId,
          activityId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async setPackageDefaultAssignedUser(
    token: string,
    customerNumber: string,
    packageName: string,
    userId: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/packages/${packageName}/assignees/${userId}/assign`,
      {
        method: "PUT",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          userId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return response.json();
  }

  static async setReassignedUser(
    token: string,
    customerNumber: string,
    packageName: string,
    newUser: string,
    currentUser: string
  ) {
    const response = await fetch(`${AppConfig.API_URL}/activities/reassign`, {
      method: "POST",
      headers: {
        ...getAuthorizationHeader(token),
      },
      body: JSON.stringify({
        customer_ids: [customerNumber],
        current_user: currentUser,
        include_unassigned: false,
        new_user: newUser,
        service_type: packageName,
      }),
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return response.json();
  }

  static async setActivityDeadline(
    token: string,
    customerNumber: string,
    activityOriginalId: string,
    deadline: Date,
    upcoming: boolean
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/activities/deadline`,
      {
        method: "PATCH",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          deadline: format(deadline, DateFormat.YearDayMonth),
          upcoming,
          activityId: activityOriginalId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async postActivityMessage(
    token: string,
    customerNumber: string,
    activityOriginalId: string,
    messageContent: string,
    status: ActivityStatus,
    serviceArea?: string
  ): Promise<ActivityInstanceMessage> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/activities/messages`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          message_content: messageContent,
          status,
          service_area: serviceArea,
          activityId: activityOriginalId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.json();
  }

  static async fetchUserCustomersActivities(
    token: string,
    startDate: Date,
    endDate: Date,
    customerNumbers?: string[],
    serviceBoxId?: string
  ) {
    const params = new URLSearchParams({
      start_date: format(startDate, DateFormat.YearDayMonth),
      end_date: format(endDate, DateFormat.YearDayMonth),
      service_box_id: serviceBoxId || "",
    });

    const cacheDisabled = localStorage.getItem(ACTIVITIES_CACHE_DISABLED);
    if (cacheDisabled !== "true") {
      params.append("disable_cache", "true");
    }

    const response = await fetch(
      `${AppConfig.API_URL}/activities?${params.toString()}`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          customer_ids: customerNumbers,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const batchResponse: BatchResponse<CustomerActivitiesServices> =
      await response.json();
    const result = ParseBatchResponse(batchResponse, {
      cls: CustomerActivitiesServices,
    });

    return result;
  }

  static async fetchFormQuestions(
    token: string,
    orgNumber: string,
    activityId: string,
    formId: string | null
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${orgNumber}/forms/${formId}`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          activityId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json: FormQuestion[] = await response.json();

    return json.map((j) => plainToClass(FormQuestion, j));
  }

  static async fetchFormAnswers(
    token: string,
    orgNumber: string,
    fiscalYear: number,
    formId: string | null,
    serviceId?: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${orgNumber}/forms/${formId}/answers/${fiscalYear}?service_box_id=${serviceId}`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json: FormAnswer[] = await response.json();

    return json.map((j) => plainToClass(FormAnswer, j));
  }

  static async fetchKYCFormAnswersBatch(
    token: string,
    orgNumber: string,
    fiscalYear?: number
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${orgNumber}/forms/kyc-answers`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          fiscalYear,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json: FormAnswerBatch[] = await response.json();

    return json.map((j) => plainToClass(FormAnswerBatch, j));
  }

  static async setActivityInstanceCompleted(
    token: string,
    customerId: string,
    activityId: string,
    userId: string,
    originalId: string,
    formQuestions?: FormQuestion[],
    serviceBoxId?: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerId}/activities/complete`,
      {
        method: "PUT",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          userId,
          formQuestions,
          originalId,
          activityId,
          serviceBoxId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return true;
  }

  static async setActivityInstanceUncompleted(
    token: string,
    customerId: string,
    activityId: string,
    originalId: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerId}/activities/uncomplete`,
      {
        method: "PUT",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          activityId,
          originalId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return true;
  }

  static async updatePackages(
    token: string,
    customerId: string,
    packageIds: CustomerPackage[]
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerId}/package`,
      {
        method: "PATCH",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify(packageIds),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return true;
  }

  static async updateAssignment(
    token: string,
    customerId: string,
    assignmentId: string,
    update: Partial<Assignment>
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerId}/assignments/${assignmentId}`,
      {
        method: "PATCH",
        body: JSON.stringify(update),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return true;
  }

  static async fetchCustomerByOrgNr(token: string, orgNumber: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/activities-backend/${orgNumber}`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return response.json();
  }

  static async fetchActivitiesDefinitions(token: string) {
    const response = await fetch(`${AppConfig.API_URL}/activities_table`, {
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json: ActivityDefinition[] = await response.json();

    return json.map((j) => plainToClass(ActivityDefinition, j));
  }

  static async getServiceTypesList(token: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/list/facts_cb_service_types`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json: ServiceTypeDefinition[] = await response.json();

    return json.map((j) => plainToClass(ServiceTypeDefinition, j));
  }

  static async syncCustomerActivities(token: string, customerId: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerId}/activities/sync`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.json();
  }

  static async fetchUnfinishedActivities(
    token: string,
    startDate: Date,
    endDate: Date,
    customerNumbers: string[]
  ) {
    const params = new URLSearchParams({
      start_date: format(startDate, DateFormat.YearDayMonth),
      end_date: format(endDate, DateFormat.YearDayMonth),
    });

    const cacheDisabled = localStorage.getItem(ACTIVITIES_CACHE_DISABLED);
    if (cacheDisabled !== "true") {
      params.append("disable_cache", "true");
    }

    const response = await fetch(
      `${AppConfig.API_URL}/activities/unfinished?${params.toString()}`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          customer_ids: customerNumbers,
        }),
      }
    );

    const batchResponse: BatchResponse<CustomerActivitiesServices> =
      await response.json();
    const result = ParseBatchResponse(batchResponse, {
      cls: CustomerActivitiesServices,
    });

    return result;
  }

  static async assignUserToActivities(
    token: string,
    body: AssignUserToActivitiesRequest
  ) {
    const response = await fetch(`${AppConfig.API_URL}/activities/assign`, {
      method: "POST",
      headers: { ...getAuthorizationHeader(token) },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const responseData: GenericResponse<string[]> = await response.json();
    return responseData;
  }

  static async assignUserToActivity(
    token: string,
    originalId: string,
    body: AssignUserToActivityRequest
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/activities/${originalId}/assign`,
      {
        method: "PUT",
        headers: { ...getAuthorizationHeader(token) },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const responseData: GenericResponse<AssignUserToActivityResponse> =
      await response.json();
    return responseData;
  }

  static async getServiceBoxActivities(
    token: string,
    customerId: string,
    serviceBoxId: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerId}/service_boxes/${serviceBoxId}`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.json();
  }

  static async getActivitiesTeam(token: string, customerNumbers: string[]) {
    const response = await fetch(`${AppConfig.API_URL}/teams/activities`, {
      method: "POST",
      headers: {
        ...getAuthorizationHeader(token),
      },
      body: JSON.stringify({
        customer_ids: customerNumbers,
      }),
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }

    const responseData: GenericResponse<ActivitiesTeam[]> =
      await response.json();
    return responseData;
  }

  static async getActivitiesPerAssignedUser(
    token: string,
    abortSignal: AbortSignal,
    body: GetActivitiesPerAssignedUserRequest
  ) {
    const response = await fetch(`${AppConfig.API_URL}/activities/assigned`, {
      method: "POST",
      headers: { ...getAuthorizationHeader(token) },
      body: JSON.stringify(body),
      signal: abortSignal,
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const responseData: GenericResponse<
      GetActivitiesPerAssignedUserResponse[]
    > = await response.json();
    return responseData;
  }

  static async runDeliverySimulations(
    token: string,
    customerNumbers: string[],
    isSimulation: boolean,
    dryRun: boolean = true
  ) {
    const response = await fetch(`${AppConfig.API_URL}/simulate_deliveries`, {
      method: "POST",
      headers: {
        ...getAuthorizationHeader(token),
      },
      body: JSON.stringify({
        customer_ids: customerNumbers,
        is_request: true,
        is_simulation: isSimulation,
        dry_run: dryRun,
      }),
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }

    const responseData: GenericResponse<any> = await response.json();
    return responseData;
  }

  static async getOneDriveShortcutFolder(token: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/onedriveshortcut_folder?version=2`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return response.json();
  }
}
