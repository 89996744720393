import { DrawerBody, DrawerFooter, Spinner } from "@fluentui/react-components";
import { ArrowRight16Regular } from "@fluentui/react-icons";
import { addHours } from "date-fns";
import { useEffect, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { DatePicker } from "components/date/DatePicker";
import DrawerHeader from "components/drawer/drawerHeader/DrawerHeader";
import OverlayDrawer from "components/OverlayDrawer";
import { SpinnerSize } from "components/spinner";
import Switch from "components/switch";
import { useTranslation } from "hooks";
import { DateFormat } from "libs/date/date-format";
import {
  ActivityDefinition,
  ActivityInstance,
  FormQuestion,
  FormQuestionAnswerAllowedTypes,
  FormQuestionAnswerType,
  ServiceInstance,
} from "models/activities/activity";
import { ActivityAppendix } from "models/activities/ActivityAppendix";
import { Customer } from "models/customer";
import { User } from "models/user";
import { RootState } from "state";
import {
  completeActivity,
  fetchFormQuestions,
  setFormQuestionAnswer,
} from "state/activities/actions";
import { AdditionalTaxDocuments } from "./AdditionalTaxDocuments";

type DeductionModuleFormProps = {
  setShowQuestionsModal: (show: boolean) => void;
  customer: Customer;
  activityInstance: ActivityInstance;
  currentUser: User;
  showQuestionsModal: boolean;
  service?: ServiceInstance;
  definition: ActivityDefinition;
};

type FromQuestionGroup = {
  group_header: string;
  group_sort_order: number;
  group_questions: FormQuestion[];
};

export function DeductionModuleForm({
  setShowQuestionsModal,
  customer,
  activityInstance,
  currentUser,
  showQuestionsModal,
  service,
  definition,
}: DeductionModuleFormProps) {
  const formQuestions = useSelector(
    (state: RootState) => state.activities.formQuestions
  );

  const dispatch = useDispatch();
  const { translate, language, dateFormatter } = useTranslation();

  const currentFormId = useRef<string>();
  const formQuestionsGrouped: FromQuestionGroup[] = [];

  useEffect(() => {
    if (
      activityInstance.form_id !== null &&
      (formQuestions.length === 0 ||
        currentFormId.current !== activityInstance.form_id)
    ) {
      dispatch(fetchFormQuestions(customer, activityInstance));
      currentFormId.current = activityInstance.form_id;
    }
  });

  function getGroupedQuestions() {
    if (formQuestionsGrouped.length > 0) {
      return formQuestionsGrouped;
    }

    formQuestions.forEach((question) => {
      const group = formQuestionsGrouped.find(
        (existingGroup) => existingGroup.group_header === question.group_header
      );

      if (group) {
        group.group_questions.push(question);
      } else {
        formQuestionsGrouped.push({
          group_header: question.group_header,
          group_sort_order: question.group_sort_order,
          group_questions: [question],
        });
      }
    });
    formQuestionsGrouped.sort(
      (a, b) => a.group_sort_order - b.group_sort_order
    );
    return formQuestionsGrouped;
  }

  function updateAnswer(
    answer: FormQuestionAnswerAllowedTypes,
    questionId: string
  ) {
    dispatch(setFormQuestionAnswer(answer, questionId));
  }

  const handleSendIn = () => {
    dispatch(
      completeActivity(
        customer,
        activityInstance,
        currentUser.graphId,
        [...formQuestions],
        service?.service_box_id
      )
    );
    currentFormId.current = "";
    setShowQuestionsModal(false);
  };

  const isLoadingForm = () => {
    return (
      formQuestions.length === 0 ||
      currentFormId.current !== activityInstance.form_id ||
      getGroupedQuestions().length === 0
    );
  };

  const renderFooter = () => {
    return (
      <>
        <div>
          <Button
            variant="outline-primary"
            className="py-sm full-width"
            onClick={() => {
              setShowQuestionsModal(false);
              currentFormId.current = "";
            }}
          >
            {translate("CANCEL")}
          </Button>
        </div>

        <div>
          <Button
            disabled={!formQuestions.length}
            variant="primary"
            className="py-sm full-width"
            onClick={handleSendIn}
          >
            <span className="d-flex align-items-center">
              <ArrowRight16Regular className="mr-sm" /> {translate("SEND")}
            </span>
          </Button>
        </div>
      </>
    );
  };

  return (
    <OverlayDrawer
      modalType="non-modal"
      size="medium"
      showPanel={
        showQuestionsModal &&
        activityInstance.form_id != null &&
        activityInstance.form_id !== ""
      }
    >
      <DrawerHeader
        onDismiss={() => {
          setShowQuestionsModal(false);
        }}
        header={activityInstance.getTitle(language, definition)}
        notTranslatable
      />
      <DrawerBody>
        <Row className="gy-md">
          <Col xs={12}>
            <p className="fw-bold no-margin">
              {translate("DEDUCTION_WARNING_HEADER")}
            </p>
          </Col>

          <Col xs={12}>{translate("LOCK_ACTIVITY_WARNING")}</Col>
        </Row>

        <Row className="gy-md mt-md">
          {isLoadingForm() && (
            <div>
              <Spinner size={SpinnerSize.ExtraSmall} />
            </div>
          )}

          {!isLoadingForm() &&
            getGroupedQuestions().map((group) => {
              return (
                <Row className="gy-md mt-md" key={group.group_header}>
                  <hr />
                  {group.group_header && (
                    <h4 className="divider">
                      <b>{group.group_header}</b>
                    </h4>
                  )}
                  {group.group_questions.map((question) => {
                    return (
                      <Col xs={12} key={question.question_id}>
                        {question.answer_type ===
                          FormQuestionAnswerType.DatePicker && (
                          <>
                            <div className="fw-bold">{question.question}</div>
                            <DatePicker
                              placeholder=""
                              fullWidth
                              dateFormatter={(date) =>
                                dateFormatter(date, DateFormat.YearDayMonth)
                              }
                              selectedDate={
                                question.answer
                                  ? new Date(question.answer as string)
                                  : new Date()
                              }
                              onSelectDate={(date) => {
                                if (!date) {
                                  return;
                                }

                                updateAnswer(
                                  addHours(date, 12).toISOString(),
                                  question.question_id
                                );
                              }}
                            />
                          </>
                        )}

                        {question.answer_type ===
                          FormQuestionAnswerType.Boolean && (
                          <Switch
                            checked={question.answer === "true"}
                            label={question.question}
                            onToggleMethod={() => {
                              updateAnswer(
                                question.answer === "true" ? "false" : "true",
                                question.question_id
                              );
                            }}
                          />
                        )}

                        {question.answer_type ===
                          FormQuestionAnswerType.Appendice &&
                        question.answer ? (
                          <AdditionalTaxDocuments
                            value={
                              question.answer as unknown as ActivityAppendix[]
                            }
                            onChange={(newValue) => {
                              updateAnswer(newValue, question.question_id);
                            }}
                          />
                        ) : null}
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
        </Row>
      </DrawerBody>
      <DrawerFooter className="justify-content-between">
        {renderFooter()}
      </DrawerFooter>
    </OverlayDrawer>
  );
}
