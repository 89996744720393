import { Info20Regular } from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";

import Tooltip from "components/tooltip/Tooltip";
import { useTranslation } from "hooks/use-translate";
import {
  BooleanWithText,
  FormAnswer,
  FormQuestionAnswerType,
} from "models/activities/activity";
import "./YearlyEvaluationKYCReadonly.scss";

type Props = {
  formAnswer: FormAnswer;
  index: number;
};

export function YearlyEvaluationKYCFormRowReadonly({
  formAnswer,
  index,
}: Props) {
  const { translate } = useTranslation();

  //  Points column is hidden as per DV-2569
  //  NOTE: this commented code is needed for future!
  // const getQuestionPoints = () => {
  //   return (formAnswer?.answer as BooleanWithText)?.value.toString() === "true"
  //     ? "10"
  //     : "0";
  // };

  return (
    <Row className="px-lg py-sm align-items-center">
      <Col md={1}>{index + 1}</Col>
      <Col md={9}>{formAnswer.question}</Col>
      <Col md={2}>
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <span className="pr-sm">
              {(formAnswer?.answer as BooleanWithText)?.value.toString() ===
              "true"
                ? translate("YES")
                : translate("NO")}
            </span>
            {formAnswer?.answer_type ===
              FormQuestionAnswerType.BooleanWithText &&
              (formAnswer?.answer as BooleanWithText)?.comment && (
                <Tooltip
                  notTranslatable
                  content={
                    (formAnswer?.answer as BooleanWithText)?.comment ?? ""
                  }
                >
                  <Info20Regular className="cursor-pointer v-align-sub" />
                </Tooltip>
              )}
          </div>
        </div>
      </Col>
      {/* Points column is hidden as per DV-2569 
          NOTE: this commented code is needed for future! */}
      {/* <Col md={1}>
        <div>{getQuestionPoints()} / 10</div>
      </Col> */}
    </Row>
  );
}
