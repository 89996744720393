import { useContext } from "react";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { Edit16Regular } from "@fluentui/react-icons";

import Tooltip from "components/tooltip/Tooltip";
import { useTranslation } from "hooks";
import {
  CustomerManagement,
  CustomerManagementRoles,
  CustomerManagementWithIndex,
} from "models/customerManagement";
import { TranslationKey } from "i18n";
import { ManageCustomersContext } from "../context/ManageCustomersContext";

enum EmployeeChangeActon {
  AllProjects,
  AllProjectsManager,
  AllProjectsSales,
  SelectedProjects,
  SelectedProjectsManager,
  SelectedProjectsSales,
}

const DIVIDER = "divider";

type MenuButtonStructureType = (MenuButtonStructure | typeof DIVIDER)[];

interface MenuButtonStructure {
  tooltipContent?: string;
  action: EmployeeChangeActon;
  content: string;
  disabled?: boolean;
}

interface ChangeInManagementMenuButtonProps {
  setShowModal: (show: boolean) => void;
}

export default function ChangeInManagementMenuButton({
  setShowModal,
}: ChangeInManagementMenuButtonProps) {
  const { translate } = useTranslation();

  const {
    assignee,
    assigneeFirstName,
    setAffectedCustomersCounter,
    setAffectedProjectsCounter,
    setAffectedRoles,
    selectedRows,
    setSelectedRows,
    unlockedCustomers,
    selectedCustomers,
  } = useContext(ManageCustomersContext);

  if (!assignee) {
    throw new Error("Missing assignee!");
  }

  const filteredProjectsByAssigneeIsProjectManager = (
    customers: CustomerManagementWithIndex[]
  ) =>
    customers.flatMap(({ customer }) =>
      customer.projects.filter(
        (project) => project.projectmanager_graph_id === assignee.id
      )
    );

  const filteredCustomersByAssigneeIsSalesPerson = (
    customers: CustomerManagementWithIndex[]
  ) =>
    customers.filter(
      ({ customer }) => customer.salesperson_graph_id === assignee.id
    );

  const filteredCustomersByAssigneeIsProjectManager = (
    customers: CustomerManagementWithIndex[]
  ) =>
    customers.filter(({ customer }) =>
      customerHasProjectManagedByAssignee(customer)
    );

  const customerHasProjectManagedByAssignee = ({
    projects,
  }: CustomerManagement) =>
    projects.some((project) => project.projectmanager_graph_id === assignee.id);

  const isAssigneeProjectManager = (customers: CustomerManagementWithIndex[]) =>
    !!filteredProjectsByAssigneeIsProjectManager(customers).length;

  const isAssigneeSalesPerson = (customers: CustomerManagementWithIndex[]) =>
    !!filteredCustomersByAssigneeIsSalesPerson(customers).length;

  const handleEmployeeChangeAction = (action: EmployeeChangeActon) => {
    switch (action) {
      case EmployeeChangeActon.AllProjects: {
        setSelectedRows(new Set(unlockedCustomers.map(({ index }) => index)));

        const affectedCustomers =
          filteredCustomersByAssigneeIsSalesPerson(unlockedCustomers);
        setAffectedCustomersCounter(affectedCustomers.length);

        const affectedProjects =
          filteredProjectsByAssigneeIsProjectManager(unlockedCustomers);
        setAffectedProjectsCounter(affectedProjects.length);

        setAffectedRoles(CustomerManagementRoles.Both);

        break;
      }

      case EmployeeChangeActon.AllProjectsManager: {
        const customersAssigneeIsProjectManager =
          filteredCustomersByAssigneeIsProjectManager(unlockedCustomers);
        setSelectedRows(
          new Set(
            customersAssigneeIsProjectManager.flatMap(({ index }) => index)
          )
        );

        setAffectedCustomersCounter(0);

        const affectedProjects = filteredProjectsByAssigneeIsProjectManager(
          customersAssigneeIsProjectManager
        );
        setAffectedProjectsCounter(affectedProjects.length);

        setAffectedRoles(CustomerManagementRoles.ProjectManager);

        break;
      }

      case EmployeeChangeActon.AllProjectsSales: {
        const affectedCustomers =
          filteredCustomersByAssigneeIsSalesPerson(unlockedCustomers);

        setSelectedRows(new Set(affectedCustomers.map(({ index }) => index)));
        setAffectedCustomersCounter(affectedCustomers.length);
        setAffectedProjectsCounter(0);
        setAffectedRoles(CustomerManagementRoles.SalesPerson);

        break;
      }

      case EmployeeChangeActon.SelectedProjects: {
        const affectedCustomers =
          filteredCustomersByAssigneeIsSalesPerson(selectedCustomers);
        setAffectedCustomersCounter(affectedCustomers.length);

        const affectedProjects =
          filteredProjectsByAssigneeIsProjectManager(selectedCustomers);
        setAffectedProjectsCounter(affectedProjects.length);

        setAffectedRoles(CustomerManagementRoles.Both);

        break;
      }

      case EmployeeChangeActon.SelectedProjectsManager: {
        setAffectedCustomersCounter(0);

        const affectedProjects =
          filteredProjectsByAssigneeIsProjectManager(selectedCustomers);
        setAffectedProjectsCounter(affectedProjects.length);

        setAffectedRoles(CustomerManagementRoles.ProjectManager);

        break;
      }

      case EmployeeChangeActon.SelectedProjectsSales: {
        const affectedCustomers =
          filteredCustomersByAssigneeIsSalesPerson(selectedCustomers);
        setAffectedCustomersCounter(affectedCustomers.length);

        setAffectedProjectsCounter(0);

        setAffectedRoles(CustomerManagementRoles.SalesPerson);

        break;
      }

      default:
        throw new Error("Invalid employee change acton");
    }

    setShowModal(true);
  };

  const buildAllProjectsBothRolesButton = () => {
    return {
      tooltipContent: !selectedRows.size
        ? translate(
            "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_ALL_PROJECTS.TOOLTIP",
            [assigneeFirstName]
          )
        : "",
      action: EmployeeChangeActon.AllProjects,
      content: translate(
        "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_ALL_PROJECTS"
      ),
      disabled: !unlockedCustomers.length || !!selectedRows.size,
    };
  };

  const buildAllProjectsManagerButton = () => {
    const isProjectManager = isAssigneeProjectManager(unlockedCustomers);

    let tooltipTranslationKey: TranslationKey | undefined;
    if (!isProjectManager) {
      tooltipTranslationKey =
        "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_MANAGER_ALL_PROJECTS.TOOLTIP_1";
    } else if (!selectedRows.size) {
      tooltipTranslationKey =
        "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_MANAGER_ALL_PROJECTS.TOOLTIP_2";
    }

    return {
      tooltipContent: tooltipTranslationKey
        ? translate(tooltipTranslationKey, [assigneeFirstName])
        : "",
      action: EmployeeChangeActon.AllProjectsManager,
      content: translate(
        "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_MANAGER_ALL_PROJECTS"
      ),
      disabled: !isProjectManager || !!selectedRows.size,
    };
  };

  const buildAllProjectsSalesButton = () => {
    const isSalesPerson = isAssigneeSalesPerson(unlockedCustomers);

    let tooltipTranslationKey: TranslationKey | undefined;
    if (!isSalesPerson) {
      tooltipTranslationKey =
        "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_SALES_ALL_PROJECTS.TOOLTIP_1";
    } else if (!selectedRows.size) {
      tooltipTranslationKey =
        "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_SALES_ALL_PROJECTS.TOOLTIP_2";
    }

    return {
      tooltipContent: tooltipTranslationKey
        ? translate(tooltipTranslationKey, [assigneeFirstName])
        : "",
      action: EmployeeChangeActon.AllProjectsSales,
      content: translate(
        "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_SALES_ALL_PROJECTS"
      ),
      disabled: !isSalesPerson || !!selectedRows.size,
    };
  };

  const buildSelectedProjectsBothRolesButton = () => {
    return {
      action: EmployeeChangeActon.SelectedProjects,
      content: translate(
        "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_SELECTED_PROJECTS"
      ),
      disabled: !selectedRows.size,
    };
  };

  const buildSelectedProjectsManagerButton = () => {
    const isProjectManager = isAssigneeProjectManager(selectedCustomers);

    let tooltipContent = "";
    if (selectedRows.size && !isProjectManager) {
      tooltipContent = translate(
        "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_MANAGER_SELECTED_PROJECTS.TOOLTIP",
        [assigneeFirstName]
      );
    }

    return {
      tooltipContent,
      action: EmployeeChangeActon.SelectedProjectsManager,
      content: translate(
        "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_MANAGER_SELECTED_PROJECTS"
      ),
      disabled: !selectedRows.size || !isProjectManager,
    };
  };

  const buildSelectedProjectsSalesButton = () => {
    const isSalesPerson = isAssigneeSalesPerson(selectedCustomers);

    let tooltipContent = "";
    if (selectedRows.size && !isSalesPerson) {
      tooltipContent = translate(
        "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_SALES_SELECTED_PROJECTS.TOOLTIP",
        [assigneeFirstName]
      );
    }

    return {
      tooltipContent,
      action: EmployeeChangeActon.SelectedProjectsSales,
      content: translate(
        "MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.ACTION_SALES_SELECTED_PROJECTS"
      ),
      disabled: !selectedRows.size || !isSalesPerson,
    };
  };

  const menuButtonStructure: MenuButtonStructureType = [
    buildAllProjectsBothRolesButton(),
    buildAllProjectsManagerButton(),
    buildAllProjectsSalesButton(),
    DIVIDER,
    buildSelectedProjectsBothRolesButton(),
    buildSelectedProjectsManagerButton(),
    buildSelectedProjectsSalesButton(),
  ];

  return (
    <Menu positioning="below-end">
      <MenuTrigger disableButtonEnhancement>
        <MenuButton
          className="change-user-in-management-menu-btn"
          icon={<Edit16Regular />}
        >
          {translate("MANAGE_CUSTOMERS.CHANGE_MENU_BUTTON.TITLE", [
            assigneeFirstName,
          ])}
        </MenuButton>
      </MenuTrigger>

      <MenuPopover className="max-content-min">
        <MenuList className="fw-semibold">
          {menuButtonStructure.map((item, index) => {
            if (item === DIVIDER) {
              // eslint-disable-next-line react/no-array-index-key
              return <MenuDivider key={index} />;
            }

            return (
              <Tooltip
                key={item.content}
                content={item.tooltipContent || ""}
                positioning="before"
                enabled={!!item.tooltipContent}
                notTranslatable
              >
                <MenuItem
                  disabled={item.disabled}
                  onClick={() => handleEmployeeChangeAction(item.action)}
                  className="max-content-min"
                >
                  {item.content}
                </MenuItem>
              </Tooltip>
            );
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
}
