/* eslint-disable no-underscore-dangle */
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";

import { RootState } from "state";
import { useTranslation } from "hooks/use-translate";
import { Dropdown } from "components/dropdown/Dropdown";
import { Deal } from "models/deals/deal";
import { DealMultiSelectDropdownProps } from "models/deals/fieldTypes";
import { useUpdateDealProperty } from "hooks/myBusinessOpportunities/use-update-deal-property";

import "./DealFieldsStyles.scss";

function DealMultiSelectProductDropdown({
  deal,
  configuration,
  disabled = false,
  stage,
}: DealMultiSelectDropdownProps) {
  const { disableProductsToMatchPOGServices, isLoading } =
    useUpdateDealProperty();
  const { translate } = useTranslation();
  const {
    formState,
    setValue,
    getValues,
    trigger,
    control,
    watch,
    resetField,
  } = useFormContext<Partial<Deal>>();

  const value = watch(configuration.property) as string[] | undefined;

  const isDisabled = isLoading || disabled;

  const products = useSelector((state: RootState) => state.sales.products.data);

  const [selectedIds, setSelectedIds] = useState(value ?? []);

  const error = formState.errors[configuration.property] as FieldError;

  const businessArea = getValues("affarsomrade_deal_");
  const selectedBusinessArea =
    businessArea && businessArea !== ""
      ? businessArea
      : deal.affarsomrade_deal_;

  useEffect(() => {
    if (configuration.property === "productIds") {
      if (selectedBusinessArea !== deal.affarsomrade_deal_) {
        setSelectedIds([]);
        setValue(configuration.property, [], {
          shouldDirty: true,
        });
        setValue("amount", 0, {
          shouldDirty: true,
          shouldValidate: true,
        });
      } else {
        setSelectedIds(deal.productIds);
        resetField("amount");
        resetField("productIds");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessArea, configuration.property, deal.affarsomrade_deal_]);

  useEffect(() => {
    setSelectedIds(value ?? []);
  }, [value]);

  const handleOnChange = (selectedOptions: string[]) => {
    if (selectedOptions) {
      setSelectedIds(selectedOptions);
      const selectedProducts = products.filter((p) =>
        selectedOptions.includes(p.id)
      );
      if (configuration.property === "productIds") {
        const totalPrice = selectedProducts.reduce(
          (sum, curr) => sum + curr.price,
          0
        );
        setValue("amount", totalPrice, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
      setValue(configuration.property, selectedOptions, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  return (
    <Controller
      control={control}
      name={configuration.property}
      rules={{
        validate: {
          isValid: (val) => {
            const productIds = val as string[] | undefined;
            if (
              productIds &&
              stage?.configuration?.RequiredFields.includes("productIds") &&
              productIds.length < 1
            ) {
              return translate("FIELD_REQUIRED");
            }
          },
        },
      }}
      render={({ field: { onChange } }) => (
        <Dropdown
          multiselect
          disabled={isDisabled}
          dropdownClassName={`deal-dropdown ${
            isDisabled ? "deal-dropdown-disabled" : ""
          }`}
          placeholder={configuration.placeholder}
          onOptionSelect={(_, data) => {
            onChange();
            handleOnChange(data.selectedOptions);
          }}
          onBlur={(e) => {
            e.preventDefault();
            trigger();
          }}
          errorMessage={error?.message}
          defaultSelectedOptions={selectedIds}
          selectedOptions={selectedIds}
          options={disableProductsToMatchPOGServices(
            selectedIds,
            selectedBusinessArea
          )}
        />
      )}
    />
  );
}

export default DealMultiSelectProductDropdown;
