import { useDispatch, useSelector } from "react-redux";

import { KycAPI } from "api/kyc";
import { appendError, appendToastMessage } from "state/notifications";
import { RootState } from "state";
import { AppDispatch } from "state/use-app-redux";
import { useNavigate } from "react-router-dom";
import { Customer } from "models/customer";
import DealContactDetails from "models/offer/DealContactDetails";
import { SendNewKYCContacts } from "./new-kyc-types";

export function useSendNewKYC() {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { currentUser } = useSelector((state: RootState) => state.users);

  const sendNewKYC = async (
    customer: Customer,
    selectedContacts: DealContactDetails[]
  ) => {
    const kycContacts: SendNewKYCContacts[] = [];
    selectedContacts.forEach((contact) => {
      kycContacts.push({
        email: contact.email,
        first_name: contact.first_name,
        last_name: contact.last_name,
        ssn: contact.social_security_number,
        mobile_phone: contact.mobile_phone,
      });
    });

    const sendKYC = {
      org_num: customer.company_registration_number,
      consultant: {
        email: currentUser.email,
        first_name: currentUser.firstName,
        last_name: currentUser.lastName,
      },
      contacts: kycContacts,
    };

    const contactsNames = kycContacts
      .map((contact) => `${contact.first_name} ${contact.last_name}`)
      .join(", ");

    try {
      await KycAPI.sendKYC(sendKYC);
      dispatch(
        appendToastMessage("NEW_KYC_FORM.SENT_SUCCESSFULLY", "success", [
          contactsNames,
          customer.name,
        ])
      );
      navigate("/assignments");
    } catch (e) {
      dispatch(appendError("NEW_KYC_FORM.FAILED_TO_SEND_KYC", e as Error));
    }
  };

  return {
    sendNewKYC,
  };
}
