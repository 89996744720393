import {
  ArrowUpload20Regular,
  Delete20Regular,
  DocumentPdf20Regular,
} from "@fluentui/react-icons";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { useTranslation } from "hooks/use-translate";
import { getTruncatedString } from "libs/truncate-string";
import { BoxContext, useBoxContext } from "./BoxLayout";
import { BoxHeader } from "./BoxHeader";

type BoxFileUploadProps = {
  title: string;
  actionTitle: string;
  onFileDropped: (file: File) => void;
  onFileDelete?: (fileName: string) => void;
  fileName?: string;
  accept?: { [key: string]: string[] };
  actionSubtitle?: string;
  onError?: () => void;
};
export function BoxFileUpload({
  title,
  actionTitle,
  onFileDropped,
  onFileDelete,
  fileName = "",
  accept,
  actionSubtitle,
  onError,
}: BoxFileUploadProps) {
  const { translate } = useTranslation();
  const { disabled } = useBoxContext();

  const onDrop = useCallback(
    (files: File[]) => {
      if (disabled) {
        return;
      }

      if (files && files.length > 0 && !disabled) {
        onFileDropped(files[0]);
      }
    },
    [onFileDropped, disabled]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onError,
    ...(accept && { accept }),
  });

  const renderUploadButton = () => {
    if (isDragActive) {
      return (
        <>
          <ArrowUpload20Regular className="text-color-blue" />
          <span className="box-action-subtitle">
            {translate("COMPLETION_DROP_FILES_HOVER")}
          </span>
        </>
      );
    }

    return (
      <>
        <ArrowUpload20Regular className="text-color-blue" />
        <span className="ml-sm">{actionTitle}</span>
        {actionSubtitle && (
          <span className="box-action-subtitle">{actionSubtitle}</span>
        )}
      </>
    );
  };

  const renderUploadedFile = () => {
    return (
      <span className="d-flex align-items-center">
        <DocumentPdf20Regular />
        <span className="ml-sm">{getTruncatedString(fileName, 20)}</span>
        <span className="ml-md">
          <Delete20Regular
            color="#EC666B"
            className="cursor-pointer"
            onClick={() => onFileDelete?.(fileName)}
          />
        </span>
      </span>
    );
  };

  return (
    <BoxContext.Provider value={{ disabled: !!disabled }}>
      <div
        {...(!fileName && getRootProps())}
        className={`box${disabled ? " disabled" : ""}${
          isDragActive ? " isDraggingFile" : ""
        }`}
      >
        <BoxHeader>{title}</BoxHeader>

        <span className="box-action mt-xl">
          {!fileName && <input {...getInputProps()} />}

          {fileName ? renderUploadedFile() : renderUploadButton()}
        </span>
      </div>
    </BoxContext.Provider>
  );
}
