import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";

import "./index.scss";
import { useTranslation } from "hooks/use-translate";
import { AppDispatch } from "state/use-app-redux";
import { Content, ContentBody } from "components/content/Content";
import { ContentHeader } from "components/content/ContentHeader";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { PersonDetails } from "models/profile";
import { appendError } from "state/notifications";
import { useGetCustomersForManagementQuery } from "api/customerManagementApi";
import { LayoutHeader, LayoutWrapper } from "components/layout/Layout";
import { PersonPicker } from "components/people";
import { fetchPropertiesByObjectAndPropertyName } from "state/sales/actions";
import {
  DynamicDealPropertiesObjectName,
  DynamicDealPropertiesPropertyName,
} from "constants/enums/dealEnums";
import { ManageCustomersContext } from "./context/ManageCustomersContext";
import { ManageCustomersRouteHelper } from "./routes/manageCustomersRoutes";

export default function ManageCustomers() {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const { setCustomers, assignee, setAssignee, resetContext } = useContext(
    ManageCustomersContext
  );

  const [assigneeId, setAssigneeId] = useState<string | undefined>();

  const { data, isFetching, isError, error, refetch } =
    useGetCustomersForManagementQuery(assignee?.userPrincipalName ?? "", {
      skip: !assigneeId || !assignee,
    });

  useEffect(() => {
    resetContext();

    dispatch(
      fetchPropertiesByObjectAndPropertyName(
        DynamicDealPropertiesObjectName.COMPANIES,
        DynamicDealPropertiesPropertyName.RECOMMENDED_PROGRAM
      )
    );
    dispatch(
      fetchPropertiesByObjectAndPropertyName(
        DynamicDealPropertiesObjectName.COMPANIES,
        DynamicDealPropertiesPropertyName.CHOSEN_PROGRAM
      )
    );
  }, [resetContext, dispatch]);

  const resetAssignee = useCallback(() => {
    setAssignee(undefined);
    setAssigneeId(undefined);
  }, [setAssignee]);

  useEffect(() => {
    if (isError && error) {
      dispatch(
        appendError(
          "MANAGE_CUSTOMERS.FAILED_TO_FETCH_CUSTOMERS_FOR_MANAGEMENT",
          error as Error
        )
      );

      resetAssignee();
    }
  }, [isError, error, dispatch, resetAssignee]);

  const onPersonChange = (person?: PersonDetails) => {
    if (!person) {
      resetAssignee();
      return;
    }

    if (!person.userPrincipalName) {
      dispatch(
        appendError(
          "MANAGE_CUSTOMERS.FAILED_TO_FETCH_CUSTOMERS_FOR_MANAGEMENT",
          {
            message: "userPrincipalName missing",
          } as Error
        )
      );
      return;
    }

    setAssignee(person);
    setAssigneeId(person.id);
    refetch();
  };

  useEffect(() => {
    if (assigneeId && !isFetching && !isError && data) {
      setCustomers(data.data);
      navigate(ManageCustomersRouteHelper.getListOfCustomers());
    }
  }, [assigneeId, isFetching, isError, setCustomers, data, navigate]);

  return (
    <>
      <LayoutHeader>
        <h1>{translate("MANAGE_CUSTOMERS.LANDING_PAGE.MAIN_HEADER_TITLE")}</h1>
      </LayoutHeader>
      <LayoutWrapper>
        <Content>
          <ContentHeader
            title={translate(
              "MANAGE_CUSTOMERS.LANDING_PAGE.CONTENT_HEADER_TITLE"
            )}
            subtitles={[
              translate(
                "MANAGE_CUSTOMERS.LANDING_PAGE.CONTENT_HEADER_SUBTITLE"
              ),
            ]}
            loadingStatus={
              isFetching ? LoadingStatusEnum.PENDING : LoadingStatusEnum.IDLE
            }
          />
          <ContentBody>
            <Row className="project-sales-picker minHeight-100 justify-content-center px-md-xxl">
              <Col className="my-auto no-padding flex-xl-1">
                <div className="horizontal-divider" />
              </Col>
              <Col
                xs="auto"
                className="project-sales-picker-content d-flex justify-content-center align-items-center"
              >
                <span className="text-color-gray-700 max-content-min">
                  {translate("MANAGE_CUSTOMERS.LANDING_PAGE.SEARCH_AS")}:
                </span>
                <span className="fw-semibold ml-sm mr-md max-content-min">
                  {translate(
                    "MANAGE_CUSTOMERS.LANDING_PAGE.SEARCH_AS_PROJECT_MANAGER"
                  )}
                  ,{" "}
                  {translate(
                    "MANAGE_CUSTOMERS.LANDING_PAGE.SEARCH_AS_SALESPERSONS"
                  )}
                </span>
                <div className="person-picker-wrapper">
                  <PersonPicker
                    selectionMode="single"
                    emptyPlaceholder={translate(
                      "MANAGE_CUSTOMERS.LANDING_PAGE.PERSON_PICKER.PLACEHOLDER"
                    )}
                    selectedUsers={assigneeId ? [{ id: assigneeId }] : []}
                    onPersonChange={(_, __, person) => onPersonChange(person)}
                    disabled={isFetching}
                  />
                </div>
              </Col>
              <Col className="my-auto no-padding flex-xl-1">
                <div className="horizontal-divider" />
              </Col>
            </Row>
          </ContentBody>
        </Content>
      </LayoutWrapper>
    </>
  );
}
