import React from "react";
import { Spinner } from "@fluentui/react-components";
import { useSelector } from "react-redux";

import { RootState } from "state";
import { useTranslation } from "hooks";
import { uuidv4 } from "libs/uuid";
import { Customer } from "models/customer";
import { Button } from "components/button";
import { Persona } from "components/people";
import Tooltip from "components/tooltip/Tooltip";
import useGetCustomerTeam from "hooks/conveyorBelt/use-get-customer-team";

type Props = {
  customer: Customer;
  setShowTeamModal: (show: boolean) => void;
};

function AssignmentTeam({ customer, setShowTeamModal }: Props) {
  const { translate } = useTranslation();
  const {
    activitiesTeams: { isLoading },
  } = useSelector((state: RootState) => state.activities);

  const { allAssignees, projectManagersForCustomer, customerActivities } =
    useGetCustomerTeam({
      customer,
    });

  const isOnboardedInCB =
    (customerActivities && customerActivities.active) ||
    customer.deliveryPlan.some((d) => d.is_locked_in);

  return (
    <div className="d-flex assignment-team">
      <Tooltip content="TEAM_MODAL">
        <div
          onClick={() => setShowTeamModal(true)}
          className="d-flex cursor-pointer align-items-center pt-xs"
        >
          <div className="text-wrap-none">
            <span>{translate("PROJECT_MANAGERS")}</span>
          </div>
          <div className="vertical-divider pr-sm ml-sm" />
          <div>
            <div className="d-flex align-items-center text-wrap-none">
              {projectManagersForCustomer.length !== 0 ? (
                <>
                  {projectManagersForCustomer.map(
                    (proj, index) =>
                      index < 3 && (
                        <div key={proj.email}>
                          <Persona userId={proj.email} className="me-1" />
                        </div>
                      )
                  )}
                  {projectManagersForCustomer.length > 3 && (
                    <Button
                      variant="outline-outline"
                      className="mx-0 px-0 text-primary"
                      size="md"
                    >
                      <span className="mx-0 px-0 text-primary fpx-12">
                        +{projectManagersForCustomer.length - 3}
                      </span>
                    </Button>
                  )}
                </>
              ) : (
                <span className="text-gray fs-italic">
                  {translate("ASSIGNED_TEAM.NO_PROJECT_MANAGER")}
                </span>
              )}
            </div>
          </div>
        </div>
      </Tooltip>
      <Tooltip content="TEAM_MODAL">
        <div
          onClick={() => setShowTeamModal(true)}
          className="d-flex cursor-pointer align-items-center pt-xs"
        >
          <div className="text-wrap-none">
            <span>{translate("ASSIGNEES")}</span>
          </div>
          <div className="vertical-divider pr-sm ml-sm" />
          <div className="d-flex">
            {isLoading ? (
              <div className="py-xs">
                <Spinner size="extra-small" />
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-end">
                {isOnboardedInCB && allAssignees.length > 0 ? (
                  <>
                    {allAssignees.map(
                      (assignedUser, index) =>
                        index < 3 && (
                          <Persona
                            className="mx-1"
                            userId={assignedUser}
                            key={`${uuidv4()}-${assignedUser}`}
                          />
                        )
                    )}
                    {allAssignees.length > 3 && (
                      <Button
                        variant="outline-outline"
                        className="mx-0 px-0 text-primary"
                        size="md"
                      >
                        <span className="mx-0 px-0 text-primary fpx-12">
                          +{allAssignees.length - 3}
                        </span>
                      </Button>
                    )}
                  </>
                ) : (
                  <span className="text-gray fs-italic">
                    {translate("ASSIGNED_TEAM.NO_ASSIGNEE")}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </Tooltip>
    </div>
  );
}
export default React.memo(AssignmentTeam);
