import { Spinner } from "@fluentui/react-components";
import {
  ArrowRight16Regular,
  ArrowUpRight16Regular,
  DividerShort24Regular,
  Edit20Regular,
  ToggleLeft20Regular,
} from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "components/button";
import ContextualMenu from "components/contextualMenu/ContextualMenu";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { capitalize } from "libs/capitalize";
import { formatDate } from "libs/date/date-format";
import { renderIcon } from "libs/render-icon";
import { ServiceNote } from "models/customerSettings/customerSettingsServiceNote";
import { ServiceNoteDb } from "models/customerSettings/customerSettingsServiceNoteDb";
import { CustomerPageDetailedPageEnum } from "constants/enums/CustomerPageDetailedPage.enum";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import {
  resetOpenCreateModalFor,
  setOpenCreateModalFor,
} from "state/customerSettings/customerSettingsSlice";
import { updateServiceNotesCallerWrapper } from "state/customerSettings/customerSettingsThunk";
import CustomerPageServiceNoteReadModal from "./customerPageServiceNoteReadModal";
import CustomerPageServiceNotesModal from "./customerPageServiceNotesModal";

const MAX_TEXT = 500;
const MAX_ELEMENTS = 3;

type Props = {
  customerId: string;
  customerManagerId: string;
};
export default function CustomerPageServiceNotes({
  customerId,
  customerManagerId,
}: Props) {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const serviceNotes = useSelector(
    (state: RootState) => state.settings.serviceNotes.data
  );
  const currentServiceLine = useSelector(
    (state: RootState) => state.settings.serviceLines.currentServiceLine
  );

  const searchTerm = useSelector(
    (state: RootState) => state.settings.searchTerm
  );

  const openCreateModalFor = useSelector(
    (state: RootState) => state.settings.openCreateModalFor
  );

  const location = useLocation();
  const lastUrlSegment = location.pathname.split("/").pop();
  const isDetailedPage =
    lastUrlSegment === CustomerPageDetailedPageEnum.SERVICE_NOTES;

  const serviceNotesFilteredByServiceLine = currentServiceLine
    ? serviceNotes
        .filter((e) =>
          e.servicelines.filter(
            (serviceLine) => serviceLine.id === currentServiceLine.id
          )
        )
        .slice(0, MAX_ELEMENTS)
    : serviceNotes;
  const serviceNotesToShowFrom = isDetailedPage
    ? serviceNotesFilteredByServiceLine
    : serviceNotesFilteredByServiceLine.slice(0, MAX_ELEMENTS);

  const serviceNotesToShow = searchTerm
    ? serviceNotesToShowFrom.filter(
        (noteEl) =>
          noteEl.modified_by.firstName.toLowerCase().includes(searchTerm) ||
          noteEl.modified_by.lastName.toLowerCase().includes(searchTerm) ||
          noteEl.title?.toLowerCase().includes(searchTerm) ||
          noteEl.notes.toLowerCase().includes(searchTerm)
      )
    : serviceNotesToShowFrom;

  const { currentUser } = useSelector((state: RootState) => state.users);
  const loadingStatus = useSelector(
    (state: RootState) => state.settings.serviceNotes.status
  );

  const [openModal, setOpenModal] = useState(false);
  const [openReadModal, setOpenReadModal] = useState(false);
  const [currentNote, setCurrentNote] = useState<ServiceNote>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const openServiceNotePage = () => {
    navigate(AppRouteHelper.getCustomerServiceNotePage(customerId));
  };

  useEffect(() => {
    if (openCreateModalFor === CustomerPageDetailedPageEnum.SERVICE_NOTES) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [openCreateModalFor]);

  const disableNote = (serviceNote: ServiceNote) => {
    const note = {
      ...serviceNote,
      modified_by: currentUser.graphId,
      enabled: false,
    } as ServiceNoteDb;
    dispatch(updateServiceNotesCallerWrapper(note));
    dispatch(resetOpenCreateModalFor());
  };

  const onEditNote = async (note: ServiceNote) => {
    setIsEditMode(true);
    await dispatch(
      setOpenCreateModalFor(CustomerPageDetailedPageEnum.SERVICE_NOTES)
    );
    setCurrentNote(note);
    setOpenModal(true);
  };

  const getServiceNoteCreator = (firstName: string, lastName: string) => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return translate("USER_NOT_FOUND");
  };

  return (
    <Row className="m-auto">
      <Col md={12} className="cs-content p-lg">
        {loadingStatus === LoadingStatusEnum.PENDING && (
          <Spinner size={SpinnerSize.Tiny} />
        )}
        {searchTerm && serviceNotesToShow.length === 0 && (
          <Row>{translate("NO_DATA_SEARCH")}</Row>
        )}
        {!searchTerm && serviceNotesToShow.length === 0 && (
          <Row>
            <Col>
              {isDetailedPage
                ? translate("NO_NOTES_PAGE")
                : translate("NO_NOTES_SECTION")}
            </Col>
          </Row>
        )}
        {serviceNotesToShow.map((serviceNote, index) => {
          const dateString = formatDate(serviceNote.modified_date);
          const shortenNote = isDetailedPage
            ? false
            : serviceNote.notes.length > MAX_TEXT;
          const noteFormatted = isDetailedPage
            ? capitalize(serviceNote.notes)
            : capitalize(serviceNote.notes.slice(0, MAX_TEXT));
          return (
            <Row key={`note-${serviceNote.id}`}>
              <Col>
                <Row className="fw-15">
                  <Col className="d-flex align-items-center">
                    {serviceNote.title && (
                      <span className="mr-xxs">
                        <b>{serviceNote.title}</b>
                      </span>
                    )}
                    {serviceNote.title && (
                      <span className="mr-xxs">
                        <DividerShort24Regular className="text-muted" />
                      </span>
                    )}
                    <span className="mr-xxs">{dateString}</span>
                    <span className="mr-xxs">
                      <DividerShort24Regular className="text-muted" />
                    </span>
                    <span className="modified-by">
                      {getServiceNoteCreator(
                        serviceNote.modified_by.firstName,
                        serviceNote.modified_by.lastName
                      )}
                    </span>
                    {isDetailedPage &&
                      (serviceNote.created_by === currentUser.graphId ||
                        customerManagerId === currentUser.id) && (
                        <div>
                          <ContextualMenu
                            menuItems={[
                              {
                                text: `${translate("SERVICE_NOTE_EDIT")}`,
                                icon: () => renderIcon(<Edit20Regular />),
                                onClick: () => {
                                  onEditNote(serviceNote);
                                },
                              },
                              {
                                text: `${translate("SERVICE_NOTE_DISABLE")}`,
                                icon: () => renderIcon(<ToggleLeft20Regular />),
                                onClick: () => {
                                  disableNote(serviceNote);
                                },
                              },
                            ]}
                          />
                        </div>
                      )}
                  </Col>
                </Row>
                <Row className="note-body mt-sm">
                  <Col>
                    {noteFormatted} {shortenNote && <span>...</span>}
                  </Col>
                </Row>
                {shortenNote && !isDetailedPage && (
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        variant="link"
                        onClick={() => {
                          setCurrentNote(serviceNote);
                          setOpenReadModal(true);
                        }}
                      >
                        {translate("READ_MORE")}
                        <ArrowRight16Regular className="ml-xs" />
                      </Button>
                    </Col>
                  </Row>
                )}
                {index !== serviceNotesToShow.length - 1 && (
                  <Row className="horizontal-divider mb-lg mt-lg" />
                )}
              </Col>
            </Row>
          );
        })}
      </Col>
      {!isDetailedPage && (
        <Col md={12}>
          <Row className="mt-md">
            <div className="d-flex align-items-end mb-xs px-0">
              <Button
                variant="outline-outline"
                className="justify-content-start primary-colored-text"
                onClick={openServiceNotePage}
              >
                <ArrowUpRight16Regular />
                <span className="d-inline mx-sm fw-bold primary">
                  {translate("NOTE_NEW_PAGE")}
                </span>
              </Button>
            </div>
          </Row>
        </Col>
      )}

      {!isEditMode && openModal && (
        <CustomerPageServiceNotesModal
          customerId={customerId}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}
      {currentNote && isEditMode && openModal && (
        <CustomerPageServiceNotesModal
          customerId={customerId}
          openModal={openModal}
          setOpenModal={setOpenModal}
          currentNote={currentNote}
          setIsEditMode={setIsEditMode}
        />
      )}
      {openReadModal && currentNote && (
        <CustomerPageServiceNoteReadModal
          currentNote={currentNote}
          openModal={openReadModal}
          setOpenModal={setOpenReadModal}
        />
      )}
    </Row>
  );
}
