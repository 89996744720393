import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

interface AccordionContextProps {
  isOpen: boolean;
  toggle: () => void;
  expanded?: boolean;
}

export const AccordionContext = createContext<AccordionContextProps>({
  isOpen: false,
  toggle: () => {},
  expanded: false,
});

interface IAccordionProvider {
  children: ReactNode;
  isInitiallyOpen?: boolean;
  expanded?: boolean | undefined;
}

export function AccordionProvider({
  children,
  isInitiallyOpen = false,
  expanded = undefined,
}: IAccordionProvider) {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (expanded !== undefined) {
      setIsOpen(expanded);
    }
  }, [expanded]);

  const initContextState = useMemo(
    () => ({
      isOpen,
      toggle,
      expanded,
    }),
    [isOpen, toggle, expanded]
  );

  return (
    <AccordionContext.Provider value={initContextState}>
      {children}
    </AccordionContext.Provider>
  );
}
