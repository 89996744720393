import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Tooltip from "components/tooltip/Tooltip";
import { MenuItemType } from "./MenuItemGroup";

export type MenuItemProps = {
  item: MenuItemType;
  collapsed: boolean;
};

export function MenuItem({ item, collapsed }: MenuItemProps) {
  const location = useLocation();
  const [itemSelected, setIsActive] = useState(false);

  useEffect(() => {
    if (
      item.url &&
      item.url === `/${location.pathname.split("/")[1]}`.concat(location.search)
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [location, collapsed, item.url]);

  const getActiveLinkClassName = ():
    | string
    | ((props: { isActive: boolean }) => string)
    | undefined => {
    const activeMenuItemClass = collapsed ? "selected" : "selected flex-grow-1";

    if (item.url && location.pathname.startsWith(item.url)) {
      return activeMenuItemClass;
    }
    return ({ isActive }) => (isActive ? activeMenuItemClass : "");
  };

  if (!item.url) {
    return null;
  }

  return (
    <Tooltip
      content={item.title}
      positioning="after"
      enabled={!!collapsed}
      notTranslatable
    >
      <ul className={`menu-group my-xs ${!collapsed ? "d-flex" : ""}`}>
        {!collapsed && (
          <div className={`${itemSelected ? "selected-full" : ""}`} />
        )}
        {item.isExternalLink ? (
          <a key={item.url} href={item.url} rel="noreferrer" target="_blank">
            <li
              className={`menu-group-header ${
                collapsed ? "py-sm px-sm hover-menu" : "p-sm"
              } fw-bold`}
            >
              {item.icon || <span />}
              {!collapsed && (
                <span className="menu-group-title">{item.title}</span>
              )}
            </li>
          </a>
        ) : (
          <NavLink
            className={getActiveLinkClassName()}
            key={item.url}
            to={item.url}
          >
            <li
              className={`menu-group-header ${
                collapsed ? "py-sm px-sm hover-menu" : "p-sm"
              } fw-bold`}
            >
              {item.icon || <span />}
              {!collapsed && (
                <span className="menu-group-title">{item.title}</span>
              )}
            </li>
          </NavLink>
        )}
        {collapsed && (
          <div className={`${itemSelected && `selected-collapsed`}`} />
        )}
      </ul>
    </Tooltip>
  );
}
