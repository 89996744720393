import { TranslationKey } from "i18n";
import { CompanyType } from "models/deals/deal";

export const ItemTypes = {
  DEAL: "deal",
};

export const companyTypes: { label: TranslationKey; value: CompanyType }[] = [
  {
    label: "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_PRIVATE_PERSON",
    value: "Privatperson",
  },
  {
    label: "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_LIMITED_COMPANY",
    value: "Aktiebolag",
  },
  {
    label: "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_TRADING_COMPANY",
    value: "Handelsbolag",
  },
  {
    label: "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_PRIVATE_BUSINESS",
    value: "Enskildnäringsverksamhet",
  },
  {
    label: "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_LIMITED_PARTNERSHIP",
    value: "Kommanditbolag",
  },
  {
    label:
      "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_NON_PROFIT_ASSOCIATION",
    value: "Ideell förening",
  },
  {
    label:
      "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_COMMUNITY_ASSOCIATION",
    value: "Samfällighetsförening",
  },
  {
    label: "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_OTHER",
    value: "Annat",
  },
];
