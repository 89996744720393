import { plainToClass } from "class-transformer";
import produce from "immer";
import { Customer, CustomersLudvigServiceStatus } from "models/customer";
import { CustomerDelivery } from "models/deliveryPlan";
import {
  APPEND_CUSTOMER,
  CREATE_DELIVERY,
  CustomersAction,
  DELETE_DELIVERIES_BATCH,
  DELETE_DELIVERY,
  GET_CUSTOMER_TAX_OBJECTS,
  LOAD_CUSTOMERS,
  LOADING_CUSTOMERS,
  LOADING_CUSTOMERS_FAILED,
  LOADING_NEW_CUSTOMER,
  SET_CUSTOMER_LOADING,
  SET_CUSTOMER_SIE_FILE_PRIORITIZE_LOADING,
  SET_CUSTOMER_SIE_FILE_PRIORITY,
  SET_CUSTOMER_SIE_FILES,
  SET_CUSTOMER_UI_STATE,
  SET_CUSTOMERS_LUDVIG_SERVICES,
  SET_IS_SEARCH_RESULT,
  SET_SEARCH_CUSTOMERS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMERS_LUDVIG_SERVICES,
  UPDATE_DELIVERY,
} from ".";

export type CustomerState = {
  customer: Customer;
  isFromSearch: boolean;
  isLoading: boolean;
  hasLoaded: boolean;
  hasLoadedDeliveryPlan: boolean;
};

export type CustomersState = {
  data: CustomerState[];
  customer_ludvig_services: CustomersLudvigServiceStatus[];
  isLoadingNewCustomer: boolean;
  isLoading: boolean;
  hasFailed: boolean;
  isSearchResult: boolean;
};

const initialCustomersState: CustomersState = {
  data: [],
  customer_ludvig_services: [],
  isLoadingNewCustomer: false,
  isLoading: false,
  hasFailed: false,
  isSearchResult: false,
};

const sortCustomerSieFiles = (customer: Customer) => {
  return (
    customer.sieFiles &&
    customer.sieFiles.sort((a, b) =>
      a.last_modified > b.last_modified ? -1 : 1
    )
  );
};

export function customersReducer(
  state = initialCustomersState,
  action: CustomersAction
) {
  switch (action.type) {
    case UPDATE_CUSTOMERS_LUDVIG_SERVICES: {
      const new_customer = action.payload[0];
      const filtered_services = state.customer_ludvig_services.filter(
        (customer) => customer.customer_number !== new_customer.customer_number
      );
      return {
        ...state,
        customer_ludvig_services: [...filtered_services, new_customer],
      };
    }
    case SET_CUSTOMERS_LUDVIG_SERVICES: {
      return {
        ...state,
        customer_ludvig_services: action.payload,
      };
    }
    case SET_CUSTOMER_UI_STATE: {
      return produce(state, ({ data }) => {
        const { customer, state: newState } = action.payload;

        const c = data.find(
          (c) => c.customer.customer_number === customer.customer_number
        );

        if (!c) {
          throw new Error(
            `Could not find customer state ${customer.customer_number}`
          );
        }

        if (newState.hasLoaded !== undefined) {
          c.hasLoaded = newState.hasLoaded;
        }

        if (newState.hasLoadedDeliveryPlan !== undefined) {
          c.hasLoadedDeliveryPlan = newState.hasLoadedDeliveryPlan;
        }

        if (newState.isLoading !== undefined) {
          c.isLoading = newState.isLoading;
        }

        if (newState.isFromSearch !== undefined) {
          c.isFromSearch = newState.isFromSearch;
        }
      });
    }
    case UPDATE_CUSTOMER: {
      const customersState = state.data;
      const customerState = customersState.find(
        (c) => c.customer.customer_number === action.payload.customer_number
      );

      if (!customerState) {
        throw new Error("Could not find customer in state");
      }

      return {
        ...state,
        data: [
          ...customersState.filter(
            (c) => c.customer.customer_number !== action.payload.customer_number
          ),
          {
            ...customerState,
            customer: plainToClass(Customer, {
              ...customerState.customer,
              ...action.payload.update,
            }),
          },
        ],
        isLoading: false,
        hasFailed: false,
      };
    }
    case LOAD_CUSTOMERS: {
      return {
        ...state,
        data: [...action.payload],
        isLoading: false,
        hasFailed: false,
      };
    }
    case SET_SEARCH_CUSTOMERS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        hasFailed: false,
      };
    }
    case CREATE_DELIVERY: {
      return produce(state, ({ data: customers }) => {
        const customerState = customers.find(
          (c) =>
            c.customer.customer_number ===
            action.payload.customer.customer_number
        );

        if (!customerState) {
          throw new Error("Could not find customer in state");
        }
        customerState.customer.deliveryPlan = [
          ...customerState.customer.deliveryPlan,
          action.payload.delivery,
        ];
      });
    }
    case UPDATE_DELIVERY: {
      return produce(state, ({ data: customerStates }) => {
        const customerState = customerStates.find(
          (c) =>
            c.customer.customer_number ===
            action.payload.customer.customer_number
        );

        if (!customerState) {
          throw new Error("Could not find customer in state");
        }

        const deliveryPlanIndex = customerState.customer.deliveryPlan.findIndex(
          (d) => d.uuid === action.payload.deliveryId
        );

        customerState.customer.deliveryPlan[deliveryPlanIndex] = plainToClass(
          CustomerDelivery,
          {
            ...customerState.customer.deliveryPlan[deliveryPlanIndex],
            ...action.payload.update,
          }
        );
      });
    }
    case DELETE_DELIVERY: {
      return produce(state, ({ data: customerStates }) => {
        const customerState = customerStates.find(
          (c) =>
            c.customer.customer_number ===
            action.payload.customer.customer_number
        );

        if (!customerState) {
          throw new Error("Could not find customer in state");
        }

        customerState.customer.deliveryPlan =
          customerState.customer.deliveryPlan.filter(
            (d) => d.uuid !== action.payload.deliveryId
          );
      });
    }
    case DELETE_DELIVERIES_BATCH: {
      return produce(state, ({ data: customerStates }) => {
        const customerState = customerStates.find(
          (c) =>
            c.customer.customer_number ===
            action.payload.customer.customer_number
        );

        if (!customerState) {
          throw new Error("Could not find customer in state");
        }

        const deletedDeliveries = action.payload.batchResponse
          .filter((deletedDelivery) => deletedDelivery.delivery.deleted_at)
          .map((deletedDelivery) => deletedDelivery.delivery_uuid);

        customerState.customer.deliveryPlan =
          customerState.customer.deliveryPlan.filter(
            (d) => !deletedDeliveries.includes(d.uuid)
          );
      });
    }
    case SET_CUSTOMER_LOADING: {
      const customerStates = state.data;
      const customerState = customerStates.find(
        (c) =>
          c.customer.customer_number === action.payload.customer.customer_number
      );

      if (!customerState) {
        throw new Error("Could not find customer in state");
      }

      return {
        ...state,
        data: [
          ...customerStates.filter(
            (c) =>
              c.customer.customer_number !==
              action.payload.customer.customer_number
          ),
          {
            ...customerState,
            isLoading: action.payload.isLoading,
          },
        ],
        isLoading: false,
        hasFailed: false,
      };
    }
    case APPEND_CUSTOMER: {
      return {
        ...state,
        data: [
          ...state.data,
          {
            ...action.payload,
            hasLoaded: false,
            isLoading: false,
            hasLoadedDeliveryPlanner: false,
          },
        ],
        isLoading: false,
        hasFailed: true,
      };
    }
    case LOADING_CUSTOMERS_FAILED: {
      return {
        ...state,
        isLoading: false,
        hasFailed: true,
      };
    }
    case LOADING_CUSTOMERS: {
      return {
        ...state,
        isLoading: action.payload,
        hasFailed: false,
      };
    }
    case LOADING_NEW_CUSTOMER: {
      return {
        ...state,
        isLoadingNewCustomer: action.payload,
      };
    }
    case SET_CUSTOMER_SIE_FILES: {
      return produce(state, ({ data: customerStates }) => {
        const customerState = customerStates.find(
          (c) =>
            c.customer.customer_number ===
            action.payload.customer.customer_number
        );

        if (!customerState) {
          throw new Error("Could not find customer in state");
        }

        customerState.customer.sieFiles = action.payload.sieFiles;
        sortCustomerSieFiles(customerState.customer);
      });
    }
    case SET_CUSTOMER_SIE_FILE_PRIORITIZE_LOADING: {
      return produce(state, ({ data: customerStates }) => {
        const customerState = customerStates.find(
          (c) =>
            c.customer.customer_number ===
            action.payload.customer.customer_number
        );

        if (!customerState) {
          throw new Error("Could not find customer in state");
        }

        const { sieFile } = action.payload;
        sieFile.isLoading = action.payload.loading;

        customerState.customer.sieFiles = [
          ...customerState.customer.sieFiles.filter(
            (e) => e.key !== sieFile.key && e.source !== sieFile.source
          ),
          sieFile,
        ];

        sortCustomerSieFiles(customerState.customer);
      });
    }
    case SET_CUSTOMER_SIE_FILE_PRIORITY: {
      return produce(state, ({ data: customerStates }) => {
        const customerState = customerStates.find(
          (c) =>
            c.customer.customer_number ===
            action.payload.customer.customer_number
        );

        if (!customerState) {
          throw new Error("Could not find customer in state");
        }

        const { sieFile } = action.payload;
        const customerSieFile = action.payload.customer.sieFiles.find(
          (file) => file.key === sieFile.key && file.source === sieFile.source
        );

        if (customerState && customerSieFile) {
          customerSieFile.justPrioritized = true;
          customerState.customer.sieFiles = [
            ...customerState.customer.sieFiles,
            customerSieFile,
          ];
          sortCustomerSieFiles(customerState.customer);
        }
      });
    }
    case GET_CUSTOMER_TAX_OBJECTS: {
      return produce(state, ({ data: customerStates }) => {
        const customerState = customerStates.find(
          (c) => c.customer.customer_number === action.payload.customerNumber
        );

        if (!customerState) {
          throw new Error("Could not find customer in state");
        }

        customerState.customer.taxObjects = action.payload.taxObjects;
      });
    }
    case SET_IS_SEARCH_RESULT: {
      return {
        ...state,
        isSearchResult: action.payload,
      };
    }
    default:
      return state;
  }
}
