import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft20Regular } from "@fluentui/react-icons";

import { LoadingStatus, RootState } from "state";
import { useTranslation } from "hooks";
import { Button } from "components/button";
import { getBackButtonText } from "views/createNew/offer/routes/offerRoutes";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { useRoutingForOffer } from "./useRoutingForOffer";

type Props = {
  customBackButtonRoute?: string;
  backButtonText?: string;
  loadingStatus?: LoadingStatus;
  onBeforePrevious?: () => void;
  onPrevious?: () => void;
};

export default function WizardBackButton({
  customBackButtonRoute,
  backButtonText,
  loadingStatus,
  onBeforePrevious,
  onPrevious,
}: Props) {
  const { ts } = useTranslation();
  const currentOffer = useSelector(
    (state: RootState) => state.offers.currentOffer.data
  );
  const navigate = useNavigate();
  const location = useLocation();
  const routing = useRoutingForOffer(location.pathname, currentOffer);
  const buttonText =
    backButtonText || ts(getBackButtonText(location.pathname, currentOffer));

  if (!routing.previous && !customBackButtonRoute) {
    return null;
  }

  const handleClick = () => {
    if (onBeforePrevious) {
      onBeforePrevious();
      return;
    }

    if (onPrevious) {
      onPrevious();
    }

    if (customBackButtonRoute) {
      navigate(customBackButtonRoute);
      return;
    }
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
  };

  return (
    <Button
      disabled={loadingStatus === LoadingStatusEnum.PENDING}
      variant="outline-primary"
      onClick={handleClick}
    >
      <span className="d-flex flex-row align-items-center">
        <ArrowLeft20Regular className="mx-xs" />
        <span className="px-sm">{buttonText}</span>
      </span>
    </Button>
  );
}
