import AppConfig from "app-config";
import { authMethod } from "auth";
import { ExternalKYCdata } from "models/customerSettings/customerSettingsExternalKYC";
import { SendNewKYC } from "views/conveyorBelt/components/newKYC/new-kyc-types";
import { getAuthorizationHeader } from "./libs/auth-header";

export class KycAPI {
  static async sendKYC(body: SendNewKYC) {
    const token = await authMethod.getStoredAccessToken();
    const response = await fetch(
      `${AppConfig.API_URL}/contracts/envelopes/ekyc`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    const res = await response.json();
    return res;
  }

  static async getFailedKYCs(token: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/contracts/envelopes/failed-kyc-statuses`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
    const res = await response.json();
    return res;
  }

  static async getKYCForm(
    envelopeId: string,
    documentId: string,
    fileId: string
  ) {
    const token = await authMethod.getStoredAccessToken();

    const response = await fetch(
      `${AppConfig.API_URL}/contracts/envelopes/url?envelope_id=${envelopeId}&document_id=${documentId}&file_id=${fileId}`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
    const res = await response.json();
    return res;
  }

  static async manuallyApproveFailedKYC(
    token: string,
    envelopeId: string,
    comment: string,
    kycStatus = false
  ) {
    const reqBody = {
      envelope_id: envelopeId,
      ext_kyc_status: kycStatus,
      comment,
      ext_kyc_approved_date: new Date(),
    };
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/kyc-status`,
      {
        method: "POST",
        body: JSON.stringify(reqBody),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
    const res = await response.json();
    return res;
  }

  static async getExternalKYCForCustomer(orgNumber: string, token: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/contracts/envelopes/${orgNumber}`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
    const res: ExternalKYCdata[] = await response.json();
    return res;
  }
}
