import { useState } from "react";
import { useTranslation } from "hooks/use-translate";
import { useSelector } from "react-redux";
import { Spinner } from "@fluentui/react-components";

import { RootState } from "state";
import Paginator from "components/Pagination";
import { paginatorOptions } from "constants/paginatorOptions";
import { SpinnerSize } from "components/spinner";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { ExternalKYCTable } from "./ExternalKYCTable";

export function ExternalKYCPage() {
  const { translate } = useTranslation();

  const [activePage, setActivePage] = useState<number>(1);
  const [itemsShownPerPage, setItemsShownPerPage] = useState<number>(15);

  const externalKYCdata = useSelector(
    (state: RootState) => state.settings.externalKYC.data
  );
  const externalKYCstatus = useSelector(
    (state: RootState) => state.settings.externalKYC.status
  );

  const externalKYCdataToShow = externalKYCdata?.slice(
    (activePage - 1) * itemsShownPerPage,
    activePage * itemsShownPerPage
  );

  return (
    <div className="new-page-content">
      <div className="d-flex customer-settings-table-sticky contentHeader">
        <div className="header-title d-flex  align-items-center">
          <h3 className="pl-sm">{translate("CUSTOMER_PAGE_EXTERNAL_KYC")}</h3>
          <span className="px-sm  text-muted font-size-em-5">
            ({externalKYCdata.length})
          </span>
        </div>
        <div className="ml-auto">
          {externalKYCdata && (
            <Paginator
              itemsLength={externalKYCdata.length || 0}
              defaultItemsShownPerPage={itemsShownPerPage}
              showItemsLabel={translate("SHOW")}
              options={paginatorOptions}
              onSelectPage={setActivePage}
              onChangeShownItems={(value) => setItemsShownPerPage(value)}
            />
          )}
        </div>
      </div>

      {externalKYCstatus === LoadingStatusEnum.PENDING ? (
        <Spinner className="ms-2" size={SpinnerSize.ExtraSmall} />
      ) : (
        <div className="p-lg">
          <ExternalKYCTable externalKYCdata={externalKYCdataToShow} />
        </div>
      )}
    </div>
  );
}
