/**
 * A horizontal line that is placed between boxes
 */
export function BoxHorizontalHr() {
  return (
    <div className="d-flex align-items-center">
      <hr style={{ width: "50px" }} />
    </div>
  );
}
