import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { SolidAccordion } from "components/accordion/solidAccordion";
import { useTranslation } from "hooks/use-translate";
import { CustomerDetailsParams } from "routes";
import { RootState } from "state";
import {
  resetExpandAll,
  resetSearchTerm,
} from "state/customerSettings/customerSettingsSlice";
import { AppDispatch } from "state/use-app-redux";
import { YearlyEvaluationKYCFormContentReadonly } from "./YearlyEvaluationKYCFormContentReadonly";

function CustomerPageKYCPage() {
  const { translate, dateFormatter } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { customerId } = useParams<CustomerDetailsParams>();
  const { data } = useSelector(
    (state: RootState) => state.settings.formKYCAnswersBatch
  );

  useEffect(() => {
    dispatch(resetExpandAll());
    dispatch(resetSearchTerm());
  }, [dispatch]);

  return (
    <div className="new-page-content">
      <div className="header-title px-sm d-flex pb-sm header-sticky bg-white">
        <h3 className="pl-sm stringLength">
          {translate("CUSTOMER_PAGE_KYC_EVALUATIONS")}
        </h3>
        <span className="accordion-subtitle align-self-center ml-sm">
          ({data.length})
        </span>
      </div>
      {customerId ? (
        data.map((kyc) => (
          <div key={kyc.completed_at.toDateString()}>
            <SolidAccordion
              title={dateFormatter(kyc.completed_at, "yyyy-MM-dd")}
              subtitles={[kyc.completed_by_user]}
            >
              <YearlyEvaluationKYCFormContentReadonly kyc={kyc} />
            </SolidAccordion>
          </div>
        ))
      ) : (
        <div>{translate("NO_CUSTOMER_ID")}</div>
      )}
    </div>
  );
}

export { CustomerPageKYCPage };
