import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  Content,
  ContentBody,
  ContentFooter,
} from "components/content/Content";
import { Breadcrumb, ContentHeader } from "components/content/ContentHeader";
import { SectionProps } from "components/section/Section";
import { LoadingStatus, RootState } from "state";
import {
  getAvailableWizardStepsForCreateBusinessOpportunity,
  useStepperItemsForCreateBusinessOpportunity,
} from "helpers/businessOpportunity/BusinessOpportunityStepperHelper";
import {
  WizardNavigationButtons,
  WizardNavigationProps,
} from "./WizardNavigationButtons";

type CustomerWizardSectionProps = {
  serviceName?: string;
  titleBreadCrumb?: string;
  numberBreadCrumb?: number;
  loadingStatus?: LoadingStatus;
  subtitles?: string[];
  hasDivider?: boolean;
  customBackButtonRoute?: string;
  disabledBackMessage?: string | JSX.Element | JSX.Element[];
  content?: ReactNode;
  backMessageTooltip?: string;
} & WizardNavigationProps &
  SectionProps;

function WizardSectionBusinessOpportunity({
  serviceName = "",
  titleBreadCrumb,
  numberBreadCrumb,
  onNext = () => true,
  onPrevious,
  isNextDisabled,
  onBeforeNext,
  children,
  predefinedNextRoute,
  hideNavigation = false,
  loadingStatus,
  subtitles,
  nextLabel,
  backLabel,
  isNextHidden,
  disabledBackMessage,
  customBackButtonRoute,
  hasDivider,
  content,
  backMessageTooltip,
}: CustomerWizardSectionProps) {
  const location = useLocation();

  const { isNewCustomer } = useSelector((state: RootState) => state.sales);

  const availableSteps =
    getAvailableWizardStepsForCreateBusinessOpportunity(isNewCustomer);

  const steps = useStepperItemsForCreateBusinessOpportunity(
    location.pathname,
    availableSteps
  );
  const currentStep = steps.find((step) => step.state?.active);

  const activeSubSteps =
    currentStep?.subSteps
      .filter((ss) => ss.state?.active && ss.label !== currentStep.label)
      .flatMap((ss) => ss.label) ?? [];

  const getBreadcrumbs = (): Breadcrumb[] => {
    let result = [];

    if (serviceName) {
      result.push({ title: serviceName });
    }

    if (currentStep?.label) {
      result.push({ title: currentStep.label });
    }

    if (activeSubSteps && activeSubSteps.length > 0) {
      result = [
        ...result,
        ...activeSubSteps.map((activeSubStep, index) => ({
          title:
            index === activeSubSteps.length - 1 && numberBreadCrumb
              ? `${activeSubStep} (${numberBreadCrumb})`
              : activeSubStep,
        })),
      ];
    }

    if (titleBreadCrumb) {
      result.push({ title: titleBreadCrumb });
    }

    return result.length > 0 ? result : [];
  };

  return (
    <Content>
      <ContentHeader
        loadingStatus={loadingStatus}
        subtitles={subtitles}
        breadcrumbs={getBreadcrumbs()}
        hasDivider={hasDivider}
        content={content}
      />

      <ContentBody>{children}</ContentBody>

      {!hideNavigation && (
        <ContentFooter>
          <WizardNavigationButtons
            onNext={onNext}
            onBeforeNext={onBeforeNext}
            isNextDisabled={isNextDisabled}
            predefinedNextRoute={predefinedNextRoute}
            onPrevious={onPrevious}
            hideNavigation={hideNavigation}
            nextLabel={nextLabel}
            backLabel={backLabel}
            isNextHidden={isNextHidden}
            disabledBackMessage={disabledBackMessage}
            customBackButtonRoute={customBackButtonRoute}
            backMessageTooltip={backMessageTooltip}
          />
        </ContentFooter>
      )}
    </Content>
  );
}

export default WizardSectionBusinessOpportunity;
