/**
 * Aligns many Box components horizontally so that each box will have the same height
 */
export function Boxes({ children }: any) {
  return (
    <div className="boxes d-flex justify-content-evenly align-items-stretch">
      {children}
    </div>
  );
}
