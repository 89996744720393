import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "@fluentui/react-components";

import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { CustomerSettingsContact } from "models/customerSettings/customerSettingsContact";
import { CustomerPageDetailedPageEnum } from "constants/enums/CustomerPageDetailedPage.enum";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { CompanyInformation } from "models/offer/Company";
import { CustomerDetailsParams } from "routes";
import { RootState } from "state";
import {
  resetExpandAll,
  resetSearchTerm,
} from "state/customerSettings/customerSettingsSlice";
import {
  fetchCompanyInfoFromSales,
  SearchBy,
  updateCompanyInfo,
} from "state/offer/companyThunks";
import { AppDispatch } from "state/use-app-redux";
import CustomerContactDeleteModal from "./CustomerContactDeleteModal";
import CustomerPageContactCard from "./customerPageContactCard";
import CustomerPageContactModal from "./customerPageContactModal";

export function CustomerPageContactsPage() {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const contacts = useSelector(
    (state: RootState) => state.settings.contacts.data
  );
  const openCreateModalFor = useSelector(
    (state: RootState) => state.settings.openCreateModalFor
  );

  const customers = useSelector((state: RootState) => state.customers.data);
  const { data: companyInfoData } = useSelector(
    (state: RootState) => state.offers.companyInfo
  );
  const { customerId } = useParams<CustomerDetailsParams>();
  const customer = customers.find(
    (e) => e.customer.customer_number === customerId
  )?.customer;
  const searchTerm = useSelector(
    (state: RootState) => state.settings.searchTerm
  );
  const contactsStatus = useSelector(
    (state: RootState) => state.settings.contacts.status
  );

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentContact, setCurrentContact] =
    useState<CustomerSettingsContact>();
  const [isLoading, setIsLoading] = useState(false);

  let filteredContacts = searchTerm
    ? contacts.filter(
        (contact) =>
          (contact.firstname &&
            contact.firstname.toLowerCase().includes(searchTerm)) ||
          (contact.lastname &&
            contact.lastname.toLowerCase().includes(searchTerm)) ||
          (contact.email && contact.email.toLowerCase().includes(searchTerm))
      )
    : contacts;

  const primaryContact = filteredContacts.find(
    (contact) => contact.id === companyInfoData?.primary_contact_id
  );

  if (primaryContact) {
    filteredContacts = filteredContacts.filter(
      (contact) => contact.id !== companyInfoData?.primary_contact_id
    );
    filteredContacts.unshift(primaryContact);
  }

  useEffect(() => {
    dispatch(resetExpandAll());
    dispatch(resetSearchTerm());
  }, [dispatch]);

  useEffect(() => {
    if (openCreateModalFor === CustomerPageDetailedPageEnum.CONTACTS) {
      setOpenModal(true);
    }
  }, [dispatch, openCreateModalFor]);

  const handleEditContact = (contactId: string) => {
    const currContact = contacts.find((contact) => contact.id === contactId);
    if (currContact) {
      setCurrentContact(currContact);
    }
    setOpenModal(true);
  };

  const handleDeleteContact = (contactId: string) => {
    const currContact = contacts.find((contact) => contact.id === contactId);
    if (currContact) {
      setCurrentContact(currContact);
    }
    setOpenDeleteModal(true);
  };

  const handleSetPrimaryContact = async (contactId: string) => {
    if (customerId) {
      setIsLoading(true);
      const company: Partial<CompanyInformation> = {
        primary_contact_id: contactId,
      };

      await dispatch(
        updateCompanyInfo({ customerNumber: customerId, company })
      );
      await dispatch(
        fetchCompanyInfoFromSales({
          searchByParam: SearchBy.CustomerNumber,
          customerId,
        })
      );
      setIsLoading(false);
    }
  };

  const onClose = (arg: boolean) => {
    if (!arg) {
      setCurrentContact(undefined);
      setOpenModal(arg);
      setOpenDeleteModal(arg);
    } else {
      setOpenModal(arg);
      setOpenDeleteModal(arg);
    }
  };

  return (
    <>
      <div className="header-title px-sm d-flex">
        <h3 className="pl-sm stringLength">{translate("CONTACTS")}</h3>
        <span className="accordion-subtitle align-self-center ml-sm">
          ({contacts.length})
        </span>
      </div>
      {((contactsStatus && contactsStatus === LoadingStatusEnum.PENDING) ||
        isLoading) && <Spinner size={SpinnerSize.Tiny} />}

      {!contacts && (
        <Row>
          <div>{translate("NO_CONTACTS_FOUND")}</div>
        </Row>
      )}
      <Row className="d-flex flex-row">
        <Col md={12}>
          <Row className="p-md">
            {filteredContacts.map(
              (contact, index) =>
                contact.id && (
                  <CustomerPageContactCard
                    key={`contacts-${contact.id}`}
                    contact={contact}
                    editingContact={handleEditContact}
                    deletingContact={handleDeleteContact}
                    setPrimaryContact={handleSetPrimaryContact}
                    isPrimaryContact={
                      companyInfoData?.primary_contact_id === contact.id
                    }
                    md={6}
                    lg={6}
                    xl={3}
                    xxl={3}
                  />
                )
            )}
          </Row>
        </Col>
      </Row>
      {openModal &&
        customer &&
        customerId &&
        (currentContact ? (
          <CustomerPageContactModal
            openModal={openModal}
            setOpenModal={onClose}
            orgNum={customer.company_registration_number}
            customerNumber={customer.customer_number}
            contact={currentContact}
          />
        ) : (
          <CustomerPageContactModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            newContact
            orgNum={customer.company_registration_number}
            customerNumber={customer.customer_number}
          />
        ))}
      {openDeleteModal && currentContact?.email && customerId && (
        <CustomerContactDeleteModal
          customerNumber={customerId}
          contactEmail={currentContact.email}
          setOpenModal={onClose}
        />
      )}
    </>
  );
}
