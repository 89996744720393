import { unwrapResult } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";

import AppConfig from "app-config";
import { normalizeOrgNrAndSSN } from "libs/number-format";
import { Customer } from "models/customer";
import { CreateDeal } from "models/deals/deal";
import { CompanyInformation } from "models/offer/Company";
import { User } from "models/user";
import { CUSTOMER_TYPE_PRIVATE_PERSON } from "constants/dealConstants";
import { SearchBy, fetchCompanyInfoFromSales } from "state/offer/companyThunks";
import { resetCompanyInfo } from "state/offer/offersSlice";
import { resetOffer } from "state/offer/offersThunks";
import {
  fetchCustomerContactsByOrgNr,
  fetchDealsByOrgNumber,
  setIsNewDealCustomer,
} from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import { BusinessOpportunityWizardRouteHelper } from "views/createNew/businessOpportunity/routes/BusinessOpportunityWizardRoutes";

export class DataWithStatus {
  data: string | null;

  status: string;
}

export const fetchDataForNewBusinessOpportunity = async (
  customer: Customer,
  dispatch: AppDispatch,
  navigate: NavigateFunction
): Promise<DataWithStatus> => {
  const normalizedNumber = normalizeOrgNrAndSSN(customer.customer_number);
  if (!normalizedNumber) {
    return { data: "Invalid number", status: "error" };
  }
  dispatch(setIsNewDealCustomer(false));
  dispatch(resetCompanyInfo());
  dispatch(resetOffer());

  try {
    const tempCustomerResult = await dispatch(
      fetchCompanyInfoFromSales({
        customerId: normalizedNumber,
        searchByParam: SearchBy.CustomerNumber,
      })
    );

    if (tempCustomerResult.meta.requestStatus === "rejected") {
      throw tempCustomerResult.payload;
    }

    const tempCustomer = unwrapResult(tempCustomerResult);

    if (tempCustomer.org_number) {
      dispatch(fetchDealsByOrgNumber(tempCustomer.org_number));
      dispatch(fetchCustomerContactsByOrgNr(tempCustomer.org_number));
    }
    navigate(BusinessOpportunityWizardRouteHelper.getCustomerDetailsRoute());
    return { data: null, status: "success" };
  } catch (error) {
    let errorMessage = "";
    if (typeof error === "string") {
      errorMessage = error.toUpperCase();
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: errorMessage, status: "error" };
  }
};

const getCompanyName = (
  deal: CreateDeal,
  companyInfoData: CompanyInformation | undefined,
  isNewCustomer: boolean
) => {
  if (isNewCustomer) {
    if (deal.customer_type !== CUSTOMER_TYPE_PRIVATE_PERSON) {
      return deal.company;
    }
    return "";
  }
  return companyInfoData?.name;
};

export const createNewDealFromContext = (
  deal: CreateDeal,
  currentUser: User | undefined,
  companyInfoData: CompanyInformation | undefined,
  isNewCustomer: boolean,
  isCurrentUserDealAssignee: boolean
): Partial<CreateDeal> => {
  return {
    ...deal,
    phone: deal.phone,
    first_name: deal.first_name,
    last_name: deal.last_name,
    mobile: deal.mobile,
    contact_email: deal.contact_email,

    pipeline: AppConfig.FEATURES.DEALS.DEFAULT_CUSTOMER_PIPELINE,
    deal_source: deal.deal_source,
    organisationsnummer: isNewCustomer
      ? normalizeOrgNrAndSSN(deal.organisationsnummer)
      : normalizeOrgNrAndSSN(companyInfoData?.org_number),
    company: getCompanyName(deal, companyInfoData, isNewCustomer),
    customer_type: deal.customer_type,
    km_deal_created_by_user: currentUser?.email || "",
    assignee_email: isCurrentUserDealAssignee
      ? currentUser?.email
      : deal?.assignee_email,
    assignee_firstname: isCurrentUserDealAssignee
      ? currentUser?.firstName
      : deal?.assignee_firstname,
    assignee_lastname: isCurrentUserDealAssignee
      ? currentUser?.lastName
      : deal?.assignee_lastname,
  };
};
