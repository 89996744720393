import { DropdownOption } from "../components/dropdown";

export enum IdType {
  DrivingLicense = "DrivingLicense",
  Passport = "Passport",
  SISIdCard = "SisIdCard",
  NationalIdCard = "NationalIdCard",
  IDcard = "IdCard",
}

export const IdTypeOptions: DropdownOption<string>[] = [
  { value: IdType.DrivingLicense, label: "Driving License" },
  { value: IdType.Passport, label: "Passport" },
  { value: IdType.NationalIdCard, label: "National ID Card" },
  { value: IdType.SISIdCard, label: "SIS-marked ID card" },
  {
    value: IdType.IDcard,
    label: "Identity card for people registered in Sweden",
  },
];
