import { useTranslation } from "hooks/use-translate";
import { AccountPeriodPickerModel } from "models/offer/AccountPeriodPicker";
import React from "react";
import styles from "./AccountingPeriodPicker.module.scss";

type AccountPeriodPickerProps = {
  value: AccountPeriodPickerModel;
  onChange: (data: AccountPeriodPickerModel) => void;
  years: string[];
};

export default function AccountingPeriodPicker({
  value,
  onChange,
  years,
}: AccountPeriodPickerProps) {
  const { translate } = useTranslation();

  const onChangeStartYear = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLInputElement;
    onChange({ startYear: target.value, startMonth: value.startMonth });
  };

  const onChangeStartMonth = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLInputElement;
    onChange({ startYear: value.startYear, startMonth: target.value });
  };

  return (
    <div className={styles.wrapper}>
      <select
        className={styles.yearSelect}
        onChange={onChangeStartYear}
        value={value.startYear}
      >
        {years.map((year: string) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>

      <select
        className={`${styles.monthSelect} ml-sm`}
        onChange={onChangeStartMonth}
        value={value.startMonth}
      >
        <option value="01">{translate("JANUARY")}</option>
        <option value="02">{translate("FEBRUARY")}</option>
        <option value="03">{translate("MARCH")}</option>
        <option value="04">{translate("APRIL")}</option>
        <option value="05">{translate("MAY")}</option>
        <option value="06">{translate("JUNE")}</option>
        <option value="07">{translate("JULY")}</option>
        <option value="08">{translate("AUGUST")}</option>
        <option value="09">{translate("SEPTEMBER")}</option>
        <option value="10">{translate("OCTOBER")}</option>
        <option value="11">{translate("NOVEMBER")}</option>
        <option value="12">{translate("DECEMBER")}</option>
      </select>
    </div>
  );
}
