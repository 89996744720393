export const FILTER_ACTIVITY_USERS_IDS = "localFilterUserIds";
export const FILTER_NEXT_ACTIVITY_USERS = "localFilterNextActivityUsers";
export const FILTER_ACTIVITY_SERVICE_TYPES = "localFilterServiceTypes";
export const FILTER_ACTIVITY_DEFINITIONS = "localFilterActivityDefinitions";
export const FILTER_ACTIVITY_FREQUENCIES = "localFilterActivityFrequencies";
export const FILTER_ACTIVITY_STATUSES = "localFilterActivityStatuses";
export const FILTER_SELECTED_DATE = "localFilterSelectedDate";
export const FILTER_OVERDUE_ENABLED = "localFilterOverdueEnabled";
export const FILTER_ACTIVITY_DEADLINE = "localFilterActivityDeadline";
export const SORT_HANDED_DOCS = "localSortHandedDocs";
export const SELECTED_TAB_VIEW = "localSelectedTabView";
export const FILTER_INCOMPLETE_ENABLED = "localFilterIncompleteEnabled";
export const ACTIVITIES_CACHE_DISABLED = "localActivitiesCacheDisabled";
export const SEARCH_BY = "localSearchBy";
export const TOGGLE_EXPAND_BUTTON = "localToggleExpandButton";
export const ACTIVITY_STATUS_PASSED_DEADLINE_KEY = "PASSED_DEADLINE";
