import { useState } from "react";
import { Tag } from "@fluentui/react-components";
import {
  Info20Regular,
  People16Regular,
  Search20Regular,
  Warning20Regular,
} from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import Tooltip from "components/tooltip/Tooltip";
import { useTranslation } from "hooks";
import { Recurrence } from "models/activities/activity";
import { Customer } from "models/customer";
import { RootState } from "state";
import {
  reassignPackageAssignedUser,
  updatePackageDefaultAssignedUser,
} from "state/activities/actions";
import { useAppDispatch } from "state/use-app-redux";
import useGetCustomerTeam from "hooks/conveyorBelt/use-get-customer-team";
import AssignmentTeam from "./AssignmentTeam";
import CustomerDetailsMenu from "./CustomerDetailsMenu";
import TeamModal from "../modals/TeamModal";
import { CustomerDetailsPanel } from "./CustomerDetailsPanel";

type Props = {
  customer: Customer;
  isExpanded: boolean;
  isCollapsedView: boolean;
};

export default function CustomerDetails({ customer, isExpanded, isCollapsedView }: Props) {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();

  const customerState = useSelector(
    (state: RootState) => state.customers.data
  ).find((data) => data.customer.customer_number === customer.customer_number);

  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showCustomerDetailsPanel, setShowCustomerDetailsPanel] =
    useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");

  const openCustomerPanel = () => {
    if (showCustomerDetailsPanel) {
      if (customer.customer_number === selectedCustomer) {
        setShowCustomerDetailsPanel(false);
        setSelectedCustomer("");
      } else {
        setSelectedCustomer(customer.customer_number);
      }
    } else {
      setShowCustomerDetailsPanel(true);
      setSelectedCustomer(customer.customer_number);
    }
  };

  const { assigneesCount } = useGetCustomerTeam({ customer });

  const customerIsFromSearch = customerState?.isFromSearch;

  function customerFrequencyRecurrence(customerObj: Customer) {
    if (customerObj.frequency && customerObj.frequency !== Recurrence.Unknown) {
      const freq = customerObj.frequency;
      return translate(`FREQUENCY_PILL.${freq}`);
    }
  }
  const emptyActivitiesReason = [
    {
      visible: !customer.fiscalYearMonthEnd,
      message: translate("CUSTOMER_MISSING_FISCAL_YEARS"),
    },
    {
      visible: !customer.hasAcceptedTerms(),
      message: translate("CUSTOMER_HAS_NOT_ACCEPTED_GENERAL_TERMS"),
    },
  ];

  const hasWarning = emptyActivitiesReason.some((reason) => reason.visible);

  const onPackagePersonPickerChange = async (
    packageName: string,
    userEmail: string,
    isDefaultAssignee: boolean,
    assignedUser?: string
  ) => {
    if (isDefaultAssignee) {
      const response = await dispatch(
        updatePackageDefaultAssignedUser(customer, packageName, userEmail)
      );
      return response;
    }
    if (!isDefaultAssignee && assignedUser) {
      const response = await dispatch(
        reassignPackageAssignedUser(
          customer,
          userEmail,
          assignedUser,
          packageName
        )
      );
      return response;
    }
  };

  return (
    <>
      <div className="w-100">
        <Row>
          <Col xs={8} className="d-flex">
            {customerIsFromSearch && (
              <Tooltip content="CUSTOMER_IS_FROM_SEARCH">
                <span className="pr-xs">
                  <Search20Regular color="red" />
                </span>
              </Tooltip>
            )}
            <Tooltip content={customer.name} notTranslatable>
              <div className="text-max-char pt-xs min-width-170 customer-details-name">
                <span className="align-items-center fpx-14 fw-semibold">
                  {customer.name}
                </span>
              </div>
            </Tooltip>
          </Col>
          <Col xs={4} className="d-flex align-items-center justify-content-end">
            <div className="vertical-divider pr-sm ml-sm" />
            {hasWarning && (
              <div>
                <Tooltip
                  hasJSXContent
                  content={
                    <div className="flex-column-tooltip-content align-items-start">
                      {emptyActivitiesReason.map((reason) => (
                        <span key={reason.message}>
                          {reason.visible && <li> {reason.message} </li>}
                        </span>
                      ))}
                    </div>
                  }
                >
                  <Warning20Regular className="color-red mr-sm" />
                </Tooltip>
              </div>
            )}
            <Tooltip content="VIEW_CLIENT_INFO">
              <Info20Regular
                className="cursor-pointer"
                onClick={openCustomerPanel}
              />
            </Tooltip>
            <Tooltip content="MORE_OPTIONS">
              <CustomerDetailsMenu customer={customer} />
            </Tooltip>
          </Col>
        </Row>
        {!isCollapsedView && (
          <div className="d-flex align-items-center mt-sm">
            <Tag
              className={`customer-details-pill ${
                !customer.fiscalYearMonthEnd ? "bg-redLightest" : ""
              }`}
            >
              {customer.fiscalYearMonthEnd ? (
                <Tooltip content="FISCAL_YEAR">
                  <span className="fw-bold fpx-12">{`${customer.fiscalYearMonthEnd}-${customer.fiscalYearDayEnd}`}</span>
                </Tooltip>
              ) : (
                <Tooltip content="CUSTOMER_DETAILS.NO_FISCAL_YEAR">
                  <span className="fs-italic fpx-12 text-gray">
                    {translate("CUSTOMER_DETAILS.MISSING_FISCAL_YEAR")}
                  </span>
                </Tooltip>
              )}
            </Tag>
            <Tag className="customer-details-pill mx-md">
              {customerFrequencyRecurrence(customer) ? (
                <Tooltip content="FREQUENCY">
                  <span className="fw-bold fpx-12">
                    {customerFrequencyRecurrence(customer)}
                  </span>
                </Tooltip>
              ) : (
                <Tooltip content="CUSTOMER_DETAILS.NO_FREQUENCY">
                  <span className="fs-italic fpx-12 text-gray">
                    {translate("CUSTOMER_DETAILS.MISSING_FREQUENCY")}
                  </span>
                </Tooltip>
              )}
            </Tag>
            {!isExpanded && (
              <Tooltip content="TEAM_MODAL">
                <Tag
                  className="customer-details-pill cursor-pointer"
                  onClick={() => setShowTeamModal(!showTeamModal)}
                >
                  <div className="d-flex align-items-center">
                    <People16Regular className="mr-xs" />
                    <span className="fw-bold fpx-12">
                      {assigneesCount + (customer.salesperson_username ? 1 : 0)}
                    </span>
                  </div>
                </Tag>
              </Tooltip>
            )}
          </div>
        )}
        {isExpanded && !isCollapsedView && (
          <div className="d-flex mt-xs">
            <AssignmentTeam
              customer={customer}
              setShowTeamModal={setShowTeamModal}
            />
          </div>
        )}
        {showTeamModal && (
          <TeamModal
            customer={customer}
            onPackagePersonPickerChange={onPackagePersonPickerChange}
            isOpen
            onDismiss={(open1: boolean) => {
              setShowTeamModal(open1);
            }}
          />
        )}
      </div>
      {!!selectedCustomer && (
        <CustomerDetailsPanel
          selectedCustomer={selectedCustomer}
          showCustomerDetailsPanel={showCustomerDetailsPanel}
          setShowCustomerDetailsPanel={setShowCustomerDetailsPanel}
        />
      )}
    </>
  );
}
