import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  TableColumnDefinition,
  createTableColumn,
} from "@fluentui/react-components";
import { Add20Filled } from "@fluentui/react-icons";

import AppConfig from "app-config";
import { useTranslation } from "hooks/use-translate";
import { RootState } from "state";
import { formatDate } from "libs/date/date-format";
import { numberAsSwedishCurrency } from "libs/number-format";
import Tooltip from "components/tooltip/Tooltip";
import Switch from "components/switch";
import { PotentialDealItem } from "models/deals/deal";
import WizardSectionCustomer from "../components/WizardSectionCustomer";
import { CreateCustomerContext } from "../context/CreateCustomerContext";
import { CustomerWizardRouteHelper } from "../routes/createCustomerWizardRoutes";

export default function CustomerPotentialDealsPage() {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const {
    dealsByOrgNumber: { data: potentialDeals },
    dealOptions: { data: dealOptions },
    pipelines: { data: pipelines },
    products: { data: products },
  } = useSelector((state: RootState) => state.sales);

  const { dealIdToUpdate, toggleDealIdToUpdate } = useContext(
    CreateCustomerContext
  );

  const dealSources = dealOptions.find((d) => d.name === "deal_source");

  const items: PotentialDealItem[] = potentialDeals
    .filter(
      (deal) => deal.dealstage !== AppConfig.FEATURES.DEALS.DEAL_STAGE_LOST
    )
    .map((deal) => {
      return {
        dealId: deal.id,
        // eslint-disable-next-line no-underscore-dangle
        businessArea: deal.affarsomrade_deal_,
        dateCreatedFormatted: formatDate(deal.createdAt),
        dateCreatedAsMilliseconds: new Date(deal.createdAt).getTime(),
        sourceValue: parseInt(deal.deal_source, 10),
        sourceLabel:
          dealSources?.options.find((o) => o.value === deal.deal_source)
            ?.label || "",
        services: products
          .filter((product) => deal.productIds?.includes(product.id))
          .map((product) => product.name),
        businessValueAmount: deal.amount,
        businessValueAmountFormatted:
          deal.amount !== undefined
            ? numberAsSwedishCurrency(deal.amount, true)
            : "",
        contactPersonName: deal.contact
          ? `${deal.contact.firstname} ${deal.contact.lastname}`
          : "",
        stage:
          pipelines
            .find((pipeline) => pipeline.id === deal.pipeline)
            ?.stages.find((stage) => stage.id === deal.dealstage)?.label || "",
        responsiblePersonEmail: deal.deal_assignee_email || "",
      };
    });
  const formatHeaderCell = (text: string) => {
    return <span className="fw-semibold">{text}</span>;
  };

  const columns: TableColumnDefinition<PotentialDealItem>[] = [
    createTableColumn<PotentialDealItem>({
      columnId: "dealSelection",
      renderHeaderCell: () => "",
      renderCell: (item) => {
        return (
          <Switch
            checked={item.dealId === dealIdToUpdate}
            onToggleMethod={(_, { checked }) =>
              toggleDealIdToUpdate(checked ? item.dealId : "")
            }
          />
        );
      },
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "businessArea",
      compare: (a, b) => a.businessArea.localeCompare(b.businessArea),
      renderHeaderCell: () => formatHeaderCell(translate("DEAL_BUSINESS_AREA")),
      renderCell: (item) => item.businessArea,
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "dateCreated",
      compare: (a, b) =>
        a.dateCreatedAsMilliseconds - b.dateCreatedAsMilliseconds,
      renderHeaderCell: () => formatHeaderCell(translate("DATE_CREATED")),
      renderCell: (item) => item.dateCreatedFormatted,
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "sourceUnit",
      compare: (a, b) => a.sourceLabel.localeCompare(b.sourceLabel),
      renderHeaderCell: () => formatHeaderCell(translate("DEAL_SOURCE")),
      renderCell: (item) => item.sourceLabel,
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "services",
      compare: (a, b) => a.services.length - b.services.length,
      renderHeaderCell: () => formatHeaderCell(translate("SERVICES")),
      renderCell: (item) => {
        return (
          <>
            {item.services?.[0]}
            {item.services.length > 1 && (
              <span className="ml-sm">
                <Tooltip
                  hasJSXContent
                  content={
                    <>
                      {item.services.slice(1).map((service, index) => (
                        <div key={`${item.dealId}_${service}`}>
                          <div
                            className={
                              index === item.services.slice(1).length - 1
                                ? ""
                                : "mb-md"
                            }
                          >
                            {service}
                          </div>
                        </div>
                      ))}
                    </>
                  }
                >
                  <span className="cursor-pointer text-color-blue">
                    +{item.services.length - 1}
                  </span>
                </Tooltip>
              </span>
            )}
          </>
        );
      },
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "businessValue",
      compare: (a, b) => {
        const amountA = a.businessValueAmount ?? 0;
        const amountB = b.businessValueAmount ?? 0;
        return amountA - amountB;
      },
      renderHeaderCell: () => formatHeaderCell(translate("BUSINESS_VALUE")),
      renderCell: (item) =>
        item.businessValueAmount === null ? (
          <span className="body-italic-light">{translate("NO_VALUE")}</span>
        ) : (
          item.businessValueAmountFormatted
        ),
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "contactPerson",
      compare: (a, b) => a.contactPersonName.localeCompare(b.contactPersonName),
      renderHeaderCell: () => formatHeaderCell(translate("CONTACT_PERSON")),
      renderCell: (item) => item.contactPersonName,
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "stage",
      compare: (a, b) => a.stage.localeCompare(b.stage),
      renderHeaderCell: () => formatHeaderCell(translate("STAGE")),
      renderCell: (item) => item.stage,
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "responsiblePerson",
      compare: (a, b) =>
        a.responsiblePersonEmail.localeCompare(b.responsiblePersonEmail),
      renderHeaderCell: () => formatHeaderCell(translate("RESPONSIBLE_PERSON")),
      renderCell: (item) => item.responsiblePersonEmail,
    }),
  ];

  return (
    <WizardSectionCustomer
      serviceName={translate("BUSINESS_DETAILS")}
      titleBreadCrumb={translate("POTENTIAL_DEALS_LIST")}
      subtitles={[translate("POTENTIAL_DEALS_LIST_SUBTITLE")]}
      nextLabel="GO_TO_DEAL_INFORMATION"
      backLabel="BACK_TO_CONTACT_DETAILS"
      isNextHidden={!dealIdToUpdate}
      disabledBackMessage={
        <div className="text-center">
          {translate("BUSINESS_DETAILS_DISABLE_BACK_MESSAGE_UPDATE")}
        </div>
      }
    >
      <div className="sticky-button-container">
        <DataGrid
          items={items}
          columns={columns}
          sortable
          className-="sticky-button-list"
        >
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell }) => (
                <DataGridHeaderCell className="dg-th-cell">
                  {renderHeaderCell()}
                </DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody<PotentialDealItem>>
            {({ item, rowId }) => (
              <DataGridRow<PotentialDealItem> key={rowId}>
                {({ renderCell }) => (
                  <DataGridCell className="dg-td-cell">
                    {renderCell(item)}
                  </DataGridCell>
                )}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>

        <div className="sticky-button-wrapper">
          <Button
            className="ludvig-transparent-btn"
            appearance="transparent"
            icon={<Add20Filled />}
            onClick={() => {
              toggleDealIdToUpdate("");

              navigate(
                CustomerWizardRouteHelper.getCustomerDealInformationRoute()
              );
            }}
          >
            {translate("CREATE_BUSINESS_DEAL")}
          </Button>
        </div>
      </div>
    </WizardSectionCustomer>
  );
}
