import { RouteConfig } from "routes";
import CustomerBusinessAreaPicker from "../wizard/CustomerBusinessAreaPicker";
import CustomerDetailsPage from "../wizard/CustomerDetailsPage";
import CustomerContactDetailsPage from "../wizard/CustomerContactDetailsPage";
import CustomerPotentialDealsPage from "../wizard/CustomerPotentialDealsPage";
import CustomerBusinessDetailsPage from "../wizard/CustomerBusinessAreaPage";
import CustomerRegisteredPage from "../wizard/CustomerRegisteredPage";

export const CustomerWizardRouteHelper = {
  getCustomerBusinessAreaRoute: () => "/create-customer",
  getCustomerDetailsPageRoute: () =>
    "/create-customer/customer-information/company-details",
  getCustomerContactDetailsRoute: () =>
    "/create-customer/customer-information/contacts",
  getCustomerPotentialDealsRoute: () =>
    "/create-customer/business-details/potential-deals",
  getCustomerDealInformationRoute: () =>
    "/create-customer/business-details/deal-information",
  getCustomerRegisteredRoute: () => "/create-customer/registration/complete",
};

const createCustomerWizardRoutes: RouteConfig[] = [
  {
    path: CustomerWizardRouteHelper.getCustomerBusinessAreaRoute(),
    element: <CustomerBusinessAreaPicker />,
    subRoutes: [],
  },
  {
    path: CustomerWizardRouteHelper.getCustomerDetailsPageRoute(),
    element: <CustomerDetailsPage />,
    subRoutes: [],
  },
  {
    path: CustomerWizardRouteHelper.getCustomerContactDetailsRoute(),
    element: <CustomerContactDetailsPage />,
    subRoutes: [],
  },
  {
    path: CustomerWizardRouteHelper.getCustomerPotentialDealsRoute(),
    element: <CustomerPotentialDealsPage />,
    subRoutes: [],
  },
  {
    path: CustomerWizardRouteHelper.getCustomerDealInformationRoute(),
    element: <CustomerBusinessDetailsPage />,
    subRoutes: [],
  },
  {
    path: CustomerWizardRouteHelper.getCustomerRegisteredRoute(),
    element: <CustomerRegisteredPage />,
    subRoutes: [],
  },
  {
    path: "*",
    element: <p>No such route!</p>,
    subRoutes: [],
  },
];

export default createCustomerWizardRoutes;
