import { useSelector } from "react-redux";

import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";
import {
  getServiceCategoryFromEconomyServiceArea,
  ServiceCategory,
} from "models/offer/ServiceLine";
import { RootState } from "state";
import { TaxObjectsList } from "views/createNew/offer/wizard/IncomeTax/TaxObjectsList";
import {
  SERVICE_CATEGORY_INCOME_TAX,
  SERVICE_GROUP_INCOME_TAX,
} from "views/createNew/offer/wizard/consts/offer-contst";

export default function CustomerPageTaxObjects() {
  const customerTaxObjects = useSelector(
    (state: RootState) => state.settings.taxObjects.customerTaxObjects
  );
  const taxServiceObjects = useSelector(
    (state: RootState) => state.settings.taxObjects.taxServiceObjects
  );
  const serviceAreas = useSelector(
    (state: RootState) => state.serviceArea.data
  );
  const incomeTaxTemplate =
    getServiceCategoryFromEconomyServiceArea(
      ACCOUNTING_SERVICE_LINE,
      SERVICE_GROUP_INCOME_TAX,
      SERVICE_CATEGORY_INCOME_TAX,
      serviceAreas
    ) ?? ({} as ServiceCategory);

  return customerTaxObjects && taxServiceObjects && serviceAreas ? (
    <div className="p-lg cs-content">
      <TaxObjectsList
        customerTaxObjects={customerTaxObjects}
        taxServiceObjects={taxServiceObjects}
        incomeTaxServices={incomeTaxTemplate.services}
        onDismiss={() => {}}
        actions={false}
      />
    </div>
  ) : null;
}
