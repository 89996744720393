import { SieFilesAPI } from "api/sieFiles";
import { Content, ContentBody } from "components/content/Content";
import { ContentHeader } from "components/content/ContentHeader";
import { isValidOrganizationNumberOrSSN } from "libs/is-valid-organization-number-or-ssn";
import { asPercentage } from "libs/math";
import { AccountPeriodPickerModel } from "models/offer/AccountPeriodPicker";
import {
  CalculatedVolumeAnalysis,
  VolumeAnalysisData,
  VolumeAnalysisDatainner,
} from "models/VolumeAnalysisData";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import AccountingPeriodPicker from "./AccountingPeriodPicker";
import styles from "./VolumeAnalysis.module.scss";
import { calcPeriodDiff, calcVolumeAnalysis } from "./VolumeAnalysisMath";

const intialAccountPeriodData = { startYear: "", startMonth: "01" };

type VolumeAnalysisProps = {
  organizationNumber: string;
};

export function VolumeAnalysis({ organizationNumber }: VolumeAnalysisProps) {
  const isComponentUnmounted = useRef(false); // Used to prevent memory leaks since we have a async operation that could be done after component has unmounted

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [volumeAnalysis, setVolumeAnalysis] = useState<VolumeAnalysisData[]>(
    []
  );
  const [availableYears, setAvailableYears] = useState<string[]>([]);

  const [isVoucherCountExpanded, setIsVoucherCountExpanded] = useState(false);
  const [isTransactionCountExpanded, setIsTransactionCountExpanded] =
    useState(false);

  const [accountPeriod1, setAccountPeriod1] =
    useState<AccountPeriodPickerModel>(intialAccountPeriodData);
  const [accountPeriod2, setAccountPeriod2] =
    useState<AccountPeriodPickerModel>(intialAccountPeriodData);
  const [numberOfMonths, setNumberOfMonths] = useState(12);

  const [aggregatedPeriod1, setAggregatedPeriod1] =
    useState<CalculatedVolumeAnalysis | null>(null);
  const [aggregatedPeriod2, setAggregatedPeriod2] =
    useState<CalculatedVolumeAnalysis | null>(null);
  const [periodsDiff, setPeriodsDiff] =
    useState<VolumeAnalysisDatainner | null>(null);

  const handlePeriod1DateChanged = useCallback(
    (data: AccountPeriodPickerModel): void => {
      if (!data) {
        return;
      }

      setAccountPeriod1(data);
    },
    []
  );

  const handlePeriod2DateChanged = useCallback(
    (data: AccountPeriodPickerModel): void => {
      if (!data) {
        return;
      }

      setAccountPeriod2(data);
    },
    []
  );

  const handleChangeMonthCount = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setNumberOfMonths(parseInt(target.value, 10));

    if (aggregatedPeriod1 && aggregatedPeriod2) {
      setAccountPeriod1({
        startYear: aggregatedPeriod1.periodStart.slice(0, 4),
        startMonth: aggregatedPeriod1.periodStart.slice(4, 6),
      });
      setAccountPeriod2({
        startYear: aggregatedPeriod2.periodStart.slice(0, 4),
        startMonth: aggregatedPeriod2.periodStart.slice(4, 6),
      });
    }
  };

  useEffect(() => {
    return () => {
      isComponentUnmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (!isValidOrganizationNumberOrSSN(organizationNumber)) {
      return;
    }

    const onVolumeAnalysisLoaded = (
      volumeAnalysisData: VolumeAnalysisData[]
    ) => {
      if (isComponentUnmounted.current) {
        return;
      }
      const minVersion = "0.8";

      const compatibleVolumeAnalyses = volumeAnalysisData.filter(
        (va) => va.algorithm_version >= minVersion
      );

      setVolumeAnalysis(compatibleVolumeAnalyses);
      setIsLoadingData(false);
    };

    SieFilesAPI.getVolumeAnalysis(organizationNumber, onVolumeAnalysisLoaded);
  }, [organizationNumber]);

  useEffect(() => {
    const tempAvailableYears = Array.from(
      new Set(
        volumeAnalysis.flatMap((v) => [
          v.rar_to.slice(0, 4),
          v.rar_from.slice(0, 4),
        ])
      )
    )
      .sort()
      .reverse();

    setAvailableYears(tempAvailableYears);

    const initialData = {
      startYear: tempAvailableYears.length > 0 ? tempAvailableYears[0] : "",
      startMonth: "01",
    };
    handlePeriod1DateChanged(initialData);
    handlePeriod2DateChanged(initialData);
  }, [volumeAnalysis, handlePeriod1DateChanged, handlePeriod2DateChanged]);

  useEffect(() => {
    const period1Analysis = calcVolumeAnalysis(
      volumeAnalysis,
      accountPeriod1,
      numberOfMonths
    );
    setAggregatedPeriod1(period1Analysis);

    const period2Analysis = calcVolumeAnalysis(
      volumeAnalysis,
      accountPeriod2,
      numberOfMonths
    );
    setAggregatedPeriod2(period2Analysis);

    const periodDiff = calcPeriodDiff(period1Analysis, period2Analysis);
    setPeriodsDiff(periodDiff);
  }, [volumeAnalysis, numberOfMonths, accountPeriod1, accountPeriod2]);

  const doesPeriodsOverlap = (
    p1: CalculatedVolumeAnalysis | null,
    p2: CalculatedVolumeAnalysis | null
  ) => {
    if (p1 !== null && p2 !== null) {
      return p1.periodStart <= p2.periodEnd;
    }

    return false;
  };

  const uniformDict = (
    dict1In: { [series: string]: number },
    dict2In: { [series: string]: number }
  ) => {
    const dict1 = dict1In;
    const dict2 = dict2In;

    Object.keys(dict1).forEach((key) => {
      if (!(key in dict2)) {
        dict2[key] = 0;
      }
    });

    Object.keys(dict2).forEach((key) => {
      if (!(key in dict1)) {
        dict1[key] = 0;
      }
    });
  };

  const renderSeries = (
    dict1: { [series: string]: number },
    dict2: { [series: string]: number },
    flipp: boolean
  ) => {
    uniformDict(dict1, dict2);

    const result = Object.keys(dict1).map((key, value) => (
      <div key={key} className={styles.lineContainer}>
        <div className={styles.expandedLine}>{key}</div>
        <div className={styles.expandedLine}>{dict1[key]}</div>
        <div className={styles.expandedLine}>{dict2[key]}</div>
        <div className={styles.expandedLine}>
          {flipp
            ? asPercentage(dict2[key], dict1[key])
            : asPercentage(dict1[key], dict2[key])}
        </div>
      </div>
    ));

    return <div>{result}</div>;
  };

  const renderTransactionCountExpandButton = (
    <Button
      color="tertiary"
      size="sm"
      className={styles.button}
      onClick={() => {
        setIsTransactionCountExpanded(!isTransactionCountExpanded);
      }}
    >
      {(isTransactionCountExpanded && "-") || "+"}
    </Button>
  );

  const renderVoucherCountExpandButton = (
    <Button
      color="tertiary"
      size="sm"
      onClick={() => {
        setIsVoucherCountExpanded(!isVoucherCountExpanded);
      }}
    >
      {(isVoucherCountExpanded && "-") || "+"}
    </Button>
  );

  const renderLine = (content?: any) => {
    let tempContent = content;
    if (typeof content === "number") {
      tempContent = content.toFixed(1);
    }

    return <div className={styles.line}>{tempContent}</div>;
  };

  const renderContent = () => {
    return (
      <>
        <div className={styles.warningsWrapper}>
          {
            doesPeriodsOverlap(aggregatedPeriod1, aggregatedPeriod2) &&
              "De valda perioderna överlappar" // TODO: Wrap in a nice border or something
          }
        </div>

        <div className={styles.lineContainer}>
          <div className={styles.lineContainerHeader}>
            <div>
              &nbsp;Periodlängd:&nbsp;
              <input
                className="ml-sm"
                size={3}
                type="number"
                onChange={(value) => handleChangeMonthCount(value)}
                value={numberOfMonths}
              />
              &nbsp;månader
            </div>
          </div>

          <div className={styles.lineContainerHeader}>
            <div>
              <AccountingPeriodPicker
                value={accountPeriod1}
                years={availableYears}
                onChange={handlePeriod1DateChanged}
              />
              <span className={styles.periodSpan} title="Perioden som avses">
                {aggregatedPeriod1?.periodStart} -{" "}
                {aggregatedPeriod1?.periodEnd}
              </span>
            </div>
          </div>

          <div className={styles.lineContainerHeader}>
            <div>
              <AccountingPeriodPicker
                value={accountPeriod2}
                years={availableYears}
                onChange={handlePeriod2DateChanged}
              />

              <span className={styles.periodSpan} title="Perioden som avses">
                {aggregatedPeriod2?.periodStart} -{" "}
                {aggregatedPeriod2?.periodEnd}
              </span>
            </div>
          </div>

          <div className={styles.lineContainerHeader}>
            <div>Förändring (%)</div>
          </div>
        </div>

        {aggregatedPeriod1 && aggregatedPeriod2 && periodsDiff ? (
          <>
            <div>
              <div className={styles.lineContainer}>
                <div className={styles.lineWB}>
                  Antal verifikat {renderVoucherCountExpandButton}
                </div>
                {renderLine(aggregatedPeriod1.voucher_count_total)}
                {renderLine(aggregatedPeriod2.voucher_count_total)}
                {renderLine(
                  Number.isNaN(periodsDiff.voucher_count_total)
                    ? "N/A"
                    : periodsDiff.voucher_count_total
                )}
              </div>

              {isVoucherCountExpanded
                ? renderSeries(
                    aggregatedPeriod1.voucher_count_per_series,
                    aggregatedPeriod2.voucher_count_per_series,
                    aggregatedPeriod1.periodStart <
                      aggregatedPeriod2.periodStart
                  )
                : null}
            </div>

            <div>
              <div className={styles.lineContainer}>
                <div className={styles.lineWB}>
                  Antal transaktionsrader {renderTransactionCountExpandButton}
                </div>
                {renderLine(aggregatedPeriod1.transaction_count_total)}
                {renderLine(aggregatedPeriod2.transaction_count_total)}
                {renderLine(
                  Number.isNaN(periodsDiff.transaction_count_total)
                    ? "N/A"
                    : periodsDiff.transaction_count_total
                )}
              </div>

              {isTransactionCountExpanded
                ? renderSeries(
                    aggregatedPeriod1.transaction_count_per_series,
                    aggregatedPeriod2.transaction_count_per_series,
                    aggregatedPeriod1.periodStart <
                      aggregatedPeriod2.periodStart
                  )
                : null}
            </div>

            <div className={styles.lineContainer}>
              <div className={styles.lineText}>Omsättning</div>
              {renderLine(aggregatedPeriod1.revenue)}
              {renderLine(aggregatedPeriod2.revenue)}
              {renderLine(
                Number.isNaN(periodsDiff.revenue) ? "N/A" : periodsDiff.revenue
              )}
            </div>

            <div className={styles.lineContainer}>
              <div className={styles.lineText}>Bruttovinst</div>
              {renderLine(aggregatedPeriod1.gross_margin)}
              {renderLine(aggregatedPeriod2.gross_margin)}
              {renderLine(
                Number.isNaN(periodsDiff.gross_margin)
                  ? "N/A"
                  : periodsDiff.gross_margin
              )}
            </div>

            <div className={styles.lineContainer}>
              <div className={styles.lineText}>EBIT-vinst</div>
              {renderLine(aggregatedPeriod1.ebit_revenue)}
              {renderLine(aggregatedPeriod2.ebit_revenue)}
              {renderLine(
                Number.isNaN(periodsDiff.ebit_revenue)
                  ? "N/A"
                  : periodsDiff.ebit_revenue
              )}
            </div>

            <div className={styles.lineContainer}>
              <div className={styles.lineText}>Balansomslutning</div>
              {renderLine(Math.abs(aggregatedPeriod1.balance_sheet_total))}
              {renderLine(Math.abs(aggregatedPeriod2.balance_sheet_total))}
              {renderLine(
                Number.isNaN(periodsDiff.balance_sheet_total)
                  ? "N/A"
                  : periodsDiff.balance_sheet_total
              )}
            </div>

            <div className={styles.lineContainer}>
              <div className={styles.lineText}>Antal nollrader</div>
              {renderLine(aggregatedPeriod1.zero_account_transaction_count)}
              {renderLine(aggregatedPeriod2.zero_account_transaction_count)}
              {renderLine(
                Number.isNaN(periodsDiff.zero_account_transaction_count)
                  ? "N/A"
                  : periodsDiff.zero_account_transaction_count
              )}
            </div>

            <div className={styles.lineContainer}>
              <div className={styles.lineText}>Program</div>
              {renderLine(volumeAnalysis[0].program)}
              {renderLine(volumeAnalysis[0].program)}
              {renderLine(null)}
            </div>
          </>
        ) : null}
      </>
    );
  };

  const renderNoContent = () => {
    if (!isLoadingData) {
      return <div>Det finns ingen volymanalys att visa</div>;
    }

    return null;
  };

  return (
    <Content>
      <ContentHeader
        title="Volymanalys"
        loadingStatus={isLoadingData ? "pending" : "succeeded"}
      />

      <ContentBody>
        {volumeAnalysis && volumeAnalysis.length > 0
          ? renderContent()
          : renderNoContent()}
      </ContentBody>
    </Content>
  );
}
