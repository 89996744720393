import { ReactNode, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  Add20Regular,
  ArrowLeft20Regular,
  Edit20Regular,
} from "@fluentui/react-icons";

import { Service } from "models/offer/ServiceLine";
import { TranslationKey } from "i18n";
import { Button } from "components/button";
import { TaxObjectDetails } from "models/offer/TaxObject";
import { TaxObjectService } from "models/offer/TaxObjectService";
import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { defaultPaginatorOptions } from "components/Pagination/consts";
import { useTranslation } from "hooks/use-translate";
import Paginator from "components/Pagination";
import { RootState } from "state";
import { isEqualSSN } from "libs/is-equal-ssn";
import { CompanyTypes } from "models/offer/Company";
import { TaxObjectForm } from "views/createNew/offer/wizard/IncomeTax/TaxObjectForm";

interface TaxObjectsProps {
  customerTaxObjects: TaxObjectDetails[];
  taxServiceObjects: TaxObjectService[];
  incomeTaxServices: Service[];
  onDismiss: () => void;
  onSave?: (
    taxObjectDetails: TaxObjectDetails,
    activeServices: TaxObjectService[]
  ) => void;
  actions?: boolean;
  label?: TranslationKey;
}

export function TaxObjectsList({
  customerTaxObjects,
  taxServiceObjects,
  incomeTaxServices,
  onSave,
  onDismiss,
  actions = true,
  label = "GO_BACK_TO_INCOME_TAX",
}: TaxObjectsProps) {
  const { translate, ts } = useTranslation();
  const [activePage, setActivePage] = useState<number>(1);
  const [showModal, setShowModal] = useState<{
    header: ReactNode;
    content: ReactNode;
    footer: ReactNode;
  }>({ header: "", content: "", footer: "" });
  const [itemsShownPerPage, setItemsShownPerPage] = useState<number>(15);

  const customer = useSelector(
    (state: RootState) => state.offers.currentOffer.data?.customer
  );

  type TaxObjectListItem = {
    taxObject: TaxObjectDetails;
    services: Service[];
  };

  const taxObjectList: TaxObjectListItem[] = customerTaxObjects.map((ctObj) => {
    const ctObjServiceIds = taxServiceObjects
      .filter((to) =>
        isEqualSSN(
          to.customer_tax_object.social_security_number,
          ctObj.social_security_number
        )
      )
      .map((s) => s.service_matrix_id);
    const services = incomeTaxServices.filter((s) =>
      ctObjServiceIds.includes(s.service_matrix_id)
    );

    return { taxObject: ctObj, services };
  });

  function isTaxObjectForCompany(
    taxObjectDetails: TaxObjectDetails
  ): boolean | undefined {
    return isEqualSSN(
      taxObjectDetails.social_security_number,
      customer?.org_number
    );
  }

  const activePageObjects = taxObjectList.slice(
    (activePage - 1) * itemsShownPerPage,
    activePage * itemsShownPerPage
  );

  const setModalFooter = (footer: ReactNode) => {
    setShowModal((prev) => ({ ...prev, footer }));
  };

  function openEditModal(taxObjectDetails?: TaxObjectDetails) {
    const availableDealTaxObject =
      !taxObjectDetails ||
      (taxObjectDetails.type === CompanyTypes.SE_PrivatePerson &&
        !isTaxObjectForCompany(taxObjectDetails))
        ? Service.getIncomeTaxServicesForIndividual(incomeTaxServices)
        : Service.getIncomeTaxServicesForCompany(incomeTaxServices);
    setShowModal((prev) => ({
      ...prev,
      header: (
        <ModalHeader
          headerTitleText={
            taxObjectDetails
              ? `${translate("EDIT_TAX_OBJECT_FOR")} ${
                  taxObjectDetails.social_security_number
                }`
              : translate("NEW_TAX_OBJECT")
          }
        />
      ),
      content: (
        <TaxObjectForm
          onSave={(taxObject, taxObjectServices) => {
            onSave?.(taxObject, taxObjectServices);
            setShowModal({ header: "", content: "", footer: "" });
          }}
          isForCompany={
            taxObjectDetails && isTaxObjectForCompany(taxObjectDetails)
          }
          dealTaxServices={taxServiceObjects}
          customerTaxObjects={customerTaxObjects}
          taxObject={taxObjectDetails}
          showInModal
          setModalFooter={setModalFooter}
          incomeTaxServicesTemplate={availableDealTaxObject}
        />
      ),
    }));
  }

  return (
    <div className="h-100">
      <Modal
        header={showModal.header}
        footer={showModal.footer}
        size="fit-content-min"
        isOpen={showModal.content !== ""}
        onDismiss={() => setShowModal({ header: "", content: "", footer: "" })}
      >
        {showModal.content}
      </Modal>

      <div className="h-100">
        <Paginator
          itemsLength={taxObjectList.length}
          defaultItemsShownPerPage={itemsShownPerPage}
          onChangeShownItems={(v) => setItemsShownPerPage(v)}
          label={translate("TAX_OBJECT_LABEL")}
          onSelectPage={setActivePage}
          options={defaultPaginatorOptions}
        />

        <div className="pt-sm px-sm min-width-900">
          <Row className="bottom-border">
            <Col md={2} className="fw-bold pt-sm pb-xs">
              {translate("SSN")}
            </Col>

            <Col md={2} className="fw-bold pt-sm pb-xs">
              {translate("NAME")}
            </Col>

            <Col md={6} className="fw-bold pt-sm pb-xs">
              {translate("OFFER_SERVICES")}
            </Col>

            <Col md={1} className="fw-bold pt-sm pb-xs">
              {translate("STATUS")}
            </Col>

            {actions && (
              <Col md={1} className="fw-bold pt-sm pb-xs">
                {translate("ACTION")}
              </Col>
            )}
          </Row>

          {!taxObjectList.length ? (
            <>{translate("NO_TAX_OBJECTS_FOUND")}</>
          ) : (
            activePageObjects.map((to) => (
              <Row
                className={`bottom-border text-wrap ${
                  isTaxObjectForCompany(to.taxObject) && "bgBlueLightest"
                }`}
                key={to.taxObject.social_security_number}
              >
                <Col md={2} className="pb-xs pt-sm">
                  {to.taxObject.social_security_number}
                </Col>

                <Col md={2} className="pb-xs pt-sm">
                  {`${to.taxObject.first_name} ${to.taxObject.last_name}`}
                </Col>

                <Col md={6} className="pb-xs pt-sm">
                  <div className="d-flex">
                    {to.services.map((s) => (
                      <div
                        className="d-flex"
                        key={`${s.name}-${to.taxObject.social_security_number}`}
                      >
                        <span className="mx-1">{`${s.name}`}</span>
                        <span className="inline-vertical-divider" />
                      </div>
                    ))}
                  </div>
                </Col>

                <Col md={1} className="pb-xs pt-sm">
                  {ts(to.taxObject.state.toUpperCase())}
                </Col>

                {actions && (
                  <Col md={1} className="pb-1 pt-2">
                    <Edit20Regular
                      className="pog-link cursor-pointer"
                      onClick={() => openEditModal(to.taxObject)}
                    />
                  </Col>
                )}
              </Row>
            ))
          )}
        </div>

        <div className="d-flex py-lg">
          <div
            className="d-flex pog-link cursor-pointer"
            onClick={() => openEditModal()}
          >
            <Add20Regular />
            <div className="px-sm">{translate("ADD_NEW_TAX_OBJECT")}</div>
          </div>
        </div>
        {actions && (
          <div className="pt-sm">
            <Button variant="outline-primary" onClick={() => onDismiss()}>
              <div className="d-flex">
                <span className="px-sm">
                  <ArrowLeft20Regular />
                </span>
                <span className="px-sm">{translate(label)}</span>
              </div>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
