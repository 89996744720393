import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@fluentui/react-components";
import Switch from "components/switch";

import { useTranslation } from "hooks/use-translate";
import DealContactDetails from "models/offer/DealContactDetails";
import { useState } from "react";

type NewKYCTableProps = {
  contacts: DealContactDetails[];
  onToggle: (contactId: string, isChecked: boolean | undefined) => void;
};

export function NewKYCTable({ contacts, onToggle }: NewKYCTableProps) {
  const { translate } = useTranslation();

  const [checkedContacts, setCheckedContacts] = useState<string[]>([]);

  const tableHeaderItems = [
    {
      key: "name",
      label: translate("NEW_KYC_FORM.NAME"),
    },
    {
      key: "email",
      label: translate("NEW_KYC_FORM.EMAIL"),
    },
    {
      key: "ssn",
      label: translate("NEW_KYC_FORM.SSN"),
    },
    {
      key: "mobile",
      label: translate("NEW_KYC_FORM.PHONE_NUMBER"),
    },
    {
      key: "kyc",
      label: translate("NEW_KYC_FORM.KYC_CONTACT"),
    },
  ];

  const handleToggle = (cntactId: string, checked: boolean) => {
    onToggle(cntactId, checked);
    if (checked) {
      setCheckedContacts([...checkedContacts, cntactId]);
    } else {
      const selectedIds = checkedContacts.filter((id) => id !== cntactId);
      setCheckedContacts(selectedIds);
    }
  };

  return (
    <Table className="table-fs-14">
      <TableHeader className="fw-semibold">
        <TableRow>
          {tableHeaderItems.map((item) => (
            <TableHeaderCell key={item.key}>{item.label}</TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {contacts.map((contact) => (
          <TableRow key={contact.id}>
            <TableCell className="text-max-char">
              {`${contact.first_name} ${contact.last_name}`}
            </TableCell>
            <TableCell className="text-max-char">{contact.email}</TableCell>
            <TableCell>{contact.social_security_number}</TableCell>
            <TableCell>{contact.phone_number}</TableCell>
            <TableCell>
              <Switch
                className="no-margin"
                onToggleMethod={(e, { checked }) => {
                  handleToggle(contact.id, checked);
                }}
                checked={checkedContacts.includes(contact.id)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
