import { useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { RootState } from "state";
import { LayoutWrapper } from "components/layout/Layout";
import { UserSnapshot } from "models/user";
import { useCustomersContext } from "views/conveyorBelt/context/CustomersContext";
import ServicesTable from "./ServicesTable";

type Props = {
  selectedActivityFilterUsers: UserSnapshot[];
  isCollapsedView: boolean;
  incompleteFilterEnabled: boolean;
};

export default function AssignmentManagementBody({
  selectedActivityFilterUsers,
  isCollapsedView,
  incompleteFilterEnabled,
}: Props) {
  const { allCustomers } = useCustomersContext();

  const { isLoading } = useSelector((state: RootState) => state.customers);

  const { translate } = useTranslation();

  return (
    <LayoutWrapper>
      {!isLoading && allCustomers.length === 0 ? (
        <p>{translate("NO_SEARCH_CUSTOMER_RESULTS")}</p>
      ) : (
        <ServicesTable
          selectedActivityFilterUsers={selectedActivityFilterUsers}
          isFullLoading={isLoading}
          isCollapsedView={isCollapsedView}
          incompleteFilterEnabled={incompleteFilterEnabled}
        />
      )}
    </LayoutWrapper>
  );
}