import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "state";
import { Customer } from "models/customer";
import { uuidv4 } from "libs/uuid";
import SkeletonServiceRow from "./SkeletonServiceRow";
import ServiceTableCustomerRow from "./ServiceTableCustomerRow";

type Props = {
  filteredMonths: Date[];
  customers: Customer[];
  selectedCustomerNumbers: string[];
  setSelectedCustomerNumbers: React.Dispatch<React.SetStateAction<string[]>>;
  isFullLoading: boolean;
  isCollapsedView: boolean;
  isExpanded: boolean;
  showCompleted?: boolean;
  showCheckbox?: boolean;
};

export default function ServiceTableContent({
  filteredMonths,
  customers,
  selectedCustomerNumbers,
  setSelectedCustomerNumbers,
  isFullLoading,
  isCollapsedView = false,
  isExpanded,
  showCompleted = false,
  showCheckbox = false,
}: Props) {
  const { isLoading: isActivitiesLoading, timelineWindow } = useSelector(
    (state: RootState) => state.activities
  );
  const [timelineLoadingMonthIndexes, setTimelineLoadingMonthIndexes] =
    useState<number[]>([]);

  const isFilteredByMonth = filteredMonths.length === 1;

  const handleCheckbox = useCallback((customerNumber: string) => {
    setSelectedCustomerNumbers((prev) => {
      if (prev.includes(customerNumber)) {
        return prev.filter((cn) => cn !== customerNumber);
      }
      return [...prev, customerNumber];
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isActivitiesLoading) {
      const oldTimeline = localStorage.getItem("oldTimeline") ?? "";
      const newTimeline = timelineWindow.toString();

      const oldDates = oldTimeline.split(",").map((dateStr) => dateStr.trim());
      const newDates = newTimeline.split(",").map((dateStr) => dateStr.trim());

      // Identify indexes of months that are in the new timeline but not in the old timeline
      const newMonths = newDates.reduce((result, date, index) => {
        if (!oldDates.includes(date)) {
          result.push(index);
        }
        return result;
      }, [] as number[]);

      setTimelineLoadingMonthIndexes(newMonths);
    } else {
      setTimelineLoadingMonthIndexes([]);
    }
  }, [isActivitiesLoading, timelineWindow]);

  return (
    <div className="pb-md px-md">
      {isFullLoading &&
        [...Array(10)].map(() => (
          <SkeletonServiceRow
            key={uuidv4()}
            isFull={isFullLoading}
            isExpanded={isExpanded}
            isCollapsedView={isCollapsedView}
            isFilteredByMonth={isFilteredByMonth}
          />
        ))}
      {!isFullLoading &&
        customers.map((customer, index) => (
          <ServiceTableCustomerRow
            key={customer.customer_number}
            customer={customer}
            isCollapsedView={isCollapsedView}
            isExpanded={isExpanded}
            filteredMonths={filteredMonths}
            showCompleted={showCompleted}
            checkbox={
              showCheckbox
                ? selectedCustomerNumbers.includes(customer.customer_number)
                : undefined
            }
            handleCheckbox={handleCheckbox}
            showDivider={index !== customers.length - 1}
            timelineLoadingMonthIndexes={timelineLoadingMonthIndexes}
          />
        ))}
    </div>
  );
}
