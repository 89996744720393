import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { plainToClass } from "class-transformer";
import { Billing } from "models/offer/Billing";
import { CompanyInformation } from "models/offer/Company";
import { DealCustomer } from "models/offer/Customer";
import { CustomerDeal } from "models/offer/CustomerDeal";
import { DealContact } from "models/offer/DealContact";
import DealContactDetails from "models/offer/DealContactDetails";
import { DealEmployee } from "models/offer/Employee";
import { SelectedPickerOption } from "models/offer/Picker";
import { Offer } from "models/offer/SavedOffers";
import {
  ServiceArea,
  ServiceGroup,
  ServiceLine,
} from "models/offer/ServiceLine";
import { LoadingStatus } from "state";
import { deleteCustomerContactCaller } from "state/customerSettings/customerSettingsThunk";
import {
  createOrUpdateCompanyContact,
  createOrUpdateCustomer,
  fetchCompanyInfoFromSales,
  fetchCustomerBilling,
  fetchCustomerContactsByOrgNr,
} from "./companyThunks";
import {
  copyDeal,
  deleteDeal,
  fetchDeal,
  fetchDeals,
  fetchOffers,
  fetchOfferTemplate,
  patchOffer,
  sendCreateDealFromOffer,
  sendOfferForCalculation,
  sendOfferForSigning,
} from "./offersThunks";

export enum CompletionStatus {
  INITIAL,
  FILE_DOWNLOADED,
  FILE_UPLOADING,
  FAIL,
  SUCCESS,
}

export interface OfferState {
  status: LoadingStatus;
  data: Offer[];
  createdDeals: {
    status: LoadingStatus;
    data: CustomerDeal[];
  };
  currentOffer: {
    status: LoadingStatus;
    data?: CustomerDeal;
  };
  customerBillings: {
    status: LoadingStatus;
    data?: Billing;
  };
  error: string[];
  offerTemplate: {
    status: LoadingStatus;
    data?: CustomerDeal;
  };
  availableCompanyContacts: {
    status: LoadingStatus;
    data: DealContactDetails[];
  };
  selectedServiceType: string;
  selectedServiceOptions: SelectedPickerOption[];
  completionStatus?: CompletionStatus;
  companyInfo: {
    data?: CompanyInformation;
    status: LoadingStatus;
  };
}

const initialState: OfferState = {
  status: "idle",
  data: [],
  createdDeals: { status: "idle", data: [] },
  currentOffer: { status: "idle", data: undefined },
  customerBillings: { status: "idle", data: undefined },
  error: [] as string[],
  offerTemplate: {
    data: undefined,
    status: "idle",
  },
  availableCompanyContacts: {
    status: "idle",
    data: [],
  },
  selectedServiceType: "",
  selectedServiceOptions: [],
  companyInfo: {
    data: undefined,
    status: "idle",
  },
};

export const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    addOffers: (state, action: PayloadAction<Offer[]>) => {
      state.data = action.payload;
    },
    resetCompanyInfo: (state) => {
      state.companyInfo = {
        data: undefined,
        status: "idle",
      };
    },
    resetOffers: (state) => {
      state.data = [];
      state.status = "idle";
      state.createdDeals.data = [];
      state.createdDeals.status = "idle";

      state.currentOffer = { status: "idle", data: undefined };
      state.completionStatus = undefined;
      state.companyInfo = { status: "idle", data: undefined };
    },
    updateOffers: (
      state,
      action: PayloadAction<{ id: string; data: Partial<CustomerDeal> }>
    ) => {
      const createdDeals = [...state.createdDeals.data];
      const updatedDeal = createdDeals.find(
        (deal) => deal.id === action.payload.id
      );
      if (updatedDeal) {
        const filteredDeals = createdDeals.filter(
          (deal) => deal.id !== updatedDeal.id
        );

        state.createdDeals.data = [
          ...filteredDeals,
          { ...updatedDeal, ...action.payload.data },
        ];
      }
    },
    resetContacts: (state) => {
      state.availableCompanyContacts.status = "idle";
      state.availableCompanyContacts.data = [];
    },
    setCurrentOffer: (state, action: PayloadAction<CustomerDeal>) => {
      state.currentOffer.data = {
        ...(state.currentOffer?.data as CustomerDeal),
        ...action.payload,
      };
      state.currentOffer.status = "idle";
    },
    overwriteCurrentOffer: (state, action: PayloadAction<CustomerDeal>) => {
      state.currentOffer.data = {
        ...action.payload,
      };
      state.currentOffer.status = "idle";
    },
    updateCurrentOffer: (
      state,
      action: PayloadAction<Partial<CustomerDeal>>
    ) => {
      state.currentOffer.data = {
        ...(state.currentOffer?.data as CustomerDeal),
        ...action.payload,
      };
    },
    /**
     * Updates the contact in the offer contact list or adds a new contact if none exist yet
     */
    updateOfferContact: (state, action: PayloadAction<DealContact>) => {
      if (!state.currentOffer.data) {
        throw new Error("No active offer");
      }

      state.currentOffer.data.contacts = [action.payload];
    },
    updateOfferCustomer: (
      state,
      action: PayloadAction<Partial<DealCustomer>>
    ) => {
      if (!state.currentOffer.data) {
        throw new Error("No active offer");
      }

      state.currentOffer.data.customer = plainToClass(DealCustomer, {
        ...state.currentOffer.data.customer,
        ...action.payload,
      });
    },
    setOfferSelectedServiceAreas: (
      state,
      action: PayloadAction<ServiceArea[]>
    ) => {
      if (!state.currentOffer.data) {
        throw new Error("No active offer");
      }

      state.currentOffer.data.service_areas = action.payload;
    },
    addOfferSelectedServiceLine: (
      state,
      action: PayloadAction<ServiceLine>
    ) => {
      if (!state.currentOffer.data) {
        throw new Error("No active offer");
      }

      if (!state.offerTemplate.data) {
        throw new Error("No template data");
      }
      // Check for the service template in availableServices
      const templateServiceArea = state.offerTemplate.data.service_areas.find(
        (s) => s.service_lines.some((sl) => sl.name === action.payload.name)
      );

      if (!templateServiceArea) {
        throw new Error(
          "Could not map selected service name to template service"
        );
      }

      const selectedServiceArea = state.currentOffer.data.service_areas.find(
        (sa) => sa.name === templateServiceArea.name
      );

      if (!selectedServiceArea) {
        state.currentOffer.data.service_areas = [
          {
            name: templateServiceArea.name,
            service_lines: [
              {
                name: action.payload.name,
                service_groups: [],
                sorting_number: action.payload.sorting_number,
                authorized_by: "",
              },
            ],
          },
        ];
      } else {
        selectedServiceArea.service_lines.push({
          name: action.payload.name,
          service_groups: [],
          sorting_number: action.payload.sorting_number,
          authorized_by: action.payload.authorized_by,
        });
      }
    },
    removeOfferSelectedServiceLine: (
      state,
      action: PayloadAction<ServiceLine>
    ) => {
      if (!state.currentOffer.data) {
        throw new Error("No active offer");
      }

      if (!state.offerTemplate.data) {
        throw new Error("No template data");
      }

      // Check for the service area in template
      const templateServiceArea = state.offerTemplate.data.service_areas.find(
        (s) => s.service_lines.some((sl) => sl.name === action.payload.name)
      );

      if (!templateServiceArea) {
        throw new Error(
          "Could not map selected service name to template service"
        );
      }

      const selectedServiceArea = state.currentOffer.data.service_areas.find(
        (sa) => sa.service_lines.some((sl) => sl.name === action.payload.name)
      );

      if (!selectedServiceArea) {
        throw new Error("Could not remove from non-existing service area");
      } else {
        selectedServiceArea.service_lines =
          selectedServiceArea.service_lines.filter(
            (sa) => sa.name !== action.payload.name
          );
      }
    },
    setOfferEmployee: (state, action: PayloadAction<DealEmployee>) => {
      if (!state.currentOffer.data) {
        throw new Error("No active offer");
      }

      state.currentOffer.data.changed_by_username = action.payload.email;
    },
    addServiceLineGroup: (state, action: PayloadAction<ServiceGroup>) => {
      if (!state.currentOffer.data) {
        throw new Error("No active offer");
      }

      if (!state.offerTemplate.data) {
        throw new Error("No template data");
      }

      // Find all service lines in template
      const templateServiceLines =
        state.offerTemplate.data.service_areas.flatMap(
          (sa) => sa.service_lines
        );

      // Check for the specific service line in template
      const selectedTemplateServiceLine = templateServiceLines.find((sl) =>
        sl.service_groups.some((sg) => sg.name === action.payload.name)
      );

      if (!selectedTemplateServiceLine) {
        throw new Error(
          "Could not map selected service group name to template service"
        );
      }

      // Find the selected service line in the current offer
      const selectedServiceLine = state.currentOffer.data.service_areas
        .flatMap((sa) => sa.service_lines)
        .find((sl) => sl.name === selectedTemplateServiceLine.name);

      if (!selectedServiceLine) {
        throw new Error("Could not find selected service line in offer");
      }

      selectedServiceLine.service_groups.push({
        name: action.payload.name,
        service_categories: action.payload.service_categories,
        sorting_number: action.payload.sorting_number,
        skip_price_calculation: action.payload.skip_price_calculation,
      });
    },
    updateServiceLineGroup: (state, action: PayloadAction<ServiceGroup>) => {
      // TODO: Implement this
      throw new Error("Not implemented yet");
    },
    removeServiceLineGroup: (state, action: PayloadAction<ServiceGroup>) => {
      if (!state.currentOffer.data) {
        throw new Error("No active offer");
      }

      if (!state.offerTemplate.data) {
        throw new Error("No template data");
      }

      // Find all service lines in template
      const templateServiceLines =
        state.offerTemplate.data.service_areas.flatMap(
          (sa) => sa.service_lines
        );

      // Check for the specific service line in template
      const selectedTemplateServiceLine = templateServiceLines.find((sl) =>
        sl.service_groups.some((sg) => sg.name === action.payload.name)
      );

      if (!selectedTemplateServiceLine) {
        throw new Error(
          "Could not map selected service group name to template service"
        );
      }

      // Find the selected service line in the current offer
      const selectedServiceLine = state.currentOffer.data.service_areas
        .flatMap((sa) => sa.service_lines)
        .find((sl) => sl.name === selectedTemplateServiceLine.name);

      if (!selectedServiceLine) {
        throw new Error("Could not find selected service line in offer");
      }

      // remove tax objects for service line group
      const removedServices = selectedServiceLine.service_groups
        .find((sg) => sg.name === action.payload.name)
        ?.service_categories.flatMap((sc) => sc.services)
        .filter((s) => s.pog_income_tax)
        .map((s) => s.service_matrix_id);

      const cleanedTaxObjects = state.currentOffer.data.tax_objects.filter(
        (to) => !removedServices?.includes(to.service_matrix_id)
      );

      state.currentOffer.data.tax_objects = cleanedTaxObjects;

      selectedServiceLine.service_groups =
        selectedServiceLine.service_groups.filter(
          (sg) => sg.name !== action.payload.name
        );
    },
    setSelectedServiceType(state, action: PayloadAction<string>) {
      state.selectedServiceType = action.payload;
    },
    resetSelectedServiceType(state) {
      state.selectedServiceType = "";
    },
    addSelectedServiceOption(
      state,
      action: PayloadAction<SelectedPickerOption>
    ) {
      if (!state.selectedServiceType) {
        throw new Error("No selected service type");
      }
      // remove the options that belong to other group
      const filteredOptions = state.selectedServiceOptions.filter(
        (option) => option.groupId === action.payload.groupId
      );
      state.selectedServiceOptions = [...filteredOptions, action.payload];
    },
    removeSelectedServiceOption(
      state,
      action: PayloadAction<SelectedPickerOption>
    ) {
      state.selectedServiceOptions = state.selectedServiceOptions.filter(
        (option) => option.name !== action.payload.name
      );
    },
    resetSelectedServiceOptions(state) {
      state.selectedServiceOptions = [];
    },
    setSelectedCustomerSource(state, action: PayloadAction<string>) {
      if (state.currentOffer.data) {
        state.currentOffer.data.deal_source = action.payload;
      }
    },
    resetSelectedCustomerSource(state) {
      if (state.currentOffer.data) {
        state.currentOffer.data.deal_source = "";
      }
    },
    setCompletionStatus(state, action: PayloadAction<CompletionStatus>) {
      state.completionStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOffers.pending, (state: OfferState) => {
      state.status = "pending";
    });
    builder.addCase(fetchOffers.fulfilled, (state: OfferState, { payload }) => {
      state.status = "idle";
      state.data = payload;
    });
    builder.addCase(fetchOffers.rejected, (state: OfferState, { error }) => {
      state.error = [...state.error, error.message ?? ""];
      state.data = [];
      state.status = "failed";
    });
    builder.addCase(fetchCompanyInfoFromSales.pending, (state: OfferState) => {
      state.currentOffer.status = "pending";
      state.customerBillings.status = "idle";
      state.companyInfo.status = "pending";
    });
    builder.addCase(
      fetchCompanyInfoFromSales.fulfilled,
      (state: OfferState, { payload }) => {
        state.currentOffer.status = "idle";
        state.companyInfo = {
          data: payload,
          status: "idle",
        };
      }
    );
    builder.addCase(
      fetchCompanyInfoFromSales.rejected,
      (state: OfferState, { error }) => {
        state.error = [...state.error, error.message ?? ""];
        state.currentOffer.status = "failed";
        state.companyInfo.status = "failed";
      }
    );
    builder.addCase(sendOfferForCalculation.pending, (state: OfferState) => {
      state.currentOffer.status = "pending";
    });
    builder.addCase(
      sendOfferForCalculation.fulfilled,
      (state: OfferState, action: PayloadAction<Partial<CustomerDeal>>) => {
        state.currentOffer.data = {
          ...(state.currentOffer?.data as CustomerDeal),
          ...action.payload,
        };
        state.currentOffer.status = "idle";
      }
    );
    builder.addCase(
      sendOfferForCalculation.rejected,
      (state: OfferState, { error }) => {
        state.error = [...state.error, error.message ?? ""];
        state.currentOffer.status = "failed";
      }
    );

    builder.addCase(patchOffer.pending, (state: OfferState) => {
      state.currentOffer.status = "pending";
    });
    builder.addCase(patchOffer.fulfilled, (state: OfferState) => {
      state.currentOffer.status = "idle";
    });
    builder.addCase(patchOffer.rejected, (state: OfferState, { error }) => {
      state.error = [...state.error, error.message ?? ""];
      state.currentOffer.status = "failed";
    });

    builder.addCase(
      fetchCustomerBilling.fulfilled,
      (state: OfferState, { payload }) => {
        state.customerBillings.data = payload;
        state.customerBillings.status = "idle";
      }
    );
    builder.addCase(fetchCustomerBilling.pending, (state: OfferState) => {
      state.customerBillings.status = "pending";
    });
    builder.addCase(
      fetchCustomerBilling.rejected,
      (state: OfferState, { error }) => {
        state.error = [...state.error, error.message ?? ""];
        state.customerBillings.status = "failed";
      }
    );
    builder.addCase(
      fetchOfferTemplate.fulfilled,
      (state: OfferState, { payload }) => {
        state.offerTemplate.data = payload;
      }
    );
    builder.addCase(sendCreateDealFromOffer.pending, (state: OfferState) => {
      state.currentOffer.status = "pending";
    });
    builder.addCase(
      sendCreateDealFromOffer.fulfilled,
      (state: OfferState, { payload }) => {
        state.currentOffer.data = payload as CustomerDeal;
        state.currentOffer.status = "idle";
      }
    );
    builder.addCase(fetchDeals.rejected, (state: OfferState, { error }) => {
      state.error = [...state.error, error.message ?? ""];
      state.createdDeals.status = "failed";
    });
    builder.addCase(fetchDeals.pending, (state: OfferState) => {
      state.createdDeals.status = "pending";
    });
    builder.addCase(fetchDeals.fulfilled, (state: OfferState, { payload }) => {
      state.createdDeals.status = "idle";
      state.createdDeals.data = payload;
    });
    builder.addCase(fetchDeal.rejected, (state: OfferState, { error }) => {
      state.error = [...state.error, error.message ?? ""];
      state.createdDeals.status = "failed";
    });
    builder.addCase(fetchDeal.fulfilled, (state: OfferState, { payload }) => {
      state.currentOffer.data = {
        ...(state.currentOffer?.data as CustomerDeal),
        ...payload,
      };
      state.currentOffer.status = "idle";
    });
    builder.addCase(fetchDeal.pending, (state: OfferState) => {
      state.currentOffer.status = "pending";
    });

    builder.addCase(copyDeal.rejected, (state: OfferState, { error }) => {
      state.error = [...state.error, error.message ?? ""];
      state.currentOffer.status = "failed";
    });
    builder.addCase(copyDeal.fulfilled, (state: OfferState, { payload }) => {
      state.currentOffer.data = payload;
      state.currentOffer.status = "idle";
    });
    builder.addCase(copyDeal.pending, (state: OfferState) => {
      state.currentOffer.status = "pending";
    });

    builder.addCase(deleteDeal.rejected, (state: OfferState, { error }) => {
      state.error = [...state.error, error.message ?? ""];
      state.currentOffer.status = "failed";
    });
    builder.addCase(deleteDeal.fulfilled, (state: OfferState, { payload }) => {
      state.createdDeals.data = state.createdDeals.data.filter(
        (deal) => deal.id !== payload.deleted
      );
      state.currentOffer.status = "idle";
    });
    builder.addCase(deleteDeal.pending, (state: OfferState) => {
      state.currentOffer.status = "pending";
    });
    builder.addCase(
      fetchCustomerContactsByOrgNr.pending,
      (state: OfferState) => {
        state.availableCompanyContacts.status = "pending";
      }
    );
    builder.addCase(
      fetchCustomerContactsByOrgNr.rejected,
      (state: OfferState, { error }) => {
        state.error = [...state.error, error.message ?? ""];
        state.availableCompanyContacts.status = "failed";
      }
    );
    builder.addCase(
      fetchCustomerContactsByOrgNr.fulfilled,
      (state: OfferState, { payload }) => {
        state.availableCompanyContacts = {
          status: "idle",
          data: payload,
        };
      }
    );
    builder.addCase(deleteCustomerContactCaller.pending, (state) => {
      state.currentOffer.status = "pending";
    });
    builder.addCase(
      deleteCustomerContactCaller.fulfilled,
      (state, { payload }) => {
        const contacts = [...state.availableCompanyContacts.data];
        const filteredContacts = contacts.filter(
          (contact) => contact.email !== payload.contactEmail
        );
        state.currentOffer.status = "succeeded";
        state.availableCompanyContacts.data = filteredContacts;
      }
    );
    builder.addCase(deleteCustomerContactCaller.rejected, (state) => {
      state.currentOffer.status = "failed";
    });
    builder.addCase(
      sendOfferForSigning.rejected,
      (state: OfferState, { error }) => {
        state.error = [...state.error, error.message ?? ""];
        state.currentOffer.status = "failed";
      }
    );
    builder.addCase(
      sendOfferForSigning.fulfilled,
      (state: OfferState, { payload }) => {
        state.currentOffer.data = payload as CustomerDeal;
        state.currentOffer.status = "idle";
      }
    );
    builder.addCase(sendOfferForSigning.pending, (state: OfferState) => {
      state.currentOffer.status = "pending";
    });
    builder.addCase(
      createOrUpdateCompanyContact.pending,
      (state: OfferState) => {
        state.availableCompanyContacts.status = "pending";
      }
    );
    builder.addCase(
      createOrUpdateCompanyContact.rejected,
      (state: OfferState, { error }) => {
        state.error = [...state.error, error.message ?? ""];
        state.availableCompanyContacts.status = "failed";
      }
    );
    builder.addCase(
      createOrUpdateCompanyContact.fulfilled,
      (state: OfferState, { payload }) => {
        const existingContact = state.availableCompanyContacts.data?.find(
          (contact) => contact.id === payload.id
        );

        if (existingContact) {
          Object.assign(existingContact, payload);
        } else {
          state.availableCompanyContacts.data = [
            ...state.availableCompanyContacts?.data,
            payload,
          ];
        }
        state.availableCompanyContacts.status = "idle";
      }
    );

    builder.addCase(createOrUpdateCustomer.pending, (state: OfferState) => {
      if (state.currentOffer.data) {
        state.currentOffer.status = "pending";
      }
    });
    builder.addCase(
      createOrUpdateCustomer.rejected,
      (state: OfferState, { error }) => {
        state.error = [...state.error, error.message ?? ""];
        if (state.currentOffer.data) {
          state.currentOffer.status = "failed";
        }
      }
    );
    builder.addCase(
      createOrUpdateCustomer.fulfilled,
      (state: OfferState, { payload }) => {
        if (state.currentOffer.data) {
          state.currentOffer.data.customer = plainToClass(DealCustomer, {
            ...state.currentOffer.data.customer,
            ...payload,
          });
        }

        state.currentOffer.status = "idle";
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  addOffers,
  resetCompanyInfo,
  resetOffers,
  updateOffers,
  resetContacts,
  updateCurrentOffer,
  updateOfferCustomer,
  updateOfferContact,
  setCurrentOffer,
  overwriteCurrentOffer,
  setOfferSelectedServiceAreas,
  addOfferSelectedServiceLine,
  removeOfferSelectedServiceLine,
  addServiceLineGroup,
  removeServiceLineGroup,
  updateServiceLineGroup,
  setSelectedServiceType,
  resetSelectedServiceType,
  addSelectedServiceOption,
  removeSelectedServiceOption,
  resetSelectedServiceOptions,
  setSelectedCustomerSource,
  resetSelectedCustomerSource,
  setCompletionStatus,
} = offersSlice.actions;
