import { Language } from "i18n";
import {
  ActivityDefinition,
  ActivityInstance,
  CustomerActivitiesServices,
  ServiceInstance,
} from "models/activities/activity";
import {
  ActivityInstancesGroup,
  ServiceActivitiesGroup,
} from "views/conveyorBelt/components/serviceActivities";

export const checkIfIncompleteActivitiesExist = (
  definitionsMap: { [key: string]: ActivityDefinition },
  excludeActivity: ActivityInstance,
  language: Language,
  currentServiceActivitiesForMonth: CustomerActivitiesServices[],
  service?: ServiceInstance
) => {
  const changeOccured = currentServiceActivitiesForMonth[0];
  const checkExistanceOfService = !!(
    currentServiceActivitiesForMonth.length > 0 &&
    currentServiceActivitiesForMonth[0]?.services &&
    currentServiceActivitiesForMonth[0]?.services.length > 0
  );
  const checkServiceActivities = checkExistanceOfService
    ? currentServiceActivitiesForMonth[0].services[0].activities
    : [];

  let activitiesOfService;
  if (checkServiceActivities) {
    activitiesOfService = checkServiceActivities;
  }

  if (checkServiceActivities && changeOccured) {
    activitiesOfService = checkServiceActivities;
  } else {
    activitiesOfService = service?.activities;
  }

  const activityGroups = activitiesOfService?.reduce((sum, current) => {
    const definition = definitionsMap[current.activity_type];
    if (!sum[current.group.group_idx]) {
      sum[current.group.group_idx] = {
        categoryName: current.getGroupTitle(language, definition),
        categorySortingNumber: current.getGroupSortingNum(),
        activities: [],
      };
    }

    sum[current.group.group_idx].activities = [
      ...sum[current.group.group_idx].activities,
      current,
    ];

    return sum;
  }, {} as ServiceActivitiesGroup);

  const sortedActivitiesKeys =
    activityGroups &&
    Object.keys(activityGroups).sort((key1, key2) => {
      const category1 = activityGroups[key1].categorySortingNumber;
      const category2 = activityGroups[key2].categorySortingNumber;

      return category1.toString().localeCompare(category2.toString());
    });

  const isAnyActivityIncomplete =
    sortedActivitiesKeys &&
    sortedActivitiesKeys.some((key) => {
      const group: ActivityInstancesGroup = activityGroups[key];

      const nrOfActivitiesWithinGroupIncomplete = group.activities.some(
        (activity) => {
          if (excludeActivity.activity_id === activity.activity_id) {
            return false;
          }

          return !activity.completed_at;
        }
      );

      return nrOfActivitiesWithinGroupIncomplete;
    });

  return isAnyActivityIncomplete;
};
