import { Col, Row } from "react-bootstrap";

import Modal from "components/modal";
import { useTranslation } from "hooks/use-translate";
import { DetailedInfo } from "models/customerSettings/customerSettingsDetailedInfo";
import { ModalHeader } from "components/modal/ModalHeader";

type Props = {
  currentInfo: DetailedInfo;
  openModal: boolean;
  setOpenModal: (arg: boolean) => void;
};

export default function CustomerPageInfoModal({
  currentInfo,
  openModal,
  setOpenModal,
}: Props) {
  const { translate } = useTranslation();
  return (
    <Modal
      isOpen={openModal}
      onDismiss={() => setOpenModal(false)}
      size="medium"
      header={<ModalHeader headerTitleText={currentInfo.title.toUpperCase()} />}
    >
      <Row className="p-lg">
        <Col md={8} className="px-0 fw-bold mb-md">
          <span>{currentInfo.customerdetailssubcategory.subcategory_en},</span>
          <span className="p-sm">
            {
              currentInfo.customerdetailssubcategory.customerdetailscategory
                .category_en
            }
          </span>
        </Col>
        <Col md={4} className="mb-md d-flex justify-content-end">
          <span className="fw-bold px-sm">{translate("MODIFIED_BY")}:</span>
          <span>
            {currentInfo.modified_by_user.firstName}{" "}
            {currentInfo.modified_by_user.lastName}
          </span>
        </Col>
        <Col md={12}>
          <Row className="pb-sm">{currentInfo.content}</Row>
        </Col>
      </Row>
    </Modal>
  );
}
