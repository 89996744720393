import { useState } from "react";
import { Button } from "@fluentui/react-components";
import { ChevronLeft20Regular } from "@fluentui/react-icons";

import Tooltip from "components/tooltip/Tooltip";

type Props = { projectManagerProjects: string };

export default function ShowMoreProjects({ projectManagerProjects }: Props) {
  const [showAll, setShowAll] = useState(false);
  const projects = projectManagerProjects.split(", ");

  const handleToggle = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const renderProjects = (projectsToRender: string[]) => {
    return projectsToRender.map((project, index) => (
      <span key={project}>
        {project.toUpperCase()}
        {index < projectsToRender.length - 1 ? ", " : ""}
      </span>
    ));
  };

  return (
    <div className="d-flex align-items-start">
      <div>
        {showAll
          ? renderProjects(projects)
          : renderProjects(projects.slice(0, 4))}
      </div>
      {projects.length > 4 && (
        <Tooltip content={showAll ? "CLICK_TO_COLLAPSE" : "SHOW_ALL"}>
          <Button
            appearance="transparent"
            className="mx-0 p-0 text-primary min-width-35 blue-link"
            onClick={handleToggle}
            icon={showAll ? <ChevronLeft20Regular /> : null}
          >
            {!showAll && `+${projects.length - 4}`}
          </Button>
        </Tooltip>
      )}
    </div>
  );
}
