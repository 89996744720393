import { useState } from "react";
import { useSelector } from "react-redux";
import {
  ContractDownLeft20Regular,
  ExpandUpRight20Regular,
  Info20Filled,
} from "@fluentui/react-icons";

import { RootState } from "state";
import Checkbox from "components/checkbox";
import { Label } from "components/label";
import { useTranslation } from "hooks/use-translate";
import { Activity, Recurrence } from "models/activities/activity";
import { EditActivityStatus } from "state/activities/actions";
import { frequencyLabelMap } from "helpers/conveyorBelt/conveyorBeltHelpers";
import { ActivityGroup } from "views/conveyorBelt/components/serviceActivities";

type Props = {
  group: ActivityGroup;
  openActivityDetailsPanel: (activity: Activity) => void;
  showFrequency: boolean;
  editActivitiesStatuses: EditActivityStatus[];
  setEditActivitiesStatuses: (statuses: EditActivityStatus[]) => void;
} & React.HTMLProps<HTMLTableElement>;

export function EditActivitiesGroup({
  group,
  showFrequency,
  setEditActivitiesStatuses,
  openActivityDetailsPanel,
  editActivitiesStatuses,
  ...rest
}: Props) {
  const { translate, language } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { dataMap: definitionsMap } = useSelector(
    (state: RootState) => state.activities.definitions
  );

  const setActivitiesEditStatus = (
    activities: Activity[],
    isSelected: boolean
  ) => {
    // Filter out any previous edit state for the given activities
    const filteredEditActivities = editActivitiesStatuses.filter(
      (e) => !activities.some((a) => a.activity_type === e.activity_type)
    );

    // Put together the new edit statuses for the given activities
    const newEditActivities: EditActivityStatus[] = activities.map(
      (newActivity) => {
        return {
          activity_type: newActivity.activity_type,
          selected: isSelected,
        };
      }
    );

    setEditActivitiesStatuses([
      ...filteredEditActivities,
      ...newEditActivities,
    ]);
  };

  const isSelectedActivityType = (activity: Activity) => {
    const editState = editActivitiesStatuses.find(
      (e) => e.activity_type === activity.activity_type
    );

    if (!editState) {
      return false;
    }

    return editState.selected;
  };

  const groupName = group.categoryName;
  const allSelected = group.activities.every((a) => isSelectedActivityType(a));
  const someSelected = group.activities.some((a) => isSelectedActivityType(a));

  const renderEditActivityRow = (activity: Activity) => {
    const definition = definitionsMap[activity.activity_type];
    const selected = isSelectedActivityType(activity);
    const hasOneTimeDates = activity.existing_dates
      ? activity.existing_dates.length > 0
      : false;

    return (
      <tr
        key={activity.activity_type}
        className={["activity-row", selected ? "selected" : ""].join(" ")}
      >
        <td>
          <div className="d-flex align-items-center">
            <Info20Filled
              className="icon-blue mr-sm"
              onClick={() => openActivityDetailsPanel(activity)}
            />
            {activity.getTitle(language, definition)}
            {hasOneTimeDates && (
              <span className="text-color-blue p-lg-1 text-bold">*</span>
            )}
          </div>
        </td>
        <td>
          {showFrequency && (
            <Label
              variant={
                frequencyLabelMap[
                  activity.getRecurrence(definition) || Recurrence.Monthly
                ]
              }
            >
              {translate(
                activity.getRecurrence(definition) || Recurrence.Monthly
              )}
            </Label>
          )}
        </td>
        <td>
          <Checkbox
            checked={selected}
            onChange={() => setActivitiesEditStatus([activity], !selected)}
          />
        </td>
      </tr>
    );
  };

  return (
    <table {...rest}>
      <thead>
        <tr className="background-gray-400">
          <th className="w-50">
            {expanded ? (
              <ContractDownLeft20Regular
                className="cursor-pointer mr-sm mb-xs"
                onClick={() => setExpanded(!expanded)}
              />
            ) : (
              <ExpandUpRight20Regular
                className="cursor-pointer mr-sm mb-xs"
                onClick={() => setExpanded(!expanded)}
              />
            )}
            <span className={`${someSelected && "text-color-blue"}`}>
              {groupName}
            </span>
          </th>
          <th className="w-30" />
          <th>
            <b>
              <Checkbox
                onChange={() =>
                  setActivitiesEditStatus(group.activities, !allSelected)
                }
                checked={allSelected}
                label={translate("SELECT_ALL_OPTIONAL")}
              />
            </b>
          </th>
        </tr>

        {expanded && group.activities.length > 0 && (
          <tr>
            <th>{translate("ACTIVITY")}</th>
            <th>{showFrequency && translate("FREQUENCY")}</th>
            <th>{translate("SELECTED")}</th>
          </tr>
        )}
      </thead>

      {expanded && (
        <tbody>
          {group.activities.map(renderEditActivityRow)}
          {group.activities.length === 0 && (
            <tr>
              <td colSpan={3}>
                <p>{translate("NO_AVAILABLE_OPTIONAL_ACTIVITIES")}</p>
              </td>
            </tr>
          )}
        </tbody>
      )}
    </table>
  );
}
