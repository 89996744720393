import { Droppable } from "react-beautiful-dnd";

import { useTranslation } from "hooks/use-translate";
import { Deal } from "models/deals/deal";
import { StageDeal } from "../StageDeal";

type Props = {
  stageName: string;
  deals: Deal[];
};

export function StageColumn({ stageName, deals }: Props) {
  const { translate } = useTranslation();

  return (
    <Droppable droppableId={stageName} type="DEAL" key={stageName}>
      {(provided, snapshot) => (
        <td
          ref={provided.innerRef}
          className={`stage-column ${
            snapshot.isDraggingOver ? "background-500" : "background-450"
          }`}
          {...provided.droppableProps}
        >
          {deals.map((deal, i) => (
            <StageDeal key={deal.id} index={i} deal={deal} />
          ))}

          {deals.length === 0 && (
            <p className="mb-0 text-color-gray-700">
              {translate("NO_DEALS_IN_THIS_STAGE")}
            </p>
          )}

          {provided.placeholder}
        </td>
      )}
    </Droppable>
  );
}
