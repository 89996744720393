import Stepper, { Step } from "components/stepper";
import { useTranslation } from "hooks/use-translate";
import { CustomerDeal } from "models/offer/CustomerDeal";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "state";
import { getAvailableWizardSteps } from "../../routes/offerRoutes";

/**
 * Generate stepper items for the wizard, based on the contents of the customer offer and the current location.
 * @param currentLocationPath The current location.
 * @param currentOffer The customer offer to use for generating the steps.
 * @returns The stepper items for the wizard.
 */
export function useStepperItemsForOffer(
  currentLocationPath: string,
  currentOffer?: CustomerDeal
): Step[] {
  const { ts } = useTranslation();
  const availableSteps = getAvailableWizardSteps(currentOffer);

  // strip the current path from the url params if there are any
  const currentPathParamsIndex = currentLocationPath.search(/\/[0-9]/);
  const currentBasePath =
    currentPathParamsIndex > 0
      ? currentLocationPath.slice(0, currentPathParamsIndex)
      : currentLocationPath;

  const currentActiveIndex = availableSteps.findIndex((step) =>
    step.steps.some(
      (ss) =>
        ss.path === currentBasePath || `${ss.path}/edit` === currentBasePath
    )
  );

  if (currentActiveIndex === -1) {
    throw new Error(`Unknown route ${currentBasePath}`);
  }

  return availableSteps.map((s, index) => {
    const passed = currentActiveIndex > index;
    const activeChildIndex = s.steps.findIndex(
      (subS) =>
        subS.path === currentBasePath || `${subS.path}/edit` === currentBasePath
    );

    return {
      key: index.toString(),
      label: ts(s.labelKey),
      subSteps: s.steps.map(
        (ss, subIndex) =>
          ({
            ...ss,
            subSteps: [],
            key: ss.labelKey,
            label: ss.labelKey ? ts(ss.labelKey) : "",
            state: {
              visible: ss.visible,
              passed: passed || activeChildIndex >= subIndex,
              valid: ss.validate(),
              active: activeChildIndex === subIndex,
              disabled: ss.disabled,
            },
          } as Step)
      ),
      state: {
        active: currentActiveIndex === index,
        disabled: s.disabled,
        valid: s.steps.every((ss) => ss.validate()),
        passed,
        visible: true,
      },
    } as Step;
  });
}

export default function WizardStepper() {
  const customerOffer = useSelector(
    (state: RootState) => state.offers.currentOffer.data
  );
  const location = useLocation();
  const stepperSteps: Step[] = useStepperItemsForOffer(
    location.pathname,
    customerOffer
  );

  const sortedSteps = stepperSteps
    .filter((step) => !step.state?.disabled)
    .map((step) => {
      if (step.subSteps) {
        const tempStep = {
          ...step,
          subSteps: step.subSteps.filter((subStep) => !subStep.state?.disabled),
        };

        return tempStep;
      }

      return step;
    })
    .sort((a, b) => a.key.localeCompare(b.key));

  return <Stepper steps={sortedSteps} />;
}
