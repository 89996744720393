import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Spinner,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@fluentui/react-components";

import { appendError } from "state/notifications";
import { AppDispatch } from "state/use-app-redux";
import { KycAPI } from "api/kyc";
import { useTranslation } from "hooks/use-translate";
import { formatDate } from "libs/date/date-format";
import { FailedKYCCustomer } from "models/offer/FailedKYCCustomer";
import { Persona } from "components/people";
import { Button } from "components/button";
import { SpinnerSize } from "components/spinner";
import { ViewType } from "models/mgt";
import { SelectedCustomer } from "../FailedKYCCustomers";
import "./failedKYCCustomersTable.scss";

type FailedKYCCustomersTableProps = {
  failedCustomers: FailedKYCCustomer[];
  selectedCustomer?: SelectedCustomer;
  onToggle: (
    customer: SelectedCustomer,
    isChecked: boolean | undefined
  ) => void;
};

export function FailedKYCCustomersTable({
  failedCustomers,
  selectedCustomer,
  onToggle,
}: FailedKYCCustomersTableProps) {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const [downloadingKYCid, setDownloadingKYCid] = useState<string>();

  const KYC_MAX_SCORE = 11510;

  const tableHeaderItems = [
    {
      key: "approveToggle",
    },
    {
      key: "customerName",
      label: translate("KYC_ADMINISTRATION.TABLE.CUSTOMER_NAME"),
    },
    {
      key: "customerNumber",
      label: translate("KYC_ADMINISTRATION.TABLE.CUSTOMER_NUMBER"),
    },
    {
      key: "customerManager",
      label: translate("KYC_ADMINISTRATION.TABLE.LUDVIG_CONTACT"),
    },
    {
      key: "scoreSummary",
      label: translate("KYC_ADMINISTRATION.TABLE.SCORE_SUMMARY"),
    },
    {
      key: "kycDate",
      label: translate("KYC_ADMINISTRATION.TABLE.KYC_DATE_FAILURE"),
    },
    {
      key: "kycForm",
      label: translate("KYC_ADMINISTRATION.TABLE.KYC_FORM"),
    },
  ];

  const handleDownloadKYCForm = async (
    envelopeId: string,
    documentId: string,
    fileId: string
  ) => {
    setDownloadingKYCid(envelopeId);
    try {
      const downloadURL = await KycAPI.getKYCForm(
        envelopeId,
        documentId,
        fileId
      );
      const a = document.createElement("a");
      a.href = downloadURL;
      a.download = "file.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      dispatch(
        appendError(
          "KYC_ADMINISTRATION.FAILED_TO_DOWNLOAD_KYC_FORM",
          error as Error
        )
      );
    } finally {
      setDownloadingKYCid(undefined);
    }
  };

  return (
    <Table className="table-fs-14 table-kyc">
      <TableHeader className="fw-semibold">
        <TableRow>
          {tableHeaderItems.map((item) => (
            <TableHeaderCell
              key={item.key}
              className={`table-kyc-header-item-${item.key}`}
            >
              {item.label}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {failedCustomers.map((customer) => (
          <TableRow key={customer.envelope_id}>
            <TableCell>
              <Switch
                disabled={
                  selectedCustomer &&
                  customer.envelope_id !== selectedCustomer?.envelopeId
                }
                className="no-margin"
                onChange={(e, { checked }) =>
                  onToggle(
                    {
                      envelopeId: customer.envelope_id,
                      customerName: customer.customer_name,
                    },
                    checked
                  )
                }
              />
            </TableCell>
            <TableCell>{customer.customer_name}</TableCell>
            <TableCell>{customer.customer_number}</TableCell>
            <TableCell>
              <Persona
                userId={customer.ludvig_contact_email}
                view={ViewType.oneline}
              />
            </TableCell>
            <TableCell>
              {customer.total_risk_sum} / {KYC_MAX_SCORE}
            </TableCell>
            <TableCell>{formatDate(new Date(customer.created_date))}</TableCell>
            <TableCell>
              <Button
                variant="link"
                className="blue-link p-0 text-left"
                onClick={() =>
                  handleDownloadKYCForm(
                    customer.envelope_id,
                    customer.document_id,
                    customer.file_id
                  )
                }
              >
                <div className="d-flex align-items-center">
                  <span className="fw-semibold">
                    {translate("KYC_ADMINISTRATION.DOWNLOAD_KYC_FORM")}
                  </span>
                  {downloadingKYCid === customer.envelope_id && (
                    <Spinner className="ml-sm" size={SpinnerSize.ExtraTiny} />
                  )}
                </div>
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
