import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "@fluentui/react-components";

import Paginator from "components/Pagination";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { CustomerDetailsParams } from "routes";
import { RootState } from "state";
import {
  fetchPGUAssignmentData,
  fetchPGUData,
  fetchPGUDetailsData,
  fetchPGUDetailsPerTaskData,
  fetchPGUFiscalYearData,
  fetchPGUPartialyInvoicedData,
} from "state/activities/actions";
import { AppDispatch } from "state/use-app-redux";
import { defaultPaginatorOptions } from "components/Pagination/consts";
import AssignmentTable from "./assignmentTable";
import DetailsPerTaskTablePGU from "./detailsPerTaskTable";
import DetailsTablePGU from "./detailsTable";
import FiscalYearTable from "./fiscalYearTable";
import PartiallyInvoicedTable from "./partiallyInvoicedTable";
import PguMainTable from "./pguMainTable";

type Props = {
  tableType:
    | "main"
    | "assignment"
    | "financial_statements"
    | "detailed_table"
    | "detailed_per_task"
    | "partial_invoice";
};

export default function CustomerSettingsPguPage({ tableType }: Props) {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const {
    customerId,
    jobnumber,
    purposename,
    detailsTaskName,
    partialInvoice,
  } = useParams<CustomerDetailsParams>();
  const [pguData, setPguData] = useState<any>();

  const customers = useSelector((state: RootState) => state.customers.data);
  const customer = customers.find(
    (e) => e.customer.customer_number === customerId
  )?.customer;

  const [activePage, setActivePage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [itemsShownPerPage, setItemsShownPerPage] = useState<number>(15);

  const activePageObjects = pguData?.slice(
    (activePage - 1) * itemsShownPerPage,
    activePage * itemsShownPerPage
  );

  useEffect(() => {
    const init = async () => {
      if (customer) {
        setLoading(true);
        if (tableType === "main") {
          dispatch(fetchPGUData(customer))
            .then((res) => {
              setPguData(res);
              setLoading(false);
            })
            .catch((err) => console.log(err));
        } else if (tableType === "assignment" && customerId && jobnumber) {
          dispatch(fetchPGUAssignmentData(customerId, jobnumber))
            .then((res) => {
              setPguData(res);
              setLoading(false);
            })
            .catch((err) => console.log(err));
        } else if (
          tableType === "detailed_per_task" &&
          customerId &&
          jobnumber &&
          detailsTaskName
        ) {
          dispatch(
            fetchPGUDetailsPerTaskData(customerId, jobnumber, detailsTaskName)
          )
            .then((res: any) => {
              setPguData(res);
              setLoading(false);
            })
            .catch((err: any) => console.log(err));
        } else if (
          tableType === "partial_invoice" &&
          customerId &&
          jobnumber &&
          partialInvoice
        ) {
          dispatch(
            fetchPGUPartialyInvoicedData(customerId, jobnumber, partialInvoice)
          )
            .then((res: any) => {
              setPguData(res);
              setLoading(false);
            })
            .catch((err: any) => console.log(err));
        }
      }
    };
    init();
  }, [
    detailsTaskName,
    customer,
    customerId,
    dispatch,
    jobnumber,
    tableType,
    partialInvoice,
  ]);

  useEffect(() => {
    setLoading(true);
    const init = async () => {
      if (
        customerId &&
        jobnumber &&
        purposename &&
        tableType === "financial_statements"
      ) {
        dispatch(fetchPGUFiscalYearData(customerId, jobnumber, purposename))
          .then((res: any) => {
            setPguData(res);
            setLoading(false);
          })
          .catch((err: any) => console.log(err));
      }
    };
    init();
  }, [tableType, purposename, jobnumber, customerId, dispatch]);

  useEffect(() => {
    if (customerId && jobnumber && tableType === "detailed_table") {
      dispatch(fetchPGUDetailsData(customerId, jobnumber, "True"))
        .then((res: any) => {
          setPguData(res);
          setLoading(false);
        })
        .catch((err: any) => console.log(err));
    }
  }, [tableType, jobnumber, customerId, dispatch]);

  const headerTitle = {
    main: "CUSTOMER_PAGE_PGU" as TranslationKey,
    assignment: "ASSIGNMENT" as TranslationKey,
    financial_statements: "FINANCIAL_STATEMENTS" as TranslationKey,
    detailed_table: "DETAILED" as TranslationKey,
    detailed_per_task: "PER_TASK" as TranslationKey,
    partial_invoice: "INVOICED_VALUE" as TranslationKey,
  };

  const headerSubTitle = {
    main: `(${activePageObjects?.length || 0})`,
    assignment: jobnumber,
    financial_statements: purposename,
    detailed_table: "",
    detailed_per_task: jobnumber,
    partial_invoice: "",
  };

  return (
    <div className="new-page-content">
      <div className="d-flex customer-settings-table-sticky contentHeader">
        <div className="header-title d-flex  align-items-center">
          <h3 className="pl-sm">{translate(`${headerTitle[tableType]}`)}</h3>
          <span className="px-sm  text-muted font-size-em-5">
            {headerSubTitle[tableType]}
          </span>
        </div>
        <div className="ml-auto">
          {pguData && (
            <Paginator
              itemsLength={pguData?.length || 0}
              defaultItemsShownPerPage={itemsShownPerPage}
              showItemsLabel={translate("SHOW")}
              options={defaultPaginatorOptions}
              onSelectPage={setActivePage}
              onChangeShownItems={(v) => setItemsShownPerPage(v)}
            />
          )}
        </div>
      </div>

      {loading ? (
        <Spinner size={SpinnerSize.ExtraSmall} className="ms-2" />
      ) : (
        <>
          {pguData && tableType === "main" && (
            <PguMainTable
              activePageObjects={activePageObjects}
              customerNumber={customer?.customer_number}
            />
          )}

          {pguData && tableType === "assignment" && (
            <AssignmentTable
              activePageObjects={activePageObjects}
              loading={loading}
            />
          )}

          {pguData && tableType === "financial_statements" && (
            <FiscalYearTable activePageObjects={activePageObjects} />
          )}

          {pguData && tableType === "detailed_table" && (
            <DetailsTablePGU activePageObjects={activePageObjects} />
          )}

          {pguData && tableType === "detailed_per_task" && (
            <DetailsPerTaskTablePGU activePageObjects={activePageObjects} />
          )}

          {pguData && tableType === "partial_invoice" && (
            <PartiallyInvoicedTable activePageObjects={activePageObjects} />
          )}
        </>
      )}
    </div>
  );
}
