/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
import { Transform, Type } from "class-transformer";

import { DealType } from "constants/enums/myBusinessOpportunities.enum";
import { CompanyInformation } from "models/offer/Company";

export type CompanyType =
  | "Privatperson"
  | "Aktiebolag"
  | "Handelsbolag"
  | "Enskildnäringsverksamhet"
  | "Kommanditbolag"
  | "Ideell förening"
  | "Samfällighetsförening"
  | "Annat";

export type Priority = "low" | "medium" | "high";

export type InboundOutboundType = "inbound" | "outbound" | null;

export type DealTypeKey = keyof typeof DealType;

export type DealProperty = keyof Deal;

export type DealOption = {
  name: DealProperty;
  label: string;

  options: {
    label: string;
    value: string;
    display_order: number;
  }[];
};

export class CreateDeal {
  pipeline: string;

  dealstage: string;

  dealname: string;

  organisationsnummer: string;

  first_name: string;

  last_name: string;

  amount: number;

  customer_type: string;

  company: string;

  assignee_email?: string;

  assignee_firstname?: string;

  assignee_lastname?: string;

  owner_email?: string;

  region?: string;

  office_cluster?: string;

  office_name?: string;

  meddelande_salj: string;

  deal_source: string;

  affarsomrade_deal_: string;

  productIds?: string[];

  contact?: DealContact;

  contact_email: string;

  contact_email_searched: boolean;

  aterkommande_aktiviteter: string;

  phone: string;

  mobile: string;

  km_deal_created_by_user?: string;

  km_deal_created_by_user_email?: string;

  customer_number?: string;

  supress_asignee_email?: boolean;

  postal_address: string;

  postnummer: string;

  postal_city: string;
}

export type SearchContact = {
  address: string;
  city: string;
  country: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  mobile: string;
  phone: string;
  roles: [];
  aterkommande_aktiviteter: string;
  aktivitet_event: string;
  social_security_number: string;
  organization?: string;
  zip?: string;
  registrationNumber?: string;
};

export class DealContact {
  id?: string;

  firstname: string;

  lastname: string;

  email: string;

  mobilephone: string;

  organization?: string;

  registrationNumber?: string;

  roles?: [];

  phone?: string;

  address?: string;

  city?: string;

  country?: string;

  zip?: string;

  aterkommande_aktiviteter: string;

  aktivitet_event: string;

  constructor(data?: DealContact) {
    this.id = data?.id ?? "";
    this.firstname = data?.firstname ?? "";
    this.lastname = data?.lastname ?? "";
    this.email = data?.email ?? "";
    this.mobilephone = data?.mobilephone ?? "";
    this.organization = data?.organization ?? "";
    this.registrationNumber = data?.registrationNumber ?? "";
    this.roles = data?.roles ?? [];
    this.phone = data?.phone ?? "";
    this.address = data?.address ?? "";
    this.city = data?.city ?? "";
    this.country = data?.country ?? "";
    this.zip = data?.zip ?? "";
    this.aterkommande_aktiviteter = data?.aterkommande_aktiviteter ?? "";
    this.aktivitet_event = data?.aktivitet_event ?? "";
  }
}

export class Deal {
  id: string;

  /**
   * Deal type
   */
  deal_type: DealType;

  /**
   * Deal source, i.e. customer called, emailed, visited the website, etc.
   */
  deal_source: string;

  /**
   * Company org number
   */
  organisationsnummer: string;

  /**
   * Name of the company
   */
  company: string;

  /**
   * Vouchers
   */
  verifikationer: string;

  /**
   * Number of salaries
   */
  antal_l_ner: string;

  /**
   * Description of the company business
   */
  foretagsbeskrivning: string;

  /**
   * Other information about the property
   */
  ovrig_info_fastighet: string;

  /**
   * Municipality in which the property is located
   */
  state: string;

  /**
   * Company revenue
   */
  oms_ttning: number;

  /**
   *
   */
  narmaste_kontor_deal: string;

  /**
   * Company address
   */
  postal_address: string;

  /**
   * Company postal number
   */
  postal_address_co: string;

  /**
   * Company postal number
   */
  postnummer: string;

  /**
   * Company postal city
   */
  postal_city: string;

  /**
   * Company phone number
   */
  company_phone: string;

  /**
   * Company form
   */
  bolagsform: CompanyType;

  /**
   * The status of the customer
   */
  customer_status: string;

  /**
   *
   */
  free_consulting: string | null;

  /**
   * Internal notes for the deal
   */
  meddelande_salj: string;

  /**
   * Priority of the deal
   */
  hs_priority: Priority;

  /**
   * Message from the contact
   */
  message: string;

  /**
   * Name of the property
   */
  fastighetsbeteckning: string;

  /**
   * The county in which the property is located
   */
  vardeindiktator_lan: string;

  /**
   * Size of the forestry property in cubic metres
   */
  v_rdeindikator_storlek_skogsmark_m3sk_: number;

  /**
   * Size of the  property in cubic metres
   */
  vardeindikator_storlek_akermark_hektar_: number;

  /**
   *
   */
  vardeindikator_storlek_skogsmark_hektar_: number;

  /**
   *
   */
  v_rdeindikator_resultat: string;

  /**
   *
   */
  vardeindiktator_fastighet_i_jamforelse_med_andra_fastigheter_i_ditt_omr_de_avkastningsf_rm_ga_arron: string;

  /**
   *
   */
  vardeindiktator_fastighet_i_jamforelse_med_andra_fastigheter_i_ditt_omrade: string;

  /**
   * Location of where the customer entered their data in the first place (generally a web page)
   */
  recent_conversion_event_name: string;

  /**
   * Aktivitet
   */
  aktivitet: string;

  /**
   * Återkommande aktiviteter
   */
  aterkommande_aktiviteter: string;

  /**
   * Efterfrågade tjänster ekonomi
   */
  efterfr_gade_tj_nster_ekonomi: string[];

  /**
   * Charged by hourly rate
   */
  debiteras_enligt_pristaxa: boolean;

  /**
   * Requested type of meeting
   */
  onskemal_typ_av_mote: string;

  /**
   * Areas of law
   */
  juridiska_omraden: string;

  /**
   * Other information from law unit
   */
  information_om_arendet_jurisenhet_: string;

  /**
   * Business area
   */
  affarsomrade_deal_: string;

  /**
   * Name of the deal, could be the name of the person/company who is buying
   */
  dealname: string;

  /**
   * Deal status
   */
  deal_status: string;

  /**
   * Url to POG quote for the deal
   */
  pog_quote_url: string;

  /**
   * Created via pog
   */
  pog_created: boolean;

  /**
   * User email that created the deal
   */
  km_deal_created_by_user_email: string;

  /**
   * User id that created the deal
   */
  km_deal_created_by_user: string;

  /**
   * Contact object
   */
  @Transform(({ value }) => {
    return !value
      ? {
          firstname: "",
          lastname: "",
          email: "",
          mobilephone: "",
          phone: "",
          address: "",
          country: "",
          city: "",
          zip: "",
          aterkommande_aktiviteter: "",
          aktivitet_event: "",
        }
      : value;
  })
  contact: DealContact;

  /**
   * Customer contact firstname
   */
  get contactFirstname() {
    return this.contact?.firstname;
  }

  set contactFirstname(value: string) {
    this.contact.firstname = value;
  }

  get contactActivityEvent() {
    return this.contact?.aktivitet_event;
  }

  set contactActivityEvent(value: string) {
    this.contact.aktivitet_event = value;
  }

  get contactRecurringActivities() {
    return this.contact?.aterkommande_aktiviteter;
  }

  set contactRecurringActivities(value: string) {
    this.contact.aterkommande_aktiviteter = value;
  }

  /**
   * Customer contact firstname
   */
  get contactLastname() {
    return this.contact?.lastname;
  }

  set contactLastname(value: string) {
    this.contact.lastname = value;
  }

  /**
   * Customer contact email
   */
  get contactEmail() {
    return this.contact?.email;
  }

  set contactEmail(value: string) {
    this.contact.email = value;
  }

  /**
   * Customer contact phone number
   */
  get contactPhoneNumber() {
    return this.contact?.phone || this.contact?.mobilephone || "";
  }

  set contactPhoneNumber(value: string) {
    this.contact.phone = value;
  }

  /**
   * Value of the deal
   */
  amount?: number;

  /**
   * Owner to the deal
   */
  owner: string;

  /**
   * Employee, by Hubspot ID, assigned to the deal
   */
  deal_assignee: string | null;

  /**
   * Employee, by email, assigned to the deal
   */
  deal_assignee_email: string | null;

  /**
   * Identifier for the stage the deal is currently in
   */
  dealstage: string;

  /**
   * ID of the pipeline this deal is flowing through
   */
  pipeline: string;

  /**
   * Selected products IDs
   */
  productIds: string[];

  /**
   *
   */
  closed_lost_reason: string;

  /**
   * Reason for lost deal
   */
  lost_reason: string;

  /**
   * Inkommande förfrågan/proaktiv kontakt (inbound_outbound) propery from hs
   */
  inbound_outbound: InboundOutboundType;

  /**
   * Date of the last change in terms of booking a meeting / etc. of this deal
   */
  @Type(() => Date)
  lastActivityDate: Date;

  /**
   * Date of the last change
   */
  @Type(() => Date)
  lastModifiedDate: Date;

  /**
   * Date of the last move to a new stage
   */
  @Type(() => Date)
  entered_current_stage_date: Date;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;

  @Type(() => Date)
  closedate?: Date;

  dealtype: "existingbusiness" | "potentialbusiness" | null;

  hubspot_company?: Partial<CompanyInformation>;

  constructor(data?: Partial<Deal>) {
    this.id = data?.id ?? "";
    this.meddelande_salj = data?.meddelande_salj ?? "";
    this.hs_priority = data?.hs_priority ?? "low";
    this.deal_status = data?.deal_status ?? "";
    this.free_consulting = data?.free_consulting ?? null;
    this.affarsomrade_deal_ = data?.affarsomrade_deal_ ?? "";
    this.productIds = data?.productIds ?? [];
    this.amount = data?.amount ?? 0;
    this.closedate = data?.closedate;
    this.lost_reason = data?.lost_reason ?? "";
    this.organisationsnummer = data?.organisationsnummer ?? "";
    this.contact = new DealContact(data?.contact);
  }
}

export type PotentialDealItem = {
  dealId: string;
  businessArea: string;
  dateCreatedFormatted: string;
  dateCreatedAsMilliseconds: number;
  sourceValue: number;
  sourceLabel: string;
  services: string[];
  businessValueAmount?: number;
  businessValueAmountFormatted: string;
  contactPersonName: string;
  stage: string;
  responsiblePersonEmail: string;
};

export type DynamicPropertyOption = {
  value: string;
  hidden: boolean;
  displayOrder: number;
  label: string;
  description: string | null;
};
