import {useCallback, useState} from "react";
import {Tree, TreeItem, TreeItemLayout, Checkbox as FluentUICheckbox } from "@fluentui/react-components";
import {useSelector} from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {RootState} from "state";
import { AppRouteHelper, CustomerDetailsParams } from "routes";
import {authMethod} from "auth";
import {Button} from "components/button";
import {LayoutHeader, LayoutHeaderLeft} from "components/layout/Layout";
import {ActivitiesAPI} from "api/activities";


type AssignmentData = {
    assignment_uuid: string;
    start_date: string;
    activity_type: string;
    activity_name: string;
    recurrence: string;
    type_ext: string;
    activities: string[];
    onetime: boolean;
}

type DeliveryData = {
    customer_id: string;
    deliveries: {
        delivery_uuid: string;
        delivery_name: string;
        start_date: string;
        ends_at: string;
        packages: {
            package_uuid: string;
            package_title: string;
            package_recurrence: string;
            package_key_str: string;
            assignments: AssignmentData[]
        }[]
    }[],
    assignments: AssignmentData[]
}

export function CustomerSimulationDeliveriesPage() {
    const [fixData, setFixData] = useState(false);
    const [repair, setRepair] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deliveriesData, setDeliveriesData] = useState<DeliveryData>();
    const [fixedDeliveriesData, setFixedDeliveriesData] = useState<DeliveryData>();
    const {customerId} = useParams<CustomerDetailsParams>();
    const navigate = useNavigate();

    const customers = useSelector((state: RootState) => state.customers.data);
    const customer = customers.find((e) => e.customer.customer_number === customerId)?.customer;

    const runSimulation = useCallback(async () => {
        if (customer && customerId) {
            setIsLoading(true)
            const token = await authMethod.getStoredAccessToken();
            try {
                const response = await ActivitiesAPI.runDeliverySimulations(token, [customerId], fixData, !repair);
                if (fixData) {
                    setFixedDeliveriesData(response.data[customerId])
                } else {
                    setDeliveriesData(response.data[customerId])
                }
                setIsLoading(false)
            } catch (err) {
                setIsLoading(false)
                if (fixData) {
                    setFixedDeliveriesData(undefined)
                } else {
                    setDeliveriesData(undefined)
                }
            }
        }
    }, [fixData, customer, customerId, repair]);

    if (!customer || !customerId) {
        return <div>There is a problem with that customer</div>
    }

    const getTotalActivities = (data?: DeliveryData) => {
        if (!data) {
            return 0;
        }

        const { deliveries, assignments } = data;
        let total = 0;

        deliveries.forEach((delivery) => {
            delivery.packages?.forEach((p) => {
                p.assignments?.forEach(assignment => {
                    total = total + assignment.activities?.length;
                })
            })
        })

        assignments.forEach(assignment => {
            total = total + assignment.activities?.length;
        });

        return total;
    }

    const renderAssignmentsData = (assignments: AssignmentData[]) => {
        return (
            <Tree aria-label="Simulation">
                {assignments
                    .sort((a, b) => (a.activity_name > b.activity_name) ? 1 : ((b.activity_name > a.activity_name) ? -1 : 0))
                    .map(a => {
                        return (
                            <TreeItem itemType="branch">
                                <TreeItemLayout><b>{a.activity_name}</b>
                                    <span className="text-muted" style={{fontSize: '10px'}}> {a.activity_type} [{a.recurrence}]</span>
                                    {a.onetime && <span className="text-color-blue" style={{fontSize: '10px'}}> Onetime</span> }
                                </TreeItemLayout>
                                <Tree aria-label="Simulation">
                                    {a.activities
                                        .sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
                                        .map(deadline => {
                                        return (
                                            <TreeItem itemType="leaf">
                                                <TreeItemLayout>{deadline}</TreeItemLayout>
                                            </TreeItem>
                                        )
                                    })}
                                </Tree>
                            </TreeItem>
                        )
                    })}
            </Tree>
        )
    }

    const renderDeliveryData = (data?: DeliveryData) => {
        if (!data) {
            return 'No simulation yet'
        }

        return (<Tree aria-label="Simulation">
            {data.deliveries
                .sort((a, b) => (a.delivery_name > b.delivery_name) ? 1 : ((b.delivery_name > a.delivery_name) ? -1 : 0))
                .map((delivery) => {
                return (
                    <TreeItem itemType="branch">
                        <TreeItemLayout>
                            <span><b>{delivery.delivery_name}</b></span>
                            <span style={{fontSize: '10px'}}> Start date: {delivery.start_date}</span>
                            <span style={{fontSize: '10px'}}> Ends at: {delivery.ends_at}</span>
                        </TreeItemLayout>
                        <Tree aria-label="Simulation">
                            { delivery.packages.map(p => {
                                return (
                                    <TreeItem itemType="branch">
                                        <TreeItemLayout>{p.package_title} - {p.package_key_str} [{p.package_recurrence}]</TreeItemLayout>
                                        <Tree aria-label="Simulation">
                                            {renderAssignmentsData(p.assignments)}
                                        </Tree>
                                    </TreeItem>
                                )
                            }) }
                        </Tree>
                    </TreeItem>
                )
            })}
        </Tree>)
    }

    const mapActivities = (data: DeliveryData) => {
        const originalData = new Map<string, string[]>();
        data.deliveries.forEach(d => {
            d.packages.forEach((p) => {
                p.assignments.forEach((ass) => {
                    const key = ass.activity_type+(ass.type_ext ? ass.type_ext : '');
                    if (!originalData.has(key)) {
                        originalData.set(key, [])
                    }

                    const d = originalData.get(key) as string[];
                    originalData.set(key, d.concat(ass.activities));
                });
            });
        });

        data.assignments.forEach((ass) => {
            const key = ass.activity_type+(ass.type_ext ? ass.type_ext : '');
            if (!originalData.has(key)) {
                originalData.set(key, [])
            }

            const d = originalData.get(key) as string[];
            originalData.set(key, d.concat(ass.activities))

        })

        return originalData;
    }

    const getDifference = () => {
        if (!deliveriesData || !fixedDeliveriesData) {
            return undefined;
        }

        const originalData = mapActivities(deliveriesData)
        const fixedData = mapActivities(fixedDeliveriesData);

        const missing = new Map<string, string[]>();
        const extra = new Map<string, string[]>();

        originalData.forEach((originalDeadlines, key) => {
           if (fixedData.has(key)) {
               const fixedDeadlines = fixedData.get(key) as string[];
               fixedDeadlines.forEach((v) => {
                    if (!originalDeadlines.includes(v))  {
                        if (!extra.has(key)) {
                           extra.set(key, [v])
                        } else {
                            const e = extra.get(key) as string[];
                            extra.set(key, e.concat([v]))
                        }
                    }
               });
           } else {
               missing.set(key, originalDeadlines);
           }
        });

        fixedData.forEach((fixedDeadlines, key) => {
           if (originalData.has(key)) {
               const originalDeadlines = originalData.get(key) as string[];
               originalDeadlines.forEach((v) => {
                    if (!fixedDeadlines.includes(v))  {
                        if (!missing.has(key)) {
                           missing.set(key, [v])
                        } else {
                            const e = missing.get(key) as string[];
                            missing.set(key, e.concat([v]))
                        }
                    }
               });
           } else {
               if (!extra.has(key)) {
                   extra.set(key, fixedDeadlines)
               } else {
                   const e = extra.get(key) as string[];
                   extra.set(key, e.concat(fixedDeadlines))
               }
           }
        });

        return <div>
            <p>Missing activities:</p>
            <ul>
                {Array.from(missing).map(([key, values]) => {
                    return <li>
                        <span><b>{key}:</b></span>
                        <span className="p-lg-2">{values.join(', ')}</span>
                    </li>
                })}
            </ul>
            <p>Extra activities:</p>
            <ul>
                {Array.from(extra).map(([key, values]) => {
                    return <li>
                        <span><b>{key}:</b></span>
                        <span className="p-lg-2">{values.join(', ')}</span>
                    </li>
                })}
            </ul>
        </div>
    }

    const toggleRepair = () => {
        if (!repair) {
            setFixData(true)
        }

        setRepair(!repair)
    }

    return (<>
            <LayoutHeader>
                <LayoutHeaderLeft>
                <div className="d-flex  align-items-center justify-content-between">
                        <div className="pr-md">
                            <h1>Simulation of deliveries page: {customer.name}</h1>
                        </div>
                    </div>
                </LayoutHeaderLeft>
            </LayoutHeader>
            <div className="full-width">
                <FluentUICheckbox checked={fixData} disabled={isLoading || repair} onChange={() => {
                    setFixData(!fixData)
                }} className="ml-xxs" label="Fix data"/>
                <FluentUICheckbox checked={repair} disabled={isLoading} onChange={() => toggleRepair()} className="ml-xxs" label="Repair"/>
                <Button onClick={() => runSimulation()} disabled={isLoading}>{repair ? 'Repair (irreversible)' : 'Run simulation'}</Button>
                <br />
                <br />
                <Button onClick={() => {
                    navigate({pathname: AppRouteHelper.getCustomerDeliveryPlanner(customerId)})
                }}>Delivery Planner</Button>
            </div>
            <div className="d-flex  align-items-start justify-content-evenly mt-5">
                <div className="">
                    <h2>Original data</h2>
                    <h3>Total activities: {getTotalActivities(deliveriesData)} </h3>
                    <hr/>
                    <h3>Deliveries:</h3>
                    {renderDeliveryData(deliveriesData)}
                    <hr/>
                    <h3>Optional activities (assignment without a delivery):</h3>
                    {deliveriesData && renderAssignmentsData(deliveriesData.assignments)}
                </div>
                <div className="">
                    <h2>Fixed data</h2>
                    <h3>Total activities: {getTotalActivities(fixedDeliveriesData)} </h3>
                    {getDifference()}
                    <hr/>
                    <h3>Deliveries:</h3>
                    {renderDeliveryData(fixedDeliveriesData)}
                    <hr/>
                    <h3>Optional activities (assignment without a delivery):</h3>
                    {fixedDeliveriesData && renderAssignmentsData(fixedDeliveriesData.assignments)}
                </div>
            </div>

        </>
    );
}