import { useEffect, useMemo, useState } from "react";
import { ArrowLeft20Regular, ArrowRight20Regular } from "@fluentui/react-icons";
import { useForm } from "react-hook-form";

import AppConfig from "app-config";
import { useTranslation } from "hooks";
import { useGetCompanyTypesQuery } from "api/ludvigApi";
import { Deal } from "models/deals/deal";
import { Stage } from "models/deals/stage";
import { CompanyInformation } from "models/offer/Company";
import Modal, { ModalStepsConfig } from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";
import ElementLoadingOverlay from "components/overlay/ElementLoadingOverlay/ElementLoadingOverlay";
import { DealInformationRequiredCompanyForm } from "views/myBusinessOpportunities/components/stages/components/DealInformationRequiredCompanyForm";
import { DealInformationRequiredForm } from "views/myBusinessOpportunities/components/stages/components/DealInformationRequiredForm";
import { useUpdateDealProperty } from "hooks/myBusinessOpportunities/use-update-deal-property";

type Props = {
  onFinished: () => void;
  onDismiss: () => void;
  deal?: Deal;
  toStage?: Stage;
  isOpen: boolean;
};

export function DealInformationRequiredFormTwoStepModal({
  onFinished,
  onDismiss,
  deal,
  toStage,
  isOpen,
}: Props) {
  const { translate } = useTranslation();
  const { handleFormSubmit, isLoading } = useUpdateDealProperty();
  const { data: companyTypeList } = useGetCompanyTypesQuery();

  const [stepsConfig, setStepsConfig] = useState<ModalStepsConfig>({
    activeStep: 1,
    stepsCount: 2,
  });

  const informationRequiredMethods = useForm<Partial<Deal>>({
    mode: "onTouched",
    defaultValues: useMemo(() => {
      return deal;
    }, [deal]),
  });

  const companyFormMethods = useForm<Partial<CompanyInformation>>({
    mode: "onTouched",
    defaultValues: {
      name: deal?.company,
      org_number: deal?.organisationsnummer,
      zip: deal?.postnummer,
      city: deal?.postal_city,
      address: deal?.postal_address,
    },
  });

  const company = companyFormMethods.watch();

  useEffect(() => {
    if (deal) {
      informationRequiredMethods.reset(deal);
    }
  }, [deal, informationRequiredMethods]);

  if (!toStage) {
    return <></>;
  }

  if (!deal) {
    return <></>;
  }

  const stageConfiguration = toStage.configuration;
  if (!stageConfiguration) {
    throw new Error(`Could not find stage configuration`);
  }

  const disableSave =
    stepsConfig.activeStep === 1
      ? isLoading || !informationRequiredMethods.formState.isValid
      : isLoading || !companyFormMethods.formState.isValid;

  const informationRequiredformValues = informationRequiredMethods.watch();
  const dirtyFields = Object.keys(
    informationRequiredMethods.formState.dirtyFields
  );

  const requiredFields = toStage.configuration?.RequiredFields.split(";");
  const handleOnSave = async () => {
    if (stepsConfig.activeStep === 1) {
      setStepsConfig({ ...stepsConfig, activeStep: 2 });
    } else {
      const companyName =
        deal.company ?? `${deal.contact.firstname} ${deal.contact.lastname}`;
      const hubspotCompany = { ...company, name: companyName };

      await handleFormSubmit(
        informationRequiredformValues,
        requiredFields ?? dirtyFields,
        deal,
        hubspotCompany
      );
      if (toStage.configuration && stageConfiguration.onMove) {
        stageConfiguration.onMove(deal);
      }
      onFinished();
    }
  };

  const handleCloseModal = () => {
    if (stepsConfig.activeStep === 2) {
      setStepsConfig({ ...stepsConfig, activeStep: 1 });
    } else {
      return onDismiss();
    }
  };

  return (
    <Modal
      size="medium"
      stepsConfig={stepsConfig}
      header={
        <ModalHeader
          headerTitleText={translate("DEAL_REQUIRED_FIELDS_DIALOG.HEADER", [
            deal.dealname,
            toStage.label,
          ])}
          subheaderContent={
            <div
              className="pl-sm contentSubHeader"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={
                stepsConfig.activeStep === 1
                  ? {
                      __html: translate(
                        "DEAL_REQUIRED_FIELDS_DIALOG.SUBHEADER",
                        [deal.dealname]
                      ),
                    }
                  : {
                      __html: translate(
                        "DEAL_REQUIRED_FIELDS_DIALOG.SUBHEADER_COMPANY"
                      ),
                    }
              }
            />
          }
        />
      }
      onDismiss={() => {
        informationRequiredMethods.reset();
        onDismiss();
      }}
      isOpen={isOpen}
      footer={
        <ModalFooter
          isDisabled={disableSave}
          onCancel={() => handleCloseModal()}
          onSave={() => handleOnSave()}
          sendButtonIcon={
            stepsConfig.activeStep === 1 && <ArrowRight20Regular />
          }
          cancelButtonIcon={
            stepsConfig.activeStep === 2 && <ArrowLeft20Regular />
          }
          labelSubmit={
            stepsConfig.activeStep === 1
              ? "DEAL_REQUIRED_FIELDS_DIALOG.PROCEED"
              : "DEAL_REQUIRED_FIELDS_DIALOG.SUBMIT"
          }
          labelCancel={
            stepsConfig.activeStep === 1
              ? "CANCEL"
              : "DEAL_REQUIRED_FIELDS_DIALOG.GO_BACK"
          }
        />
      }
    >
      {stepsConfig.activeStep === 1 && (
        <DealInformationRequiredForm
          deal={deal}
          toStage={toStage}
          formMethods={informationRequiredMethods}
        />
      )}

      {stepsConfig.activeStep === 2 &&
        (!companyTypeList ? (
          <ElementLoadingOverlay />
        ) : (
          deal &&
          toStage.configuration?.StageId ===
            AppConfig.FEATURES.DEALS.DEAL_STAGE_WON && (
            <DealInformationRequiredCompanyForm
              formMethods={companyFormMethods}
              companyTypeList={companyTypeList}
              orgNum={deal?.organisationsnummer}
            />
          )
        ))}
    </Modal>
  );
}
