export default class DealContactDetails {
  constructor(data?: Partial<DealContactDetails>) {
    this.id = data?.id ?? "";
    this.social_security_number = data?.social_security_number ?? "";
    this.first_name = data?.first_name ?? "";
    this.last_name = data?.last_name ?? "";
    this.email = data?.email ?? "";
    this.mobile_phone = data?.mobile_phone ?? "";
    this.phone_number = data?.phone_number ?? "";
    this.changed_by_username = data?.changed_by_username ?? "";
    this.created_by_username = data?.created_by_username ?? "";
  }

  /**
   * HubSpot contact ID
   */
  id: string;

  /**
   * Social security number
   */
  social_security_number: string;

  /**
   * First name
   */
  first_name: string;

  /**
   * Last name
   */
  last_name: string;

  /**
   * Email address
   */
  email: string;

  /**
   * Mobile phone number
   */
  mobile_phone: string;

  /**
   * Phone number (only stored in HS)
   */
  phone_number?: string;

  /**
   * Email of the employee that created the contact
   */
  changed_by_username: string;

  /**
   * Email of the employee that last changed the contact
   */
  created_by_username: string;
}
