import { SalesAPI } from "api/deals";
import { CustomersAPI } from "api/customers";
import { OrganizationAPI } from "api/organization";
import { authMethod } from "auth";
import { mapSearchToDealContact } from "helpers/SearchToDealContact";
import { Debounce } from "libs/debounce";
import { normalizeOrgNrAndSSN } from "libs/number-format";
import { timeout } from "libs/timeout-promise";
import { wrapRetryAsync } from "libs/wrap-retry";
import { sortDealsByEnteredCurrentStageDate } from "libs/sort-deals-by-date";
import {
  CreateDeal,
  Deal,
  DealContact,
  DealOption,
  DynamicPropertyOption,
  SearchContact,
} from "models/deals/deal";
import { DealReturnProps } from "models/deals/dealReturnProps";
import { Pipeline } from "models/deals/pipeline";
import { Product } from "models/deals/product";
import { Office } from "models/organization";
import { StageConfiguration } from "models/deals/stagesConfig";
import { DynamicDealPropertiesObjectName, DynamicDealPropertiesPropertyName } from "constants/enums/dealEnums";
import {
  APPEND_DEAL,
  CREATE_NEW_CONTACT,
  DELETE_CONTACT,
  REMOVE_DEAL,
  RESET_CURRENT_DEAL,
  RESET_CUSTOMER_CONTACTS,
  SET_CURRENT_DEAL,
  SET_CUSTOMER_CONTACTS,
  SET_CUSTOMER_CONTACTS_LOADING,
  SET_DEALS,
  SET_DEALS_BY_ORG_NUMBER,
  SET_DEALS_BY_ORG_NUMBER_HAS_FAILED,
  SET_DEAL_PROPERTIES,
  SET_DYNAMIC_DEAL_PROPERTIES,
  SET_HAS_FAILED,
  SET_IS_NEW_DEAL_CUSTOMER,
  SET_LOADING_DEALS,
  SET_LOADING_DEALS_BY_ORG_NUMBER,
  SET_LOADING_DEAL_OPTIONS,
  SET_LOADING_PIPELINES,
  SET_LOADING_PRODUCTS,
  SET_PIPELINES,
  SET_PRODUCTS,
  SET_STAGE_CONFIGURATION,
  SalesAction,
  SetDynamicDealPropertiesAction,
  UPDATE_CONTACT,
  UPDATE_DEAL,
  UPDATE_DEAL_CONTACT,
} from ".";
import { MyThunkAction } from "..";
import { appendError, appendToastMessage } from "../notifications";

const debounceUpdate = new Debounce(1500);

export function fetchBusinessOpportunities(): MyThunkAction<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch(setLoadingDeals(true));
      return dispatch(fetchDeals());
    } catch (e) {
      dispatch(
        appendError("FAILED_TO_FETCH_BUSINESS_OPPORTUNITIES", e as Error)
      );
      dispatch(setHasFailed(true));
    }
  };
}

export function fetchDealProperties(): MyThunkAction {
  return async (dispatch) => {
    dispatch(setLoadingDealOptions(true));
    try {
      const properties = await SalesAPI.fetchDealsProperties();
      dispatch(setDealProperties(properties));
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_DEALS", e as Error));
      throw e;
    } finally {
      dispatch(setLoadingDealOptions(false));
    }
  };
}

export function fetchPropertiesByObjectAndPropertyName(
  objectName: DynamicDealPropertiesObjectName,
  propertyName: DynamicDealPropertiesPropertyName
): MyThunkAction {
  return async (dispatch) => {
    try {
      const properties = await SalesAPI.fetchPropertiesByObjectAndPropertyName(
        objectName,
        propertyName
      );
      dispatch(setDynamicDealProperties(objectName, propertyName, properties));
    } catch (e) {
      dispatch(
        appendError("FAILED_TO_FETCH_DEALS_PROPERTIES", {
          cause: `objectName: ${objectName}, propertyName: ${propertyName}`,
        } as unknown as Error)
      );
      throw e;
    }
  };
}

export function fetchDeals(): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setDeals([]));
      dispatch(setLoadingDeals(true));
      const token = await authMethod.getStoredAccessToken();
      const deals = await SalesAPI.fetchDeals(token);
      dispatch(setDeals(sortDealsByEnteredCurrentStageDate(deals)));
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_DEALS", e as Error));
      throw e;
    } finally {
      dispatch(setLoadingDeals(false));
    }
  };
}

export function fetchDealById(dealId: string): MyThunkAction<Promise<Deal>> {
  return async (dispatch) => {
    try {
      dispatch(setLoadingDeals(true));
      const token = await authMethod.getStoredAccessToken();
      const deal = await SalesAPI.fetchDealById(token, dealId);
      return deal;
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_DEAL", e as Error));
      throw e;
    } finally {
      dispatch(setLoadingDeals(false));
    }
  };
}

// This function always forces for the association to be sent
// as false towards SalesAPI.fetchDealsByOrgNumber, as this is
// the only use-case for this function in the application at
// the moment. Should there be a need for the association to be
// true, this function should be updated to accept a parameter
// for the association and pass it along.
export function fetchDealsByOrgNumber(orgNumber: string): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setDealsByOrgNumber([]));
      dispatch(setLoadingDealsByOrgNumber(true));
      const token = await authMethod.getStoredAccessToken();
      const deals = await SalesAPI.fetchDealsByOrgNumber(
        token,
        orgNumber,
        false
      );

      dispatch(setDealsByOrgNumber(deals));
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_DEALS", e as Error));
      throw e;
    } finally {
      dispatch(setLoadingDealsByOrgNumber(false));
    }
  };
}

export function fetchPipelines(): MyThunkAction {
  return async (dispatch) => {
    dispatch(setLoadingPipelines(true));
    try {
      const token = await authMethod.getStoredAccessToken();
      await dispatch(fetchHubspotStagesConfiguration());
      const pipelines = await SalesAPI.fetchPipelines(token);
      dispatch(setPipelines(pipelines));
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_PIPELINES", e as Error));
      throw e;
    } finally {
      dispatch(setLoadingPipelines(false));
    }
  };
}

export function fetchProducts(): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setLoadingProducts(true));
      const token = await authMethod.getStoredAccessToken();
      const products = await SalesAPI.fetchProducts(token);
      dispatch(setProducts(products));
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_PRODUCTS", e as Error));
      throw e;
    } finally {
      dispatch(setLoadingProducts(false));
    }
  };
}

export function createNewDeal(deal: Partial<CreateDeal>): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setLoadingDeals(true));
      const token = await authMethod.getStoredAccessToken();
      const retryCreate = wrapRetryAsync<void>(
        () => SalesAPI.createDeal(token, deal),
        {
          attemptDelay: () => 1000,
          attemptsLimit: 3,
        }
      );
      await retryCreate();
    } catch (e) {
      dispatch(appendError("FAILED_TO_CREATE_DEAL", e as Error));
      throw e;
    } finally {
      dispatch(setLoadingDeals(false));
      dispatch(fetchDeals());
    }
  };
}

export function updateContact(
  contactId: string,
  update: Partial<SearchContact>
): MyThunkAction<Promise<DealContact>> {
  return async (dispatch) => {
    try {
      dispatch(setLoadingDeals(true));
      const token = await authMethod.getStoredAccessToken();
      const updatedContact = await SalesAPI.updateContact(
        token,
        contactId,
        update
      );
      const dealContact = mapSearchToDealContact(updatedContact);
      dispatch(setUpdateContact(dealContact));
      return dealContact;
    } catch (e) {
      dispatch(appendError("FAILED_TO_CREATE_DEAL", e as Error));
      throw e;
    } finally {
      dispatch(setLoadingDeals(false));
    }
  };
}

export function deleteContact(
  contactEmail: string,
  customerNumber: string,
  showToastMessage: boolean
): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setLoadingDeals(true));
      const token = await authMethod.getStoredAccessToken();
      await CustomersAPI.deleteCustomerContact(
        token,
        contactEmail,
        customerNumber
      );
      removeDeletedContact(contactEmail);
      if (showToastMessage) {
        dispatch(appendToastMessage("DELETE_CS_CONTACT_SUCCESS", "success"));
      }
    } catch (e) {
      dispatch(appendError("UNKNOWN_ERROR", e as Error));
      throw e;
    } finally {
      dispatch(setLoadingDeals(false));
    }
  };
}

export function createNewContact(
  contact: Partial<SearchContact>,
  shouldAssociateWithCompany = false
): MyThunkAction<Promise<DealContact>> {
  return async (dispatch) => {
    try {
      dispatch(setLoadingDeals(true));

      const token = await authMethod.getStoredAccessToken();
      const newContact = await SalesAPI.createContact(
        token,
        contact,
        shouldAssociateWithCompany
          ? normalizeOrgNrAndSSN(contact.organization)
          : undefined
      );

      // Delaying the response due to Hubspot API having some delays when creating contacts
      // https://community.hubspot.com/t5/APIs-Integrations/Hubspot-contacts-creation-taking-time-irrespective-of-api/m-p/374471
      timeout(5000);
      const newDealContact = mapSearchToDealContact(newContact);
      dispatch(setCreateNewContact(newDealContact));
      return newDealContact;
    } catch (e) {
      dispatch(appendError("FAILED_TO_CREATE_DEAL", e as Error));
      throw e;
    } finally {
      dispatch(setLoadingDeals(false));
    }
  };
}

export function createUpdateContact(
  contact: Partial<SearchContact>
): MyThunkAction<Promise<DealContact | undefined>> {
  return async (dispatch) => {
    try {
      let contactReturned: DealContact | undefined;
      if (!contact.id) {
        contactReturned = await dispatch(createNewContact(contact));
      } else {
        contactReturned =
          (contact.id &&
            (await dispatch(updateContact(contact.id, contact)))) ||
          undefined;
      }
      return contactReturned;
    } catch (e) {
      dispatch(appendError("FAILED_TO_CREATE_OR_UPDATE_CONTACT", e as Error));
    }
  };
}

export function moveDealToStage(
  dealId: string,
  stageId: string
): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setLoadingDeals(true));

      dispatch(
        updateDeal(dealId, {
          updatedAt: new Date(),
          entered_current_stage_date: new Date(),
          dealstage: stageId,
        })
      );

      const token = await authMethod.getStoredAccessToken();
      await SalesAPI.updateDeal(token, dealId, {
        dealstage: stageId,
      });
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_DEAL", e as Error));
    } finally {
      dispatch(setLoadingDeals(false));
    }
  };
}

export function returnDealToManager(
  deal: Deal,
  returnProps: DealReturnProps
): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setLoadingDeals(true));
      dispatch(removeDeal(deal));

      const token = await authMethod.getStoredAccessToken();
      await SalesAPI.returnDeal(token, deal.id, returnProps);
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_DEAL", e as Error));
    } finally {
      dispatch(setLoadingDeals(false));
    }
  };
}

export function updateDealProducts(
  dealId: string,
  productIds: string[],
  calculateTotalPrice = true
): MyThunkAction {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingDeals(true));

      if (calculateTotalPrice) {
        const products = getState().sales.products.data.filter((p) =>
          productIds.includes(p.id)
        );
        const totalPrice = products.reduce((sum, curr) => sum + curr.price, 0);

        dispatch(updateDeal(dealId, { amount: totalPrice, productIds }));
      } else {
        dispatch(updateDeal(dealId, { productIds }));
      }

      const token = await authMethod.getStoredAccessToken();
      await SalesAPI.updateProducts(token, dealId, productIds);
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_DEAL", e as Error));
    } finally {
      dispatch(setLoadingDeals(false));
    }
  };
}

export function updateDealValues(
  dealId: string,
  update: Partial<Deal>
): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setLoadingDeals(true));
      const token = await authMethod.getStoredAccessToken();
      await SalesAPI.updateDeal(token, dealId, update);
      dispatch(updateDeal(dealId, update));
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_DEAL", e as Error));
    } finally {
      dispatch(setLoadingDeals(false));
    }
  };
}

export function updateDealDebounceNetwork(
  dealId: string,
  change: Partial<Deal>
): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(updateDeal(dealId, change));
      const token = await authMethod.getStoredAccessToken();
      await debounceUpdate.fire(async () => {
        dispatch(setLoadingDeals(true));
        return SalesAPI.updateDeal(token, dealId, change);
      });
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_DEAL", e as Error));
    } finally {
      dispatch(setLoadingDeals(false));
    }
  };
}

export function updateDealContactDebounceNetwork(
  dealId: string,
  contact: DealContact,
  change: Partial<DealContact>
): MyThunkAction {
  return async (dispatch) => {
    try {
      await debounceUpdate.fire(async () => {
        const contactId = contact.id;
        if (contactId !== undefined) {
          const token = await authMethod.getStoredAccessToken();
          dispatch(setLoadingDeals(true));
          return SalesAPI.updateContact(token, contactId, change);
        }

        const createdContact = await dispatch(createNewContact(contact));
        dispatch(updateDealContact(dealId, createdContact));
      });
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_CONTACT", e as Error));
    } finally {
      dispatch(setLoadingDeals(false));
    }
  };
}

export function updateDealResponsible(
  dealId: string,
  userEmail: string
): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setLoadingDeals(true));
      const token = await authMethod.getStoredAccessToken();
      await SalesAPI.updateDeal(token, dealId, {
        owner: userEmail,
      });

      dispatch(updateDeal(dealId, { owner: userEmail }));
    } catch (e) {
      dispatch(appendError("FAILED_TO_UPDATE_DEAL", e as Error));
    } finally {
      dispatch(setLoadingDeals(false));
    }
  };
}

export function searchContact(
  email: string
): MyThunkAction<Promise<SearchContact[] | undefined>> {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const contact = await SalesAPI.searchContact(token, email);
      return contact;
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_CONTACTS", e as Error));
    }
  };
}

export function fetchOffices(): MyThunkAction<Promise<Office[] | undefined>> {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const offices = await OrganizationAPI.fetchOffices(token);
      const sortedOffices = offices.sort((a, b) => (a.name < b.name ? -1 : 1));
      return sortedOffices;
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_OFFICES", e as Error));
    }
  };
}

export function fetchHubspotStagesConfiguration(): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const stages = await SalesAPI.getHubspotStagesList(token);
      dispatch(setStagesConfiguration(stages));
    } catch (e) {
      dispatch(appendError("FAILED_TO_GET_STAGES_CONFIGURATION", e as Error));
    }
  };
}

export function fetchCustomerContactsByOrgNr(orgNum: string): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      dispatch(setCustomerContactsLoading(true));
      const contacts = await SalesAPI.getCustomerContactsByOrgNumber(
        token,
        orgNum
      );
      dispatch(setCustomerContacts(contacts));
    } catch (e) {
      dispatch(setCustomerContactsLoading(false));
      dispatch(appendError("FAILED_TO_GET_CUSTOMER_CONTACTS", e as Error));
    }
  };
}

export function fetchOrganisationInMaconomyData(): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const data = await OrganizationAPI.fetchOrganisationInMaconomy(token);
      return data;
    } catch (e) {
      dispatch(
        appendError("FAILED_TO_FETCH_ORGANISATION_IN_MACONOMY_DATA", e as Error)
      );
    }
  };
}

export function setHasFailed(hasFailed: boolean): SalesAction {
  return {
    type: SET_HAS_FAILED,
    payload: hasFailed,
  };
}

export function setDealsByOrgNumberHasFailed(hasFailed: boolean): SalesAction {
  return {
    type: SET_DEALS_BY_ORG_NUMBER_HAS_FAILED,
    payload: hasFailed,
  };
}

export function setLoadingDeals(isLoading: boolean): SalesAction {
  return {
    type: SET_LOADING_DEALS,
    payload: isLoading,
  };
}

export function setLoadingPipelines(isLoading: boolean): SalesAction {
  return {
    type: SET_LOADING_PIPELINES,
    payload: isLoading,
  };
}

export function setLoadingProducts(isLoading: boolean): SalesAction {
  return {
    type: SET_LOADING_PRODUCTS,
    payload: isLoading,
  };
}

export function setLoadingDealOptions(isLoading: boolean): SalesAction {
  return {
    type: SET_LOADING_DEAL_OPTIONS,
    payload: isLoading,
  };
}

export function setLoadingDealsByOrgNumber(isLoading: boolean): SalesAction {
  return {
    type: SET_LOADING_DEALS_BY_ORG_NUMBER,
    payload: isLoading,
  };
}

export function appendDeal(deal: Deal): SalesAction {
  return {
    type: APPEND_DEAL,
    payload: deal,
  };
}

export function removeDeal(deal: Deal): SalesAction {
  return {
    type: REMOVE_DEAL,
    payload: deal,
  };
}

export function setStagesConfiguration(
  stageConfiguration: StageConfiguration[]
): SalesAction {
  return {
    type: SET_STAGE_CONFIGURATION,
    payload: stageConfiguration,
  };
}

export function setDealProperties(dealOptions: DealOption[]): SalesAction {
  return {
    type: SET_DEAL_PROPERTIES,
    payload: dealOptions,
  };
}

export function setDeals(deals: Deal[]): SalesAction {
  return {
    type: SET_DEALS,
    payload: deals,
  };
}

export function setDealsByOrgNumber(deals: Deal[]): SalesAction {
  return {
    type: SET_DEALS_BY_ORG_NUMBER,
    payload: deals,
  };
}

export function setPipelines(pipelines: Pipeline[]): SalesAction {
  return {
    type: SET_PIPELINES,
    payload: pipelines,
  };
}

export function setProducts(products: Product[]): SalesAction {
  return {
    type: SET_PRODUCTS,
    payload: products,
  };
}

export function updateDeal(id: string, update: Partial<Deal>): SalesAction {
  return {
    type: UPDATE_DEAL,
    payload: {
      id,
      update,
    },
  };
}

export function updateDealContact(
  id: string,
  update: Partial<DealContact>
): SalesAction {
  return {
    type: UPDATE_DEAL_CONTACT,
    payload: {
      id,
      update,
    },
  };
}

export function setCurrentDeal(deal: Partial<Deal>): SalesAction {
  return {
    type: SET_CURRENT_DEAL,
    payload: {
      deal,
    },
  };
}

export function resetCurrentDeal(): SalesAction {
  return {
    type: RESET_CURRENT_DEAL,
  };
}

export function setCustomerContacts(contacts: DealContact[]): SalesAction {
  return {
    type: SET_CUSTOMER_CONTACTS,
    payload: contacts,
  };
}

export function resetCustomerContacts(): SalesAction {
  return {
    type: RESET_CUSTOMER_CONTACTS,
  };
}

export function setCustomerContactsLoading(isLoading: boolean): SalesAction {
  return {
    type: SET_CUSTOMER_CONTACTS_LOADING,
    payload: isLoading,
  };
}

export function setCreateNewContact(contact: DealContact): SalesAction {
  return {
    type: CREATE_NEW_CONTACT,
    payload: contact,
  };
}

export function setUpdateContact(contact: Partial<DealContact>): SalesAction {
  return {
    type: UPDATE_CONTACT,
    payload: contact,
  };
}

export function removeDeletedContact(contactEmail: string): SalesAction {
  return {
    type: DELETE_CONTACT,
    payload: { contactEmail },
  };
}

export function setIsNewDealCustomer(isNewCustomer: boolean): SalesAction {
  return {
    type: SET_IS_NEW_DEAL_CUSTOMER,
    payload: isNewCustomer,
  };
}

export function setDynamicDealProperties(
  objectName: string,
  propertyName: string,
  properties: DynamicPropertyOption[]
): SetDynamicDealPropertiesAction {
  return {
    type: SET_DYNAMIC_DEAL_PROPERTIES,
    payload: {
      objectName,
      propertyName,
      properties,
    },
  };
}
