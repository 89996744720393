import {
  ArrowLeft20Regular,
  Checkmark20Regular,
  Warning20Regular,
} from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@fluentui/react-components";

import { RHFInput } from "components/input/RHFInput";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { SpinnerSize } from "components/spinner";
import Switch from "components/switch";
import VerticalStepper from "components/verticalStepper";
import {
  MAX_CHAR_FIRST_NAME,
  MAX_CHAR_LAST_NAME,
} from "constants/maxCharConsts";
import { useTranslation } from "hooks/use-translate";
import {
  generatePlaceholderEmail,
  isPlaceholderEmail,
} from "libs/generate-placeholder-email";
import { isValidEmail } from "libs/is-valid-email";
import { isValidPhone } from "libs/is-valid-phone";
import { getDigitsFromString } from "libs/number-format";
import { CustomerSettingsContact } from "models/customerSettings/customerSettingsContact";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { RootState } from "state";
import { resetOpenCreateModalFor } from "state/customerSettings/customerSettingsSlice";
import {
  createCustomerContactCallerWrapper,
  deleteCustomerContactCallerWrapper,
  updateCustomerContactCallerWrapper,
} from "state/customerSettings/customerSettingsThunk";
import { searchContact } from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";

type Props = {
  openModal: boolean;
  setOpenModal: (arg: boolean) => void;
  newContact?: boolean;
  orgNum: string;
  customerNumber: string;
  contact?: CustomerSettingsContact;
};
export default function CustomerPageContactModal({
  openModal,
  setOpenModal,
  newContact = false,
  orgNum,
  customerNumber,
  contact,
}: Props) {
  const dispatch: AppDispatch = useDispatch();
  const { translate } = useTranslation();
  const { data: contacts, status: isLoading } = useSelector(
    (state: RootState) => state.settings.contacts
  );

  const {
    register,
    formState: { errors, isValid },
    setValue,
    trigger,
    reset,
    getValues,
  } = useForm<CustomerSettingsContact>({
    mode: "onTouched",
    defaultValues: {
      id: contact && contact.id,
      firstname: contact && contact.firstname,
      lastname: contact && contact.lastname,
      mobilephone:
        contact && contact.mobilephone !== "Mobiltelefon saknas"
          ? contact.mobilephone
          : "",
      phone: contact && contact.phone,
      email: contact && contact.email,
    },
  });

  const emailValue = getValues("email");

  const initContactFormState = {
    isEnteringEmail: false,
    isLoadingContact: false,
    isLoadingContacts: false,
    isExistingContact: false,
    isEmailFilled: false,
    hasPlaceholderEmail: isPlaceholderEmail(emailValue),
  };

  const [contactFormState, setContactFormState] =
    useState(initContactFormState);

  const {
    isEnteringEmail,
    isLoadingContact,
    isExistingContact,
    isLoadingContacts,
    isEmailFilled,
    hasPlaceholderEmail,
  } = contactFormState;

  const [closeModal, setCloseModal] = useState(false);
  const [existingContactError, setExistingContactError] = useState("");

  useEffect(() => {
    if (isLoading !== LoadingStatusEnum.PENDING && closeModal) {
      setOpenModal(false);
    }
  }, [isLoading, closeModal, setOpenModal]);

  const title = newContact
    ? translate("CREATE_CS_CONTACT_TITLE")
    : translate("UPDATE_CS_CONTACT_TITLE");

  const firstNameOptions = register("firstname", {
    required: translate("FIRSTNAME_REQUIRED"),
  });

  const lastNameOptions = register("lastname", {
    required: translate("LASTNAME_REQUIRED"),
  });

  const emailOptions = register("email", {
    required: translate("EMAIL_REQUIRED"),
    validate: {
      isValid: (value) => {
        return isValidEmail(value) || translate("EMAIL_VALIDATION_MESSAGE");
      },
    },
  });

  const mobilePhoneOptions = register("mobilephone", {
    validate: {
      isValid: (value) => {
        return (
          isValidPhone(value) || translate("MOBILE_PHONE_VALIDATION_MESSAGE")
        );
      },
    },
  });

  const phoneOptions = register("phone", {
    required: translate("PHONE_REQUIRED"),
    validate: {
      isValid: (value) => {
        return isValidPhone(value) || translate("PHONE_VALIDATION_MESSAGE");
      },
    },
  });

  const fetchContact = async (email: string) => {
    if (!isValidEmail(email)) {
      return;
    }
    setContactFormState((prev) => ({
      ...prev,
      isEmailFilled: true,
      isEnteringEmail: false,
      isLoadingContact: !(email === contact?.email),
    }));
    if (email === contact?.email) {
      return;
    }

    try {
      const fetchedContacts = await dispatch(searchContact(email));
      const contactFound = fetchedContacts && fetchedContacts.length > 0;

      if (contactFound) {
        const existingContact = {
          ...fetchedContacts[0],
          lastname: fetchedContacts[0].lastName,
          firstname: fetchedContacts[0].firstName,
          mobilephone: fetchedContacts[0].mobile,
        };
        const isCustomerContact = contacts.find(
          (customerContact) => customerContact.id === existingContact.id
        );

        if (isCustomerContact) {
          setExistingContactError(
            translate(
              "CUSTOMERS.OVERVIEW.CONTACTS.MODAL.CONTACT_EXISTS_CUSTOMER"
            )
          );
          setContactFormState((prev) => ({
            ...prev,
            isExistingContact: true,
          }));
        } else {
          setValue("id", existingContact.id);
        }
        if (newContact) {
          reset(existingContact);
        }
      } else {
        if (newContact) {
          setValue("firstname", "");
          setValue("lastname", "");
          setValue("mobilephone", "");
          setValue("phone", "");
        }
        setContactFormState((prev) => ({
          ...prev,
          isExistingContact: false,
        }));
      }
    } finally {
      setContactFormState((prev) => ({
        ...prev,
        isLoadingContact: false,
      }));
      trigger();
    }
  };

  const handleToggleEmail = (isChecked?: boolean) => {
    const email = isChecked ? generatePlaceholderEmail() : "";
    setContactFormState((prev) => ({
      ...prev,
      hasPlaceholderEmail: isChecked || false,
      isEmailFilled: isChecked || false,
    }));
    if (isChecked) {
      reset();
      setContactFormState((prev) => ({
        ...prev,
        isExistingContact: false,
      }));
    }
    setValue("email", email, {
      shouldValidate: true,
    });
  };

  const emailFieldDisabled = isLoadingContact || hasPlaceholderEmail;

  const renderEmailForm = () => (
    <Row className="mb-md pr-xl">
      <Col lg={6}>
        <label className="fw-semibold">{translate("EMAIL")} *</label>
        <RHFInput
          type="email"
          required
          placeholder="email@example.com"
          disabled={emailFieldDisabled}
          errorMessage={
            isExistingContact ? (
              <>
                <Warning20Regular className="mr-xs" /> {existingContactError}
              </>
            ) : (
              errors.email?.message
            )
          }
          register={emailOptions}
          onChange={({ target }) => {
            setContactFormState((prev) => ({
              ...prev,
              isExistingContact: false,
            }));
            setValue("email", target.value, {
              shouldValidate: true,
            });
          }}
          onBlur={() => {
            fetchContact(emailValue);
          }}
          onKeyDown={() => {
            setContactFormState((prev) => ({
              ...prev,
              isEnteringEmail: true,
            }));
          }}
        />
      </Col>
      {isLoadingContact && (
        <Col className="d-flex align-items-center">
          <Spinner className="mt-lg" size={SpinnerSize.ExtraSmall} />
        </Col>
      )}
      <Col xs={12}>
        <Switch
          checked={isPlaceholderEmail(emailValue)}
          disabled={isLoadingContact}
          label={translate("CONTACT_MISSING_EMAIL")}
          onToggleMethod={(e, { checked }) => handleToggleEmail(checked)}
        />
      </Col>
    </Row>
  );

  const contactDetailsFormDisabled = isEnteringEmail || isLoadingContact;
  const renderContactDetailsForm = () => (
    <Row>
      <Row>
        <Col lg={6} className="mb-md">
          <label className="fw-semibold">{translate("FIRST_NAME")} *</label>
          <RHFInput
            placeholder={translate("FIRST_NAME")}
            errorMessage={errors.firstname?.message}
            register={firstNameOptions}
            disabled={contactDetailsFormDisabled}
            value={getValues("firstname")}
            maxChar={MAX_CHAR_FIRST_NAME}
            onChange={({ target }) => {
              setValue("firstname", target.value, {
                shouldValidate: true,
              });
            }}
          />
        </Col>
        <Col lg={6} className="mb-md">
          <label className="fw-semibold">{translate("LAST_NAME")} *</label>
          <RHFInput
            placeholder={translate("LAST_NAME")}
            errorMessage={errors.lastname?.message}
            register={lastNameOptions}
            disabled={contactDetailsFormDisabled}
            value={getValues("lastname")}
            maxChar={MAX_CHAR_LAST_NAME}
            onChange={({ target }) => {
              setValue("lastname", target.value, {
                shouldValidate: true,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mb-md">
          <label className="fw-semibold">
            {translate("TELEPHONE_NUMBER")} *
          </label>
          <RHFInput
            type="text"
            placeholder={translate("TELEPHONE_NUMBER")}
            errorMessage={errors.phone?.message}
            register={phoneOptions}
            disabled={contactDetailsFormDisabled}
            onChange={({ target }) => {
              setValue("phone", getDigitsFromString(target.value), {
                shouldValidate: true,
              });
            }}
          />
        </Col>
        <Col lg={6} className="mb-md">
          <label className="fw-semibold">{translate("MOBILE_PHONE")}</label>
          <RHFInput
            type="text"
            placeholder={translate("MOBILE_PHONE")}
            errorMessage={errors.mobilephone?.message}
            register={mobilePhoneOptions}
            disabled={contactDetailsFormDisabled}
            onChange={({ target }) => {
              setValue("mobilephone", getDigitsFromString(target.value), {
                shouldValidate: true,
              });
            }}
          />
        </Col>
      </Row>
    </Row>
  );

  const customerSteps = [
    {
      key: "1",
      label: translate("EMAIL_VERIFICATION"),
      circleLabel: "A",
      component: renderEmailForm(),
      state: {
        highlighted: !emailValue || !isValidEmail(emailValue),
        active: true,
        disabled: isLoadingContact,
      },
    },
    {
      key: "2",
      label: translate("CONTACT_PERSON_DETAILS"),
      circleLabel: "B",
      component:
        !isEmailFilled && newContact ? (
          <span className="text-gray fs-italic">
            {translate("EMAIL_VERIFY_INFO")}
          </span>
        ) : (
          renderContactDetailsForm()
        ),
      state: {
        highlighted: newContact
          ? isEmailFilled
          : !!emailValue && isValidEmail(emailValue),
        active: newContact ? isEmailFilled : !!emailValue,
        disabled: isLoadingContact,
      },
    },
  ];

  const updateContact = async () => {
    setContactFormState((prev) => ({
      ...prev,
      isLoadingContacts: true,
    }));
    const existingEmail = contact?.email;
    if (contact?.id !== getValues("id") && existingEmail) {
      await dispatch(
        deleteCustomerContactCallerWrapper({
          customerNumber,
          contactEmail: existingEmail,
          showToastMessage: false,
        })
      );
      await createContact(true);
    } else {
      await dispatch(updateCustomerContactCallerWrapper(getValues()));
    }
    setContactFormState((prev) => ({
      ...prev,
      isLoadingContacts: false,
    }));
  };

  const createContact = async (isEdit: boolean) => {
    const contactToCreate = getValues() as CustomerSettingsContact;
    await dispatch(
      createCustomerContactCallerWrapper({
        contact: contactToCreate,
        customerId: orgNum,
        isEdit,
      })
    );
  };

  const handleNext = async () => {
    if (contact) {
      await updateContact();
    } else {
      await createContact(false);
    }
    dispatch(resetOpenCreateModalFor());
    setCloseModal(true);
  };

  const onSetOpenModal = (open: boolean) => {
    setOpenModal(open);
    dispatch(resetOpenCreateModalFor());
  };

  const nextDisabled =
    !isValid ||
    isExistingContact ||
    isLoadingContact ||
    isEnteringEmail ||
    isLoadingContacts;

  return (
    <Modal
      onDismiss={() => {
        onSetOpenModal(false);
      }}
      isOpen={openModal}
      size="large"
      footer={
        <ModalFooter
          onCancel={() => onSetOpenModal(false)}
          isLoading={
            isLoading === LoadingStatusEnum.PENDING || isLoadingContacts
          }
          onSave={handleNext}
          labelSubmit={newContact ? "CREATE_CS_CONTACT" : "UPDATE_CS_CONTACT"}
          labelCancel="CANCEL"
          sendButtonIcon={<Checkmark20Regular />}
          cancelButtonIcon={<ArrowLeft20Regular />}
          isDisabled={nextDisabled}
        />
      }
      header={
        <ModalHeader
          headerTitleText={title}
          subheaderText={
            newContact
              ? "CREATE_CS_CONTACT_SUBTITLE"
              : "UPDATE_CS_CONTACT_SUBTITLE"
          }
        />
      }
    >
      <VerticalStepper steps={customerSteps} />
    </Modal>
  );
}
