import { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { ArrowRight20Filled } from "@fluentui/react-icons";
import { useSelector } from "react-redux";

import { RootState } from "state";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { PersonPicker, Persona } from "components/people";
import { useTranslation } from "hooks";
import { PersonDetails } from "models/profile";
import { ViewType } from "models/mgt";
import { ManageCustomersContext } from "../context/ManageCustomersContext";

interface ChangeEmployeeModalProps {
  onSave: () => void;
  onCancel: () => void;
}

export default function ChangeEmployeeModal({
  onSave,
  onCancel,
}: ChangeEmployeeModalProps) {
  const { translate } = useTranslation();

  const { currentUser } = useSelector((state: RootState) => state?.users);

  const {
    assignee,
    assigneeFirstName,
    newAssigneeId,
    setNewAssigneeId,
    affectedCustomersCounter,
    affectedProjectsCounter,
  } = useContext(ManageCustomersContext);

  if (!assignee) {
    throw new Error("Missing assignee!");
  }

  useEffect(() => {
    setNewAssigneeId("");
  }, [setNewAssigneeId]);

  const onPersonChange = (person?: PersonDetails) => {
    if (!person?.id) {
      setNewAssigneeId("");
      return;
    }

    setNewAssigneeId(person.id);
  };

  const renderAffectedRoles = () => {
    if (affectedCustomersCounter > 0 && affectedProjectsCounter > 0) {
      return `${translate(
        "MANAGE_CUSTOMERS.CHANGE_EMPLOYEE_MODAL.AFFECTED_ROLE.PROJECT_MANAGER"
      )}, ${translate(
        "MANAGE_CUSTOMERS.CHANGE_EMPLOYEE_MODAL.AFFECTED_ROLE.SALESPERSON"
      )}`;
    }

    if (affectedCustomersCounter > 0) {
      return translate(
        "MANAGE_CUSTOMERS.CHANGE_EMPLOYEE_MODAL.AFFECTED_ROLE.SALESPERSON"
      );
    }

    if (affectedProjectsCounter > 0) {
      return translate(
        "MANAGE_CUSTOMERS.CHANGE_EMPLOYEE_MODAL.AFFECTED_ROLE.PROJECT_MANAGER"
      );
    }

    return "";
  };

  return (
    <Modal
      className="change-user-in-management-modal"
      isOpen
      onDismiss={onCancel}
      size="medium"
      hideCloseButton
      header={
        <ModalHeader
          headerTitleText={translate(
            "MANAGE_CUSTOMERS.CHANGE_EMPLOYEE_MODAL.TITLE",
            [assigneeFirstName]
          )}
        />
      }
      footer={
        <ModalFooter
          labelSubmit="MANAGE_CUSTOMERS.CHANGE_EMPLOYEE_MODAL.CONFIRM_LABEL"
          labelCancel="MANAGE_CUSTOMERS.CHANGE_EMPLOYEE_MODAL.CANCEL_LABEL"
          onSave={onSave}
          onCancel={onCancel}
          isDisabled={!newAssigneeId}
        />
      }
    >
      <div className="px-md fw-semibold">
        <Row>
          <Col>
            {translate(
              "MANAGE_CUSTOMERS.CHANGE_EMPLOYEE_MODAL.AFFECTED_CUSTOMERS"
            )}
            : {affectedCustomersCounter}
          </Col>
        </Row>
        <Row>
          <Col>
            {translate(
              "MANAGE_CUSTOMERS.CHANGE_EMPLOYEE_MODAL.AFFECTED_PROJECTS"
            )}
            : {affectedProjectsCounter}
          </Col>
        </Row>
        <Row>
          <Col>
            {translate("MANAGE_CUSTOMERS.CHANGE_EMPLOYEE_MODAL.AFFECTED_ROLES")}
            : {renderAffectedRoles()}
          </Col>
        </Row>

        <div className="assignee-pickers-body">
          <div>
            <Row className="mb-xs">
              <Col>
                {translate(
                  "MANAGE_CUSTOMERS.CHANGE_EMPLOYEE_MODAL.CURRENT_ASSIGNEE"
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Persona
                  userId={assignee.id}
                  view={ViewType.oneline}
                  className="disabled"
                />
              </Col>
            </Row>
          </div>
          <div>
            <ArrowRight20Filled />
          </div>
          <div className="pl-lg pb-lg">
            <Row className="mb-xs">
              <Col>
                {translate(
                  "MANAGE_CUSTOMERS.CHANGE_EMPLOYEE_MODAL.NEW_ASSIGNEE"
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <PersonPicker
                  selectionMode="single"
                  emptyPlaceholder={translate(
                    "MANAGE_CUSTOMERS.LANDING_PAGE.PERSON_PICKER.PLACEHOLDER"
                  )}
                  excludeMe={assignee.id === currentUser.graphId}
                  userFilters={`not(id eq '${assignee.id}')`}
                  selectedUsers={newAssigneeId ? [{ id: newAssigneeId }] : []}
                  onPersonChange={(_, __, person) => onPersonChange(person)}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Modal>
  );
}
