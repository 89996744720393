import { useDispatch } from "react-redux";

import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { useTranslation } from "hooks/use-translate";
import { deleteCustomerContactCallerWrapper } from "state/customerSettings/customerSettingsThunk";
import { AppDispatch } from "state/use-app-redux";

type Props = {
  customerNumber: string;
  contactEmail: string;
  setOpenModal: (arg: boolean) => void;
};

export default function CustomerContactDeleteModal({
  customerNumber,
  contactEmail,
  setOpenModal,
}: Props) {
  const dispatch: AppDispatch = useDispatch();
  const { translate } = useTranslation();

  const deleteContact = async () => {
    setOpenModal(false);
    await dispatch(
      deleteCustomerContactCallerWrapper({
        customerNumber,
        contactEmail,
        showToastMessage: true,
      })
    );
  };

  return (
    <Modal
      onDismiss={() => setOpenModal(false)}
      isOpen
      size="medium"
      footer={
        <ModalFooter
          onSave={() => {
            deleteContact();
          }}
          labelSubmit="CUSTOMER_PAGE.DELETE_CONTACT"
          onCancel={() => setOpenModal(false)}
        />
      }
      header={<ModalHeader headerTitleText="CUSTOMER_PAGE.DELETE_CONTACT" />}
    >
      <div>
        <div className="d-flex px-md ">
          <span className="p-sm">
            {translate("CUSTOMER_PAGE.DELETE_CONTACT_QUESTION")}
          </span>
        </div>
      </div>
    </Modal>
  );
}
