import { Row, Col } from "react-bootstrap";

import AppConfig from "app-config";
import {
  AccordionComp,
  FoldableItem,
} from "components/accordion/BootstrapAccordion";

import { useTranslation } from "hooks/use-translate";
import { decodeHtmlEntity } from "libs/decode-html-entity";
import { Appendix } from "models/offer/CustomerDeal";
import OfferTeam from "../OfferTeam";

interface ContractTextsProps {
  contractTexts: Appendix[];
}

function ContractTexts({ contractTexts }: ContractTextsProps) {
  const { translate } = useTranslation();

  function getFoldableItemsFromTexts(): FoldableItem[] {
    const contractItems = contractTexts.map((appendix) => {
      const title = appendix.appendix_title;
      const textsInAppendix = appendix.contract_texts
        .flatMap((ct) => ct.header_swe)
        .join("   ");
      return {
        title: (
          <Row className="w-100">
            <Col md={3}>
              <div className="subH1 fw-semibold">{title}</div>
            </Col>
            <Col>
              <div className="bodyLight">{textsInAppendix}</div>
            </Col>
          </Row>
        ),
        content: appendix.contract_texts.map((contractText) => (
          <Row>
            <Col md={{ span: 9, offset: 3 }}>
              <div
                key={`${contractText.header_swe}-${contractText.id}`}
                className="pr-lg"
              >
                <div className="header-title pb-sm">
                  <div className="subH1 fw-bold">{contractText.header_swe}</div>
                </div>
                <div
                  className="summary-texts-wrapper mt-md"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: decodeHtmlEntity(contractText.content_swe),
                  }}
                />
              </div>
            </Col>
          </Row>
        )),
        key: title,
      };
    });

    const teamItem = {
      title: (
        <Row className="w-100">
          <Col md={3}>
            <div className="subH1 fw-semibold">{translate("TEAM")}</div>
          </Col>
          <Col>
            <div className="bodyLight">
              <span>{translate("SALESPERSONS")}</span>{" "}
              <span className="pl-lg"> {translate("PROJECT_MANAGERS")} </span>
            </div>
          </Col>
        </Row>
      ),
      content: [
        <Row key="offerTeam">
          <Col md={{ span: 9, offset: 3 }}>
            <OfferTeam />
          </Col>
        </Row>,
      ],
    };

    return AppConfig.STAGE === "production"
      ? contractItems
      : [...contractItems, teamItem];
  }

  return <AccordionComp foldableItems={getFoldableItemsFromTexts()} />;
}

export default ContractTexts;
