import { AppDispatch } from "state/use-app-redux";

import { isPlaceholderEmail } from "libs/generate-placeholder-email";
import { searchContact } from "state/sales/actions";
import { createOrUpdateCompanyContact } from "state/offer/companyThunks";
import {
  addOfferSelectedServiceLine,
  addServiceLineGroup,
  updateCurrentOffer,
  updateOfferContact,
} from "state/offer/offersSlice";
import { updateCustomerIncomeTaxData } from "state/offer/offersThunks";
import { DealCustomer } from "models/offer/Customer";
import {
  Service,
  ServiceCategory,
  ServiceGroup,
  ServiceLine,
} from "models/offer/ServiceLine";
import { TaxObjectDetails } from "models/offer/TaxObject";
import { TaxObjectService } from "models/offer/TaxObjectService";
import { User } from "models/user";
import { DealContact, SigningMethod } from "models/offer/DealContact";
import { Deal } from "models/deals/deal";
import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";

export function createIncomeTaxObjectServices(
  services: Service[],
  customer: DealCustomer,
  currentUser: User,
  dispatch: AppDispatch
) {
  const incomeTaxServices = services.filter(
    (service) => service.pog_income_tax
  );
  if (incomeTaxServices.length > 0) {
    const companyTaxObject = TaxObjectDetails.getTaxObjectFromCustomerData(
      customer,
      currentUser
    );
    const newTaxObjectsServices: TaxObjectService[] = incomeTaxServices.map(
      (service) => ({
        customer_tax_object: companyTaxObject,
        service_matrix_id: service.service_matrix_id,
      })
    );
    dispatch(
      updateCurrentOffer({
        tax_objects: newTaxObjectsServices ?? [],
      })
    );
    dispatch(
      updateCustomerIncomeTaxData({
        taxDetailsFormData: companyTaxObject,
        newTaxObjectsServices,
      })
    );
  }
}

export async function createOrUpdateHSContactInPogOffer(
  deal: Deal,
  currentUser: User,
  dispatch: AppDispatch
) {
  if (!deal.contact.email) {
    return;
  }
  // search the contact by deal's contact email
  const contacts = await dispatch(searchContact(deal.contact.email));
  const contactFound = contacts?.[0];

  const contactData = {
    is_signer: false,
    signing_method: isPlaceholderEmail(deal.contact.email)
      ? SigningMethod.PHYSICAL
      : SigningMethod.BANKID,
    contact: {
      first_name: deal.contact.firstname,
      last_name: deal.contact.lastname,
      social_security_number: contactFound?.social_security_number ?? "",
      mobile_phone: deal.contact.mobilephone,
      phone_number: deal.contact.phone,
      email: deal.contact.email,
      changed_by_username: currentUser.email,
      created_by_username: currentUser.email,
      id: "",
    },
  };

  // create or update pog contact with deal's contact
  const pogContact = await dispatch(
    createOrUpdateCompanyContact({
      contactData: contactData.contact,
      orgNum: deal.organisationsnummer,
    })
  ).unwrap();

  if (pogContact) {
    const refreshedDealContact = new DealContact({
      ...contactData,
      is_signer: !!pogContact.social_security_number,
      contact: pogContact,
    });

    // refresh the contact with new newest data
    dispatch(updateOfferContact(refreshedDealContact));
  }
}

export function getServiceCategoryWithDefaultServices(
  serviceCategory: ServiceCategory
): ServiceCategory {
  const initialServices = serviceCategory.services
    .filter((service) => service.input_form_type_state)
    .map((service) => ({ ...service, units: 1 }));

  return { ...serviceCategory, services: initialServices };
}

// adding the serviceLine and service groups to the offer
// (eg. Accounting as service area, Income tax as service group etc.)
export function addServiceLinesAndGroupsToPogOffer(
  offerDealCustomer: DealCustomer,
  currentUser: User,
  mappedDealProducts: string[],
  serviceLines: ServiceLine[],
  serviceGroups: ServiceGroup[],
  dispatch: AppDispatch
) {
  const accountingServiceLine = serviceLines.find(
    (serviceLine) => serviceLine.name === ACCOUNTING_SERVICE_LINE
  );
  if (accountingServiceLine) {
    dispatch(addOfferSelectedServiceLine(accountingServiceLine));
  }

  const dealServiceGroups = serviceGroups.filter((group) =>
    mappedDealProducts.includes(group.name.toLowerCase())
  );
  dealServiceGroups.forEach((serviceGroup) => {
    const groupCatagories = serviceGroup.service_categories
      .filter((category) => category.name)
      .map((category) => {
        const serviceCategory = getServiceCategoryWithDefaultServices(category);
        return serviceCategory;
      });

    if (groupCatagories.length > 0) {
      const allServices = groupCatagories.flatMap((sc) => sc.services);

      createIncomeTaxObjectServices(
        allServices,
        offerDealCustomer,
        currentUser,
        dispatch
      );
      const newGroup: ServiceGroup = {
        name: serviceGroup.name,
        service_categories: groupCatagories,
        sorting_number: serviceGroup.sorting_number,
        skip_price_calculation: serviceGroup.skip_price_calculation,
      };
      dispatch(addServiceLineGroup(newGroup));
    }
  });
}
