import { Checkmark20Regular, Warning20Regular } from "@fluentui/react-icons";
import { differenceInDays } from "date-fns";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AppRouteHelper } from "routes";
import { RootState } from "state";
import { setServiceBoxActivities } from "state/activities/actions";
import { useTranslation } from "hooks/use-translate";
import { ServiceInstance } from "models/activities/activity";
import { ActivityStatusMap } from "views/conveyorBelt/components/serviceActivities";
import StatusIndicatorIcon from "components/icon/StatusIndicatorIcon";
import { Persona } from "components/people";
import Tooltip from "components/tooltip/Tooltip";
import "./ServiceBox.scss";

type Props = {
  service: ServiceInstance;
  customerNumber: string;
} & React.HTMLProps<HTMLDivElement>;

export function ServiceBox({ service, customerNumber, ...props }: Props) {
  const { translate, language } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classNames = `service-box mt-xs ${
    service.isCompleted() ? "completed" : ""
  } ${service.isOverdue() ? "overdue" : ""}`;
  const { dataMap: definitionsMap } = useSelector(
    (state: RootState) => state.activities.definitions
  );
  const next = service.getIncompleteSorted()[0];
  const nextDefinition = next && definitionsMap[next.activity_type];
  const nextActivityName = next && next.getTitle(language, nextDefinition);
  const name = service.getTitleWithWeek(language);
  const isComplete = service.isCompleted();
  const isReportCompleted = service.getReportFinished();
  const progress = service.getProgress();
  const statuses = service.getAttentionStatuses();
  const reportDeadline = service.getReportDeadline();
  const deadlineTodayDifference =
    reportDeadline && differenceInDays(reportDeadline, new Date());

  const openService = () => {
    dispatch(setServiceBoxActivities([service], customerNumber));
    navigate({
      pathname: AppRouteHelper.getAssignmentsServices(
        customerNumber,
        service.service_box_id
      ),
    });
  };

  const renderDaysLeftOrOverdue =
    !isReportCompleted &&
    deadlineTodayDifference &&
    deadlineTodayDifference !== 0 ? (
      <Row>
        {deadlineTodayDifference > 0 ? (
          <span className="stringLength o-50">
            {deadlineTodayDifference}
            {` ${translate("DAYS").toLowerCase()} ${translate(
              "LEFT"
            ).toLowerCase()}`}
          </span>
        ) : (
          <span className="stringLength o-50">
            {-deadlineTodayDifference}
            {` ${translate("DAYS").toLowerCase()} ${translate(
              "OVERDUE"
            ).toLowerCase()}`}
          </span>
        )}
      </Row>
    ) : undefined;

  return (
    <div className={classNames} onClick={openService} {...props}>
      <Row
        className={
          !isComplete
            ? "service-box-content h-100 px-sm pt-xs"
            : "service-box-content h-100 px-sm"
        }
      >
        <Col className="my-xxs mx-0">
          <div className="d-flex align-items-center justify-content-between pb-xs my-xxs w-100 max-height-20 ">
            <div className="d-flex align-items-center service-box-top-wrapper flex-shrink-2">
              <Tooltip
                notTranslatable
                content={name}
                childrenClassName="service-name"
              >
                <p className="m-0 fw-600">{name}</p>
              </Tooltip>
              {service.recurrence && (
                <>
                  <div className="vertical-divider pr-sm ml-sm" />
                  <Tooltip
                    content={service.recurrence}
                    childrenClassName="service-frequency"
                  >
                    <p className="fw-600 mb-0">
                      {translate(service.recurrence)}
                    </p>
                  </Tooltip>
                </>
              )}
            </div>
            <div className="d-flex align-items-center flex-grow-2">
              {!isComplete &&
                statuses &&
                statuses.map((status) => (
                  <Tooltip
                    key={status}
                    hasJSXContent
                    content={
                      <div className="d-flex align-items-center">
                        <StatusIndicatorIcon
                          status={ActivityStatusMap.get(status)}
                        />
                        <span className="pl-sm">{translate(status)}</span>
                      </div>
                    }
                  >
                    <StatusIndicatorIcon
                      status={ActivityStatusMap.get(status)}
                    />
                  </Tooltip>
                ))}
              {service.isOverdue() && (
                <Tooltip
                  hasJSXContent
                  content={
                    <div className="d-flex align-items-center">
                      <Warning20Regular color="var(--red)" />
                      <span className="pl-sm">
                        {translate("PASSED_DEADLINE")}
                      </span>
                    </div>
                  }
                >
                  <Warning20Regular
                    data-testid="overdue-warning"
                    color="var(--red)"
                  />
                </Tooltip>
              )}
            </div>
            {isComplete && (
              <p className="m-0 p-0 fw-semibold">
                <Checkmark20Regular
                  className="ms-2"
                  data-testid="completed-check"
                />
              </p>
            )}
          </div>
          {!isComplete && (
            <Row>
              <Col md={10} className="pr-xxs">
                <Row className="pt-xxs">
                  {!isComplete && progress > 0 && (
                    <Col
                      xs={
                        !deadlineTodayDifference ||
                        deadlineTodayDifference === 0
                          ? 12
                          : 6
                      }
                      className="stringLength"
                    >
                      <span className="o-50 pr-xs">{translate("NEXT")}:</span>
                      <Tooltip notTranslatable content={nextActivityName}>
                        <span className="w-100">{nextActivityName}</span>
                      </Tooltip>
                    </Col>
                  )}
                  <Col
                    xs={!isReportCompleted && progress ? 6 : 12}
                    className={`d-flex ${
                      !isReportCompleted && progress ? "pl-lg" : ""
                    }`}
                  >
                    {!isComplete && progress === 0 && (
                      <div className="o-50 pr-xs">
                        {`${translate("NOT_STARTED")}${
                          renderDaysLeftOrOverdue ? ":" : ""
                        }`}
                      </div>
                    )}

                    {renderDaysLeftOrOverdue}
                  </Col>
                </Row>
              </Col>

              <Col
                md={2}
                className="align-items-center d-flex justify-content-end pr-sm"
              >
                <span className="align-items-end">
                  <Persona userId={next.assigned_user} />
                </span>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      {!isComplete && (
        <progress className="service-box-progress-bar" value={progress} />
      )}
    </div>
  );
}
