import { Outlet } from "react-router-dom";

import { ACCOUNTING_SERVICE_LINE as SERVICE_LINE_ACCOUNTING } from "constants/servicesConsts";
import { CustomerDeal } from "models/offer/CustomerDeal";
import { SelectedPickerOption } from "models/offer/Picker";
import { StepDefinition } from "models/WIzardSteps";
import { AppRouteHelper, RouteConfig } from "routes";
import CompletionManualKyc from "views/offer/wizard/CompletionManualKyc";
import { SigningMethod } from "models/offer/DealContact";
import { availableTogglesMeta } from "../components/servicePicker/ServicePickerHelper";
import Business from "../wizard/Business";
import Completion from "../wizard/Completion";
import { availableServiceTypes } from "../wizard/configuration/service-type-config";
import {
  SERVICE_CATEGORY_SMALL_BUSINESS,
  SERVICE_GROUP_SMALL_BUSINESS,
  SERVICE_GROUP_SMALL_BUSINESS_EF,
} from "../wizard/consts/offer-contst";
import CurrentAccounting from "../wizard/CurrentAccounting";
import CustomerContactContacts from "../wizard/CustomerContactDetails";
import CustomerDetails from "../wizard/CustomerDetails";
import CustomerSearch from "../wizard/CustomerSearch";
import CustomerTypes from "../wizard/CustomerType";
import IncomeTax from "../wizard/IncomeTax";
import { KYCSection } from "../wizard/KYC";
import { OffersRedirectScreen } from "../wizard/OfferRedirectScreen";
import { Services } from "../wizard/Services";
import ServiceType from "../wizard/ServiceType";
import SmallBusiness from "../wizard/SmallBusiness";
import Summary from "../wizard/Summary";
import YearlyStatement from "../wizard/YearlyStatement";

export const OfferRouteHelper = {
  getServiceType: () => "/offers",
  getCustomerSearch: () => "/offers/search",
  getCustomerDetails: () => "/offers/customer_details",
  getContactInfo: () => "/offers/contact_info",
  getCustomerType: () => "/offers/customer_type",
  getKYC: () => "/offers/kyc",
  getRegisterCompany: () => "/offers/register_company",
  getServices: (serviceGroupName = "", edit = false) => {
    let result = "/offers/services";

    if (serviceGroupName !== "") {
      result += `/${serviceGroupName.replaceAll(" ", "_").toLowerCase()}`;
    }

    if (edit) {
      result += "/edit";
    }

    return result;
  },
  getServicesAnnualAccounts: () =>
    `${OfferRouteHelper.getServices()}/annual_accounts`,
  getServicesCurrentAccounting: () =>
    `${OfferRouteHelper.getServices()}/current_accounting`,
  getServicesIncomeTax: () => `${OfferRouteHelper.getServices()}/income_tax`,
  getServicesSmallBusinessEF: () =>
    `${OfferRouteHelper.getServices()}/small_business_(ef)`,
  getServicesSmallBusiness: () =>
    `${OfferRouteHelper.getServices()}/small_business`,
  getSummary: () => "/offers/summary",
  getExistingSummary: (orgId = ":orgId", offerId = ":offerId") =>
    `${OfferRouteHelper.getSummary()}/${orgId}/offer/${offerId}`,
  getManualSignKyc: () => {
    return "/offers/completion/manual";
  },
  getExistingManualSignKyc: (orgId = ":orgId", offerId = ":offerId") =>
    `${OfferRouteHelper.getManualSignKyc()}/${orgId}/offer/${offerId}`,
  getCompletion: () => {
    return "/offers/completion/confirmation";
  },
  getExistingCompletion: (orgId = ":orgId", offerId = ":offerId") =>
    `${OfferRouteHelper.getCompletion()}/${orgId}/offer/${offerId}`,
  getOffersRedirect: () => "/offers/redirect",
};

const offerRoutes: RouteConfig[] = [
  {
    path: OfferRouteHelper.getServiceType(),
    element: <ServiceType />,
    subRoutes: [],
  },
  {
    path: OfferRouteHelper.getCustomerSearch(),
    element: <CustomerSearch />,
    subRoutes: [],
  },
  {
    path: OfferRouteHelper.getCustomerDetails(),
    element: <Business />,
    subRoutes: [],
  },
  {
    path: OfferRouteHelper.getContactInfo(),
    element: <CustomerContactContacts />,
    subRoutes: [],
  },
  {
    path: OfferRouteHelper.getCustomerType(),
    element: <CustomerTypes />,
    subRoutes: [],
  },
  {
    path: OfferRouteHelper.getKYC(),
    element: <KYCSection />,
    subRoutes: [],
  },

  {
    path: OfferRouteHelper.getRegisterCompany(),
    element: <CustomerDetails />,
    subRoutes: [],
  },
  {
    path: OfferRouteHelper.getServices(),
    element: <Outlet />,
    subRoutes: [
      { path: "", element: <Services />, subRoutes: [] },
      {
        path: OfferRouteHelper.getServicesAnnualAccounts(),
        element: <Outlet />,
        subRoutes: [
          { path: "", element: <YearlyStatement />, subRoutes: [] },
          {
            path: ":edit",
            element: <YearlyStatement />,
            subRoutes: [],
          },
        ],
      },
      {
        path: OfferRouteHelper.getServicesCurrentAccounting(),
        element: <Outlet />,
        subRoutes: [
          { path: "", element: <CurrentAccounting />, subRoutes: [] },
          {
            path: ":edit",
            element: <CurrentAccounting />,
            subRoutes: [],
          },
        ],
      },
      {
        path: OfferRouteHelper.getServicesIncomeTax(),
        element: <Outlet />,
        subRoutes: [
          { path: "", element: <IncomeTax />, subRoutes: [] },
          {
            path: ":edit",
            element: <IncomeTax />,
            subRoutes: [],
          },
        ],
      },
      {
        path: OfferRouteHelper.getServicesSmallBusinessEF(),
        element: <Outlet />,
        subRoutes: [
          {
            path: "",
            element: (
              <SmallBusiness
                serviceLine={SERVICE_LINE_ACCOUNTING}
                serviceGroup={SERVICE_GROUP_SMALL_BUSINESS_EF}
                serviceCategory={SERVICE_CATEGORY_SMALL_BUSINESS}
              />
            ),
            subRoutes: [],
          },
          {
            path: ":edit",
            element: (
              <SmallBusiness
                serviceLine={SERVICE_LINE_ACCOUNTING}
                serviceGroup={SERVICE_GROUP_SMALL_BUSINESS_EF}
                serviceCategory={SERVICE_CATEGORY_SMALL_BUSINESS}
              />
            ),
            subRoutes: [],
          },
        ],
      },
      {
        path: OfferRouteHelper.getServicesSmallBusiness(),
        element: <Outlet />,
        subRoutes: [
          {
            path: "",
            element: (
              <SmallBusiness
                serviceLine={SERVICE_LINE_ACCOUNTING}
                serviceGroup={SERVICE_GROUP_SMALL_BUSINESS}
                serviceCategory={SERVICE_CATEGORY_SMALL_BUSINESS}
              />
            ),
            subRoutes: [],
          },
          {
            path: ":edit",
            element: (
              <SmallBusiness
                serviceLine={SERVICE_LINE_ACCOUNTING}
                serviceGroup={SERVICE_GROUP_SMALL_BUSINESS}
                serviceCategory={SERVICE_CATEGORY_SMALL_BUSINESS}
              />
            ),
            subRoutes: [],
          },
        ],
      },
    ],
  },
  {
    path: OfferRouteHelper.getSummary(),
    element: <Outlet />,
    subRoutes: [
      {
        path: "",
        element: <Summary />,
        subRoutes: [],
      },
      {
        path: OfferRouteHelper.getExistingSummary(),
        element: <Summary />,
        subRoutes: [],
      },
    ],
  },
  {
    path: OfferRouteHelper.getManualSignKyc(),
    element: <Outlet />,
    subRoutes: [
      {
        path: "",
        element: <CompletionManualKyc />,
        subRoutes: [],
      },
      {
        path: OfferRouteHelper.getExistingManualSignKyc(),
        element: <CompletionManualKyc />,
        subRoutes: [],
      },
    ],
  },
  {
    path: OfferRouteHelper.getCompletion(),
    element: <Outlet />,
    subRoutes: [
      {
        path: "",
        element: <Completion />,
        subRoutes: [],
      },
      {
        path: OfferRouteHelper.getExistingCompletion(),
        element: <Completion />,
        subRoutes: [],
      },
    ],
  },
  {
    path: OfferRouteHelper.getOffersRedirect(),
    element: <OffersRedirectScreen />,
    subRoutes: [],
  },
  {
    path: "*",
    element: <p>No such route!</p>,
    subRoutes: [],
  },
  {
    path: "",
    element: <ServiceType />,
    subRoutes: [],
  },
];

export default offerRoutes;

/**
 * Generate the steps for the wizard, based on the contents of the customer offer.
 * @param currentOffer The customer offer to use for generating the steps.
 * @returns The steps for the wizard.
 */
export function getAvailableWizardSteps(
  currentOffer?: CustomerDeal
): StepDefinition[] {
  const serviceTypeStep: StepDefinition = {
    labelKey: "OFFER_SERVICE_TYPE",
    disabled: currentOffer?.new_customer || false,
    steps: [
      {
        path: AppRouteHelper.getOffers(),
        nextRoute: OfferRouteHelper.getCustomerSearch(),
        previousRoute: AppRouteHelper.getCreateNew(),
        labelKey: "OFFER_SERVICE_TYPE",
        visible: false,
        validate: () => true,
      },
    ],
  };

  const businessStep: StepDefinition = {
    labelKey: "OFFER_BUSINESS",
    steps: [
      {
        path: OfferRouteHelper.getCustomerSearch(),
        nextRoute: OfferRouteHelper.getCustomerDetails(),
        previousRoute: AppRouteHelper.getOffers(),
        labelKey: "OFFER_CUSTOMER_SEARCH",
        visible: !currentOffer?.new_customer,
        validate: () => true,
      },
      {
        path: OfferRouteHelper.getCustomerDetails(),
        nextRoute: OfferRouteHelper.getRegisterCompany(),
        previousRoute: OfferRouteHelper.getCustomerSearch(),
        disabled: currentOffer?.new_customer || false,
        labelKey: "CUSTOMER_DETAILS",
        visible: !currentOffer?.new_customer,
        validate: () => true,
      },
    ].flatMap((f) => (f ? [f] : [])),
  };

  const customerInfoStep: StepDefinition = {
    labelKey: "OFFER_CUSTOMERINFO",
    steps: [
      {
        path: OfferRouteHelper.getRegisterCompany(),
        nextRoute: OfferRouteHelper.getContactInfo(),
        previousRoute: currentOffer?.new_customer
          ? OfferRouteHelper.getCustomerSearch()
          : OfferRouteHelper.getCustomerDetails(),
        labelKey: "CUSTOMER_DETAILS",
        visible: true,
        validate: () => true,
      },
      {
        path: OfferRouteHelper.getContactInfo(),
        nextRoute: OfferRouteHelper.getServices(),
        previousRoute: OfferRouteHelper.getCustomerDetails(),
        labelKey: "CUSTOMER_CONTACTS",
        visible: true,
        validate: () => true,
      },
    ].flatMap((f) => (f ? [f] : [])),
  };

  const servicesStep: StepDefinition = {
    labelKey: "OFFER_SERVICES",
    steps: (() => {
      if (!currentOffer?.service_areas) {
        return [];
      }

      const selectedServices = currentOffer.service_areas
        .flatMap((sa) => sa.service_lines)
        .flatMap((sl) => sl.service_groups)
        .sort((a, b) => {
          const sortA = availableTogglesMeta[a.name].sortingNumber;
          const sortB = availableTogglesMeta[b.name].sortingNumber;
          return sortA - sortB;
        });

      const servicesSteps = selectedServices.map((sg, index) => {
        // Next route should be the next service in the list, otherwise summary
        const nextRoute =
          index + 1 < selectedServices.length
            ? OfferRouteHelper.getServices(selectedServices[index + 1].name)
            : OfferRouteHelper.getSummary();

        const previousRoute =
          index - 1 >= 0
            ? OfferRouteHelper.getServices(selectedServices[index - 1].name)
            : OfferRouteHelper.getContactInfo();

        const path = OfferRouteHelper.getServices(sg.name);

        return {
          path,
          nextRoute,
          previousRoute,
          labelKey: sg.name,
          visible: true,
          validate: () => true,
        };
      });

      return [
        {
          path: OfferRouteHelper.getServices(),
          nextRoute:
            servicesSteps.length > 0 ? servicesSteps[0].path : "summary",
          previousRoute: OfferRouteHelper.getKYC(),
          labelKey: "OFFER_SERVICES",
          visible: false,
          validate: () => true,
        },
        ...servicesSteps,
      ];
    })(),
  };

  const summaryStep: StepDefinition = {
    labelKey: "OFFER_SUMMARY",
    steps: [
      {
        path: OfferRouteHelper.getSummary(),
        nextRoute: OfferRouteHelper.getManualSignKyc(),
        previousRoute: OfferRouteHelper.getServices(),
        labelKey: "SUMMARY_TITLE",
        visible: false,
        validate: () => true,
      },
    ],
  };
  const confirmationStep: StepDefinition = {
    labelKey: "COMPLETION_TITLE",
    steps: [
      {
        path: OfferRouteHelper.getManualSignKyc(),
        nextRoute: OfferRouteHelper.getCompletion(),
        previousRoute: undefined,
        labelKey: "OFFER_MANUAL_KYC",
        visible: currentOffer?.contacts[0]
          ? currentOffer.contacts[0].is_signer &&
            currentOffer.contacts[0].signing_method === SigningMethod.PHYSICAL
          : false,
        validate: () => true,
      },
      {
        path: OfferRouteHelper.getCompletion(),
        nextRoute: undefined,
        previousRoute: AppRouteHelper.getOffers(),
        labelKey: "CONFIRMATION",
        visible: true,
        validate: () => true,
      },
    ],
  };

  const routes = [
    serviceTypeStep,
    businessStep,
    customerInfoStep,
    servicesStep,
    summaryStep,
    confirmationStep,
  ];
  return routes;
}

function getTextForSpecificPath(pathname: string): string | null {
  if (pathname === OfferRouteHelper.getRegisterCompany()) {
    return "ADD_CONTACT_DETAILS";
  }
  if (pathname.includes("/edit")) {
    return "SAVE";
  }
  if (pathname === OfferRouteHelper.getCustomerDetails()) {
    return "START_CREATING_OFFER";
  }
  if (pathname === OfferRouteHelper.getContactInfo()) {
    return "CHOOSE_SERVICES";
  }
  if (pathname === OfferRouteHelper.getServices()) {
    return "ADD_SERVICE_DETAILS";
  }
  return null;
}

function getTextForServiceTypeAndOptions(
  serviceType?: string,
  selectedOptions?: SelectedPickerOption[]
): string {
  if (serviceType === availableServiceTypes[0].name) {
    return "SEARCH_CUSTOMER";
  }
  if (serviceType === availableServiceTypes[1].name) {
    if (
      selectedOptions?.length === 1 &&
      selectedOptions[0].name === "Real_estate"
    ) {
      return "CONTINUE_TO_INTRANET";
    }
    return "CONTINUE_TO_VERIFIED";
  }
  return "NEXT";
}

export function getNextButtonText(
  pathname: string,
  currentOffer?: CustomerDeal,
  serviceType?: string,
  selectedOptions?: SelectedPickerOption[]
): string {
  const specificPathText = getTextForSpecificPath(pathname);
  if (specificPathText) {
    return specificPathText;
  }

  // Handle logic for service type and selected options
  if (pathname === AppRouteHelper.getOffers() && !currentOffer?.id) {
    return getTextForServiceTypeAndOptions(serviceType, selectedOptions);
  }

  // Default case
  return "NEXT";
}

export function getBackButtonText(
  pathname: string,
  currentOffer?: CustomerDeal
): string {
  if (pathname === OfferRouteHelper.getCustomerDetails()) {
    return "MAKE_ANOTHER_SEARCH";
  }
  if (
    pathname === OfferRouteHelper.getRegisterCompany() &&
    currentOffer?.new_customer
  ) {
    return "MAKE_ANOTHER_SEARCH";
  }
  if (pathname === OfferRouteHelper.getRegisterCompany()) {
    return "BACK_TO_CUSTOMER_DETAILS";
  }

  if (pathname === OfferRouteHelper.getContactInfo()) {
    return "BACK_TO_COMPANY_DETAILS";
  }
  if (pathname === OfferRouteHelper.getServiceType()) {
    return "GO_TO_CREATE_NEW_SCREEN";
  }
  if (pathname === OfferRouteHelper.getServices()) {
    return "BACK_TO_CONTACTS";
  }
  if (pathname.includes(`${OfferRouteHelper.getServices()}/`)) {
    return "BACK_TO_SERVICE_PICKER";
  }
  if (pathname === OfferRouteHelper.getCompletion()) {
    return "GO_TO_FIRST_PAGE";
  }
  return "BACK";
}
