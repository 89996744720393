
import {  useEffect,  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";


import { useTranslation } from "hooks";
import { SigningMethod } from "models/offer/DealContact";

import { RootState } from "state";
import { SearchBy, searchForCustomer } from "state/offer/companyThunks";
import {
  fetchDeal,
} from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import { WizardSection } from "views/createNew/offer/components/wizardSection";
import CompletionPhysical from "views/offer/wizard/Completion/CompletionPhysical";
import { CompletionDigital } from "views/offer/wizard/Completion/CompletionDigital";

export enum CompletionStatus {
  INITIAL,
  FILE_DOWNLOADED,
  FILE_UPLOADING,
  FAIL,
  SUCCESS,
}

export default function Completion() {
  const { translate } = useTranslation();

  const { data: currentOffer } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  const { offerId, orgId } = useParams<{ orgId: string; offerId: string }>();

  const dispatch: AppDispatch = useDispatch();

  const contact = currentOffer?.contacts.find((c) => c.is_signer);

  useEffect(() => {
    if (orgId && offerId && !currentOffer?.id) {
      dispatch(
        searchForCustomer({
          searchData: {
            customerId: orgId,
            searchByParam: SearchBy.OrgNumber,
          },
          checkCustomerBlocked: false,
        })
      );

      dispatch(fetchDeal({ dealId: offerId, orgId }));
    }
  }, [orgId, offerId, dispatch, currentOffer?.id]);

  if (contact?.signing_method === SigningMethod.PHYSICAL) {
    return <CompletionPhysical />;
  }

  if (contact?.signing_method === SigningMethod.BANKID) {
    return <CompletionDigital />;
  }

  return (
    <WizardSection
      loadingStatus="pending"
      serviceName={translate("COMPLETION_TITLE")}
    />
  );
}
