import { Deal } from "models/deals/deal";

export class StageConfiguration {
  StageId: string;

  isHidden?: string;

  Confetti?: string;

  RequiredFields: string;

  DealStageSE: string;

  DealStageEN: string;

  SortingNumber: number;

  onMove?: (deal: Deal) => void;
}
