import { useContext } from "react";
import { Row } from "react-bootstrap";
import { Spinner } from "@fluentui/react-components";
import {
  Info20Regular,
  TriangleDown12Filled,
  TriangleRight12Filled,
} from "@fluentui/react-icons";

import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import Tooltip from "components/tooltip/Tooltip";
import { SpinnerSize } from "components/spinner";
import { AccordionContext } from "components/accordion/context/AccordionContextProvider";
import "../index.scss";

interface Props {
  title: string;
  loadingStatus?: string;
  subtitle?: string;
  infoTooltip?: string;
  lengthIndicator?: number;
}

export function TransparentAccordionHeader({
  title,
  loadingStatus,
  subtitle,
  infoTooltip,
  lengthIndicator,
}: Props) {
  const { toggle, isOpen } = useContext(AccordionContext);

  return (
    <Row className="d-flex align-items-center">
      <div className="accordion-item-header2" onClick={toggle}>
        <div className="pb-xs pr-sm">
          {isOpen ? <TriangleDown12Filled /> : <TriangleRight12Filled />}
        </div>
        <div className="d-flex">
          <div className="fw-semibold sub-h1 mr-sm">{title}</div>
          {lengthIndicator && (
            <div className="text-gray">({lengthIndicator})</div>
          )}
          {loadingStatus && loadingStatus !== LoadingStatusEnum.PENDING && (
            <span className="accordion-subtitle">{subtitle}</span>
          )}
        </div>
        {infoTooltip && (
          <div className="d-flex pl-xxs">
            <div className="d-flex justify-content-center align-items-center">
              <div className="vertical-divider pr-sm ml-sm" />
            </div>
            <div className="pt-xxs">
              <Tooltip content={infoTooltip} notTranslatable>
                <Info20Regular className="cursor-pointer v-align-sub" />
              </Tooltip>
            </div>
          </div>
        )}
        {loadingStatus && loadingStatus === LoadingStatusEnum.PENDING && (
          <span className="px-sm mt-xs">
            <Spinner size={SpinnerSize.Tiny} />
          </span>
        )}
      </div>
    </Row>
  );
}
