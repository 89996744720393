import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMonths, endOfMonth, startOfMonth } from "date-fns";

import { generateMonths } from "libs/date/generate-month";
import { RootState } from "state";
import { AppDispatch } from "state/use-app-redux";
import {
  ActivitiesTimelineWindow,
  fetchActivitiesServicesForCustomers,
  setActivitiesTimelineWindow,
} from "state/activities/actions";
import { Customer } from "models/customer";
import { UserSnapshot } from "models/user";
import { useStickyState } from "hooks";
import { TimespanFilter } from "helpers/conveyorBelt/serviceFilter";
import { TOGGLE_EXPAND_BUTTON } from "constants/conveyorBeltConsts";
import { useAssignmentManagementContext } from "views/conveyorBelt/context/AssignmentManagementContext";
import {
  isServiceInFutureMonth,
  isServiceInTimelineWindow,
} from "helpers/conveyorBelt/conveyorBeltHelpers";
import { useCustomersContext } from "views/conveyorBelt/context/CustomersContext";
import ServiceTableContent from "./ServiceTableContent";
import ServiceTableHeader from "./ServiceTableHeader";
import ChangeAssigneeModal from "../modals/ChangeAssigneeModal";
import "../../../index.scss";

export interface TimelineMonths {
  id: number;
  name: string;
}

type Props = {
  isFullLoading: boolean;
  selectedActivityFilterUsers: UserSnapshot[];
  isCollapsedView: boolean;
  incompleteFilterEnabled?: boolean;
};

export default function ServicesTable({
  isFullLoading,
  selectedActivityFilterUsers,
  isCollapsedView,
  incompleteFilterEnabled = false,
}: Props) {
  const dispatch: AppDispatch = useDispatch();

  const { filteredCustomers: customers } = useCustomersContext();

  const {
    isLoading: isActivitiesLoading,
    timelineWindow,
    unfinishedActivities: {
      data: unfinishedActivities,
      isLoading: isUnfinishedActivitiesLoading,
    },
  } = useSelector((state: RootState) => state.activities);
  const { data: customerData } = useSelector(
    (state: RootState) => state.customers
  );

  const [isExpanded, setIsExpanded] = useStickyState<boolean>(
    TOGGLE_EXPAND_BUTTON,
    false
  );

  const [timeline, setTimeline] = useState<ActivitiesTimelineWindow>();
  const [selectionModeActive, setSelectionModeActive] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [selectedCustomerNumbers, setSelectedCustomerNumbers] = useState<
    string[]
  >([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(
    isActivitiesLoading || isUnfinishedActivitiesLoading
  );

  const customersToDisplay = useMemo(
    () => (isLoading ? customers : filteredCustomers),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, filteredCustomers]
  );

  useEffect(() => {
    if (isLoading !== (isActivitiesLoading || isUnfinishedActivitiesLoading)) {
      setIsLoading(isActivitiesLoading || isUnfinishedActivitiesLoading);
    }
  }, [isActivitiesLoading, isLoading, isUnfinishedActivitiesLoading]);

  const assignee =
    selectedActivityFilterUsers.length === 1
      ? selectedActivityFilterUsers[0]
      : undefined;

  const prevCustomersRef = useRef(filteredCustomers);

  const timelineFilter = useMemo(
    () => new TimespanFilter(timelineWindow),
    [timelineWindow]
  );

  const { servicesFilter, isFiltering } = useAssignmentManagementContext();

  const onMoveTimeWindow = async (addTimelineMonths: number) => {
    const previousFirst = startOfMonth(timelineWindow[0]);
    const previousLast = endOfMonth(timelineWindow[timelineWindow.length - 1]);

    if (!localStorage.getItem("oldTimeline")) {
      localStorage.setItem("oldTimeline", timelineWindow.toString());
    }

    const newFirst = addMonths(previousFirst, addTimelineMonths);
    const newLast = addMonths(previousLast, addTimelineMonths);

    const months = generateMonths(newFirst, newLast);

    const newTimeline = await dispatch(
      setActivitiesTimelineWindow({ months, fetchActivities: false })
    ).unwrap();

    setTimeline(newTimeline);
  };

  const isOnlyOrderChanged = (
    oldCustomers: Customer[],
    newCustomers: Customer[]
  ) => {
    if (oldCustomers.length !== newCustomers.length) {
      return false;
    }

    const oldCustomerNumbers = oldCustomers
      .map((customer) => customer.customer_number)
      .sort();
    const newCustomerNumbers = newCustomers
      .map((customer) => customer.customer_number)
      .sort();

    return oldCustomerNumbers.every(
      (id, index) => id === newCustomerNumbers[index]
    );
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      if (timeline) {
        dispatch(fetchActivitiesServicesForCustomers(timeline));
      }
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [timeline, dispatch]);

  useEffect(() => {
    if (!isOnlyOrderChanged(prevCustomersRef.current, filteredCustomers)) {
      resetSelectedCustomerNumbers();
    }

    prevCustomersRef.current = filteredCustomers;
  }, [filteredCustomers]);

  useEffect(() => {
    prevCustomersRef.current = filteredCustomers;
  }, [filteredCustomers]);

  useEffect(() => {
    const newDisplayedCustomers = customers.filter((customer) => {
      const customerServices = servicesFilter(customer.customer_number);
      const unfinishedCustomerServices = servicesFilter(
        customer.customer_number,
        unfinishedActivities
      );
      const isSearchCustomer =
        customerData.length > 0 && customerData[0].isFromSearch;

      if (
        customerServices.length === 0 &&
        unfinishedCustomerServices.length === 0 &&
        !isSearchCustomer &&
        isFiltering
      ) {
        return false;
      }

      if (incompleteFilterEnabled) {
        const hasIncompleteServicesInTimeWindow = customerServices.some(
          (service) =>
            service.activities.every((activity) =>
              timelineFilter.check(activity, service)
            ) && !service.isCompleted()
        );
        if (hasIncompleteServicesInTimeWindow) {
          return true;
        }

        const hasUnfinishedActivities = unfinishedCustomerServices.some(
          (service) => {
            return (
              !isServiceInTimelineWindow(timelineWindow, service) &&
              !isServiceInFutureMonth(timelineWindow, service)
            );
          }
        );
        return hasUnfinishedActivities;
      }

      return true;
    });

    setFilteredCustomers(newDisplayedCustomers);
  }, [
    customers,
    servicesFilter,
    incompleteFilterEnabled,
    unfinishedActivities,
    timelineFilter,
    timelineWindow,
    customerData,
    isFiltering,
  ]);

  const isAllRowsChecked = () => {
    if (!selectionModeActive) {
      return;
    }

    if (
      !customers.length ||
      selectedCustomerNumbers.length === filteredCustomers.length
    ) {
      return true;
    }
    if (selectedCustomerNumbers.length) {
      return "mixed";
    }
    return false;
  };

  const handleAllRowsChecked = () => {
    if (!selectionModeActive) {
      return;
    }

    setSelectedCustomerNumbers(
      isAllRowsChecked() === true
        ? []
        : filteredCustomers.map((customer) => customer.customer_number)
    );
  };

  const resetSelectedCustomerNumbers = () => setSelectedCustomerNumbers([]);

  return (
    <div className="services-table position-relative min-width-1400">
      <ServiceTableHeader
        selectedActivityFilterUsers={selectedActivityFilterUsers}
        filteredMonths={timelineWindow}
        moveTimeWindow={onMoveTimeWindow}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        isCollapsedView={isCollapsedView}
        selectionModeActive={selectionModeActive}
        setSelectionModeActive={setSelectionModeActive}
        selectAllCheckbox={isLoading ? undefined : isAllRowsChecked()}
        handleSelectAllCheckbox={handleAllRowsChecked}
        resetSelectedCustomerNumbers={resetSelectedCustomerNumbers}
        counter={customersToDisplay.length}
        selectionCounter={
          selectionModeActive ? selectedCustomerNumbers.length : undefined
        }
        openChangeAssigneeModal={() => setShowModal(true)}
      />

      <ServiceTableContent
        filteredMonths={timelineWindow}
        customers={customersToDisplay}
        selectedCustomerNumbers={selectedCustomerNumbers}
        setSelectedCustomerNumbers={setSelectedCustomerNumbers}
        isCollapsedView={isCollapsedView}
        showCompleted={!incompleteFilterEnabled}
        isFullLoading={isFullLoading}
        isExpanded={isExpanded}
        showCheckbox={selectionModeActive}
      />

      {showModal && assignee && (
        <ChangeAssigneeModal
          onCancel={() => setShowModal(false)}
          assignee={assignee}
          selectedCustomerNumbers={selectedCustomerNumbers}
          resetSelectedCustomerNumbers={resetSelectedCustomerNumbers}
        />
      )}
    </div>
  );
}