import { FieldError, useFormContext } from "react-hook-form";

import { useTranslation } from "hooks/use-translate";
import { DateFormat } from "libs/date/date-format";
import { DatePicker } from "components/date/DatePicker";
import { Deal } from "models/deals/deal";
import { DealFieldProps } from "models/deals/fieldTypes";
import { useUpdateDealProperty } from "hooks/myBusinessOpportunities/use-update-deal-property";

function DealDatePicker({ configuration, stage }: DealFieldProps) {
  const { translate, dateFormatter } = useTranslation();
  const { isLoading } = useUpdateDealProperty();

  const today = new Date();

  const {
    formState: { errors },
    setValue,
    getValues,
    register,
    trigger,
  } = useFormContext<Partial<Deal>>();

  const error = errors[configuration.property] as FieldError;
  const value = getValues(configuration.property) as Date;

  const dealDatePickerProps = register(configuration.property, {
    validate: {
      isValid: (val) => {
        if (
          stage?.configuration?.RequiredFields.includes(
            configuration.property
          ) &&
          !val
        ) {
          return translate("FIELD_REQUIRED");
        }
      },
    },
  });

  return (
    <DatePicker
      disabled={isLoading}
      formRegister={dealDatePickerProps}
      placeholder={
        (value && value.toLocaleDateString()) || translate("SELECT_MONTH")
      }
      calendarProps={{
        minDate: today,
      }}
      errorMessage={error?.message}
      dateFormatter={(date) => dateFormatter(date, DateFormat.YearDayMonth)}
      selectedDate={value}
      onSelectDate={(newDate) => {
        setValue(configuration.property, newDate, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }}
      onBlur={() => {
        trigger();
      }}
      fullWidth
    />
  );
}

export default DealDatePicker;
