import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@fluentui/react-components";

import { Dropdown } from "components/dropdown/Dropdown";
import { Input } from "components/input";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { SpinnerSize } from "components/spinner";
import { TextArea } from "components/textarea";
import { useTranslation } from "hooks/use-translate";
import { ServiceNote } from "models/customerSettings/customerSettingsServiceNote";
import { ServiceNoteDb } from "models/customerSettings/customerSettingsServiceNoteDb";
import { RootState } from "state";
import { resetOpenCreateModalFor } from "state/customerSettings/customerSettingsSlice";
import {
  createServiceNotesCallerWrapper,
  updateServiceNotesCallerWrapper,
} from "state/customerSettings/customerSettingsThunk";
import { appendToastMessage } from "state/notifications";

type Props = {
  customerId: string;
  openModal: boolean;
  setOpenModal: (arg: boolean) => void;
  currentNote?: ServiceNote;
  setIsEditMode?: (arg: boolean) => void;
  createdFromCB?: boolean;
};

export default function CustomerPageServiceNotesModal({
  customerId,
  openModal,
  setOpenModal,
  currentNote,
  setIsEditMode,
  createdFromCB,
}: Props) {
  const { translate, language } = useTranslation();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const serviceLines = useSelector(
    (state: RootState) => state.settings?.serviceLines?.data
  );

  const serviceLineOptions = serviceLines?.map((serviceLine) => {
    return {
      text:
        language && language === "EN"
          ? serviceLine.serviceline
          : serviceLine.serviceline_se,
      value: serviceLine.id.toString(),
    };
  });

  const [newNote, setNewNote] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [selectedServiceLineIds, setSelectedServiceLineIds] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (currentNote) {
      setNewTitle(currentNote.title || "");
      setNewNote(currentNote.notes);
      setSelectedServiceLineIds(
        currentNote.servicelines?.map((serviceLine) =>
          serviceLine.id.toString()
        )
      );
    }
  }, [currentNote]);

  const createNote = () => {
    const note = {
      id: currentNote ? currentNote.id : undefined,
      notes: newNote,
      title: newTitle,
      modified_by: currentUser.graphId,
      customer_number: customerId,
      created_by: currentNote ? currentNote.created_by : currentUser.graphId,
      servicelines: serviceLines?.filter(
        (serviceLine) =>
          selectedServiceLineIds.indexOf(serviceLine.id.toString()) !== -1
      ),
    } as ServiceNoteDb;
    if (currentNote) {
      dispatch(updateServiceNotesCallerWrapper(note));
    } else {
      dispatch(createServiceNotesCallerWrapper(note));
      if (createdFromCB) {
        dispatch(appendToastMessage("CREATE_NOTE_SUCCESS_MESSAGE", "success"));
      }
    }
    dispatch(resetOpenCreateModalFor());
  };

  const onSetOpenModal = (open: boolean) => {
    setOpenModal(open);
    dispatch(resetOpenCreateModalFor());
    if (setIsEditMode && !open) {
      setIsEditMode(false);
    }
  };

  return (
    <Modal
      isOpen={openModal}
      onDismiss={() => onSetOpenModal(false)}
      size="medium"
      header={
        <ModalHeader
          headerTitleText={currentNote ? "SERVICE_NOTE_EDIT" : "CREATE_NOTE"}
        />
      }
      footer={
        <ModalFooter
          onCancel={() => onSetOpenModal(false)}
          onSave={() => {
            onSetOpenModal(false);
            createNote();
          }}
          labelSubmit={currentNote ? "UPDATE" : "CREATE"}
          labelCancel="CANCEL"
          isDisabled={
            !(selectedServiceLineIds.length > 0 && newNote && newTitle)
          }
        />
      }
    >
      {serviceLines?.length === 0 ? (
        <Spinner size={SpinnerSize.ExtraSmall} />
      ) : (
        <>
          <Row className="px-md fw-bold pb-sm">{translate("TITLE")}</Row>
          <Row className="px-md mb-md">
            <Input
              required
              placeholder={translate("INSERT_TITLE")}
              onChange={(e) => {
                setNewTitle(e.target.value);
              }}
              defaultValue={newTitle}
            />
          </Row>
          <Row className="px-md fw-bold pb-sm">{translate("SERVICE")}</Row>
          <Row className="mb-md pb-xs px-sm">
            <Dropdown
              multiselect
              placeholder={translate("SELECT_SERVICE")}
              onOptionSelect={(_, { optionValue }, isSelected) => {
                if (optionValue) {
                  setSelectedServiceLineIds(
                    isSelected
                      ? [...selectedServiceLineIds, optionValue as string]
                      : selectedServiceLineIds.filter(
                          (key) => key !== optionValue
                        )
                  );
                }
              }}
              selectedOptions={selectedServiceLineIds}
              options={serviceLineOptions}
            />
          </Row>
          <Row className="px-md fw-bold pb-sm">{translate("CONTENT")}</Row>
          <Row className="p-md">
            <TextArea
              rows={7}
              className=""
              data-testid="deal-meddelande_salj"
              placeholder="Add the service note here"
              defaultValue={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              required
            />
          </Row>
        </>
      )}
    </Modal>
  );
}
