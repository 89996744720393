import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  ChevronLeft20Regular,
  ChevronRight20Regular,
  Add16Regular,
  ArrowSync16Regular,
  ArrowMaximize24Regular,
  MultiselectLtr24Regular,
  Checkmark16Regular,
  Warning16Regular,
  Edit16Regular,
  DatabaseLightning20Regular,
} from "@fluentui/react-icons";
import {
  Button,
  CheckboxProps,
  ToolbarProps,
} from "@fluentui/react-components";
import { startOfMonth } from "date-fns";

import { RootState } from "state";
import { useTranslation } from "hooks";
import { capitalize } from "libs/capitalize";
import { UserSnapshot } from "models/user";
import {
  useLazyGetOnedriveShortcutQuery,
  useLazyGetRecreateOnedriveShortcutQuery,
} from "api/ludvigApi";
import Tooltip from "components/tooltip/Tooltip";
import NotificationModal from "components/modal/NotificationModal";
import {
  ToggleButtonsGroup,
  ToggleButtonsProps,
} from "components/toggleButtonsGroup";
import Checkbox from "components/checkbox";
import { ACTIVITIES_CACHE_DISABLED } from "constants/conveyorBeltConsts";
import { useAssignmentManagementContext } from "views/conveyorBelt/context/AssignmentManagementContext";
import "../../../index.scss";

type Props = {
  selectedActivityFilterUsers: UserSnapshot[];
  filteredMonths: Date[];
  moveTimeWindow: (moveOffset: number) => void;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  isCollapsedView?: boolean;
  selectionModeActive: boolean;
  setSelectionModeActive: (isToggled: boolean) => void;
  selectAllCheckbox: CheckboxProps["checked"];
  handleSelectAllCheckbox: () => void;
  resetSelectedCustomerNumbers: () => void;
  counter: number;
  selectionCounter?: number;
  openChangeAssigneeModal: () => void;
};

type TooltipListItemType = 1 | 2 | 3;

const TOOLTIP_LIST_ITEMS: TooltipListItemType[] = [1, 2, 3];
const SELECTED_MORE_THAN_1_EMPLOYEE_ERROR =
  "selected_more_than_1_employee_error";
const HEADER_TOGGLE_BUTTONS_NAME = "projectOverviewToggleButtonsGroup";
const TOGGLE_EXPAND_BUTTON_VALUE = "expand";
const TOGGLE_SELECTION_BUTTON_VALUE = "selection";
const TOGGLE_OPTIMIZED_DATA_VALUE = "optimizedData";
const TOOLTIP_ICON_CLASSES = "v-align-sub ml-sm";
const SUCCESS_COLOR_CSS_CLASS = "text-color-green-light";
const CONDITION_MET_ICON = (
  <Checkmark16Regular className={TOOLTIP_ICON_CLASSES} />
);

export default function ServiceTableHeader({
  selectedActivityFilterUsers,
  filteredMonths,
  moveTimeWindow,
  isExpanded,
  setIsExpanded,
  isCollapsedView = false,
  selectionModeActive,
  setSelectionModeActive,
  selectAllCheckbox,
  handleSelectAllCheckbox,
  resetSelectedCustomerNumbers,
  counter,
  selectionCounter,
  openChangeAssigneeModal,
}: Props) {
  const { translate, language } = useTranslation();
  const {
    activities: {
      isLoading: isActivitiesLoading,
      unfinishedActivities: { isLoading: isUnfinishedActivitiesLoading },
      hasOneDriveShortcutFolder,
    },
    customers: { isLoading: isCustomersLoading },
  } = useSelector((state: RootState) => state);

  const currentMonthStart = startOfMonth(new Date());
  const [isOpenOnedriveModal, setIsOpenOnedriveModal] = useState(false);
  const [isDisabledOnedriveShortcut, setIsDisabledOnedriveShortcut] =
    useState(false);
  const { isCacheDisabled, setIsCacheDisabled } =
    useAssignmentManagementContext();
  const [triggerCreateOnedrive] = useLazyGetOnedriveShortcutQuery();
  const [triggerRecreateOnedrive] = useLazyGetRecreateOnedriveShortcutQuery();
  const isLoading =
    isActivitiesLoading || isCustomersLoading || isUnfinishedActivitiesLoading;
  const hasSelectedCustomers = !!selectionCounter;
  const initialCheckedValues: Record<string, string[]> = {
    [HEADER_TOGGLE_BUTTONS_NAME]: [
      ...(isExpanded ? [TOGGLE_EXPAND_BUTTON_VALUE] : []),
      ...(isCacheDisabled ? [TOGGLE_OPTIMIZED_DATA_VALUE] : []),
    ],
  };
  const [checkedValues, setCheckedValues] =
    useState<Record<string, string[]>>(initialCheckedValues);

  useEffect(() => {
    const isSelectionButtonToggled = checkedValues[
      HEADER_TOGGLE_BUTTONS_NAME
    ]?.includes(TOGGLE_SELECTION_BUTTON_VALUE);
    setSelectionModeActive(isSelectionButtonToggled);
  }, [checkedValues, setSelectionModeActive]);

  const handleClickOnedriveShortcut = () => {
    if (hasOneDriveShortcutFolder) {
      triggerRecreateOnedrive(null);
    } else {
      triggerCreateOnedrive(null);
    }
    setIsOpenOnedriveModal(true);
  };

  const toggleExpandButton: ToggleButtonsProps = {
    buttonProps: {
      icon: <ArrowMaximize24Regular />,
      name: HEADER_TOGGLE_BUTTONS_NAME,
      value: TOGGLE_EXPAND_BUTTON_VALUE,
    },
    tooltipProps: {
      content: isExpanded
        ? "CB.HEADER.TOOLTIP_CLICK_TO_COLLAPSE"
        : "CB.HEADER.TOOLTIP_CLICK_TO_EXPAND",
    },
  };

  const getSelectionBtnTooltipTranslationKey = () => {
    if (isLoading) {
      return "CB.HEADER.SELECTION_MODE.TOOLTIP_IF_DISABLED";
    }

    return selectionModeActive
      ? "CB.HEADER.SELECTION_MODE.TOOLTIP_EXIT"
      : "CB.HEADER.SELECTION_MODE.TOOLTIP_ENABLE";
  };

  const toggleButtons: ToggleButtonsProps[] = [
    ...(!isCollapsedView ? [toggleExpandButton] : []),
    {
      buttonProps: {
        icon: <MultiselectLtr24Regular />,
        name: HEADER_TOGGLE_BUTTONS_NAME,
        value: TOGGLE_SELECTION_BUTTON_VALUE,
        disabled: isLoading,
      },
      tooltipProps: { content: getSelectionBtnTooltipTranslationKey() },
    },
    {
      buttonProps: {
        icon: <DatabaseLightning20Regular />,
        name: HEADER_TOGGLE_BUTTONS_NAME,
        value: TOGGLE_OPTIMIZED_DATA_VALUE,
        disabled: isLoading,
      },
      tooltipProps: {
        content: isCacheDisabled
          ? "CB.USE_OPTIMIZED_DATA"
          : "CB.USE_REAL_TIME_DATA",
      },
    },
  ];

  const conditionallyResetCustomerSelection = (
    oldCheckedValues: Record<string, string[]>,
    checkedItems: string[]
  ) => {
    const selectionButtonWasToggled = oldCheckedValues[
      HEADER_TOGGLE_BUTTONS_NAME
    ]?.includes(TOGGLE_SELECTION_BUTTON_VALUE);

    const selectionButtonStillToggled = checkedItems.includes(
      TOGGLE_SELECTION_BUTTON_VALUE
    );

    if (selectionButtonWasToggled && !selectionButtonStillToggled) {
      resetSelectedCustomerNumbers();
    }
  };

  const onToggleButtonsChange: ToolbarProps["onCheckedValueChange"] = (
    _,
    { name, checkedItems }
  ) => {
    setCheckedValues((oldCheckedValues) => {
      conditionallyResetCustomerSelection(oldCheckedValues, checkedItems);

      return { ...oldCheckedValues, [name]: checkedItems };
    });

    setIsExpanded(checkedItems.includes(TOGGLE_EXPAND_BUTTON_VALUE));
    setIsCacheDisabled(checkedItems.includes(TOGGLE_OPTIMIZED_DATA_VALUE));
    if (checkedItems.includes(TOGGLE_OPTIMIZED_DATA_VALUE)) {
      localStorage.setItem(ACTIVITIES_CACHE_DISABLED, "false");
    } else {
      localStorage.setItem(ACTIVITIES_CACHE_DISABLED, "true");
    }
  };

  const isSingleUserFilterActive = ():
    | boolean
    | typeof SELECTED_MORE_THAN_1_EMPLOYEE_ERROR => {
    if (selectedActivityFilterUsers.length === 0) {
      return false;
    }
    if (selectedActivityFilterUsers.length === 1) {
      return true;
    }
    return SELECTED_MORE_THAN_1_EMPLOYEE_ERROR;
  };

  const isChangeAssigneeBtnDisabled =
    !(
      isSingleUserFilterActive() === true &&
      selectionModeActive &&
      hasSelectedCustomers
    ) || isLoading;

  const renderChangeAssigneeBtnTooltip = () => {
    return (
      <ol className="mt-sm mb-0 pl-md">
        {TOOLTIP_LIST_ITEMS.map((itemNumber) => renderListItem(itemNumber))}
      </ol>
    );

    function renderListItem(order: TooltipListItemType) {
      let classes = "";
      let content: JSX.Element;

      // eslint-disable-next-line default-case
      switch (order) {
        case 1: {
          const onlySingleUserSelected = isSingleUserFilterActive();

          if (onlySingleUserSelected === true) {
            classes = SUCCESS_COLOR_CSS_CLASS;
          }

          content = (
            <>
              {translate("CB.HEADER.CHANGE_ASSIGNEE.TOOLTIP_PART_2")}
              {onlySingleUserSelected === true && CONDITION_MET_ICON}
              {onlySingleUserSelected ===
                SELECTED_MORE_THAN_1_EMPLOYEE_ERROR && (
                <Warning16Regular
                  className={`${TOOLTIP_ICON_CLASSES} text-color-red`}
                />
              )}
            </>
          );

          break;
        }

        case 2:
          if (selectionModeActive) {
            classes = SUCCESS_COLOR_CSS_CLASS;
          }

          content = (
            <>
              {translate("CB.HEADER.CHANGE_ASSIGNEE.TOOLTIP_PART_3")}
              {selectionModeActive && CONDITION_MET_ICON}
            </>
          );

          break;

        case 3:
          if (hasSelectedCustomers) {
            classes = SUCCESS_COLOR_CSS_CLASS;
          }

          content = (
            <>
              {translate("CB.HEADER.CHANGE_ASSIGNEE.TOOLTIP_PART_4")}
              {hasSelectedCustomers && CONDITION_MET_ICON}
            </>
          );

          break;
      }

      return (
        <li key={`tooltip_list_item_${order}`} className={classes}>
          {content}
        </li>
      );
    }
  };

  return (
    <div className="sticky-header">
      <div className="services-table-header">
        <div className="d-flex align-items-center">
          <div className="header-title d-flex">
            <h3 className="pl-sm no-margin">{translate("PROJECT_OVERVIEW")}</h3>
            <span className="contentHeader--counter ml-md">
              (
              {selectionCounter !== undefined
                ? `${selectionCounter}/`
                : undefined}
              {counter})
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="pr-sm ml-md py-sm vertical-divider" />
          </div>
          <div className="pl-sm d-flex">
            <ToggleButtonsGroup
              buttons={toggleButtons}
              checkedValues={checkedValues}
              onCheckedValueChange={onToggleButtonsChange}
            />
          </div>
        </div>
        <div className="d-flex">
          <Button
            icon={
              hasOneDriveShortcutFolder ? (
                <ArrowSync16Regular />
              ) : (
                <Add16Regular />
              )
            }
            onClick={handleClickOnedriveShortcut}
            disabled={isDisabledOnedriveShortcut}
          >
            {hasOneDriveShortcutFolder
              ? translate("RECREATE_ONEDRIVE_SHORTCUT")
              : translate("ONEDRIVE_SHORTCUT")}
          </Button>
          <Tooltip
            notTranslatable
            content={
              <>
                {translate("CB.HEADER.CHANGE_ASSIGNEE.TOOLTIP_PART_1")}
                {renderChangeAssigneeBtnTooltip()}
              </>
            }
          >
            <Button
              className="ml-lg"
              icon={<Edit16Regular />}
              onClick={openChangeAssigneeModal}
              disabled={isChangeAssigneeBtnDisabled}
            >
              {translate("CB.HEADER.CHANGE_ASSIGNEE")}
            </Button>
          </Tooltip>
        </div>
      </div>

      <Row xs={12} className="w-100 px-md pt-md wrapper">
        <div className="d-flex p-0 w-customer-details">
          <div className="d-flex align-items-center w-100">
            {selectAllCheckbox !== undefined && (
              <Tooltip content="CB.TABLE.HEADER.CHECKBOX.TOOLTIP">
                <Checkbox
                  checked={selectAllCheckbox}
                  onChange={handleSelectAllCheckbox}
                  noMargin={false}
                />
              </Tooltip>
            )}
            <div className="box-background details w-100">
              <span>{translate("CUSTOMER_DETAILS")}</span>
            </div>
          </div>
        </div>
        <div className="d-flex p-0 w-timeline">
          {filteredMonths.map((month, index) => (
            <Col xs={4} key={month.toString()} className="p-0">
              <div
                className={`box-background ml-sm justify-content-between p-0 align-items-center ${
                  currentMonthStart.getMonth() === month.getMonth()
                    ? "current-month"
                    : ""
                }`}
              >
                {index === 0 && filteredMonths.length > 1 && (
                  <Tooltip
                    positioning="above-start"
                    content="ACTIVITIES_LOADING"
                    enabled={isLoading}
                  >
                    <div
                      className={`timeline-arrow ${
                        currentMonthStart.getMonth() === month.getMonth()
                          ? "current-month"
                          : ""
                      }`}
                    >
                      <ChevronLeft20Regular
                        className={`${isActivitiesLoading ? "disabled" : ""}`}
                        onClick={() => moveTimeWindow(-1)}
                      />
                    </div>
                  </Tooltip>
                )}
                <span className="m-auto p-sm">
                  {capitalize(
                    month.toLocaleDateString(language, {
                      month: "long",
                      year: "numeric",
                    })
                  )}
                </span>
                {index === filteredMonths.length - 1 &&
                  filteredMonths.length > 1 && (
                    <Tooltip
                      positioning="above-end"
                      content="ACTIVITIES_LOADING"
                      enabled={isActivitiesLoading}
                    >
                      <div
                        className={`timeline-arrow ${
                          currentMonthStart.getMonth() === month.getMonth()
                            ? "current-month"
                            : ""
                        }`}
                      >
                        <ChevronRight20Regular
                          className={`${isActivitiesLoading ? "disabled" : ""}`}
                          onClick={() => moveTimeWindow(+1)}
                        />
                      </div>
                    </Tooltip>
                  )}
              </div>
            </Col>
          ))}
        </div>
      </Row>

      {isOpenOnedriveModal && (
        <NotificationModal
          isOpen
          onCancel={() => {
            setIsOpenOnedriveModal(false);
            setIsDisabledOnedriveShortcut(true);
          }}
          header={
            hasOneDriveShortcutFolder
              ? translate("RECREATE_ONEDRIVE_SHORTCUT")
              : translate("ONEDRIVE_SHORTCUT")
          }
          body={
            <>
              {hasOneDriveShortcutFolder
                ? translate("ONEDRIVE_SHORTCUT")
                : translate("ONEDRIVE_SHORTCUT_CREATING_MESSAGE")}
              <br />
              <br />
              <a
                href="https://intranet.ludvig.se/organisation/it-och-utveckling/it/lagra-filer/"
                target="_blank"
                rel="noreferrer"
              >
                {translate("ONEDRIVE_SHORTCUT_CREATING_MESSAGE_LINK")}
              </a>
            </>
          }
        />
      )}
    </div>
  );
}
