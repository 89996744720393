import { Spinner } from "@fluentui/react-components";
import { Col, Row } from "react-bootstrap";

import { AddButton } from "components/button/AddButton";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks";
import { ActivityAppendix } from "models/activities/ActivityAppendix";
import { useGetAppendicesFactsQuery } from "api/activitiesApi";
import { AdditionalTaxDocumentCountDropdown } from "./AdditionalTaxDocumentCountDropdown";
import { AdditionalTaxDocumentNameDropdown } from "./AdditionalTaxDocumentNameDropdown";

type AdditionalTaxDocumentsProps = {
  value?: ActivityAppendix[];
  disabled?: boolean;
  onChange: (value: ActivityAppendix[]) => void;
};

export function AdditionalTaxDocuments({
  value = [],
  onChange,
  disabled = false,
}: AdditionalTaxDocumentsProps) {
  const { translate } = useTranslation();

  const { data: appendicesFacts, isLoading: isAppendicesFactsLoading } =
    useGetAppendicesFactsQuery();

  const handleAddAdditionalTaxDocument = () => {
    const alreadyHasNewItem = value.some((taxDocument) => !taxDocument.id);
    if (alreadyHasNewItem) {
      return;
    }

    onChange([...value, new ActivityAppendix()]);
  };

  const handleEditAdditionalTaxDocument = (
    updatedTaxDocument: ActivityAppendix,
    index: number
  ) => {
    //
    // Removal
    //
    const previousInstance = value.find((prevTaxDocument) => {
      return prevTaxDocument.id === updatedTaxDocument.id;
    });

    if (
      previousInstance &&
      previousInstance.count > 0 &&
      updatedTaxDocument.count <= 0
    ) {
      const result = value.filter(
        (prevTaxDocument) => prevTaxDocument.id !== updatedTaxDocument.id
      );

      onChange(result);
      return;
    }

    //
    // Editing
    //
    const tempTaxDocuments = [...value];
    const tempUpdatedTaxDocument = { ...updatedTaxDocument };

    // Default first selection to 1
    if (
      tempUpdatedTaxDocument.id !== "" &&
      tempUpdatedTaxDocument.count === 0
    ) {
      tempUpdatedTaxDocument.count = 1;
    }
    tempTaxDocuments.splice(index, 1, tempUpdatedTaxDocument);

    const result = [...tempTaxDocuments];
    onChange(result);
  };

  if (isAppendicesFactsLoading || !appendicesFacts) {
    <Spinner size={SpinnerSize.ExtraSmall} />;
  }

  return (
    <Row className="gy-md">
      <Col xs={12}>
        <table>
          <thead className={disabled ? "o-50" : ""}>
            <tr className="background-gray-400 ">
              <th>{translate("ADDITIONAL_TAX_DOCUMENTS")}</th>
              <th />
            </tr>

            {true && (
              <tr>
                <th>{translate("TAX_DOCUMENTS")}</th>
                <th>{translate("AMOUNT")}</th>
              </tr>
            )}
          </thead>

          <tbody>
            {!value.length && (
              <tr>
                <td colSpan={3}>
                  {translate("ADDITIONAL_TAX_DOCUMENTS_EMPTY")}
                </td>
              </tr>
            )}

            {value.length > 0 &&
              value.map((taxDocument, index) => (
                <tr key={taxDocument.id}>
                  <td width="80%">
                    <AdditionalTaxDocumentNameDropdown
                      index={index}
                      value={taxDocument}
                      disabledOptions={value}
                      onChange={handleEditAdditionalTaxDocument}
                      disabled={disabled}
                    />
                  </td>
                  <td>
                    <AdditionalTaxDocumentCountDropdown
                      index={index}
                      value={taxDocument}
                      onChange={handleEditAdditionalTaxDocument}
                      disabled={taxDocument.id === "" || disabled}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Col>

      {value.length < (appendicesFacts?.length ?? -1) && !disabled ? (
        <Col xs={12}>
          <AddButton onClick={handleAddAdditionalTaxDocument}>
            {translate("ADD_ADDITIONAL_TAX_DOCUMENT")}
          </AddButton>
        </Col>
      ) : null}
    </Row>
  );
}
