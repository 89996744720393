import { Spinner } from "@fluentui/react-components";

import { Dropdown } from "components/dropdown";
import { SpinnerSize } from "components/spinner";
import { ActivityAppendix } from "models/activities/ActivityAppendix";
import { useGetAppendicesFactsQuery } from "api/activitiesApi";

type AdditionalTaxDocumentCountDropdownProps = {
  index: number;
  value: ActivityAppendix;
  onChange: (value: ActivityAppendix, index: number) => void;
  disabled?: boolean;
};

export function AdditionalTaxDocumentCountDropdown({
  index,
  value,
  onChange,
  disabled,
}: AdditionalTaxDocumentCountDropdownProps) {
  const { data: appendicesFacts, isLoading: isAppendicesFactsLoading } =
    useGetAppendicesFactsQuery();

  const handleOnChange = (selectedValue: number) => {
    const newValue = { ...value, count: selectedValue };
    onChange(newValue, index);
  };

  if (isAppendicesFactsLoading || !appendicesFacts) {
    return <Spinner size={SpinnerSize.ExtraSmall} />;
  }

  const appendicesFactsCountOptions = () => {
    // Add +1 so that we can select count 50
    // Keep 0 as an option as a way to remove the item
    const arrayWithNumbers = Array.from(Array(value.max_count + 1).keys());

    return arrayWithNumbers.map((number) => {
      return {
        label: number.toString(),
        value: number === 0 ? -1 : number, // because <Dropdown> sees 0 as falsy
      };
    });
  };

  return (
    <Dropdown
      disabled={isAppendicesFactsLoading || disabled}
      options={appendicesFactsCountOptions()}
      value={value.count}
      placeholder="-"
      onChange={handleOnChange}
    />
  );
}
