import { useEffect, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";

import { useTranslation } from "hooks/use-translate";
import { Dropdown } from "components/dropdown/Dropdown";
import { Deal } from "models/deals/deal";
import { DealDropdownProps } from "models/deals/fieldTypes";
import { useUpdateDealProperty } from "hooks/myBusinessOpportunities/use-update-deal-property";

import "./DealFieldsStyles.scss";

function DealDropdown({
  configuration,
  disabled = false,
  stage,
}: DealDropdownProps) {
  const { translate } = useTranslation();
  const { isLoading } = useUpdateDealProperty();

  const isDisabled = isLoading || disabled;

  const {
    formState: { errors },
    register,
    setValue,
    watch,
    trigger,
  } = useFormContext<Partial<Deal>>();

  const error = errors[configuration.property] as FieldError;
  const value = watch(configuration.property) as string;

  const [selectedId, setSelectedId] = useState(value);

  const dropdownOptions = !configuration.options
    ? []
    : configuration.options.map((option) => {
        const dropdownOption = {
          value: option.value,
          text: option.label,
        };
        return dropdownOption;
      });

  useEffect(() => {
    setSelectedId(value);
  }, [value]);

  const dealDropdownOptions = register(configuration.property, {
    validate: {
      isValid: (val) => {
        if (
          stage?.configuration?.RequiredFields.includes(
            configuration.property
          ) &&
          !val
        ) {
          return translate("FIELD_REQUIRED");
        }
      },
    },
  });

  return (
    <Dropdown
      disabled={isDisabled}
      formRegister={dealDropdownOptions}
      placeholder={configuration.placeholder}
      dropdownClassName={`deal-dropdown ${
        isDisabled ? "deal-dropdown-disabled" : ""
      }`}
      onOptionSelect={(_, data) => {
        setValue(configuration.property, data.selectedOptions[0], {
          shouldValidate: true,
          shouldDirty: true,
        });
      }}
      onBlur={(e) => {
        e.preventDefault();
        trigger();
      }}
      errorMessage={error?.message}
      defaultSelectedOptions={[selectedId]}
      selectedOptions={[selectedId]}
      options={dropdownOptions}
    />
  );
}

export default DealDropdown;
