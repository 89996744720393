import { ServiceDescription } from "models/serviceDescription";

export const SET_SERVICES = "services/SET_SERVICES";
export const LOADING_SERVICES = "services/LOADING_SERVICES";

export interface SetServicesAction {
  type: typeof SET_SERVICES;
  payload: ServiceDescription[];
}

export interface LoadingServicesAction {
  type: typeof LOADING_SERVICES;
  payload: boolean;
}

export type ServicesAction = SetServicesAction | LoadingServicesAction;
