import { Checkmark16Regular, Dismiss16Regular } from "@fluentui/react-icons";

import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { useTranslation } from "hooks/use-translate";

type Props = {
  isOpen: boolean;
  disableSave: boolean;
  setOpenModal: (isOpen: boolean) => void;
  handleOnSave: () => void;
  handleResetForm: () => void;
};

export default function StageDealConfirmationModal({
  isOpen,
  disableSave,
  setOpenModal,
  handleOnSave,
  handleResetForm,
}: Props) {
  const { translate } = useTranslation();
  return (
    <Modal
      size="medium"
      header={<ModalHeader headerTitleText="UNSAVED_CHANGES" />}
      isOpen={isOpen}
      onDismiss={() => setOpenModal(false)}
      footer={
        <ModalFooter
          onSave={handleOnSave}
          onCancel={handleResetForm}
          isDisabled={disableSave}
          cancelButtonIcon={<Dismiss16Regular />}
          sendButtonIcon={<Checkmark16Regular />}
          labelSubmit="MY_BUSINESS_OPPORTUNITIES.PANEL.SAVE_CHANGES"
          labelCancel="MY_BUSINESS_OPPORTUNITIES.PANEL.DISCARD_CHANGES"
        />
      }
    >
      <div className="text-center">
        <p className="fw-600">
          {translate("MY_BUSINESS_OPPORTUNITIES.PANEL.UNSAVED_CHANGES_TITLE")}
        </p>
        <p>
          {translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.UNSAVED_CHANGES_SUBTITLE"
          )}
        </p>
      </div>
    </Modal>
  );
}
