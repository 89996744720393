import { PillsShowDeliveriesOrServicesEnum } from "constants/enums/showDeliveriesOrServices.enum";
import { GetActivitiesPerAssignedUserResponse } from "./activity";

export const EMPTY = "N/A";

export type PillsShowDeliveriesOrServicesType =
  | PillsShowDeliveriesOrServicesEnum.Deliveries
  | PillsShowDeliveriesOrServicesEnum.Services
  | PillsShowDeliveriesOrServicesEnum.Both;

export type PillsDataType = number | typeof EMPTY;

export interface PillsData {
  customers: PillsDataType;
  deliveries: PillsDataType;
  services: PillsDataType;
  activities: PillsDataType;
}

export const emptyPillsData: PillsData = {
  customers: EMPTY,
  deliveries: EMPTY,
  services: EMPTY,
  activities: EMPTY,
};

export interface ActivityGroupedByCustomerId {
  customerId: string;
  activities: GetActivitiesPerAssignedUserResponse[];
  distinctActivityTypes: string[];
}
