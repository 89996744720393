import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
  LayoutWrapper,
} from "components/layout/Layout";
import { Content, ContentBody } from "components/content/Content";
import { ContentHeader } from "components/content/ContentHeader";
import { useTranslation } from "hooks/use-translate";
import Breadcrumb from "components/breadcrumb";
import SearchInput from "components/input/search";
import { ManageCustomersContext } from "../context/ManageCustomersContext";
import CustomersTable from "./CustomersTable";
import ChangeEmployeeModal from "./ChangeEmployeeModal";
import ChangeInManagementMenuButton from "./ChangeInManagementMenuButton";
import { ManageCustomersRouteHelper } from "../routes/manageCustomersRoutes";

export default function ListOfCustomers() {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const {
    setCustomerFilter,
    assignee,
    assigneeFullName,
    selectedRows,
    unlockedCustomers,
  } = useContext(ManageCustomersContext);

  if (!assignee) {
    throw new Error("Missing assignee!");
  }

  const [showModal, setShowModal] = useState(false);

  const handleModalSave = () => {
    setShowModal(false);

    navigate(ManageCustomersRouteHelper.getUpdateInProgress());
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <Breadcrumb
            items={[
              {
                text: translate("MANAGE_CUSTOMERS.BREADCRUMBS_FIRST_PART"),
                onClick: () =>
                  navigate(ManageCustomersRouteHelper.getLandingPage()),
              },
              { text: assigneeFullName },
            ]}
          />
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          <SearchInput
            placeholder={translate(
              "MANAGE_CUSTOMERS.FILTER_BY_NAME.PLACEHOLDER"
            )}
            onChange={(_, { value }) => setCustomerFilter(value)}
          />
        </LayoutHeaderRight>
      </LayoutHeader>

      <LayoutWrapper>
        <Content>
          <ContentHeader
            title={translate("MANAGE_CUSTOMERS.CONTENT_HEADER_TITLE")}
            counter={unlockedCustomers.length}
            selectionCounter={selectedRows.size}
            content={
              <div className="w-100 d-flex justify-content-end">
                <ChangeInManagementMenuButton setShowModal={setShowModal} />
              </div>
            }
          />
          <ContentBody>
            <CustomersTable />
          </ContentBody>
        </Content>
      </LayoutWrapper>

      {showModal && (
        <ChangeEmployeeModal
          onSave={handleModalSave}
          onCancel={handleModalCancel}
        />
      )}
    </>
  );
}
