/* eslint-disable no-underscore-dangle */
import { useSelector } from "react-redux";
import { format } from "date-fns";

import { RootState } from "state";
import { useTranslation } from "hooks/use-translate";
import { Deal } from "models/deals/deal";
import { DateFormat } from "libs/date/date-format";
import { DropdownOption } from "components/dropdown";
import { companyTypes } from "constants/myBusinessOpportunitiesContsts";
import { DealType } from "constants/enums/myBusinessOpportunities.enum";

export function useGetStaticField() {
  const { translate } = useTranslation();
  const sales = useSelector((state: RootState) => state.sales);

  const dealsOptions = sales.dealOptions;

  const dealSources = dealsOptions.data.find((d) => d.name === "deal_source");

  const dealEconomyServicesOptions = dealsOptions.data.find(
    (d) => d.name === "efterfr_gade_tj_nster_ekonomi"
  );

  const dealMeetingType = dealsOptions.data.find(
    (d) => d.name === "onskemal_typ_av_mote"
  );

  const dealsVardeIndikatorAvkastningsFormaga = dealsOptions.data.find(
    (d) =>
      d.name ===
      "vardeindiktator_fastighet_i_jamforelse_med_andra_fastigheter_i_ditt_omr_de_avkastningsf_rm_ga_arron"
  );

  const dealsVardeIndikator = dealsOptions.data.find(
    (d) =>
      d.name ===
      "vardeindiktator_fastighet_i_jamforelse_med_andra_fastigheter_i_ditt_omrade"
  );

  const dealClosestOffice = dealsOptions.data.find(
    (d) => d.name === "narmaste_kontor_deal"
  );

  const getStaticField = (property: keyof Deal, deal: Deal) => {
    switch (property) {
      case "bolagsform": {
        const selectedOption = companyTypes.find(
          (c) => c.value === deal.bolagsform
        );
        return selectedOption ? translate(selectedOption.label) : "-";
      }

      case "narmaste_kontor_deal": {
        const selectedOption = (dealClosestOffice?.options || []).find(
          (o) => o.value === deal.narmaste_kontor_deal
        );
        return selectedOption?.label || "-";
      }

      case "createdAt": {
        if (deal[property]) {
          return format(deal.createdAt, DateFormat.YearDayMonth);
        }
        return null;
      }

      case "onskemal_typ_av_mote": {
        const selectedOption = (dealMeetingType?.options || []).find(
          (o) => o.value === deal.onskemal_typ_av_mote
        );
        return selectedOption?.label || "-";
      }

      case "efterfr_gade_tj_nster_ekonomi": {
        if (!dealEconomyServicesOptions) {
          return "Kunde ej hämta tjänster";
        }
        const selectedOptions = (deal[property] || []).map((p) =>
          (dealEconomyServicesOptions.options || []).find(
            (po) => po.value === p
          )
        ) as DropdownOption<string>[];
        return (
          selectedOptions
            .filter((o) => !!o)
            .map((o) => o.label)
            .join(", ") || "-"
        );
      }

      case "vardeindikator_storlek_akermark_hektar_" ||
        "vardeindikator_storlek_skogsmark_hektar_":
        return deal[property] ? `${deal[property]} ha` : "-";

      case "v_rdeindikator_storlek_skogsmark_m3sk_" ||
        "v_rdeindikator_storlek_skogsmark_m3sk_":
        return deal[property] ? `${deal[property]} m3sk` : "-";

      case "v_rdeindikator_resultat":
        return deal[property] ? `${deal[property]} SEK` : "-";

      case "vardeindiktator_fastighet_i_jamforelse_med_andra_fastigheter_i_ditt_omr_de_avkastningsf_rm_ga_arron": {
        const selectedOption = (
          dealsVardeIndikatorAvkastningsFormaga?.options || []
        ).find((o) => o.value === deal[property]);

        return selectedOption?.label || "-";
      }

      case "vardeindiktator_fastighet_i_jamforelse_med_andra_fastigheter_i_ditt_omrade": {
        const selectedOption = (dealsVardeIndikator?.options || []).find(
          (o) => o.value === deal[property]
        );

        return selectedOption?.label || "-";
      }

      case "recent_conversion_event_name":
        return (deal[property] || "-").split("|").slice(-1);

      case "deal_type": {
        const options = Object.entries(DealType).map((d) => {
          return {
            value: d[1],
            label: d[0],
          };
        });

        return options.find((o) => o.value === deal[property])?.label || "-";
      }

      case "deal_source": {
        const selectedOption = dealSources?.options.find(
          (o) => o.value === deal[property] || ""
        );
        return selectedOption?.label || "-";
      }

      case "inbound_outbound": {
        const translations = {
          inbound: translate("MY_BUSINESS_OPPORTUNITIES.FILTERS.INBOUND"),
          outbound: translate("MY_BUSINESS_OPPORTUNITIES.FILTERS.OUTBOUND"),
        };
        const value = deal[property];

        return !value ? "-" : translations[value];
      }

      default:
        return deal[property] || "-";
    }
  };

  return {
    getStaticField,
  };
}
