/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";

import { useTranslation } from "hooks/use-translate";
import { normalizeOrgNrAndSSN } from "libs/number-format";
import { CustomersAPI } from "api/customers";
import { authMethod } from "auth";
import { Customer } from "models/customer";
import { Deal } from "models/deals/deal";
import { RootState } from "state";
import StageDealReturnModal from "./components/DealReturnModal";
import "./StageDeal.scss";
import { StageDealPanel } from "./components/StageDealPanel";
import { StageCardContent } from "./components/StageCardContent";

type Props = {
  deal: Deal;
  index: number;
};

export function StageDeal({ deal, index }: Props) {
  const { translate } = useTranslation();

  const { dealOptions, isLoading } = useSelector(
    (state: RootState) => state.sales
  );

  const [companyInfoLoading, setCompanyInfoLoading] = useState(false);
  const [createPogOfferLoading, setCreatePogOfferLoading] = useState(false);
  const [showReturnToManagerModal, setShowReturnToManagerModal] =
    useState(false);

  const [isPanelMounted, setIsPanelMounted] = useState(false);

  const [dealCompany, setDealCompany] = useState<Customer | undefined>();

  const getSpinnerLabel = () => {
    if (isLoading || companyInfoLoading) {
      return translate("DEAL_LOADING");
    }
    if (createPogOfferLoading) {
      return translate("CREATE_POG_OFFER_LOADER");
    }
  };

  const fetchCompanyInfo = useCallback(async () => {
    if (deal.organisationsnummer) {
      try {
        const token = await authMethod.getStoredAccessToken();
        const normalizedNumber = normalizeOrgNrAndSSN(deal.organisationsnummer);
        const customer = await CustomersAPI.fetchCustomerById(
          token,
          normalizedNumber
        );
        return customer;
      } catch (e) {
        return undefined;
      }
    }

    return undefined;
  }, [deal.organisationsnummer]);

  useEffect(() => {
    const fetchAndSetCompany = async () => {
      const company = await fetchCompanyInfo();
      setDealCompany(company);
      setCompanyInfoLoading(false);
    };
    if (isPanelMounted) {
      setCompanyInfoLoading(true);
      fetchAndSetCompany();
    }
  }, [deal.productIds.length, fetchCompanyInfo, isPanelMounted]);

  return (
    <div className="mb-3">
      <Draggable
        disableInteractiveElementBlocking
        draggableId={deal.id}
        index={index}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <StageCardContent
              deal={deal}
              dealOptions={dealOptions.data}
              setIsPanelMounted={setIsPanelMounted}
            />
          </div>
        )}
      </Draggable>

      {isPanelMounted && (
        <StageDealPanel
          deal={deal}
          isOpen={isPanelMounted}
          onDismiss={setIsPanelMounted}
          loading={isLoading || companyInfoLoading || createPogOfferLoading}
          label={getSpinnerLabel()}
          company={dealCompany}
          setShowReturnToManagerModal={setShowReturnToManagerModal}
          setCreatePogOfferLoading={setCreatePogOfferLoading}
        />
      )}

      {showReturnToManagerModal && (
        <StageDealReturnModal
          openModal={showReturnToManagerModal}
          setOpenModal={setShowReturnToManagerModal}
          deal={deal}
        />
      )}
    </div>
  );
}
