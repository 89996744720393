import React, { useCallback, useEffect, useState, ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { Dropdown } from "components/dropdown/Dropdown";
import { RHFInput } from "components/input/RHFInput";
import Switch from "components/switch";
import { ModalFooter } from "components/modal/ModalFooter";
import { useTranslation } from "hooks/use-translate";
import { isEqualSSN } from "libs/is-equal-ssn";
import { isValidSwedishSSN } from "libs/is-valid-ssn";
import { getDigitsFromString, normalizeOrgNrAndSSN } from "libs/number-format";
import { CompanyTypes } from "models/offer/Company";
import { Service } from "models/offer/ServiceLine";
import { TaxObjectDetails, TaxObjectState } from "models/offer/TaxObject";
import { TaxObjectService } from "models/offer/TaxObjectService";
import { useGetCompanyTypesQuery } from "api/ludvigApi";
import { RootState } from "state";

interface TaxObjectFormProps {
  isForCompany?: boolean;
  taxObject?: TaxObjectDetails;
  dealTaxServices: TaxObjectService[];
  customerTaxObjects?: TaxObjectDetails[];
  showInModal: boolean;
  isFormDisabled?: boolean;
  incomeTaxServicesTemplate: Service[];
  setModalFooter?: (footer: ReactNode) => void;
  onSave: (
    taxObjectDetails: TaxObjectDetails,
    activeServices: TaxObjectService[]
  ) => void;
}

export function TaxObjectForm({
  isForCompany = false,
  onSave,
  taxObject,
  dealTaxServices,
  customerTaxObjects,
  incomeTaxServicesTemplate: allServices,
  showInModal = false,
  setModalFooter,
  isFormDisabled = false,
}: TaxObjectFormProps) {
  const { translate } = useTranslation();
  const { currentUser } = useSelector((state: RootState) => state.users);

  const isNewTaxObject = !taxObject;

  const taxFormInitValues = !isNewTaxObject
    ? TaxObjectDetails.getUpdatedTaxDetailsObject(taxObject, currentUser)
    : TaxObjectDetails.getNewTaxObjectDetails(currentUser);

  const {
    register,
    getValues,
    setValue,
    formState: { errors, isValid },
    watch,
    trigger,
  } = useForm<TaxObjectDetails>({
    mode: "onTouched",
    defaultValues: taxFormInitValues,
  });

  const isFormValid = isForCompany || isValid;

  const { data: companyTypeList } = useGetCompanyTypesQuery();

  const formSSN = watch("social_security_number");

  const getSelectedServicesIds = useCallback(
    (ssn: string) => {
      const selectedServices = dealTaxServices.filter((dealTaxService) =>
        isEqualSSN(
          dealTaxService.customer_tax_object.social_security_number,
          ssn
        )
      );

      const selectedServiceMatrixIds = selectedServices.map(
        (tos) => tos.service_matrix_id
      );

      return selectedServiceMatrixIds;
    },
    [dealTaxServices]
  );

  const [selectedTaxServices, setSelectedTaxServices] = useState<string[]>([]);

  useEffect(() => {
    // Cleanup
    return () => setSelectedTaxServices([]);
  }, []);

  useEffect(() => {
    setSelectedTaxServices(getSelectedServicesIds(formSSN));
  }, [formSSN, getSelectedServicesIds]);

  useEffect(() => {
    if (showInModal && setModalFooter) {
      setModalFooter(
        <ModalFooter
          labelSubmit={isNewTaxObject ? "CREATE" : "UPDATE"}
          onSave={() => handleOnSave()}
          isDisabled={
            !isFormValid || (isForCompany && !selectedTaxServices.length)
          }
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFormValid,
    isNewTaxObject,
    selectedTaxServices.length,
    setModalFooter,
    showInModal,
  ]);

  if (!companyTypeList) {
    return (
      <>
        {translate(
          "CREATE_NEW.OFFER.ACCOUNTING.INCOME_TAX.TAX_OBJECT_FORM.MISSING_COMPANY_TYPE_LIST"
        )}
      </>
    );
  }

  const getSelectionAsTaxObjectServices = (
    taxObjectServiceMatrixIds?: string[]
  ): TaxObjectService[] => {
    const formTaxObjectDetails = getValues();

    if (taxObjectServiceMatrixIds) {
      const result = taxObjectServiceMatrixIds.map((serviceMatrixId) => ({
        customer_tax_object: formTaxObjectDetails,
        service_matrix_id: serviceMatrixId,
      }));

      return result;
    }

    const result = selectedTaxServices.map((serviceMatrixId) => ({
      customer_tax_object: formTaxObjectDetails,
      service_matrix_id: serviceMatrixId,
    }));

    return result;
  };

  const handleOnSave = (taxObjectServiceMatrixIds?: string[]) => {
    const taxObjectDetailsForm = getValues();
    if (taxObjectDetailsForm.state === TaxObjectState.INACTIVE) {
      onSave(taxObjectDetailsForm, []);
    } else {
      onSave(
        taxObjectDetailsForm,
        getSelectionAsTaxObjectServices(taxObjectServiceMatrixIds)
      );
    }
  };

  function updateTaxObjectDetails() {
    if (isFormValid) {
      if (!showInModal) {
        handleOnSave();
      }
    }
  }

  function addIncomeTaxService(service: Service) {
    if (isFormValid) {
      const result = [...selectedTaxServices, service.service_matrix_id];
      setSelectedTaxServices(result);

      if (!showInModal) {
        handleOnSave(result);
      }
    }
  }

  function removeIncomeTaxService(service: Service) {
    if (isFormValid) {
      const result = selectedTaxServices.filter(
        (serviceMatrixId) => serviceMatrixId !== service.service_matrix_id
      );
      setSelectedTaxServices(result);

      if (!showInModal) {
        handleOnSave(result);
      }
    }
  }

  const isToggleChecked = (service: Service) => {
    if (getValues().state !== TaxObjectState.INACTIVE) {
      return selectedTaxServices.some(
        (serviceMatrixId) => serviceMatrixId === service.service_matrix_id
      );
    }
    return false;
  };

  function renderToggles(services: Service[]): React.ReactNode {
    return services.map((service) => (
      <Switch
        className="m-1"
        key={`${service.service_matrix_id}`}
        label={service.name}
        checked={isToggleChecked(service)}
        disabled={
          isFormDisabled ||
          !isFormValid ||
          (isForCompany && service.input_is_disabled) ||
          getValues().state === TaxObjectState.INACTIVE
        }
        onToggleMethod={(_, { checked }) => {
          if (!checked) {
            removeIncomeTaxService(service);
          } else {
            addIncomeTaxService(service);
          }
        }}
      />
    ));
  }

  const isValidSSNForTaxObj = (ssn: string) => {
    const normalizedSSN = normalizeOrgNrAndSSN(ssn);

    const taxObjectSsn = dealTaxServices.flatMap((taxService) =>
      normalizeOrgNrAndSSN(
        taxService.customer_tax_object.social_security_number
      )
    );

    const customerTaxObjectSnn = customerTaxObjects?.flatMap(
      (customerTaxObject) =>
        normalizeOrgNrAndSSN(customerTaxObject.social_security_number)
    );

    if (!isForCompany && ssn.length !== 12) {
      return translate("INVALID_SSN_LENGTH");
    }

    if (
      (isNewTaxObject || taxObject.social_security_number !== ssn) &&
      (taxObjectSsn.includes(normalizedSSN) ||
        customerTaxObjectSnn?.includes(normalizedSSN))
    ) {
      return translate("DUPLICATE_SSN_MESSAGE");
    }

    return isValidSwedishSSN(ssn, !isForCompany);
  };

  const ssn = register("social_security_number", {
    required: translate("SOCIAL_SECURITY_NUMBER_REQUIRED"),
    validate: {
      isValid: (value) =>
        isValidSSNForTaxObj(value) || translate("INVALID_SSN_MESSAGE"),
    },
    onChange: (e) => {
      updateTaxObjectDetails();
    },
  });

  const firstName = register("first_name", {
    required: translate("FIRSTNAME_REQUIRED"),
    onChange: (e) => {
      updateTaxObjectDetails();
      trigger("first_name");
    },
  });

  const lastName = register("last_name", {
    required: translate("LASTNAME_REQUIRED"),
    onChange: (e) => {
      updateTaxObjectDetails();
      trigger("last_name");
    },
  });

  const renderForm = (
    <Row className="py-lg">
      {!isForCompany && (
        <>
          <Row className="pt-md">
            <Col>
              <label className="fw-bold">{translate("TAX_FORM_TITLE")}</label>
            </Col>
          </Row>

          <Col md={5} className="mb-sm">
            <Row className="py-md w-50">
              <Col>
                <label className="p-0 fw-bold">{translate("SSN")}</label>
                <RHFInput
                  placeholder={translate("SSN")}
                  errorMessage={errors.social_security_number?.message}
                  disabled={isFormDisabled}
                  register={ssn}
                  onChange={({ target }) => {
                    setValue(
                      "social_security_number",
                      getDigitsFromString(target.value),
                      { shouldValidate: true }
                    );
                  }}
                />
              </Col>
            </Row>

            <Row className="py-md">
              <Col>
                <label className="p-0 fw-bold">{translate("FIRST_NAME")}</label>
                <RHFInput
                  placeholder={translate("FIRST_NAME")}
                  errorMessage={errors.first_name?.message}
                  disabled={isFormDisabled}
                  register={firstName}
                />
              </Col>

              <Col>
                <label className="p-0 fw-bold">{translate("LAST_NAME")}</label>
                <RHFInput
                  placeholder={translate("LAST_NAME")}
                  errorMessage={errors.last_name?.message}
                  disabled={isFormDisabled}
                  register={lastName}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <label className="p-0 fw-bold">
                  {translate("COMPANY_TYPE")}
                </label>
                <Dropdown
                  disabled
                  formRegister={register("type", {
                    required: translate("COMPANY_TYPE_REQUIRED"),
                  })}
                  options={companyTypeList.map((cti) => ({
                    value: cti.CapegoID as string,
                    text: cti.NameSE,
                  }))}
                  onOptionSelect={(_, data) => {
                    if (data.optionValue) {
                      setValue("type", data.optionValue as CompanyTypes, {
                        shouldValidate: true,
                      });
                      updateTaxObjectDetails();
                    }
                  }}
                  selectedOptions={[getValues("type") ?? ""]}
                />
              </Col>

              <Col>
                <label className="p-0 fw-bold">
                  {translate("INCOME_TAX_STATUS")}
                </label>
                <Dropdown
                  formRegister={register("state")}
                  options={[
                    {
                      text: translate("ACTIVE"),
                      value: TaxObjectState.ACTIVE,
                    },
                    {
                      text: translate("INACTIVE"),
                      value: TaxObjectState.INACTIVE,
                    },
                  ]}
                  onOptionSelect={(_, data) => {
                    if (data.optionValue) {
                      setValue("state", data.optionValue as TaxObjectState, {
                        shouldValidate: true,
                      });
                      updateTaxObjectDetails();
                    }
                  }}
                  selectedOptions={[getValues("state") ?? ""]}
                />
              </Col>
            </Row>
          </Col>
          <Col md={1} className="d-flex justify-content-center">
            <div className="d-flex">
              <div className="vertical-divider" />
            </div>
          </Col>
        </>
      )}

      <Col md={isForCompany ? 12 : 6} className="mb-sm">
        <Row className="pt-xs">
          {allServices.length > 0 && (
            <>
              <Col>{renderToggles(allServices.slice(0, 4))}</Col>
              {allServices.length > 4 && (
                <Col>{renderToggles(allServices.slice(4, 8))}</Col>
              )}
              {allServices.length > 8 && (
                <Col>{renderToggles(allServices.slice(8))}</Col>
              )}
            </>
          )}
        </Row>
      </Col>
    </Row>
  );

  return <div>{renderForm}</div>;
}
