import { StageConfiguration } from "models/deals/stagesConfig";

export class Stage {
  id: string;

  label: string;

  display_order: number;

  /**
   * Stage configuration describes how deals interact with the stage, ie. when moving a deal to this stage etc.
   */
  configuration?: StageConfiguration;
}

export type ActiveFilters = {
  selectedDateRange?: string;
  inboundOutboundDeals?: string;
};
