import { CustomerDeal } from "models/offer/CustomerDeal";
import { getAvailableWizardSteps } from "views/createNew/offer/routes/offerRoutes";

/**
 * Generate the routes for the wizard, based on the contents of the customer offer.
 * @param currentLocationPath The current location.
 * @param currentOffer The customer offer to use for generating the routes.
 * @returns The routes for the wizard.
 */
export function useRoutingForOffer(
  currentLocationPath: string,
  currentOffer?: CustomerDeal
) {
  const availableSteps = getAvailableWizardSteps(currentOffer);
  const stepsWithState = availableSteps
    .filter((s) => !s.disabled)
    .flatMap((a) => a.steps)
    .filter((step) => !step.disabled)
    .map((step) => ({ ...step, valid: step.validate() }));

  // strip the current path from the url params if there are any
  const currentPathParamsIndex = currentLocationPath.search(/\/[0-9]/);
  const currentBasePath =
    currentPathParamsIndex > 0
      ? currentLocationPath.slice(0, currentPathParamsIndex)
      : currentLocationPath;

  const isQuickEdit = currentLocationPath.includes("/edit");
  const activeStepIndex = stepsWithState.findIndex((s) =>
    isQuickEdit ? `${s.path}/edit` : s.path === currentBasePath
  );

  const nextStep = () => {
    if (!stepsWithState?.[activeStepIndex]?.nextRoute) {
      return;
    }

    return isQuickEdit
      ? stepsWithState[stepsWithState.length - 1]
      : stepsWithState[activeStepIndex + 1];
  };

  const previousStep = () => {
    if (!stepsWithState?.[activeStepIndex]?.previousRoute) {
      return;
    }

    return stepsWithState[activeStepIndex - 1];
  };

  return {
    previous: previousStep(),
    next: nextStep(),
    current: stepsWithState[activeStepIndex],
    steps: stepsWithState,
  };
}
