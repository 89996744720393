/* eslint-disable no-underscore-dangle */
import { plainToClass } from "class-transformer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ContextualMenu, {
  MenuGroups,
} from "components/contextualMenu/ContextualMenu";
import {
  addServiceLinesAndGroupsToPogOffer,
  createOrUpdateHSContactInPogOffer,
} from "helpers/DealToPogHelper";
import { useTranslation } from "hooks/use-translate";
import { capitalize } from "libs/capitalize";
import { EKONOMI_BUSINESS_AREA } from "constants/servicesConsts";
import { useServiceMatrix } from "libs/service-matrix";
import { Customer } from "models/customer";
import { Deal } from "models/deals/deal";
import { LegalFormCodes } from "models/offer/Company";
import { DealCustomer } from "models/offer/Customer";
import { useGetCompanyTypesQuery } from "api/ludvigApi";
import { RootState } from "state";
import {
  SearchBy,
  createOrUpdateCustomer,
  fetchCompanyInfoFromSales,
  fetchCustomerAndAddToOffer,
} from "state/offer/companyThunks";
import {
  setSelectedCustomerSource,
  updateCurrentOffer,
  updateOfferCustomer,
} from "state/offer/offersSlice";
import { resetOffer } from "state/offer/offersThunks";
import { setCurrentDeal } from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import { hasValidOfferURL } from "libs/is-pog-deal";
import { availableTogglesMeta } from "views/createNew/offer/components/servicePicker/ServicePickerHelper";
import { OfferRouteHelper } from "views/createNew/offer/routes/offerRoutes";
import { CustomerWizardRouteHelper } from "views/createNew/customers/routes/createCustomerWizardRoutes";

type Props = {
  deal: Deal;
  company?: Customer;
  setShowReturnToManagerModal: (showReturnToManagerModal: boolean) => void;
  setCreatePogOfferLoading: (isLoading: boolean) => void;
};

export function StageDealActions({
  deal,
  company,
  setShowReturnToManagerModal,
  setCreatePogOfferLoading,
}: Props) {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  useGetCompanyTypesQuery();

  const deals = useSelector((state: RootState) => state.sales);
  const {
    products: { data: products },
  } = useSelector((state: RootState) => state.sales);
  const {
    currentOffer: { data: currentOffer },
    offerTemplate: { data: offerTemplate },
  } = useSelector((state: RootState) => state.offers);
  const { currentUser } = useSelector((state: RootState) => state.users);

  const offerCustomer = currentOffer?.customer;

  const dealCustomerUpdatedByUser = !offerCustomer?.created_by_username
    ? {
        created_by_username: currentUser.email,
        changed_by_username: currentUser.email,
      }
    : {
        changed_by_username: currentUser.email,
      };

  const { GetAllServiceGroupsFlattened, GetAllServiceLinesFlattened } =
    useServiceMatrix();

  const dealsBusinessAreas = deals.dealOptions.data.find(
    (dealOption) => dealOption.name === "affarsomrade_deal_"
  );

  const mappedDealProducts = deal.productIds.map(
    (productId) =>
      products
        ?.find((product) => product.id === productId)
        ?.description?.toLowerCase() ?? ""
  );

  const serviceGroups = GetAllServiceGroupsFlattened(offerTemplate);
  const mappedServiceGroups = serviceGroups
    .filter((group) => group.name)
    .map((group) => group.name.toLowerCase());

  const isDealWithEkonomiBusinessArea =
    dealsBusinessAreas?.options.find(
      (businessArea) => businessArea.value === deal.affarsomrade_deal_
    )?.value === EKONOMI_BUSINESS_AREA;

  const areAllDealProductsSupportedInServiceMatrix = mappedDealProducts.every(
    (productDescription) =>
      mappedServiceGroups.includes(productDescription.toLowerCase())
  );

  // pog offer creation is enabled only for Ekonomi business area and services
  // that are supported in the service-matrix since we can not create Pog offers
  // with unimplemented services
  const disableCreatePogOfferButton =
    !isDealWithEkonomiBusinessArea ||
    (deal.productIds.length > 0 && !areAllDealProductsSupportedInServiceMatrix);

  const serviceLines = GetAllServiceLinesFlattened(offerTemplate);

  const [displayCreateCustomerButton, setDisplayCreateCustomerButton] =
    useState(false);

  useEffect(() => {
    if (isDealWithEkonomiBusinessArea) {
      setDisplayCreateCustomerButton(false);
    } else {
      setDisplayCreateCustomerButton(!company);
    }
  }, [
    deal.productIds.length,
    areAllDealProductsSupportedInServiceMatrix,
    isDealWithEkonomiBusinessArea,
    company,
  ]);

  const returnToManager = () => {
    setShowReturnToManagerModal(true);
  };

  const goToPOGDeal = (url: string) => {
    const reditectPathname = new URL(url).pathname;
    navigate(reditectPathname);
  };

  // create Pog offer from Hubspot deal
  const onStartCreatingPogOfferClicked = async () => {
    setCreatePogOfferLoading(true);

    await dispatch(resetOffer());
    dispatch(setCurrentDeal(deal));
    try {
      const fethedCompany = await dispatch(
        fetchCompanyInfoFromSales({
          searchByParam: SearchBy.OrgNumber,
          customerId: deal.organisationsnummer,
        })
      ).unwrap();

      const customerHasAdressCityAndZip =
        !!fethedCompany.city && !!fethedCompany.zip && !!fethedCompany.address;

      if (fethedCompany && fethedCompany.org_number) {
        const dealCustomerPartial = DealCustomer.fromCompanyInfo(
          fethedCompany,
          currentUser
        );
        await dispatch(fetchCustomerAndAddToOffer(dealCustomerPartial));

        const offerDealCustomer = plainToClass(DealCustomer, {
          ...dealCustomerPartial,
        });

        const tempDealCustomerUpdatedByUser =
          !dealCustomerPartial.created_by_username
            ? {
                created_by_username:
                  dealCustomerPartial.created_by_username ?? currentUser.email,
                changed_by_username: currentUser.email,
              }
            : {
                changed_by_username: currentUser.email,
              };

        // only if all required field are defined we can update the customer
        if (customerHasAdressCityAndZip) {
          // this is needed to get the tax objects for the customer
          await dispatch(
            createOrUpdateCustomer(
              plainToClass(DealCustomer, {
                ...offerDealCustomer,
                ...tempDealCustomerUpdatedByUser,
              })
            )
          );
        }

        // adding the serviceLine and service groups to the offer
        // (eg. Accounting as service area, Income tax as service group etc.)
        addServiceLinesAndGroupsToPogOffer(
          offerDealCustomer,
          currentUser,
          mappedDealProducts,
          serviceLines,
          serviceGroups,
          dispatch
        );

        // create or update pog contact with deal's contact
        await createOrUpdateHSContactInPogOffer(deal, currentUser, dispatch);

        // set the hubspot_deal_id to the offer to link the new offer with the HS deal
        dispatch(
          updateCurrentOffer({
            hubspot_deal_id: deal.id,
          })
        );
        dispatch(setSelectedCustomerSource(deal.deal_source));

        setCreatePogOfferLoading(false);
        if (customerHasAdressCityAndZip) {
          // navigate the user to the offer contacts to select a signer and proceed
          navigate(OfferRouteHelper.getContactInfo());
        } else {
          // navigate the user to the customer details to populate the missing required fields
          navigate(OfferRouteHelper.getRegisterCompany());
        }
      }
    } catch (e) {
      dispatch(
        updateCurrentOffer({
          hubspot_deal_id: deal.id,
          new_customer: true,
        })
      );
      dispatch(
        updateOfferCustomer(
          plainToClass(DealCustomer, {
            ...offerCustomer,
            ...dealCustomerUpdatedByUser,
            org_number: deal.organisationsnummer,
            customer_name: deal.company,
            legal_form: deal.company
              ? LegalFormCodes.JointStockCompany
              : LegalFormCodes.PrivatePerson,
          })
        )
      );

      dispatch(setSelectedCustomerSource(deal.deal_source));
      setCreatePogOfferLoading(false);
      // navigate to offers/customer-type -> register flow
      navigate(OfferRouteHelper.getRegisterCompany());
    }
  };

  const onStartCreatingCustomerClicked = () => {
    const dealBusinessArea = dealsBusinessAreas?.options.find(
      (businessArea) => businessArea.value === deal.affarsomrade_deal_
    )?.label;
    if (dealBusinessArea) {
      // set the deal in redux to use it in the customer flow
      dispatch(setCurrentDeal(deal));
      navigate(CustomerWizardRouteHelper.getCustomerDetailsPageRoute());
    }
  };

  const dealHasAPackageServiceSelected = () => {
    return mappedDealProducts.some(
      (productDescription) =>
        availableTogglesMeta[capitalize(productDescription)] &&
        availableTogglesMeta[capitalize(productDescription)].isPackage
    );
  };

  const dealHasANonPackageServiceSelected = () => {
    return mappedDealProducts.some(
      (productDescription) =>
        availableTogglesMeta[capitalize(productDescription)] &&
        !availableTogglesMeta[capitalize(productDescription)].isPackage
    );
  };

  // pog offer creation should be disabled if business area is Ekonomi but there
  // are  selected services which are excluding like Income tax and Small business
  const hasIncompatibleProductsSelected =
    isDealWithEkonomiBusinessArea &&
    dealHasAPackageServiceSelected() &&
    dealHasANonPackageServiceSelected();

  const menuGruops: MenuGroups[] = [
    {
      title: translate("ACTIONS_SECTION"),
      groupItems: [
        {
          text: translate("CANT_HANDLE_REQ"),
          onClick: returnToManager,
          className: "ml-md",
        },
      ],
    },
  ];

  if (hasValidOfferURL(deal)) {
    menuGruops.push({
      title: translate("LINKS_SECTION"),
      groupItems: [
        {
          text: translate("TO_POG_OFFER"),
          onClick: () => goToPOGDeal(deal.pog_quote_url),
          className: "ml-md blue-link-hover",
        },
      ],
    });
  } else {
    menuGruops[0].groupItems.unshift({
      text: translate("CREATE_POG_OFFER"),
      disabled: disableCreatePogOfferButton,
      onClick: onStartCreatingPogOfferClicked,
      hasDivider: true,
      className: "ml-md",
      tooltip: {
        enabled: disableCreatePogOfferButton,
        content: hasIncompatibleProductsSelected
          ? "CREATE_POG_OFFER_INCOMPATIBLE_TOOLTIP"
          : "CREATE_POG_OFFER_DISABLED_TOOLTIP",
      },
    });
  }
  if (displayCreateCustomerButton) {
    menuGruops[0].groupItems.unshift({
      text: translate("CREATE_CUSTOMER"),
      onClick: onStartCreatingCustomerClicked,
      hasDivider: true,
      className: "ml-md",
    });
  }

  return <ContextualMenu menuGroups={menuGruops} />;
}
