import { useBoxContext } from "./BoxLayout";

type BoxActionProps = {
  title: string;
  onClick?: () => void;
  subtitle?: string;
  icon?: JSX.Element;
};

export function BoxAction({ title, onClick, subtitle, icon }: BoxActionProps) {
  const { disabled } = useBoxContext();

  const handleOnClick = () => {
    if (disabled) {
      return;
    }

    onClick?.();
  };

  return (
    <span className="box-action mt-auto" onClick={handleOnClick}>
      {!!icon && icon}
      <span className="ml-sm box-action-title">{title}</span>
      {subtitle && <span className="box-action-subtitle">{subtitle}</span>}
    </span>
  );
}
