/* eslint-disable camelcase */
import {
  createContext,
  useContext,
  useCallback,
  ReactNode,
  useMemo,
  useRef,
} from "react";
import { Customer } from "models/customer";
import {
  isCustomerWithRestrictedAccess,
  isUserTeamOwner,
} from "libs/customer-settings-helpers";
import { SearchByEnum } from "constants/enums/conveyorBelts.enum";
import { useSelector } from "react-redux";
import { RootState } from "state";
import { useCustomerDocumentsCompleted } from "hooks/conveyorBelt/use-completed-documents";
import {
  CustomerActivitiesServices,
  ServiceInstance,
} from "models/activities/activity";

interface CustomersContextProps {
  allCustomers: Customer[];
  filteredCustomers: Customer[];
}

type Props = {
  children: ReactNode;
  servicesFilter: (
    customerNumber: string,
    activitiesToFilter?: CustomerActivitiesServices[]
  ) => ServiceInstance[];
  handedDocs: boolean;
  customerSearchTerm: string;
  searchBy: string;
};

// Create the context
const CustomersContext = createContext<CustomersContextProps | undefined>(
  undefined
);

// Provider component
export function CustomersContextProvider({
  children,
  servicesFilter,
  handedDocs,
  customerSearchTerm,
  searchBy,
}: Props) {
  const { data: customers, isSearchResult } = useSelector(
    (state: RootState) => state.customers
  );

  const { currentUser } = useSelector((state: RootState) => state.users);

  const { timelineWindow } = useSelector(
    (state: RootState) => state.activities
  );

  const allCustomers = customers.map(
    (customersState) => customersState.customer
  );

  const filterCustomer = (customer: Customer) => {
    if (
      isSearchResult &&
      isCustomerWithRestrictedAccess(customer) &&
      !isUserTeamOwner(customer, currentUser)
    ) {
      return false;
    }

    if (!customerSearchTerm || searchBy !== SearchByEnum.MyCustomers) {
      return true;
    }

    const searchTerm = customerSearchTerm.toLowerCase();
    return (
      customer.salesperson_email.toLowerCase().includes(searchTerm) ||
      customer.customer_number.includes(customerSearchTerm) ||
      customer.name.toLowerCase().includes(searchTerm) ||
      customer
        .getManagers()
        .some((manager) => manager.email?.toLowerCase().includes(searchTerm))
    );
  };

  const sortedAndFilteredCustomers = allCustomers
    .filter(filterCustomer)
    .sort((a, b) => a.name.localeCompare(b.name));

  const allFilteredCustomersServices = sortedAndFilteredCustomers.flatMap(
    ({ customer_number }) => {
      return {
        customer_number,
        servicesFilter: servicesFilter(customer_number),
      };
    }
  );
  const sortedCustomerActivities = useCustomerDocumentsCompleted(
    timelineWindow,
    allFilteredCustomersServices
  );

  const filteredCustomerData = useRef<Customer[]>(sortedAndFilteredCustomers);

  const mergeSortedAndFilteredCustomers = useCallback(() => {
    for (let i = 0; i < sortedCustomerActivities.length; i++) {
      const foundCustomer = sortedAndFilteredCustomers.find(
        (f) => f.customer_number === sortedCustomerActivities[i].customer_id
      );

      const index = sortedAndFilteredCustomers.findIndex(
        ({ customer_number }) =>
          customer_number === sortedCustomerActivities[i].customer_id
      );

      sortedAndFilteredCustomers.splice(index, 1);

      if (foundCustomer) {
        sortedAndFilteredCustomers.unshift(foundCustomer);
      }
    }

    return sortedAndFilteredCustomers;
  }, [sortedAndFilteredCustomers, sortedCustomerActivities]);

  if (handedDocs) {
    filteredCustomerData.current = mergeSortedAndFilteredCustomers();
  }

  const filteredCustomers =
    filteredCustomerData.current.length > 0 && handedDocs
      ? filteredCustomerData.current
      : sortedAndFilteredCustomers;

  const value = useMemo(
    () => ({
      filteredCustomers,
      allCustomers,
    }),
    [filteredCustomers, allCustomers]
  );

  return (
    <CustomersContext.Provider value={value}>
      {children}
    </CustomersContext.Provider>
  );
}

export const useCustomersContext = () => {
  const context = useContext(CustomersContext);
  if (!context) {
    throw new Error(
      "useShowCustomersDetails must be used within a CustomersContextProvider"
    );
  }
  return context;
};
