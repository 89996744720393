import { Spinner } from "@fluentui/react-components";
import { SpinnerSize } from "components/spinner";
import { ReactNode, useContext } from "react";
import { Collapse } from "react-bootstrap";

import { AccordionContext } from "components/accordion/context/AccordionContextProvider";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import "../index.scss";

interface Props {
  children: ReactNode;
  loadingStatus?: string;
}

export function SolidAccordionContent({ children, loadingStatus }: Props) {
  const { isOpen } = useContext(AccordionContext);

  return (
    <Collapse in={isOpen} mountOnEnter unmountOnExit>
      <div>
        <div className="accordion-item-content">
          {loadingStatus && loadingStatus === LoadingStatusEnum.PENDING ? (
            <Spinner size={SpinnerSize.Tiny} />
          ) : (
            children
          )}
        </div>
      </div>
    </Collapse>
  );
}
