import { DataHistogram16Regular } from "@fluentui/react-icons";

import { TranslationKey } from "i18n";
import StatusIndicatorIcon from "components/icon/StatusIndicatorIcon";
import Tooltip from "components/tooltip/Tooltip";
import "./index.scss";

type CustomerOverviewReportsLinkProps = {
  content: TranslationKey;
  onClick?: () => void;
};
export default function CustomerOverviewReportsLink({
  content,
  onClick,
}: CustomerOverviewReportsLinkProps) {
  return onClick ? (
    <Tooltip content={content}>
      <DataHistogram16Regular onClick={onClick} className="cursor-pointer" />
    </Tooltip>
  ) : (
    <Tooltip content={content}>
      <StatusIndicatorIcon status="offline" />
    </Tooltip>
  );
}
