import { Customer, ProjectManagerForCustomer } from "models/customer";
import { useSelector } from "react-redux";
import { RootState } from "state";

export default function useGetCustomerTeam({
  customer,
}: {
  customer: Customer;
}) {
  const {
    activitiesTeams: { data: activitiesTeams },
  } = useSelector((state: RootState) => state.activities);
  const { data: activities } = useSelector(
    (state: RootState) => state.activities
  );

  const customerProject: ProjectManagerForCustomer[] = customer.projects.map(
    (project) => {
      return {
        email: project.projectManagerUsername,
        name: project.projectManagerName,
        projectType: project.projectType,
      };
    }
  );

  function distinctProjectManagerEmails(
    projectManagers: ProjectManagerForCustomer[]
  ) {
    const map = new Map();

    projectManagers.forEach(({ email, name, projectType }) => {
      if (map.has(email)) {
        const existing = map.get(email);

        if (projectType) {
          existing.projectType += existing.projectType
            ? `, ${projectType}`
            : projectType;
        }
      } else {
        map.set(email, { email, name, projectType: projectType || "" });
      }
    });

    return Array.from(map.values());
  }
  const projectManagersForCustomer =
    distinctProjectManagerEmails(customerProject);

  const assigneesData = activitiesTeams.filter(
    (activitiesTeam) => activitiesTeam.customer_id === customer.customer_number
  );

  const assigneeArray =
    assigneesData && assigneesData.length > 0
      ? assigneesData[0].service_types.flatMap(
          (assignees) => assignees.assigned_users
        )
      : [];

  const allAssignees = assigneeArray.filter(
    (item, index) => assigneeArray.indexOf(item) === index
  );

  const customerActivities = activities.find(
    (activity) => activity.customer_id === customer.customer_number
  );

  return {
    projectManagersForCustomer,
    allAssignees,
    assigneesData,
    assigneesCount: projectManagersForCustomer.length + allAssignees.length,
    customerActivities,
  };
}
