import { createAsyncThunk } from "@reduxjs/toolkit";
import { plainToClass } from "class-transformer";
import { endOfMonth, startOfMonth, subMonths } from "date-fns";

import { ActivitiesAPI } from "api/activities";
import SettingsAPI from "api/customerSettings";
import { UsersAPI } from "api/users";
import { authMethod } from "auth";
import {
  Activity,
  ActivityDefinition,
  ActivityInstance,
  ActivityStatus,
  AssignUserToActivitiesRequest,
  CustomerActivitiesServices,
  ActivitiesTeam,
  FormAnswer,
  FormQuestion,
  FormQuestionAnswerAllowedTypes,
  ServiceInstance,
  ServiceTypeDefinition,
  GetActivitiesPerAssignedUserRequest,
} from "models/activities/activity";
import { Customer } from "models/customer";
import { ActivityFormEnum } from "constants/enums/activityForms.enum";
import { ABORT_ERROR } from "constants/errors";
import * as ActionTypes from ".";
import { ActivitiesAction } from ".";
import { MyThunkAction, RootState } from "..";
import { appendError, appendToastMessage } from "../notifications";

// https://lrfkonsult.atlassian.net/browse/ML-1325
const FETCH_ACTIVITIES_SINCE_DATE = new Date(2023, 0, 1);
export const CUSTOMERS_CHUNK_SIZE = 50;

export type EditActivityStatus = {
  activity_type: string;
  selected: boolean;
};

export type ActivitiesTimelineWindow = {
  from?: Date;
  to?: Date;
};

export function fetchActivitiesForCustomers({
  customerNumbers,
  from,
  to,
  skipLoading = false,
}: {
  customerNumbers: string[];
  from?: Date;
  to?: Date;
  skipLoading?: boolean;
}): MyThunkAction {
  return async (dispatch, getState) => {
    const state = getState();

    const fromDate = from || state.activities.timelineWindow[0];
    const toDate =
      to ||
      endOfMonth(
        state.activities.timelineWindow[
          state.activities.timelineWindow.length - 1
        ]
      );

    try {
      if (!skipLoading) {
        dispatch(setLoadingServiceActivities(true));
      }

      const token = await authMethod.getStoredAccessToken();
      const promises = [];

      for (let i = 0; i < customerNumbers.length; i += CUSTOMERS_CHUNK_SIZE) {
        promises.push(
          ActivitiesAPI.fetchUserCustomersActivities(
            token,
            fromDate,
            toDate,
            customerNumbers.slice(i, i + CUSTOMERS_CHUNK_SIZE)
          )
        );
      }

      const result = await Promise.all(promises);
      const assignments = result
        .map((batch) => Array.from(batch.values()))
        .flat();
      dispatch(appendServiceActivities(assignments));
    } catch (e) {
      dispatch(appendError("FAILED_TO_LOAD_ACTIVITIES", e as Error));
      dispatch(loadingServiceActivitiesFailed());
    } finally {
      dispatch(setLoadingServiceActivities(false));
    }
  };
}

export function fetchActivitiesDefinitions(): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setActivitiesDefinitionsLoading(true));
      const token = await authMethod.getStoredAccessToken();
      const definitions = await ActivitiesAPI.fetchActivitiesDefinitions(token);
      dispatch(setActivitiesDefinitions(definitions));
      dispatch(setActivitiesDefinitionsLoading(false));
    } catch (e) {
      dispatch(appendError("FAILED_TO_LOAD_ACTIVITIES", e as Error));
      dispatch(loadingActivitiesDefinitionsFailed());
    } finally {
      dispatch(setActivitiesDefinitionsLoading(false));
    }
  };
}

async function getCustomersActivities(
  customerNumbers: string[],
  from: Date,
  to: Date
): Promise<CustomerActivitiesServices[]> {
  const token = await authMethod.getStoredAccessToken();
  const promises = [];

  for (let i = 0; i < customerNumbers.length; i += CUSTOMERS_CHUNK_SIZE) {
    promises.push(
      ActivitiesAPI.fetchUserCustomersActivities(
        token,
        from,
        to,
        customerNumbers.slice(i, i + CUSTOMERS_CHUNK_SIZE)
      )
    );
  }

  const assignments = await Promise.all(promises);
  const result = assignments.map((assignment) => {
    const serviceActivitiesAsArray = Array.from(assignment.values());
    return serviceActivitiesAsArray;
  });
  return result.flat();
}

export const fetchActivitiesServicesForCustomers = createAsyncThunk(
  "activities/FETCH_ACTIVITIES_SERVICES_FOR_CUSTOMERS",
  async ({ from, to }: { from?: Date; to?: Date }, { dispatch, getState }) => {
    const state = getState() as RootState;

    const fromDate = from || startOfMonth(state.activities.timelineWindow[0]);
    const toDate =
      to ||
      endOfMonth(
        state.activities.timelineWindow[
          state.activities.timelineWindow.length - 1
        ]
      );

    try {
      dispatch(setLoadingServiceActivities(true));
      const assignments = await getCustomersActivities(
        state.customers.data.map(({ customer }) => customer.customer_number),
        fromDate,
        toDate
      );
      dispatch(setServiceActivities(assignments));
    } catch (e) {
      dispatch(appendError("FAILED_TO_LOAD_ACTIVITIES", e as Error));
      dispatch(loadingServiceActivitiesFailed());
    } finally {
      dispatch(setLoadingServiceActivities(false));
    }
  }
);

export function fetchFormQuestions(
  customer: Customer,
  activity: ActivityInstance
): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      if (activity.form_id) {
        const formQuestions = await ActivitiesAPI.fetchFormQuestions(
          token,
          customer.company_registration_number,
          activity.activity_id,
          activity.form_id
        );

        dispatch(
          setFormQuestions(
            formQuestions,
            activity.form_id === ActivityFormEnum.FORM_5_KYC_YEARLY
          )
        );
      }
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_FORM_QUESTIONS", e as Error));
    }
  };
}

export function fetchFormAnswers(
  customer: Customer,
  activity: ActivityInstance,
  fiscalYear: number,
  serviceId?: string
): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      if (activity.form_id) {
        const formAnswers = await ActivitiesAPI.fetchFormAnswers(
          token,
          customer.company_registration_number,
          fiscalYear,
          activity.form_id,
          serviceId
        );

        dispatch(setFormAnswers(formAnswers));
      }
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_FORM_QUESTIONS", e as Error));
    }
  };
}

export function completeActivity(
  customer: Customer,
  activity: ActivityInstance,
  userId: string,
  formQuestions?: FormQuestion[],
  serviceBoxId?: string
): MyThunkAction {
  return async (dispatch) => {
    try {
      // Optimistic update
      dispatch(
        setActivity(
          plainToClass(ActivityInstance, {
            ...activity,
            completed_at: new Date(),
            completed_by_user: userId,
            formQuestions,
          })
        )
      );

      const token = await authMethod.getStoredAccessToken();
      await ActivitiesAPI.setActivityInstanceCompleted(
        token,
        customer.customer_number,
        activity.activity_id,
        userId,
        activity.original_id,
        formQuestions,
        serviceBoxId
      );
      dispatch(setFormQuestions([]));
    } catch (e) {
      // Revert update
      activity.completed_at = undefined;
      dispatch(setActivity(activity));
      dispatch(appendError("FAILED_TO_UPDATE_ACTIVITY", e as Error));
    }
  };
}

export function unCompleteActivity(
  customer: Customer,
  activity: ActivityInstance
): MyThunkAction {
  return async (dispatch) => {
    try {
      // Optimistic update
      dispatch(
        setActivity(
          plainToClass(ActivityInstance, {
            ...activity,
            completed_at: undefined,
            completed_by_user: undefined,
          })
        )
      );

      const token = await authMethod.getStoredAccessToken();
      await ActivitiesAPI.setActivityInstanceUncompleted(
        token,
        customer.customer_number,
        activity.activity_id,
        activity.original_id
      );
    } catch (e) {
      // Revert update
      dispatch(setActivity(activity));
      dispatch(appendError("FAILED_TO_UPDATE_ACTIVITY", e as Error));
    }
  };
}

export function updateActivityTypeAssignedUser(
  customer: Customer,
  originalId: string,
  userId: string
): MyThunkAction {
  return async (dispatch, getState) => {
    const customerActivities =
      getState().activities.serviceBoxActivities.data.flatMap(
        (s) => s.activities
      );

    const activityInstance = customerActivities.find(
      (activity) => activity.original_id === originalId
    );

    if (!activityInstance) {
      throw new Error("Could not find customer activity");
    }

    // Optimistic update
    dispatch(
      setActivityTypeAssigned(userId, customer.customer_number, originalId)
    );

    dispatch(setLoadingActivityInstance(customer, originalId, true));

    try {
      const token = await authMethod.getStoredAccessToken();
      const affectedActivities = await ActivitiesAPI.assignUserToActivity(
        token,
        originalId,
        { userId }
      );

      dispatch(
        setActivityTypeAssigned(
          userId,
          customer.customer_number,
          originalId,
          affectedActivities.data.affected.map((activity) => activity.id)
        )
      );
    } catch (e) {
      // Revert the update
      dispatch(
        setActivityTypeAssigned(
          activityInstance.assigned_user,
          customer.customer_number,
          originalId
        )
      );

      dispatch(appendError("FAILED_TO_UPDATE_ACTIVITY", e as Error));
    } finally {
      dispatch(
        setLoadingActivityInstance(
          customer,
          activityInstance.original_id,
          false
        )
      );
    }
  };
}

export function updateCustomerActivitiesAndTeam(
  customer: Customer,
  packageName: string,
  newUserId: string
): MyThunkAction {
  return async (dispatch) => {
    dispatch(getActivitiesTeam([customer.customer_number]));
    /*
        The `skipLoading` parameter is set to `true` to avoid triggering the loading
        indicator. This is to prevent UI flickering when multiple requests are being made in quick succession.
      */
    dispatch(
      fetchActivitiesForCustomers({
        customerNumbers: [customer.customer_number],
        skipLoading: true,
      })
    );

    dispatch(fetchUnfinishedActivities([customer.customer_number]));
    dispatch(
      setPackageDefaultAssigned(
        customer.company_registration_number,
        newUserId,
        packageName
      )
    );
    dispatch(setServiceBoxActivities([], ""));
  };
}

export function updatePackageDefaultAssignedUser(
  customer: Customer,
  packageName: string,
  userEmail: string
): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const user = await UsersAPI.fetchUser(token, userEmail);

      const response = await ActivitiesAPI.setPackageDefaultAssignedUser(
        token,
        customer.customer_number,
        packageName,
        user.id
      );

      dispatch(updateCustomerActivitiesAndTeam(customer, packageName, user.id));
      return response;
    } catch (e) {
      dispatch(
        appendError("CB.TEAM.FAILED_TO_UPDATE_DEFAULT_ASSIGNEE", e as Error)
      );
    }
  };
}

export function reassignPackageAssignedUser(
  customer: Customer,
  newUserEmail: string,
  currentUser: string,
  packageName: string
): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const newUser = await UsersAPI.fetchUser(token, newUserEmail);
      const response = await ActivitiesAPI.setReassignedUser(
        token,
        customer.customer_number,
        packageName,
        newUser.id,
        currentUser
      );

      dispatch(
        updateCustomerActivitiesAndTeam(customer, packageName, newUser.id)
      );

      return response;
    } catch (e) {
      dispatch(appendError("CB.TEAM.FAILED_TO_UPDATE_ASSIGNEE", e as Error));
    }
  };
}

export function updateActivityDeadline(
  customer: Customer,
  activityInstance: ActivityInstance,
  deadlineDate: Date,
  setDeadlineUpcoming: boolean
): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(
        activityDeadlineUpdated(
          customer,
          activityInstance.original_id,
          deadlineDate,
          setDeadlineUpcoming
        )
      );

      const token = await authMethod.getStoredAccessToken();
      await ActivitiesAPI.setActivityDeadline(
        token,
        customer.customer_number,
        activityInstance.original_id,
        deadlineDate,
        setDeadlineUpcoming
      );
    } catch (e) {
      dispatch(appendError("FAILED_TO_POST_ACTIVITY_MESSAGE", e as Error));
    }
  };
}

export function postActivityInstanceMessage(
  customer: Customer,
  activityInstance: ActivityInstance,
  messageContent: string,
  status: ActivityStatus,
  definition: ActivityDefinition
): MyThunkAction {
  return async (dispatch) => {
    dispatch(
      setLoadingActivityInstance(customer, activityInstance.original_id, true)
    );
    try {
      const token = await authMethod.getStoredAccessToken();
      const message = await ActivitiesAPI.postActivityMessage(
        token,
        customer.customer_number,
        activityInstance.original_id,
        messageContent,
        status,
        activityInstance.getServiceArea(definition)
      );

      dispatch(
        appendActivityInstanceMessage(
          customer,
          activityInstance.original_id,
          messageContent,
          status,
          message.teams_conversation_weburl,
          message.teams_conversation_id
        )
      );
    } catch (e) {
      dispatch(appendError("FAILED_TO_POST_ACTIVITY_MESSAGE", e as Error));
    } finally {
      dispatch(
        setLoadingActivityInstance(
          customer,
          activityInstance.original_id,
          false
        )
      );
    }
  };
}

export function setTimelineWindowFromLocalStorage(): MyThunkAction {
  return async (dispatch) => {
    const localTimeline = localStorage.getItem("actionsTimelineWindow");
    const localStorageTimelineWindow = localTimeline
      ? JSON.parse(localTimeline).map(
          (dateAsString: string) => new Date(String(dateAsString))
        )
      : null;
    if (localStorageTimelineWindow && localStorageTimelineWindow.length > 1) {
      dispatch(setActivitiesTimelineWindow(localStorageTimelineWindow));
    } else {
      dispatch(setActivitiesTimelineWindow({}));
    }
  };
}

export const setActivitiesTimelineWindow = createAsyncThunk(
  "activities/SET_TIMELINE_WINDOW",
  async (
    {
      months,
      fetchActivities = true,
    }: { months?: Date[]; fetchActivities?: boolean },
    { dispatch, getState }
  ) => {
    try {
      const activityTimeline = (getState() as RootState).activities
        .timelineWindow;

      const currentMonths = activityTimeline.map((date: Date) =>
        date.getMonth()
      );

      const newMonths =
        months?.length === 1
          ? months
          : months?.filter(
              (date: Date) => !currentMonths.includes(date.getMonth())
            );

      dispatch(setTimelineWindow(months));

      // No need to do anything if there are no new months in the window
      if (newMonths?.length === 0) {
        return;
      }

      const timeline: ActivitiesTimelineWindow = {};

      if (months) {
        const endMonthDate = months.length === 1 ? months[0] : undefined;
        [timeline.from] = months;
        timeline.to = endOfMonth(endMonthDate ?? months[months.length - 1]);
      }

      if (fetchActivities) {
        dispatch(fetchActivitiesServicesForCustomers(timeline));
      }

      return timeline;
    } catch (e) {
      dispatch(appendError("FAILED_TIMELINE_WINDOW_UPDATE", e as Error));
    }
  }
);

export function fetchOptionalActivities(customer: Customer): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setLoadingOptionalActivities(true));
      const token = await authMethod.getStoredAccessToken();
      const optionalActivities = await ActivitiesAPI.fetchOptionalActivityTypes(
        token,
        customer.customer_number
      );
      dispatch(setOptionalActivities(optionalActivities));
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_OPTIONAL_ACTIVITIES", e as Error));
    } finally {
      dispatch(setLoadingOptionalActivities(false));
    }
  };
}

export function fetchBalanceInvoicePaymentsData(
  customer: Customer
): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const data = await SettingsAPI.fetchBalanceInvoicePayments(
        token,
        customer.customer_number
      );
      return data;
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_OPTIONAL_ACTIVITIES", e as Error));
    }
  };
}

export function fetchPGUData(customer: Customer): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const data = await SettingsAPI.fetchPGU(token, customer.customer_number);

      return data;
    } catch (e) {
      dispatch(appendError("500_UNKNOWN_ERROR", e as Error));
    }
  };
}

export function fetchPGUAssignmentData(
  customerId: string,
  jobNumber: string
): MyThunkAction {
  return async (dispatch) => {
    const queryString = `job_number=${jobNumber}`;

    try {
      const token = await authMethod.getStoredAccessToken();
      const data = await SettingsAPI.fetchPGU(token, customerId, queryString);

      return data;
    } catch (e) {
      dispatch(appendError("500_UNKNOWN_ERROR", e as Error));
    }
  };
}

export function fetchPGUFiscalYearData(
  customerId: string,
  jobNumber: string,
  purposeName: string
): MyThunkAction {
  return async (dispatch) => {
    const queryString =
      jobNumber && purposeName
        ? `job_number=${jobNumber}&purpose_name=${purposeName}`
        : "";

    try {
      const token = await authMethod.getStoredAccessToken();
      const data = await SettingsAPI.fetchPGU(token, customerId, queryString);

      return data;
    } catch (e) {
      dispatch(appendError("500_UNKNOWN_ERROR", e as Error));
    }
  };
}

export function fetchPGUPartialyInvoicedData(
  customerId: string,
  jobNumber: string,
  partialInvoice: string
): MyThunkAction {
  return async (dispatch) => {
    const queryString =
      jobNumber && partialInvoice
        ? `job_number=${jobNumber}&partial_invoices=${partialInvoice}`
        : "";

    try {
      const token = await authMethod.getStoredAccessToken();
      const data = await SettingsAPI.fetchPGU(token, customerId, queryString);

      return data;
    } catch (e) {
      dispatch(appendError("500_UNKNOWN_ERROR", e as Error));
    }
  };
}

export function fetchPGUDetailsData(
  customerId: string,
  jobNumber: string,
  details: string
): MyThunkAction {
  return async (dispatch) => {
    const queryString =
      jobNumber && details ? `job_number=${jobNumber}&details=${details}` : "";

    try {
      const token = await authMethod.getStoredAccessToken();
      const data = await SettingsAPI.fetchPGU(token, customerId, queryString);

      return data;
    } catch (e) {
      dispatch(appendError("500_UNKNOWN_ERROR", e as Error));
    }
  };
}

export function fetchPGUDetailsPerTaskData(
  customerId: string,
  jobNumber: string,
  detailsPerTask: string
): MyThunkAction {
  return async (dispatch) => {
    const queryString =
      jobNumber && detailsPerTask
        ? `job_number=${jobNumber}&details_task_name=${detailsPerTask}`
        : "";

    try {
      const token = await authMethod.getStoredAccessToken();
      const data = await SettingsAPI.fetchPGU(token, customerId, queryString);

      return data;
    } catch (e) {
      dispatch(appendError("500_UNKNOWN_ERROR", e as Error));
    }
  };
}

export function fetchPguDownloadUrlData(
  customerNumber: string,
  queryParams?: {
    jobnumber?: string | undefined;
    purposename?: string | undefined;
    details?: string | undefined;
    detailsTaskName?: string | undefined;
    partialInvoice?: string | undefined;
  }
): MyThunkAction {
  return async (dispatch) => {
    let queryString = "";
    if (queryParams?.jobnumber) {
      queryString += `job_number=${queryParams?.jobnumber}`;
    }

    if (queryParams?.purposename) {
      queryString += `&purpose_name=${queryParams?.purposename}`;
    } else if (queryParams?.details) {
      queryString += `&details=${queryParams?.details}`;
    } else if (queryParams?.detailsTaskName) {
      queryString += `vdetails_task_name=${queryParams?.detailsTaskName}`;
    } else if (queryParams?.partialInvoice) {
      queryString += `&partial_invoices=${queryParams?.partialInvoice}`;
    }

    try {
      const token = await authMethod.getStoredAccessToken();
      const data = await SettingsAPI.fetchDownloadUrlPgu(
        token,
        customerNumber,
        queryString
      );
      return data;
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_DOWNLOAD_URL", e as Error));
    }
  };
}

export function fetchBalancesDownloadUrlData(
  customerNumber: string
): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const data = await SettingsAPI.fetchDownloadUrlBalances(
        token,
        customerNumber
      );
      return data;
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_DOWNLOAD_URL", e as Error));
    }
  };
}

async function getPromisesForUpdateOptionalServiceActivities(
  customer: Customer,
  toAdd: EditActivityStatus[],
  toRemove: EditActivityStatus[],
  isOnetime: boolean,
  startDate: Date = new Date(),
  endDate: Date = new Date()
) {
  const token = await authMethod.getStoredAccessToken();
  const promises = [];

  for (let i = 0; i < toAdd.length; i++) {
    const activity = toAdd[i];
    if (isOnetime) {
      promises.push(
        ActivitiesAPI.addOnetimeActivity(
          token,
          customer.customer_number,
          activity.activity_type,
          startDate
        )
      );
    } else {
      promises.push(
        ActivitiesAPI.addOptionalServiceActivityType(
          token,
          customer.customer_number,
          activity.activity_type,
          startDate
        )
      );
    }
  }

  for (let i = 0; i < toRemove.length; i++) {
    const activity = toRemove[i];
    promises.push(
      ActivitiesAPI.removeOptionalServiceActivityType(
        token,
        customer.customer_number,
        activity.activity_type,
        endDate
      )
    );
  }

  return Promise.all(promises);
}

export function updateOptionalServiceActivities(
  customer: Customer,
  toAdd: EditActivityStatus[],
  toRemove: EditActivityStatus[],
  isOnetime: boolean,
  startDate: Date = new Date(),
  endDate: Date = new Date()
): MyThunkAction {
  return async (dispatch, getState) => {
    dispatch(setLoadingServiceActivities(true));

    try {
      // Fire off all requests and await them all
      await getPromisesForUpdateOptionalServiceActivities(
        customer,
        toAdd,
        toRemove,
        isOnetime,
        startDate,
        endDate
      );

      dispatch(updateOptionalActivities(toAdd, toRemove));
      dispatch(
        fetchActivitiesForCustomers({
          customerNumbers: [customer.customer_number],
        })
      );
    } catch (e) {
      dispatch(loadingServiceActivitiesFailed());
      dispatch(appendError("FAILED_TO_UPDATE_OPTIONAL_ACTIVITIES", e as Error));
    } finally {
      dispatch(setLoadingServiceActivities(false));
    }
  };
}

export function removeOnetimeActivity(
  customer: Customer,
  activityInstance: ActivityInstance
): MyThunkAction {
  return async (dispatch) => {
    try {
      // Optimistic update
      dispatch(
        setActivity(
          plainToClass(ActivityInstance, {
            ...activityInstance,
            marked_deleted: true,
          })
        )
      );

      const token = await authMethod.getStoredAccessToken();
      await ActivitiesAPI.removeOnetimeActivity(
        token,
        customer.customer_number,
        activityInstance.activity_id,
        activityInstance.original_id
      );
    } catch (e) {
      // Revert update
      activityInstance.marked_deleted = false;
      dispatch(setActivity(activityInstance));
      dispatch(appendError("FAILED_TO_REMOVE_ONETIME_ACTIVITY", e as Error));
    }
  };
}

export const assignUserToActivities = createAsyncThunk(
  "",
  async (requestBody: AssignUserToActivitiesRequest, { dispatch }) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const { data: affectedActivities } =
        await ActivitiesAPI.assignUserToActivities(token, requestBody);

      if (affectedActivities.length) {
        dispatch(
          appendToastMessage(
            "ASSIGN_USER_TO_ACTIVITIES.SUCCESS.HAS_AFFECTED",
            "success",
            [affectedActivities.length.toString()]
          )
        );
      } else {
        dispatch(
          appendToastMessage(
            "ASSIGN_USER_TO_ACTIVITIES.SUCCESS.NO_AFFECTED",
            "warning"
          )
        );
      }

      return affectedActivities.length;
    } catch (e) {
      dispatch(
        appendError(
          "CB.CHANGE_ASSIGNEE_MODAL.FAILED_TO_CHANGE_ASSIGNEE",
          e as Error
        )
      );
    }
  }
);

export const getActivitiesPerAssignedUser = createAsyncThunk(
  "",
  async (
    {
      abortSignal,
      requestBody,
    }: {
      abortSignal: AbortSignal;
      requestBody: GetActivitiesPerAssignedUserRequest;
    },
    { dispatch }
  ) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const { data: affectedActivities } =
        await ActivitiesAPI.getActivitiesPerAssignedUser(
          token,
          abortSignal,
          requestBody
        );
      return affectedActivities;
    } catch (error) {
      if (error instanceof Error && error.name === ABORT_ERROR) {
        return;
      }

      dispatch(
        appendError(
          "CB.CHANGE_ASSIGNEE_MODAL.FAILED_TO_GET_ACTIVITIES_PER_ASSIGNED_USER",
          new Error("An unknown error occurred")
        )
      );
    }
  }
);

export function updateOptionalActivities(
  added: EditActivityStatus[],
  removed: EditActivityStatus[]
): ActivitiesAction {
  return {
    type: ActionTypes.UPDATE_OPTIONAL_ACTIVITIES,
    payload: { added, removed },
  };
}

export function setOptionalActivities(
  activities: Activity[]
): ActivitiesAction {
  return {
    type: ActionTypes.SET_OPTIONAL_ACTIVITIES,
    payload: activities,
  };
}

export function activityDeadlineUpdated(
  customer: Customer,
  activityId: string,
  deadlineDate: Date,
  setDeadlineUpcoming: boolean
): ActivitiesAction {
  return {
    type: ActionTypes.UPDATE_ACTIVITY_DEADLINE,
    payload: {
      customerOrgNumber: customer.company_registration_number,
      activityId,
      deadlineDate,
      setDeadlineUpcoming,
    },
  };
}

export function setLoadingActivityInstance(
  customer: Customer,
  activityId: string,
  isLoading: boolean
): ActivitiesAction {
  return {
    type: ActionTypes.SET_ACTIVITY_INSTANCE_LOADING,
    payload: {
      customerOrgNumber: customer.company_registration_number,
      activityId,
      isLoading,
    },
  };
}

export function appendActivityInstanceMessage(
  customer: Customer,
  activityId: string,
  messageContent: string,
  status: ActivityStatus,
  teamsConversationUrl?: string,
  teamsConversationId?: string
): ActivitiesAction {
  return {
    type: ActionTypes.APPEND_ACTIVITY_INSTANCE_MESSAGE,
    payload: {
      customerOrgNumber: customer.company_registration_number,
      activityId,
      messageContent,
      status,
      teamsConversationUrl,
      teamsConversationId,
    },
  };
}

export function setActivityTypeAssigned(
  userId: string,
  customerNumber: string,
  originalId: string,
  affectedActivities?: string[]
): ActivitiesAction {
  return {
    type: ActionTypes.UPDATE_ACTIVITY_TYPE_ASSIGNED_USER,
    payload: {
      userId,
      customerNumber,
      originalId,
      affectedActivities,
    },
  };
}

export function setServiceTypeAssigned(
  serviceType: string,
  userId: string,
  customerOrgNumber: string
): ActivitiesAction {
  return {
    type: ActionTypes.UPDATE_SERVICE_TYPE_DEFAULT_ASSIGNED_USER,
    payload: {
      serviceType,
      userId,
      customerOrgNumber,
    },
  };
}

export function setCustomerDefaultAssigned(
  customer: Customer,
  userId: string
): ActivitiesAction {
  return {
    type: ActionTypes.UPDATE_CUSTOMER_DEFAULT_ASSIGNED_USER,
    payload: {
      customerOrgNumber: customer.company_registration_number,
      userId,
    },
  };
}

export function setActivity(activity: ActivityInstance): ActivitiesAction {
  return {
    type: ActionTypes.UPDATE_ACTIVITY,
    payload: activity,
  };
}

export function setLoadingOptionalActivities(
  isLoading: boolean
): ActivitiesAction {
  return {
    type: ActionTypes.SET_LOADING_OPTIONAL_ACTIVITIES,
    payload: isLoading,
  };
}

export function setLoadingServiceActivities(
  isLoading: boolean
): ActivitiesAction {
  return {
    type: ActionTypes.SET_LOADING_SERVICE_ACTIVITIES,
    payload: isLoading,
  };
}

export function setTimelineWindow(months?: Date[]): ActivitiesAction {
  return {
    type: ActionTypes.SET_TIMELINE_WINDOW,
    payload: months,
  };
}

export function setServiceActivities(
  serviceActivities: CustomerActivitiesServices[]
): ActivitiesAction {
  return {
    type: ActionTypes.SET_SERVICES_ACTIVITIES,
    payload: serviceActivities,
  };
}

export function setActivitiesDefinitions(
  activitiesDefinitions: ActivityDefinition[]
): ActivitiesAction {
  return {
    type: ActionTypes.SET_ACTIVITIES_DEFINITIONS,
    payload: activitiesDefinitions,
  };
}

export function setActivitiesDefinitionsLoading(
  isLoading: boolean
): ActivitiesAction {
  return {
    type: ActionTypes.SET_ACTIVITIES_DEFINITIONS_LOADING,
    payload: isLoading,
  };
}

export function loadingActivitiesDefinitionsFailed(): ActivitiesAction {
  return {
    type: ActionTypes.LOADING_ACTIVITIES_DEFINITIONS_FAILED,
  };
}

export function appendServiceActivity(
  serviceActivity: CustomerActivitiesServices
): ActivitiesAction {
  return {
    type: ActionTypes.APPEND_SERVICES_ACTIVITY,
    payload: serviceActivity,
  };
}

export function appendServiceActivities(
  serviceActivities: CustomerActivitiesServices[]
): ActivitiesAction {
  return {
    type: ActionTypes.APPEND_SERVICES_ACTIVITIES,
    payload: serviceActivities,
  };
}

export function loadingServiceActivitiesFailed(): ActivitiesAction {
  return {
    type: ActionTypes.LOADING_SERVICES_ACTIVITIES_FAILED,
  };
}

export function setPackageDefaultAssigned(
  customerOrgNumber: string,
  userId: string,
  packageName?: string
): ActivitiesAction {
  return {
    type: ActionTypes.UPDATE_CUSTOMER_DEFAULT_ASSIGNED_USER,
    payload: {
      customerOrgNumber,
      userId,
      packageName,
    },
  };
}

export function setFormQuestions(
  formQuestions: FormQuestion[],
  removeDefaultAnswer = false
): ActivitiesAction {
  return {
    type: ActionTypes.SET_FORM_QUESTIONS,
    payload: {
      formQuestions,
      removeDefaultAnswer,
    },
  };
}

export function setFormAnswers(formAnswers: FormAnswer[]): ActivitiesAction {
  return {
    type: ActionTypes.SET_FORM_ANSWERS,
    payload: {
      formAnswers,
    },
  };
}

export function resetFormAnswers(): ActivitiesAction {
  return {
    type: ActionTypes.RESET_FORM_ANSWERS,
  };
}

export function setFormQuestionAnswer(
  answer: FormQuestionAnswerAllowedTypes,
  questionID: string
): ActivitiesAction {
  return {
    type: ActionTypes.SET_FORM_QUESTION_ANSWER,
    payload: {
      answer,
      questionID,
    },
  };
}

export function setServiceTypesList(
  serviceTypes: ServiceTypeDefinition[]
): ActivitiesAction {
  return {
    type: ActionTypes.SET_SERVICE_TYPES_LIST,
    payload: { serviceTypes },
  };
}

export function fetchServiceTypes(): MyThunkAction {
  return async (dispatch) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const serviceTypes = await ActivitiesAPI.getServiceTypesList(token);
      dispatch(setServiceTypesList(serviceTypes));
    } catch (e) {
      dispatch(appendError("FAILED_TO_LOAD_SERVICE_TYPES", e as Error));
    }
  };
}

export function appendUnfinishedActivities(
  unfinishedActivities: CustomerActivitiesServices[]
): ActivitiesAction {
  return {
    type: ActionTypes.APPEND_UNFINISHED_ACTIVITIES,
    payload: unfinishedActivities,
  };
}

export function setLoadingUnfinishedActivities(
  isLoading: boolean
): ActivitiesAction {
  return {
    type: ActionTypes.SET_LOADING_UNFINISHED_ACTIVITIES,
    payload: isLoading,
  };
}

export function loadingUnfinishedActivitiesFailed(): ActivitiesAction {
  return {
    type: ActionTypes.LOADING_UNFINISHED_ACTIVITIES_FAILED,
  };
}

export function fetchUnfinishedActivities(
  customerNumbers: string[],
  from?: Date,
  to?: Date
): MyThunkAction {
  return async (dispatch) => {
    const today = new Date();
    const lastDayOfPreviousMonth = endOfMonth(subMonths(today, 1));
    const fromDate = startOfMonth(from || FETCH_ACTIVITIES_SINCE_DATE);
    const toDate = to || lastDayOfPreviousMonth;

    dispatch(setLoadingUnfinishedActivities(true));

    const token = await authMethod.getStoredAccessToken();
    const promises = [];

    try {
      for (let i = 0; i < customerNumbers.length; i += CUSTOMERS_CHUNK_SIZE) {
        promises.push(
          ActivitiesAPI.fetchUnfinishedActivities(
            token,
            fromDate,
            toDate,
            customerNumbers.slice(i, i + CUSTOMERS_CHUNK_SIZE)
          )
        );
      }

      const result = await Promise.all(promises);
      const unfinishedActivities = result
        .map((batch) => Array.from(batch.values()))
        .flat();

      dispatch(
        appendUnfinishedActivities(
          plainToClass(CustomerActivitiesServices, unfinishedActivities)
        )
      );
    } catch (e) {
      dispatch(
        appendError("CB.FAILED_TO_LOAD_UNFINISHED_ACTIVITIES", e as Error)
      );
      dispatch(loadingUnfinishedActivitiesFailed());
    } finally {
      dispatch(setLoadingUnfinishedActivities(false));
    }
  };
}

export function setServiceBoxActivities(
  serviceBoxActivities: ServiceInstance[],
  customerId: string
): ActivitiesAction {
  return {
    type: ActionTypes.SET_SERVICE_BOX_ACTIVITIES,
    payload: { serviceBoxActivities, customerId },
  };
}

export function setLoadingServiceBoxActivities(
  isLoading: boolean
): ActivitiesAction {
  return {
    type: ActionTypes.SET_LOADING_SERVICE_BOX_ACTIVITIES,
    payload: isLoading,
  };
}

export function loadingServiceBoxActivitiesFailed(): ActivitiesAction {
  return {
    type: ActionTypes.LOADING_SERVICE_BOX_ACTIVITIES_FAILED,
  };
}

export function updateServiceBoxActivities(
  service: ServiceInstance,
  customerId: string
): ActivitiesAction {
  return {
    type: ActionTypes.UPDATE_SERVICE_ACTIVITIES,
    payload: { service, customerId },
  };
}

export function getServiceBoxActivities(
  customerId: string,
  serviceBoxId: string
): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setLoadingServiceBoxActivities(true));
      const token = await authMethod.getStoredAccessToken();
      const serviceBoxActivities = await ActivitiesAPI.getServiceBoxActivities(
        token,
        customerId,
        serviceBoxId
      );
      const { data } = serviceBoxActivities;
      dispatch(
        setServiceBoxActivities(
          [plainToClass(ServiceInstance, data)],
          customerId
        )
      );
    } catch (e) {
      dispatch(
        appendError("CB.FAILED_TO_LOAD_SERVICE_BOX_ACTIVITIES", e as Error)
      );
      dispatch(loadingServiceBoxActivitiesFailed());
    } finally {
      dispatch(setLoadingServiceBoxActivities(false));
    }
  };
}

export function appendActivitiesTeam(team: ActivitiesTeam[]): ActivitiesAction {
  return {
    type: ActionTypes.APPEND_ACTIVITIES_TEAM,
    payload: team,
  };
}

export function setLoadingActivitiesTeam(isLoading: boolean): ActivitiesAction {
  return {
    type: ActionTypes.SET_LOADING_ACTIVITIES_TEAM,
    payload: isLoading,
  };
}

export function loadingActivitiesTeamFailed(): ActivitiesAction {
  return {
    type: ActionTypes.LOADING_ACTIVITIES_TEAM_FAILED,
  };
}

export function getActivitiesTeam(customerNumbers: string[]): MyThunkAction {
  return async (dispatch) => {
    dispatch(setLoadingActivitiesTeam(true));

    const token = await authMethod.getStoredAccessToken();
    const promises = [];

    try {
      for (let i = 0; i < customerNumbers.length; i += CUSTOMERS_CHUNK_SIZE) {
        promises.push(
          ActivitiesAPI.getActivitiesTeam(
            token,
            customerNumbers.slice(i, i + CUSTOMERS_CHUNK_SIZE)
          )
        );
      }

      const result = await Promise.all(promises);
      const activitiesTeams = result.flatMap((batch) => batch.data);
      dispatch(appendActivitiesTeam(activitiesTeams));
    } catch (e) {
      dispatch(appendError("CB.FAILED_TO_LOAD_ACTIVITIES_TEAM", e as Error));
      dispatch(loadingActivitiesTeamFailed());
    } finally {
      dispatch(setLoadingActivitiesTeam(false));
    }
  };
}

export function setOneDriveShortcutFolder(
  hasOnedriveShortcutFolder: boolean
): ActivitiesAction {
  return {
    type: ActionTypes.ONEDRIVE_SHORTCUT_FOLDER,
    payload: hasOnedriveShortcutFolder,
  };
}

export function getOnedriveShortcutFolder(): MyThunkAction {
  return async (dispatch) => {
    const token = await authMethod.getStoredAccessToken();

    try {
      await ActivitiesAPI.getOneDriveShortcutFolder(token);

      dispatch(setOneDriveShortcutFolder(true));
    } catch (e) {
      dispatch(setOneDriveShortcutFolder(false));
    }
  };
}
