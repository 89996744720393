import { IdType } from "./idType";

export class DealIdCheck {
  constructor(args: Partial<DealIdCheck> = {}) {
    Object.assign(this, args);
  }

  id: number;

  deal_id: string;

  social_security_number: string;

  first_name: string;

  last_name: string;

  id_type: IdType;

  id_number: string;

  id_expiration_date: string;

  created_date: Date;

  created_by_username: string;

  changed_date: Date;

  changed_by_username: string;
}
