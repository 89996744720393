import { Edit20Regular } from "@fluentui/react-icons";
import { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { renderIcon } from "libs/render-icon";
import ContextualMenu from "components/contextualMenu/ContextualMenu";
import Switch from "components/switch";
import { useTranslation } from "hooks";
import { DealContact } from "models/deals/deal";
import { CreateBusinessOpportunityContext } from "../../context/CreateBusinessOpportunityContext";

type ContactsListProps = {
  contact: DealContact;
  onPressEdit: () => void;
};

export default function ContactListItem({
  contact,
  onPressEdit,
}: ContactsListProps) {
  const { translate } = useTranslation();

  const { updateNewDeal, newDeal } = useContext(
    CreateBusinessOpportunityContext
  );

  const handleToggleDealContact = (isChecked: boolean | undefined) => {
    updateNewDeal({
      contact: isChecked ? contact : undefined,
      first_name: isChecked ? contact.firstname : "",
      last_name: isChecked ? contact.lastname : "",
      mobile: isChecked ? contact.mobilephone : "",
      phone: isChecked ? contact.phone : "",
      contact_email: isChecked ? contact.email : "",
    });
  };

  return (
    <div className="border-bottom py-sm">
      <Row className="align-items-center">
        <Col md={3}>{`${contact.firstname} ${contact.lastname}`}</Col>
        <Col md={3}>{contact.email}</Col>

        <Col md={3}>{contact.phone}</Col>
        <Col md={2} className="d-flex">
          <Switch
            label=""
            checked={newDeal && newDeal.contact?.email === contact.email}
            onToggleMethod={(_, { checked }) =>
              handleToggleDealContact(checked)
            }
          />
        </Col>
        <Col xs={1} className="d-flex justify-content-center">
          <ContextualMenu
            menuItems={[
              {
                text: `${translate("EDIT_CONTACT")}`,
                icon: () => renderIcon(<Edit20Regular />),
                onClick: () => {
                  onPressEdit();
                },
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
}
