import AppConfig from "app-config";

export const excludeBusinessAreas = [
  "Fakturafrågor",
  "Lediga tjänster/Studentprojekt",
  "Annat",
];

export const DEFAULT_DEAL_SOURCE = "9"; //"Rekommendation från kollega";
export const DEFAULT_EMPTY_DEAL = {
  first_name: "",
  last_name: "",
  amount: 0,
  contact_email_searched: false,
  contact: undefined,
  customer_type: "",
  contact_email: "",
  phone: "",
  mobile: "",
  organisationsnummer: "",
  pipeline: AppConfig.FEATURES.DEALS.DEFAULT_CUSTOMER_PIPELINE,
  dealstage: AppConfig.FEATURES.DEALS.DEFAULT_CUSTOMER_DEAL_STAGE,
  dealname: "",
  meddelande_salj: "",
  company: "",
  customer_number: "",
  deal_source: DEFAULT_DEAL_SOURCE,
  affarsomrade_deal_: "",
  aterkommande_aktiviteter: "",
  recent_conversion_event_name: "K/M-tips",
  km_deal_created_by_user: "",
};
