import { asPercentage } from "libs/math";
import { AccountPeriodPickerModel } from "models/offer/AccountPeriodPicker";
import {
  CalculatedVolumeAnalysis,
  VolumeAnalysisData,
  VolumeAnalysisDatainner,
} from "models/VolumeAnalysisData";

export const calcVolumeAnalysis = (
  volumeAnalysisData: VolumeAnalysisData[],
  accountPeriodPickerData: AccountPeriodPickerModel,
  nrOfMonths: number
): CalculatedVolumeAnalysis => {
  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  const startMonthAsNumber = parseInt(accountPeriodPickerData.startMonth, 10);
  const endYear =
    parseInt(accountPeriodPickerData.startYear, 10) +
    Math.floor((startMonthAsNumber + nrOfMonths - 2) / 12);

  const endMonthAsNumber = ((startMonthAsNumber + nrOfMonths - 2) % 12) + 1;
  const endMonth = endMonthAsNumber.toString().padStart(2, "0");
  const min = `${accountPeriodPickerData.startYear}${accountPeriodPickerData.startMonth}`;
  const max = `${endYear}${endMonth}`;

  const filterAccountPeriodStats = (): VolumeAnalysisDatainner[] => {
    return volumeAnalysisData
      .flatMap((x) =>
        Object.keys(x.period_stats).map((k) => {
          if (min <= k && k <= max) {
            return x.period_stats[k];
          }
          return null;
        })
      )
      .filter(notEmpty);
  };

  const agg = (
    aIn: VolumeAnalysisDatainner,
    bIn: VolumeAnalysisDatainner
  ): VolumeAnalysisDatainner => {
    const a = aIn;
    const b = bIn;

    // Foreach series, generate the sum of the voucher counts/transaction counts and store in "a"'s counts
    Object.keys(b.voucher_count_per_series).forEach((key) => {
      a.voucher_count_per_series[key] = a.voucher_count_per_series[key] || 0;
      a.voucher_count_per_series[key] += b.voucher_count_per_series[key];
    });

    Object.keys(b.transaction_count_per_series).forEach((key) => {
      a.transaction_count_per_series[key] =
        a.transaction_count_per_series[key] || 0;
      a.transaction_count_per_series[key] +=
        b.transaction_count_per_series[key];
    });

    const voucherCountTotal = Object.keys(a.voucher_count_per_series).reduce(
      (acc: number, key: string) => acc + a.voucher_count_per_series[key],
      0
    );

    const transactionCountTotal = Object.keys(
      a.transaction_count_per_series
    ).reduce(
      (acc: number, key: string) => acc + a.transaction_count_per_series[key],
      0
    );

    return {
      voucher_count_per_series: a.voucher_count_per_series,
      voucher_count_total: voucherCountTotal,
      transaction_count_total: transactionCountTotal,
      transaction_count_per_series: a.transaction_count_per_series,
      revenue: a.revenue + b.revenue,
      gross_margin: a.gross_margin + b.gross_margin,
      ebit_revenue: a.ebit_revenue + b.ebit_revenue,
      balance_sheet_total: a.balance_sheet_total + b.balance_sheet_total,
      zero_account_transaction_count:
        a.zero_account_transaction_count + b.zero_account_transaction_count,
    };
  };

  const aggAccountPeriodStats = filterAccountPeriodStats().reduce(agg, {
    voucher_count_total: 0,
    voucher_count_per_series: {},
    transaction_count_total: 0,
    transaction_count_per_series: {},
    revenue: 0,
    gross_margin: 0,
    ebit_revenue: 0,
    balance_sheet_total: 0,
    zero_account_transaction_count: 0,
  });

  return {
    ...aggAccountPeriodStats,
    periodStart: min,
    periodEnd: max,
  };
};

export const calcPeriodDiff = (
  p1In: CalculatedVolumeAnalysis | null,
  p2In: CalculatedVolumeAnalysis | null
): VolumeAnalysisDatainner => {
  let p1 = p1In;
  let p2 = p2In;

  const defaultValue: VolumeAnalysisDatainner = {
    voucher_count_total: 0,
    voucher_count_per_series: {},
    transaction_count_total: 0,
    transaction_count_per_series: {},
    revenue: 0,
    gross_margin: 0,
    ebit_revenue: 0,
    balance_sheet_total: 0,
    zero_account_transaction_count: 0,
  };

  if (p1 == null || p2 == null) {
    return defaultValue;
  }

  // Switch p1 and p2 if p2 > p1
  if (p1.periodStart < p2.periodStart) {
    const temp = p2;
    p2 = p1;
    p1 = temp;
  }

  return {
    voucher_count_total: asPercentage(
      p1.voucher_count_total,
      p2.voucher_count_total
    ),
    transaction_count_total: asPercentage(
      p1.transaction_count_total,
      p2.transaction_count_total
    ),
    voucher_count_per_series: {},
    transaction_count_per_series: {},
    revenue: asPercentage(p1.revenue, p2.revenue),
    gross_margin: asPercentage(p1.gross_margin, p2.gross_margin),
    ebit_revenue: asPercentage(p1.ebit_revenue, p2.ebit_revenue),
    balance_sheet_total: asPercentage(
      p1.balance_sheet_total,
      p2.balance_sheet_total
    ),
    zero_account_transaction_count: asPercentage(
      p1.zero_account_transaction_count,
      p2.zero_account_transaction_count
    ),
  };
};
