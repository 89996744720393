import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { Picker } from "components/picker";
import { useTranslation } from "hooks";
import { CustomerTypes } from "models/customer";
import { CUSTOMER_TYPE_PRIVATE_PERSON } from "constants/dealConstants";
import { CreateBusinessOpportunityContext } from "views/createNew/businessOpportunity/context/CreateBusinessOpportunityContext";
import { useRoutingForCreateBusinessOpportunity } from "helpers/businessOpportunity/BusinessOpportunityStepperHelper";
import WizardSectionBusinessOpportunity from "views/createNew/businessOpportunity/components/WizardSectionBusinessOpportunity";
import CompanyDetailsModal from "./CompanyDetailsModal";

export default function BusinessOpportunitiesCreateCustomer() {
  const { translate } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const routing = useRoutingForCreateBusinessOpportunity(
    location.pathname,
    true
  );

  const { customerTypeSelected, toggleCustomerType, updateNewDeal } =
    useContext(CreateBusinessOpportunityContext);

  const [selectedCompanyType, setSelectedCompanyType] = useState(
    customerTypeSelected ?? ""
  );

  const [isDetailsModelOpen, setIsDetailsModalOpen] = useState(false);

  const handleOnBeforeNext = async () => {
    if (selectedCompanyType === CustomerTypes.COMPANY) {
      setIsDetailsModalOpen(true);
      return false;
    }
    return true;
  };

  const handleOnSave = () => {
    if (routing.next?.path) {
      navigate(routing.next.path);
    }
    return true;
  };

  const onCustomerTypeToggle = (customerType: string) => {
    const newCustomerType =
      customerType === selectedCompanyType ? "" : customerType;
    let dealCustomerType = "";
    if (newCustomerType) {
      dealCustomerType =
        newCustomerType === CustomerTypes.PRIVATE_PERSON
          ? CUSTOMER_TYPE_PRIVATE_PERSON
          : customerType;
    }
    setSelectedCompanyType(newCustomerType);
    updateNewDeal({
      customer_type: dealCustomerType,
    });
    toggleCustomerType(newCustomerType);
  };

  return (
    <WizardSectionBusinessOpportunity
      isNextDisabled={!selectedCompanyType}
      onBeforeNext={handleOnBeforeNext}
      subtitles={[
        translate("MY_BUSINESS_OPPORTUNITIES.WIZARD.SELECT_COMPANY_TYPE"),
      ]}
      backLabel="MAKE_ANOTHER_SEARCH"
      nextLabel="ADD_CONTACT_DETAILS"
    >
      <Row className="minHeight-100">
        <Col xl={2} lg={2} className="pt-lg m-auto">
          <div className="horizontal-divider " />
        </Col>
        <Col xl={8} lg={8} className="m-auto">
          <div className="d-flex flex-wrap justify-content-center fg-4">
            <Picker
              name={translate("CUSTOMER_TYPE_PERSON")}
              checked={selectedCompanyType === CustomerTypes.PRIVATE_PERSON}
              key={CustomerTypes.PRIVATE_PERSON}
              description={translate(
                "MY_BUSINESS_OPPORTUNITIES.WIZARD.COMPANY_TYPE_PRIVATE_PERSON_DESCRIPTION"
              )}
              className="mx-sm"
              onToggle={() =>
                onCustomerTypeToggle(CustomerTypes.PRIVATE_PERSON)
              }
              toggles={[]}
            />
            <Picker
              name={translate("CUSTOMER_TYPE_COMPANY")}
              checked={selectedCompanyType === CustomerTypes.COMPANY}
              key={CustomerTypes.COMPANY}
              description={translate(
                "MY_BUSINESS_OPPORTUNITIES.WIZARD.COMPANY_TYPE_COMPANY_DESCRIPTION"
              )}
              className="mx-sm"
              onToggle={() => onCustomerTypeToggle(CustomerTypes.COMPANY)}
              toggles={[]}
            />
          </div>
        </Col>
        <Col xl={2} lg={2} className="pt-lg m-auto">
          <div className="horizontal-divider" />
        </Col>
      </Row>
      {isDetailsModelOpen && (
        <CompanyDetailsModal
          isOpen
          onDiscard={() => setIsDetailsModalOpen(false)}
          onCancel={() => setIsDetailsModalOpen(false)}
          onSave={() => handleOnSave()}
        />
      )}
    </WizardSectionBusinessOpportunity>
  );
}
