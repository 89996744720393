import { useTranslation } from "hooks/use-translate";
import { dateLanguageFormatter } from "libs/date/date-format";
import { Customer } from "models/customer";

type Props = {
  activePageObjects: Customer[];
};

export default function PartiallyInvoicedTable({ activePageObjects }: Props) {
  const { translate, language } = useTranslation();
  const dateFormat = dateLanguageFormatter(language, "yyyy-MM-dd");
  // Find a customer with partialy invoiced data so you can apply the data bellow

  return (
    <div className="py-md horizontal-scroll">
      <table>
        <tr className="full-width px-md align-items-center my-sm fw-bold no-border">
          <td>
            <span>{translate("INVOICE")}</span>
          </td>
          <td>
            <span>{translate("DATE")}</span>
          </td>
          <td>
            <span>Suma</span>
          </td>
          <td>
            <span>{translate("TASK")}</span>
          </td>
        </tr>
        {activePageObjects &&
          activePageObjects.map((data: any) => {
            return (
              <tr className="full-width px-md align-items-center my-sm">
                <td>
                  <span>{data.invoicenumber}</span>
                </td>
                <td>
                  {data.entrydate && dateFormat(new Date(data.entrydate))}
                </td>
                <td>
                  <span>{data.sum}</span>
                </td>
                <td>
                  <span>{data.taskname}</span>
                </td>
              </tr>
            );
          })}
      </table>
    </div>
  );
}
