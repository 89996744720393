import { ChevronDown24Regular } from "@fluentui/react-icons";
import { LabelVariant } from "components/label";

import { TranslationKey } from "i18n";
import { Translate } from "i18n/translator";
import {
  CustomerActivitiesServices,
  Recurrence,
  ServiceInstance,
  ServiceTypes,
} from "models/activities/activity";

export const RecurrenceTypes = Object.values(Recurrence);
export const ServiceTypesList = Object.values(ServiceTypes);

export function generateDeadlineOptions(
  translate: Translate<TranslationKey>,
  days: number
) {
  const deadlineOptions = [];
  for (let i = 1; i <= days; i++) {
    deadlineOptions.push({
      value: i.toString(),
      text: `${i} ${translate(
        i > 1 ? "WORKING_DAYS_FROM_NOW" : "WORKING_DAY_FROM_NOW"
      )}`,
    });
  }
  return deadlineOptions;
}

export const getServiceBoxMonth = (serviceBoxId: string) => {
  const monthString = serviceBoxId.split("-").pop() ?? "";
  return Number(monthString.substring(1));
};

export const getMonthName = (month: number): TranslationKey => {
  switch (month) {
    case 2:
      return "FEBRUARY";
    case 3:
      return "MARCH";
    case 4:
      return "APRIL";
    case 5:
      return "MAY";
    case 6:
      return "JUNE";
    case 7:
      return "JULY";
    case 8:
      return "AUGUST";
    case 9:
      return "SEPTEMBER";
    case 10:
      return "OCTOBER";
    case 11:
      return "NOVEMBER";
    case 12:
      return "DECEMBER";
    default:
      return "JANUARY";
  }
};

export interface MonthOrder {
  month: number;
  year: number;
}

export const groupByYearAndMonth = (
  serviceInstances: ServiceInstance[]
): {
  groupedActivities: { [year: number]: { [month: number]: ServiceInstance[] } };
  yearsOrder: { year: number; monthsOrder: MonthOrder[] }[];
} => {
  const groupedActivities: {
    [year: number]: { [month: number]: ServiceInstance[] };
  } = {};
  const yearsOrder: { year: number; monthsOrder: MonthOrder[] }[] = [];

  serviceInstances.forEach((instance) => {
    const { month, year } = instance;

    if (!groupedActivities[year]) {
      groupedActivities[year] = {};
      yearsOrder.push({ year, monthsOrder: [] });
    }

    if (!groupedActivities[year][month]) {
      groupedActivities[year][month] = [];
      const yearEntry = yearsOrder.find((entry) => entry.year === year);
      if (yearEntry) {
        yearEntry.monthsOrder.push({ month, year });
      }
    }

    groupedActivities[year][month].push(instance);
  });

  return { groupedActivities, yearsOrder };
};

export const unfinishedActivitiesLength = (
  unfinishedActivityServices: ServiceInstance[],
  maxServicesToShow: number
) => {
  const { groupedActivities, yearsOrder } = groupByYearAndMonth(
    unfinishedActivityServices
  );

  let displayedActivityGroupsCount = 0;
  let remainingActivitiesCount = 0;

  for (const yearInstance of yearsOrder) {
    const activitiesForYear = groupedActivities[yearInstance.year];

    for (const monthInstance of yearInstance.monthsOrder) {
      if (displayedActivityGroupsCount >= maxServicesToShow) {
        const activitiesForMonth = activitiesForYear[monthInstance.month];
        remainingActivitiesCount += activitiesForMonth.length;
      }

      displayedActivityGroupsCount++;
    }
  }

  return remainingActivitiesCount > 0 ? (
    `+ ${remainingActivitiesCount}`
  ) : (
    <ChevronDown24Regular className="color-redLight" />
  );
};

export const getCustomerServices = (
  customerNumber: string,
  services: CustomerActivitiesServices[]
) => {
  return (
    services.find((service) => service.customer_id === customerNumber)
      ?.services ?? []
  );
};

const isServiceInMonth = (date: Date, { year, month }: ServiceInstance) =>
  year === date.getFullYear() && month === date.getMonth() + 1;

export const getServicesInTimelineWindow = (
  date: Date,
  servicesInTimeWindow: ServiceInstance[]
) => servicesInTimeWindow.filter((service) => isServiceInMonth(date, service));

export const isServiceInTimelineWindow = (
  timelineWindow: Date[],
  service: ServiceInstance
) => timelineWindow.some((yearMonth) => isServiceInMonth(yearMonth, service));

export const isServiceInFutureMonth = (
  timelineWindow: Date[],
  service: ServiceInstance
) => {
  const lastTimelineMonth =
    timelineWindow[timelineWindow.length - 1].getMonth() + 1;
  const lastTimelineYear =
    timelineWindow[timelineWindow.length - 1].getFullYear();

  if (service.year > lastTimelineYear) {
    return true;
  }
  if (service.year === lastTimelineYear) {
    return service.month >= lastTimelineMonth;
  }
  return false;
};

export const frequencyLabelMap: { [index: string]: LabelVariant } = {
  WEEKLY: "green",
  MONTHLY: "purple",
  QUARTERLY: "blue",
  YEARLY: "yellow",
};
