import { useSelector } from "react-redux";

import AppConfig from "app-config";
import { RootState } from "state";
import { useTranslation } from "hooks/use-translate";
import { Deal } from "models/deals/deal";
import { ActiveFilters, Stage } from "models/deals/stage";

type useDealsFiltersProps = {
  activeFilters: ActiveFilters;
  setFilteredDeals: (filteredDEals: Deal[]) => void;
};
type DealFilters = {
  selectedDateRange: (chosenDate: string) => void;
  inboundOutboundDeals: (value: string) => void;
};

export function useDealsFilters({
  activeFilters,
  setFilteredDeals,
}: useDealsFiltersProps) {
  const { translate } = useTranslation();
  const deals = useSelector((state: RootState) => state.sales.data);
  const pipelines = useSelector((state: RootState) => state.sales.pipelines);

  let dealsToShow = [...deals];

  const filteredPipelines = pipelines.data.filter((pipeline) =>
    AppConfig.FEATURES.DEALS.VISIBLE_PIPELINES.includes(pipeline.id)
  );

  const getVisibleStagesSorted = () => {
    let visibleStagesSorted: Stage[] = [];
    filteredPipelines.forEach((pipeline) => {
      visibleStagesSorted = pipeline.stages
        .filter(
          (stage) =>
            stage.configuration && stage.configuration.isHidden === "false"
        )
        .sort((a, b) => a.display_order - b.display_order);
    });
    return visibleStagesSorted;
  };

  const filterByDateOptions = [
    {
      value: "7",
      text: translate("MY_BUSINESS_OPPORTUNITIES.FILTERS.BY_WEEK"),
    },
    {
      value: "30",
      text: translate("MY_BUSINESS_OPPORTUNITIES.FILTERS.BY_MONTH"),
    },
    {
      value: "90",
      text: translate("MY_BUSINESS_OPPORTUNITIES.FILTERS.BY_QUARTER"),
    },
    {
      value: "365",
      text: translate("MY_BUSINESS_OPPORTUNITIES.FILTERS.BY_YEAR"),
    },
  ];

  const filterByInboundOutboundOptions = [
    {
      value: "inbound",
      text: translate("MY_BUSINESS_OPPORTUNITIES.FILTERS.INBOUND"),
    },
    {
      value: "outbound",
      text: translate("MY_BUSINESS_OPPORTUNITIES.FILTERS.OUTBOUND"),
    },
  ];

  const filterByDate = (chosenDate: string) => {
    dealsToShow = dealsToShow.filter((deal) => {
      const dateOffset = parseInt(chosenDate, 10);
      const earliestDate = new Date(
        new Date().setDate(new Date().getDate() - dateOffset)
      );
      earliestDate.setHours(0, 0, 0, 0);
      return (
        new Date(deal.entered_current_stage_date).getTime() >=
        earliestDate.getTime()
      );
    });
  };

  const filterByInboundOutbound = (value: string) => {
    dealsToShow = dealsToShow.filter((deal) => deal.inbound_outbound === value);
  };

  const dealsFilters: DealFilters = {
    selectedDateRange: filterByDate,
    inboundOutboundDeals: filterByInboundOutbound,
  };

  const filterDeals = () => {
    const hasActiveFilters = !Object.values(activeFilters).every(
      (el) => el === undefined
    );
    if (!hasActiveFilters) {
      setFilteredDeals(deals);
    } else {
      for (const [key, value] of Object.entries(activeFilters)) {
        if (value) {
          const filterKey = key as keyof DealFilters;
          dealsFilters[filterKey](value);
        }
      }
      setFilteredDeals(dealsToShow);
      dealsToShow = deals;
    }
  };

  return {
    filterByDateOptions,
    filterByInboundOutboundOptions,
    filteredPipelines,
    getVisibleStagesSorted,
    filterDeals,
  };
}
