import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "hooks/use-translate";
import { CustomerDeal } from "models/offer/CustomerDeal";
import { SigningMethod } from "models/offer/DealContact";
import {
  ServiceCategory,
  getServiceCategoryFromEconomyServiceArea,
} from "models/offer/ServiceLine";
import { appendError } from "state/notifications";
import { copyDeal, fetchDeal, updateOffer } from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import { setSelectedCustomerSource } from "state/offer/offersSlice";
import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";
import ContextualMenu from "components/contextualMenu/ContextualMenu";
import CustomerSourceModal from "views/createNew/offer/wizard/CustomerSource/CustomerSourceModal";
import { OfferRouteHelper } from "views/createNew/offer/routes/offerRoutes";
import { SERVICE_GROUP_CURRENT_ACCOUNTING } from "views/createNew/offer/wizard/CurrentAccounting";

interface ActionsProps {
  deal: CustomerDeal;
  sourceOptions: {
    value: string;
    text: string;
  }[];
  setDeleteOffer?: (shouldDelete: boolean) => void;
}

export function ActionsDropdown({
  deal,
  sourceOptions,
  setDeleteOffer,
}: ActionsProps) {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { service_areas: serviceAreasFromDeal } = deal;

  /**
   * Checks if the deal being loaded has a Current accounting
   * service with a price of 0. If true, it fetches the necessary
   * data and then navigates to the Current accounting screen;
   * otherwise, navigates to the Summary.
   */
  async function loadCurrentDeal() {
    const currentAccountingInDeal =
      getServiceCategoryFromEconomyServiceArea(
        ACCOUNTING_SERVICE_LINE,
        SERVICE_GROUP_CURRENT_ACCOUNTING,
        SERVICE_GROUP_CURRENT_ACCOUNTING,
        serviceAreasFromDeal
      ) ?? new ServiceCategory();

    if (currentAccountingInDeal.final_price === 0) {
      if (!deal.customer || !deal.id) {
        dispatch(
          appendError("OFFER_LINK_COPY_FAILED", {
            message: "Offer id or customer number missing",
          } as Error)
        );
        return;
      }

      await dispatch(
        fetchDeal({ orgId: deal.customer.org_number, dealId: deal.id })
      );
      navigate(OfferRouteHelper.getServicesCurrentAccounting());
      return;
    }

    navigate(
      `${OfferRouteHelper.getSummary()}/${deal.customer?.org_number}/offer/${
        deal.id
      }`
    );
  }

  const actionOptions = [
    {
      text: translate("DUPLICATE"),
      onClick: () => executeAction("copy"),
    },
  ];

  if (deal.state === "offer") {
    actionOptions.unshift({
      text: translate("DELETE"),
      onClick: () => executeAction("delete"),
    });

    actionOptions.unshift({
      text: translate("EDIT"),
      onClick: () => executeAction("edit"),
    });
  } else if (deal.state === "contract") {
    const isSigningMethodPhysical = deal.contacts.some(
      (contact) => contact.signing_method === SigningMethod.PHYSICAL
    );

    if (isSigningMethodPhysical) {
      actionOptions.unshift({
        text: translate("GOTO_PHYSICAL_METHOD_COMPLETION"),
        onClick: () => executeAction("completion"),
      });
    }
  }

  const copyExistingDeal = async () => {
    if (deal.id) {
      const copiedDeal = await dispatch(copyDeal(deal.id))
        .unwrap()
        .catch((e) => {
          dispatch(
            appendError("FAILED_TO_COPY_DEAL", {
              cause: e,
            } as unknown as Error)
          );
        });

      if (copiedDeal) {
        dispatch(setSelectedCustomerSource(copiedDeal.deal_source as string));
        navigate(OfferRouteHelper.getSummary());
      }
    }
  };

  async function executeAction(
    action: "edit" | "copy" | "completion" | "delete"
  ) {
    if (action === "edit") {
      loadCurrentDeal();
    }

    if (action === "copy") {
      if (!deal.deal_source || deal.deal_source === "") {
        setIsSourceModalOpen(true);
      } else {
        await copyExistingDeal();
      }
    }

    if (action === "completion") {
      navigate(
        OfferRouteHelper.getExistingManualSignKyc(
          deal.customer?.org_number,
          deal.id
        )
      );
    }

    if (action === "delete") {
      setDeleteOffer?.(true);
    }
  }

  const handleModalConfirm = async (dealSource: string) => {
    const orgNumber = deal.customer?.org_number;
    const dealId = deal.id;
    if (orgNumber && dealId) {
      setIsLoading(true);
      const payload = { deal_source: dealSource };
      const response = await dispatch(
        updateOffer({ orgNumber, dealId, payload })
      );
      setIsLoading(false);
      setIsSourceModalOpen(false);
      if (response.meta.requestStatus === "fulfilled") {
        await copyExistingDeal();
      }
    }
  };

  return (
    <>
      <ContextualMenu menuItems={actionOptions} />
      {isSourceModalOpen && (
        <CustomerSourceModal
          sourceOptions={sourceOptions}
          handleCancel={() => setIsSourceModalOpen(false)}
          labelCancel={translate("CANCEL")}
          handleConfirm={handleModalConfirm}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
